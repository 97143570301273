import { Masonry } from '@mui/lab';
import { FormControl, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FieldState } from 'formstate';
import { observer } from 'mobx-react';
import React from 'react';
import { TenantCorporateContext } from '..';
import { Spinner } from '../../components/spinner';
import { CorporateFeatureDto } from '../../core/api/CorporateClient';
import { FeatureType, SubFeatureType, useStore } from './store';

function checkIfAppFeature(feature: string) {
    return ['STRAVA', 'GARMIN', 'FITBIT', 'POLAR'].includes(feature);
}

const TenantFeatures = observer(() => {
    const corporateClient = React.useContext(TenantCorporateContext);
    const {
        form,
        corporateFeatures,
        enableFeatureRequest,
        disableFeatureRequest,
        enableFitnessApps
    } = useStore(corporateClient);
    const [loadingFeature, setLoadingFeature] = React.useState<boolean>(false);

    async function handleFeatureToggle(
        field: FieldState<boolean>,
        feature: CorporateFeatureDto['feature']
    ) {
        setLoadingFeature(true);

        if (field.value) {
            await disableFeatureRequest.request([feature]);
        } else {
            if (checkIfAppFeature(feature) && field) {
                await enableFitnessApps();
            }
            await enableFeatureRequest.request([feature]);
        }
        field.onChange(!field.value);
        setLoadingFeature(false);
        return;
    }

    return (
        <Box>
            {corporateFeatures.match({
                loading: () => (
                    <Box display="flex" justifyContent="center">
                        <Spinner />
                    </Box>
                ),
                fetched: () => (
                    <FormControl
                        component="fieldset"
                        variant="standard"
                        sx={{ width: '100%' }}
                    >
                        <Masonry columns={3} spacing={2}>
                            {form.current?.$.map((group) => {
                                const {
                                    $: { name, id, features }
                                } = group;
                                return (
                                    <Box key={id.value} pb={2}>
                                        <Typography
                                            sx={{ textTransform: 'uppercase' }}
                                        >
                                            {name.value}
                                        </Typography>

                                        {features.$.map((feature) => (
                                            <Box key={feature.$.id.value}>
                                                <FeatureToggle
                                                    feature={feature}
                                                    level={0}
                                                    loading={loadingFeature}
                                                    handleFeatureToggle={
                                                        handleFeatureToggle
                                                    }
                                                />
                                                {feature.$.subFeatures.$.map(
                                                    (subFeature) => (
                                                        <FeatureToggle
                                                            key={
                                                                subFeature.$.id
                                                                    .value
                                                            }
                                                            feature={subFeature}
                                                            level={1}
                                                            loading={
                                                                loadingFeature
                                                            }
                                                            handleFeatureToggle={
                                                                handleFeatureToggle
                                                            }
                                                        />
                                                    )
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                );
                            }) || <></>}
                        </Masonry>
                    </FormControl>
                )
            })}
        </Box>
    );
});

interface FeatureToggleProps {
    feature: SubFeatureType | FeatureType;
    level: 0 | 1;
    handleFeatureToggle(
        field: FieldState<boolean>,
        feature: CorporateFeatureDto['feature']
    ): void;
    loading: boolean;
}
const FeatureToggle = ({
    feature,
    loading,
    level,
    handleFeatureToggle
}: FeatureToggleProps) => (
    <FormGroup>
        <FormControlLabel
            control={
                <Switch
                    checked={feature.$.enabled.value}
                    onChange={(__) => {
                        handleFeatureToggle(
                            feature.$.enabled,
                            feature.$.id.value
                        );
                    }}
                    name={feature.$.id.value}
                    disabled={loading}
                />
            }
            labelPlacement="start"
            label={feature.$.name.value}
            sx={{
                justifyContent: 'space-between',
                paddingLeft: level + 2,
                margin: 0
            }}
        />
    </FormGroup>
);

export default TenantFeatures;
