import { Accordion, AccordionDetails, AccordionSummary, Box, Tab } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AddSteps } from './add-steps';
import { AddWorkout } from './add-workout';
import { AddFacilityVisit } from './add-facility-visit';

export const AddActivity = () => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [selectedTab, setSelectedTab] = React.useState<string>('1');

    return (
        <Accordion
            expanded={expanded}
            onChange={(_, isExpanded) => setExpanded(isExpanded)}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Add activity
            </AccordionSummary>
            <AccordionDetails>
                <TabContext value={selectedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(_, value) => setSelectedTab(value)} aria-label='lab API tabs example'>
                            <Tab label='Steps' value='1' />
                            <Tab label='Workout' value='2' />
                            <Tab label='Facility visit' value='3' />
                        </TabList>
                    </Box>
                    <TabPanel value='1'>
                        <AddSteps />
                    </TabPanel>
                    <TabPanel value='2'>
                        <AddWorkout />
                    </TabPanel>
                    <TabPanel value='3'>
                    <AddFacilityVisit/>
                </TabPanel>
                </TabContext>
            </AccordionDetails>
        </Accordion>
    );
};
