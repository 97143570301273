import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack
} from '@mui/material';
import React from 'react';
import { Button } from '../button';

interface ConfirmationModalProps {
    open?: boolean;
    title?: string;
    children: React.ReactNode;
    buttonText?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submitHandler(): Promise<false | any>;
    onModalClose(): void;
}

export const ConfirmationModal = ({
    open: initialOpen = false,
    children,
    title,
    buttonText = 'Save',
    onModalClose,
    submitHandler
}: ConfirmationModalProps) => {
    const [open, setOpen] = React.useState(initialOpen);

    const handleClose = () => {
        setOpen(false);
        onModalClose?.();
    };
    React.useEffect(() => {
        setOpen(initialOpen);
    }, [initialOpen]);

    const handleSave = async () => {
        const submit = await submitHandler();

        if (submit != false) {
            onModalClose();
        }
    };
    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth="sm">
                {title && <DialogTitle>{title}</DialogTitle>}
                <DialogContent>{children}</DialogContent>
                <DialogActions>
                    <Stack direction="row" spacing={2} ml={2} mr={2}>
                        <Button onClick={handleClose} variant="text">
                            Cancel
                        </Button>
                        <Button onClick={handleSave}>{buttonText}</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </div>
    );
};
