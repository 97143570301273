/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Error {
    /** Errors */
    errors?: object;
    /** Error code */
    code?: number;
    /** Error name */
    status?: string;
    /** Error message */
    message?: string;
}

export interface ChartDataPoint {
    key: string;
    value: number;
    percentage?: number;
}

export interface ChartSeries {
    kpi: string;
    name?: string;
    data: ChartDataPoint[];
}

export interface Chart {
    series: ChartSeries[];
    total: ChartDataPoint[];
}

export interface ChartData {
    chart: Chart;
}

export interface BaseMetadataDto {
    row_count: number;
    last_updated: string | null;
}

export interface ChartResponse {
    data: ChartData;
    metadata: BaseMetadataDto;
}

export interface TableEntry {
    value: number;
    percentage?: number;
    key: string;
}

export interface TableCell {
    value: number;
    percentage?: number;
}

export interface TableAggregations {
    sum?: TableCell;
    average?: TableCell;
}

export interface TableRow {
    kpi: string;
    level: number;
    name?: string;
    entries: TableEntry[];
    aggregations?: TableAggregations;
}

export interface TableResponse {
    data: TableRow[];
    metadata: BaseMetadataDto;
}

export interface RewardsChartDataPoint {
    key: string;
    value: number;
    percentage?: number;
    currency_symbol: string;
}

export interface RewardsChartSeries {
    kpi: string;
    level: number;
    name?: string;
    data: RewardsChartDataPoint[];
}

export interface RewardsChart {
    series: RewardsChartSeries[];
    total: RewardsChartDataPoint[];
}

export interface RewardsChartData {
    chart: RewardsChart;
}

export interface RewardsChartResponse {
    data: RewardsChartData;
    metadata: BaseMetadataDto;
}

export interface RewardsTableEntry {
    value: number;
    percentage?: number;
    key: string;
    currency_symbol: string;
}

export interface RewardsTableCell {
    value: number;
    percentage?: number;
    currency_symbol: string;
}

export interface RewardsTableAggregations {
    sum?: RewardsTableCell;
}

export interface RewardsTableRow {
    kpi: string;
    level?: number;
    name?: string;
    entries: RewardsTableEntry[];
    aggregations?: RewardsTableAggregations;
}

export interface RewardsTableResponse {
    data: RewardsTableRow[];
    metadata: BaseMetadataDto;
}

export interface ActivityCountData {
    tenant_name: string;
    count: number;
}

export interface ActivityCountResponse {
    data: ActivityCountData[];
    metadata: BaseMetadataDto;
}

export interface LiveDataIncrement {
    since: string;
    value: number;
}

export interface LiveDataChart {
    value: number;
    key: string;
}

export interface LiveDataChartEntry {
    data?: LiveDataChart[];
}

export interface LiveData {
    kpi: string;
    value: number;
    increment?: LiveDataIncrement;
    chart?: LiveDataChartEntry;
    percentage?: number;
    currency?: string;
}

export interface LiveDataSchemaResponse {
    data: LiveData[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<
    FullRequestParams,
    'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
    extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '/';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
        fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(
            (key) => 'undefined' !== typeof query[key]
        );
        return keys
            .map((key) =>
                Array.isArray(query[key])
                    ? this.addArrayQueryParam(query, key)
                    : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null &&
            (typeof input === 'object' || typeof input === 'string')
                ? JSON.stringify(input)
                : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string'
                ? JSON.stringify(input)
                : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                          ? JSON.stringify(property)
                          : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
    };

    protected mergeRequestParams(
        params1: RequestParams,
        params2?: RequestParams
    ): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected createAbortSignal = (
        cancelToken: CancelToken
    ): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean'
                ? secure
                : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter =
            this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(
            `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
            {
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData
                        ? { 'Content-Type': type }
                        : {})
                },
                signal:
                    (cancelToken
                        ? this.createAbortSignal(cancelToken)
                        : requestParams.signal) || null,
                body:
                    typeof body === 'undefined' || body === null
                        ? null
                        : payloadFormatter(body)
            }
        ).then(async (response) => {
            const r = response.clone() as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then((data) => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch((e) => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title MySports Analytics API
 * @version 1.0
 * @baseUrl /
 */
export class Api<
    SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
    metrics = {
        /**
         * No description
         *
         * @tags api-metrics
         * @name StatusList
         * @request GET:/metrics/status
         * @secure
         */
        statusList: (params: RequestParams = {}) =>
            this.request<any, Error>({
                path: `/metrics/status`,
                method: 'GET',
                secure: true,
                ...params
            })
    };
    corporate = {
        /**
         * No description
         *
         * @tags Corporate Program performance - participation
         * @name CorporateProgramPerformanceGetParticipationChart
         * @request GET:/corporate/program/participation/chart
         * @secure
         */
        corporateProgramPerformanceGetParticipationChart: (
            query: {
                tenant: string;
                year_month_from: string;
                year_month_to: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<ChartResponse, Error>({
                path: `/corporate/program/participation/chart`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Corporate Program performance - participation
         * @name CorporateProgramPerformanceGetParticipationTable
         * @request GET:/corporate/program/participation/table
         * @secure
         */
        corporateProgramPerformanceGetParticipationTable: (
            query: {
                tenant: string;
                year_month_from: string;
                year_month_to: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<TableResponse, Error>({
                path: `/corporate/program/participation/table`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Corporate Program performance - participation
         * @name CorporateProgramPerformanceGetReport
         * @request GET:/corporate/program/participation/report
         * @secure
         */
        corporateProgramPerformanceGetReport: (
            query: {
                year_month: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<any, Error>({
                path: `/corporate/program/participation/report`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags Corporate Program performance - rewards
         * @name CorporateProgramPerformanceGetRewardsChart
         * @request GET:/corporate/program/rewards/chart
         * @secure
         */
        corporateProgramPerformanceGetRewardsChart: (
            query: {
                tenant: string;
                year_month_from: string;
                year_month_to: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<RewardsChartResponse, Error>({
                path: `/corporate/program/rewards/chart`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags Corporate Program performance - rewards
         * @name CorporateProgramPerformanceGetRewardsTable
         * @request GET:/corporate/program/rewards/table
         * @secure
         */
        corporateProgramPerformanceGetRewardsTable: (
            query: {
                tenant: string;
                year_month_from: string;
                year_month_to: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<RewardsTableResponse, Error>({
                path: `/corporate/program/rewards/table`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags activity_count_report
         * @name ActivityGetCount
         * @request GET:/corporate/activity/count
         * @secure
         */
        activityGetCount: (params: RequestParams = {}) =>
            this.request<ActivityCountResponse, Error>({
                path: `/corporate/activity/count`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags live-data-report
         * @name LiveDataGetEmployeesData
         * @request GET:/corporate/live-data/employees
         * @secure
         */
        liveDataGetEmployeesData: (
            query: {
                tenant: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<LiveDataSchemaResponse, Error>({
                path: `/corporate/live-data/employees`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags live-data-report
         * @name LiveDataGetActivitiesData
         * @request GET:/corporate/live-data/activities
         * @secure
         */
        liveDataGetActivitiesData: (
            query: {
                tenant: string;
                year_month_from: string;
                year_month_to: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<LiveDataSchemaResponse, Error>({
                path: `/corporate/live-data/activities`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags live-data-report
         * @name LiveDataGetRewardsData
         * @request GET:/corporate/live-data/rewards
         * @secure
         */
        liveDataGetRewardsData: (
            query: {
                tenant: string;
                year_month_from: string;
                year_month_to: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<LiveDataSchemaResponse, Error>({
                path: `/corporate/live-data/rewards`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params
            })
    };
}
