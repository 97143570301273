import { Alert, Grid, IconButton, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Box } from '@mui/system';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { debounce } from 'lodash';
import { getTenantSourceName } from '../../tenants';
import { Spinner } from '../../components/spinner';
import { Button } from '../../components/button';
import { Label } from '../../components/label';
import { Select } from '../../components/select';
import { TenantDto, TenantSimpleDto } from '../../core/api/TenantClient';
import { ConfirmationModal } from '../../components/confirmation-modal';
import { EmptyTextField } from '../../components/textfield';
import { DeleteModal } from './delete-modal';
import { useStore } from './store';
import { TenantActivateModal } from '../../tenants/modals/tenant-activate-modal';
import { TenantDeactivateModal } from '../../tenants/modals/tenant-deactivate-modal';

export const TenantDetail = observer(() => {
    const {
        googleUserAuth,
        tenantTypes,
        updateTenantType,
        corporateFeatures,
        corporateClient,
        tenantName,
        fields,
        updateTenant
    } = useStore();
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [tenantTypeConfirmOpen, setTenantTypeConfirmOpen] =
        React.useState(false);
    const [tenantTypeChange, setTenantTypeChange] = React.useState<
        TenantDto['type'] | undefined
    >();

    const openDeactivateState = React.useState(false);
    const [, setDeactivateModalOpen] = openDeactivateState;

    const openActivateState = React.useState(false);
    const [, setActivateModalOpen] = openActivateState;

    const handleLogin = async () => {
        const session =
            await corporateClient.support.corporateSupportControllerCreateSession(
                {
                    email: googleUserAuth?.email,
                    firstName: googleUserAuth?.firstName,
                    lastName: googleUserAuth?.lastName
                }
            );
        if (session.data.redirectUrl) {
            window.open(session.data.redirectUrl, '_blank');
        }
    };
    const handleTenantChange = async (type: TenantSimpleDto['type']) => {
        setTenantTypeChange(type);
        setTenantTypeConfirmOpen(true);
    };
    const handleTenantUpdate = async () => {
        const tenantType = await updateTenantType.request([tenantTypeChange]);
        await corporateFeatures.request([tenantName]);
        return tenantType;
    };

    const updateTenantCallback = React.useCallback(
        debounce((data: typeof fields) => {
            updateTenant.request([data]);
        }, 300),
        [updateTenant]
    );

    const openLexoffice = React.useCallback(
        () => window.open(fields.lexofficeLink.value, '_blank')?.focus(),
        []
    );
    const openHubspot = React.useCallback(
        () => window.open(fields.hubspotLink.value, '_blank')?.focus(),
        []
    );

    return (
        <Box>
            {corporateFeatures.match({
                loading: () => (
                    <Box display="flex" justifyContent="center">
                        <Spinner />
                    </Box>
                ),
                fetched: ({ company, tenant }) => (
                    <Grid container spacing={2}>
                        <TenantDeactivateModal
                            openState={openDeactivateState}
                            tenant={tenant}
                            onSuccess={() =>
                                void corporateFeatures.request([tenantName])
                            }
                        />
                        <TenantActivateModal
                            openState={openActivateState}
                            tenant={tenant}
                            onSuccess={() =>
                                corporateFeatures.request([tenantName])
                            }
                        />
                        <Grid item xs={6} >
                            <Label>Company Name</Label>
                            <Typography mt={1}>{company.name}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Box mt={1} sx={{ textAlign: 'right' }}>
                                <Button onClick={() => void handleLogin()}>
                                    <Box component="span" mr={1}>
                                        Login
                                    </Box>
                                    <ExitToAppIcon fontSize="small" />
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Label>Type</Label>
                            <Select
                                label="Tenant Type"
                                value={tenant.type}
                                onChange={(val) => {
                                    handleTenantChange(val);
                                }}
                                options={tenantTypes.$ || []}
                                noLabel
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Label>Source</Label>
                            <Typography mt={2}>
                                {getTenantSourceName(tenant.source)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Label>Participants</Label>
                            <Typography mt={2}>{company.employeeCount}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Label>
                                Hubspot link
                                {fields.hubspotLink.value && (
                                    <IconButton
                                        size="small"
                                        onClick={openHubspot}
                                    >
                                        <ExitToAppIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </Label>

                            <EmptyTextField
                                type="text"
                                value={fields.hubspotLink.value}
                                onChange={(val: string) => {
                                    fields.hubspotLink.onChange(val);
                                    updateTenantCallback(fields);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Label>
                                Lexoffice link
                                {fields.lexofficeLink.value && (
                                    <IconButton
                                        size="small"
                                        onClick={openLexoffice}
                                    >
                                        <ExitToAppIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </Label>
                            <EmptyTextField
                                type="text"
                                value={fields.lexofficeLink.value}
                                onChange={(val: string) => {
                                    fields.lexofficeLink.onChange(val);
                                    updateTenantCallback(fields);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Label>Address</Label>
                            <Box
                                p={2}
                                mt={1}
                                sx={{
                                    border: 1,
                                    borderColor: 'text.disabled'
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Label>Street</Label>
                                        <Typography>
                                            {company?.address?.street}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Label>Number</Label>
                                        <Typography>
                                            {company?.address?.houseNumber}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Label>Postal code</Label>
                                        <Typography>
                                            {company?.address?.zipCode}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Label>City</Label>
                                        <Typography>
                                            {company?.address?.city}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}></Grid>
                                    <Grid item xs={6}>
                                        <Label>Country</Label>
                                        <Typography>
                                            {company?.address?.country}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                spacing={2}
                            >
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() =>
                                        void setDeleteModalOpen(true)
                                    }
                                >
                                    Delete
                                </Button>
                                {tenant.status === 'ACTIVE' && (
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() =>
                                            void setDeactivateModalOpen(true)
                                        }
                                    >
                                        Deactivate
                                    </Button>
                                )}
                                {tenant.status === 'INACTIVE' && (
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={() =>
                                            void setActivateModalOpen(true)
                                        }
                                    >
                                        Activate
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                        <DeleteModal
                            open={deleteModalOpen}
                            onModalClose={() => void setDeleteModalOpen(false)}
                        />
                        <ConfirmationModal
                            open={tenantTypeConfirmOpen}
                            submitHandler={handleTenantUpdate}
                            onModalClose={() =>
                                void setTenantTypeConfirmOpen(false)
                            }
                        >
                            <Typography variant="h6" gutterBottom>
                                Are you sure you want to change the tenant type?
                            </Typography>
                            <Alert severity="warning">
                                This process causes severe changes to the
                                product
                            </Alert>
                        </ConfirmationModal>
                    </Grid>
                )
            })}
        </Box>
    );
});

export default TenantDetail;
