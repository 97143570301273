import { useRef } from 'react';
// tslint:disable-next-line import-blacklist
import { Constructable, external, IComponentOptions, inject, TSDI } from 'tsdi';

@external
class Injector {
    // this is the only place in the app
    // where we have to allow the import of `inject`
    @inject
    private tsdi!: TSDI;

    public get<T>(Dependency: Constructable<T>, hint?: string): T {
        if (hint) {
            return this.tsdi.get(Dependency, hint);
        } else {
            return this.tsdi.get(Dependency);
        }
    }
}

function getComponentOptions<T>(
    Dependency: Constructable<T>
): IComponentOptions {
    const componentOptions: IComponentOptions | undefined =
        typeof Dependency !== 'string' &&
        Reflect.getMetadata('component:options', Dependency);

    return componentOptions || {};
}

export const injectTSDI = (() => {
    let injector: Injector | undefined;

    function ensureInjector(): Injector {
        if (!injector) {
            injector = new Injector();
        }
        return injector;
    }

    const fn = <T>(Dependency: Constructable<T>, hint?: string): T =>
        ensureInjector().get(Dependency, hint);

    fn.reset = () => {
        injector = undefined;
    };

    return fn;
})();

export function useTSDI<T>(Dependency: Constructable<T>, hint?: string): T {
    const dep = new Injector().get(Dependency, hint);
    const depState = useRef(dep);

    const componentOptions = getComponentOptions(Dependency);

    if (componentOptions.singleton === false) {
        return depState.current;
    }

    return dep;
}
