import { CircularProgress, Stack } from '@mui/material';
import React from 'react';
import { Box } from '@mui/system';
import { useWrapRequest } from 'use-wrap-request';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useSearchReducer } from '../../../components/hooks/useSearchReducer';
import useInfiniteScroll from '../../../components/hooks/use-scroll';
import { EmployeeContext } from '../index';
import { injectTSDI } from '../../../core/tsdi';
import { Api } from '../../../core/api';
import { ActivityPointsDto } from '../../../core/api/FitnessActivityClient';
import { ActivityPoints } from './activity-points';

const StyledList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const EmployeeActivityPoints = observer(() => {
    const employee = React.useContext(EmployeeContext);
    const { fitnessActivityClient } = injectTSDI(Api);
    const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
        size: 30,
        page: 0,
        sort: '-timestamp'
    });

    const [activityPoints, setActivityPoints] = React.useState<
        ActivityPointsDto[]
    >([]);

    const activityPointRequest = useWrapRequest(
        async (
            searchParams: QueryParamsDto,
            activities: ActivityPointsDto[]
        ) => {
            const result =
                await fitnessActivityClient.admin.activitySupportControllerGetActivityPoints(
                    { userId: employee.mySportsId!, ...searchParams }
                );
            setActivityPoints([...activities, ...result.data.content]);

            return { ...result.data, content: result.data.content || [] };
        },
        {}
    );

    const page = useInfiniteScroll({
        loading: activityPointRequest.loading,
        distance: 200
    });

    React.useEffect(() => {
        if (
            page > 0 &&
            activityPointRequest.$.totalPages &&
            activityPointRequest.$.totalPages > page
        ) {
            dispatch({ type: 'page', page });
        }
    }, [page]);

    React.useEffect(() => {
        activityPointRequest.request([searchParams, activityPoints]);
    }, [searchParams]);

    return (
        <>
            <Box>
                <StyledList>
                    {activityPoints.map((activityPoints) => (
                        <ActivityPoints
                            key={activityPoints.id}
                            activityPoints={activityPoints}
                        />
                    ))}
                </StyledList>
                {activityPointRequest?.loading && (
                    <Stack direction="row" justifyContent="center">
                        <CircularProgress disableShrink />
                    </Stack>
                )}
            </Box>
        </>
    );
});

export default EmployeeActivityPoints;
