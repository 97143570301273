import { SortingRule } from 'react-table';

export interface ActionDto {
    type: keyof QueryParamsDto;
    payload: SortingRule<object> | SortingRule<object>[] | string | number;
}

export type Action =
    | { type: 'sort'; sort: SortingRule<object> | SortingRule<object>[] }
    | { type: 'size'; size: number }
    | { type: 'search'; search: string }
    | { type: 'page'; page: number }
    | { type: 'reload' }
    | { type: 'reset' };

export function useSearchReducer(
    state: QueryParamsDto,
    action: Action
): QueryParamsDto {
    switch (action.type) {
        case 'page':
            return { ...state, page: action.page };
        case 'search':
            return { ...state, page: 0, search: action.search };
        case 'size':
            return {
                ...state,
                page: 0,
                size: action.size !== 0 ? action.size : 1000
            };
        case 'sort':
            if (Array.isArray(action.sort)) {
                return {
                    ...state,
                    sort: convertSortingArrayToString(action.sort)
                };
            }
            const { id, desc } = action.sort;
            return {
                ...state,
                sort: desc ? `+${id}` : `-${id}`
            };

        case 'reset':
            return { size: state.size, page: 0 };
        default:
            return { ...state };
    }
}

function convertSortingArrayToString(sorting: SortingRule<object>[]) {
    return sorting
        .reduce(
            (acc: string[], val) => [
                ...acc,
                val.desc ? `+${val.id}` : `-${val.id}`
            ],
            []
        )
        .join(',');
}
