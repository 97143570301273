import { Action } from './useSearchReducer';
import React from 'react';
import { useDebounce } from '../utils/use-debounce';

export function userSearchDebounce(dispatch: React.Dispatch<Action>) {
    const [searchTerm, setSearchTerm] = React.useState<string | undefined>(
        undefined
    );

    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const initialLoad = React.useRef(true);

    React.useEffect(() => {
        if (!initialLoad.current) {
            dispatch({ type: 'search', search: debouncedSearchTerm || '' });
        }
        initialLoad.current = false;
    }, [debouncedSearchTerm]);

    return { setSearchTerm };
}
