import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

import {
    AppBar,
    Box,
    Button,
    Container,
    InputLabel,
    lighten,
    MenuItem,
    Select,
    SelectChangeEvent,
    Toolbar,
    Typography
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { green } from '@mui/material/colors';
import { TimelineDot } from '@mui/lab';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Input } from '../../../components/Input';
import { links } from '../../../core/router';
import { injectTSDI } from '../../../core/tsdi';
import {
    CountryOptionDto,
    CustomFacilityDetailsDto,
    SelectEntryDto
} from '../../../core/api/StudioClient';
import { FormField } from '../../../core/forms';
import { drawerWidth } from '../../../app/nav';
import { InitialData } from '../../../core/initial-data';
import { AdditionalData } from '../additional-data';
import { NearbyFacilities } from '../nearby-facilities';
import { FacilityEditStore } from '../store';
import { useStore } from './store';

type FieldStatus = 'same' | 'changes' | 'empty' | 'transparent' | 'error';
type InputType = 'text' | 'number' | 'select';

interface StyledSectionFieldProps {
    separator?: boolean;
    userInput?: boolean;
    status?: FieldStatus;
    main?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface SectionItemProps<T extends any = any> {
    separator?: boolean;
    label?: string;
    noTimeline?: boolean;
    empty?: boolean;
    fields?: {
        internal: FormField<T | undefined>;
        external: FormField<T | undefined> | null;
    };
    options?: SelectEntryDto[];
    type?: InputType;
    required?: boolean;
    disabled?: boolean;
    action?: () => JSX.Element;
    title1?: () => JSX.Element;
    title2?: () => JSX.Element;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface SectionInputProps<T extends any = any> {
    type?: InputType;
    field?: FormField<T | undefined>;
    options?: SelectEntryDto[];
    disabled?: boolean;
}

interface ActionsBar {
    facility: CustomFacilityDetailsDto;

    onSave(): void;

    onReject(): void;
}

const StyledToolbar = styled(Toolbar)`
    justify-content: space-between;
    background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const StyledSectionLabel = styled.div`
    flex: 0 0 150px;
`;

function getFieldBackground(theme: DefaultTheme, status?: FieldStatus) {
    switch (status) {
        case 'changes':
            return css`
                background-color: ${lighten(theme.palette.error.dark, 0.1)};
            `;
        case 'error':
            return css`
                background-color: ${lighten(theme.palette.error.dark, 0.1)};
            `;
        case 'transparent':
            return;
        case 'empty':
            return css`
                background-color: ${theme.palette.grey[700]};
            `;

        case 'same':
        default:
            return css`
                background-color: ${theme.palette.grey[500]};
            `;
    }
}

function getStatusIcon(status?: FieldStatus) {
    switch (status) {
        case 'changes':
            return <PriorityHighIcon fontSize="small" color="error" />;
        case 'same':
            return <CheckIcon fontSize="small" style={{ color: green[500] }} />;
        default:
            return null;
    }
}

export const StyledSectionField = styled.div<StyledSectionFieldProps>`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;

    padding: 7px 15px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
    opacity: ${({ main }) => (main ? 1 : 0.7)};

    ${({ status, theme, separator }) =>
        getFieldBackground(theme, separator ? 'transparent' : status)}
`;

const StyledTimelineDot = styled(TimelineDot)`
    z-index: 2;
    height: 10px;
    width: 10px;
`;

const StyledTimelineItem = styled.div<{ separator?: boolean; empty?: boolean }>`
    display: flex;
    position: relative;
    flex: 0 0 100px;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    padding: 0 5px;

    ${({ separator, empty }) =>
        css`
            ${!separator &&
            css`
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: ${({ theme }) => theme.palette.grey[300]};
                }
            `}
            ${!empty &&
            css`
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 1px;
                    height: 100%;
                    background-color: ${({ theme }) => theme.palette.grey[300]};
                }
            `}
        `}
    ${StyledTimelineDot} {
        align-self: center;
        border-width: 1px;
    }
`;

const StyledSectionItem = styled.div<{ separator?: boolean }>`
    display: flex;
    align-items: center;
    max-height: 47px;
    ${({ separator }) =>
        separator &&
        css`
            min-height: 30px;
        `};
`;

const StyledSection = styled.div<{ separator?: boolean }>`
    ${({ separator }) =>
        separator
            ? css`
                  min-height: 100px;
              `
            : css`
                  ${StyledSectionItem} {
                      &:first-child {
                          ${StyledSectionField} {
                              border-top-left-radius: 4px;
                              border-top-right-radius: 4px;
                          }
                      }

                      &:last-child {
                          ${StyledSectionField} {
                              border-bottom-left-radius: 4px;
                              border-bottom-right-radius: 4px;
                              border-bottom: none;
                          }
                      }
                  }
              `}
`;

const StyledSelect = styled(Select)`
    width: 100%;

    &:before {
        display: none;
    }

    .MuiSelect-select {
        padding-left: 0;
    }

    .MuiInput-input {
        &:focus {
            background-color: transparent;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
`;

function getFieldStatus(same: boolean, empty?: boolean): FieldStatus {
    if (empty) {
        return 'transparent';
    } else if (!same) {
        return 'changes';
    }

    return 'same';
}

const SectionInput = observer(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T extends any>({
        type,
        field,
        options = [],
        disabled
    }: SectionInputProps<T>) => {
        if (type === 'select') {
            return (
                <StyledSelect
                    value={field?.value}
                    disabled={disabled}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={(event: SelectChangeEvent<unknown>) => {
                        field?.onChange(event.target.value as T);
                    }}
                >
                    {options.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </StyledSelect>
            );
        }

        return (
            <Input
                value={field?.value || ''}
                edit="true"
                type={type}
                onChange={(event) => {
                    field?.onChange(event.target.value as T);
                }}
                disabled={disabled}
            />
        );
    }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SectionItem = observer(
    <T extends any>({
        label,
        separator,
        title1: Title1,
        title2: Title2,
        fields,
        noTimeline,
        type,
        empty,
        options,
        required,
        disabled,
        action
    }: SectionItemProps<T>) => {
        const sameFields =
            `${fields?.external?.value}`.trimEnd() ===
            `${fields?.internal?.value}`.trimEnd();

        const fieldStatus = fields?.external?.value
            ? getFieldStatus(sameFields, separator || empty)
            : 'empty';

        const Action = React.useMemo(() => action, []);

        return (
            <>
                <StyledSectionItem separator={separator}>
                    <StyledSectionLabel>
                        <InputLabel
                            htmlFor="my-input"
                            shrink
                            required={required}
                            error={Boolean(fields?.internal.error)}
                        >
                            {label}
                        </InputLabel>
                    </StyledSectionLabel>
                    <StyledSectionField
                        separator={separator}
                        userInput
                        main
                        status={fields?.internal.error ? 'error' : undefined}
                    >
                        {!separator && (
                            <SectionInput
                                field={fields?.internal}
                                type={type}
                                options={options}
                                disabled={disabled}
                            />
                        )}
                        {Title1 && <Title1 />}
                        {Action && <Action />}
                        {!separator && getStatusIcon(fieldStatus)}
                    </StyledSectionField>
                    <StyledTimelineItem
                        separator={separator}
                        empty={noTimeline}
                    />
                    <StyledSectionField
                        separator={separator}
                        status={fieldStatus}
                    >
                        {!separator && (
                            <Input
                                defaultValue={fields?.external?.value}
                                disabled
                            />
                        )}
                        {Title2 && <Title2 />}
                    </StyledSectionField>
                </StyledSectionItem>
            </>
        );
    }
);

const FacilityDetailsForm = observer(() => {
    const { studioCategories, availableCountries } = injectTSDI(InitialData);
    const { facility } = injectTSDI(FacilityEditStore);

    return (
        <div>
            {facility.match({
                fetched: (facility) => (
                    <>
                        <FacilityEditForm
                            customFacility={facility}
                            studioCategories={studioCategories.$}
                            countryOptions={availableCountries.$}
                        />
                        <NearbyFacilities studio={facility.internalData} />
                    </>
                )
            })}
        </div>
    );
});

interface FacilityEditFormProps {
    customFacility: CustomFacilityDetailsDto;
    studioCategories: SelectEntryDto[];
    countryOptions: CountryOptionDto[];
}

const FacilityEditForm = observer(
    ({
        customFacility,
        studioCategories,
        countryOptions
    }: FacilityEditFormProps) => {
        const {
            fields,
            selectedExternalData,
            onSubmitHandler,
            onRejectHandler,
            selectedDataName,
            additionalData
        } = useStore(customFacility);

        return (
            <Container maxWidth="md" sx={{ paddingBottom: 2, paddingTop: 2 }}>
                <ActionsBar
                    facility={customFacility}
                    onSave={onSubmitHandler}
                    onReject={onRejectHandler}
                />
                <StyledSection>
                    <SectionItem
                        separator
                        noTimeline
                        empty
                        title1={() => (
                            <Typography variant="h6">User Input</Typography>
                        )}
                        title2={() => (
                            <Box display="flex" alignItems="baseline">
                                <Typography variant="h6">
                                    {selectedDataName}
                                </Typography>
                                {!selectedExternalData && (
                                    <Box ml={1}>
                                        <Typography
                                            variant="button"
                                            fontStyle="italic"
                                            color="red"
                                        >
                                            Source is empty
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        )}
                    />
                </StyledSection>
                <StyledSection>
                    <SectionItem label="Name" fields={fields.name} required />
                    <SectionItem
                        label="Category"
                        fields={fields.category}
                        options={studioCategories}
                        type="select"
                        required
                    />
                </StyledSection>
                <StyledSection>
                    <SectionItem separator />
                </StyledSection>
                <StyledSection>
                    <SectionItem
                        separator
                        empty
                        title1={() => (
                            <Typography variant="button" color="GrayText">
                                ADDRESS
                            </Typography>
                        )}
                        title2={() => (
                            <Typography variant="button" color="GrayText">
                                ADDRESS
                            </Typography>
                        )}
                    />
                </StyledSection>
                <StyledSection>
                    <SectionItem
                        label="City"
                        fields={fields.address.city}
                        required
                    />
                    <SectionItem
                        label="Street"
                        fields={fields.address.street}
                        required
                    />
                    <SectionItem
                        label="House No."
                        fields={fields.address.houseNumber}
                        required
                    />
                    <SectionItem
                        label="Zip"
                        fields={fields.address.zip}
                        required
                    />
                    <SectionItem
                        label="Country"
                        type="select"
                        options={countryOptions}
                        fields={fields.address.country}
                        required
                    />
                    <SectionItem
                        label="Latitude"
                        fields={fields.address.latitude}
                        type="number"
                        disabled
                        required
                    />
                    <SectionItem
                        label="Longitude"
                        fields={fields.address.longitude}
                        type="number"
                        disabled
                        required
                    />
                </StyledSection>
                <StyledSection>
                    <SectionItem separator />
                </StyledSection>
                {additionalData && (
                    <StyledSection>
                        <AdditionalData data={additionalData} />
                    </StyledSection>
                )}
            </Container>
        );
    }
);

const ActionsBar = ({ facility, onSave, onReject }: ActionsBar) => (
    <AppBar
        position="fixed"
        style={{ background: 'white', width: `calc(100% - ${drawerWidth}px` }}
        sx={{ top: 'auto', bottom: 0 }}
    >
        <StyledToolbar>
            <Link to={links.facility()}>Cancel</Link>
            <Box>
                <Button color="inherit" onClick={onSave}>
                    <CheckCircleIcon
                        style={{ color: green[500] }}
                        fontSize="large"
                    />
                    <Box marginLeft="6px">
                        <Typography variant="button" color="white">
                            {facility.internalData.status === 'VERIFIED'
                                ? 'Save'
                                : 'Approve'}
                        </Typography>
                    </Box>
                </Button>
                {facility.internalData.status !== 'DELETED' ? (
                    <Button color="inherit" onClick={onReject}>
                        <CancelIcon color="error" fontSize="large" />
                        <Box marginLeft="6px">
                            <Typography variant="button" color="white">
                                Reject
                            </Typography>
                        </Box>
                    </Button>
                ) : (
                    <div></div>
                )}
            </Box>
        </StyledToolbar>
    </AppBar>
);

export default FacilityDetailsForm;
