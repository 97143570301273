import { Container } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react';
import { useWrapRequest } from 'use-wrap-request';
import { PageTitle } from '../../components/page-title';
import { injectTSDI } from '../../core/tsdi';
import { Api } from '../../core/api';
import { Paper } from '../../components/paper';
import { GeneralWorkoutRules } from './general-rules';

export const WorkoutRulesDeprecated = observer(() => {
    const { tenantClient } = injectTSDI(Api);

    const currentWorkoutRules = useWrapRequest(async () => {
        const result = await tenantClient.admin.workoutRuleAdminControllerGetWorkoutRules();
        return result.data;
    });
    const workoutSources = useWrapRequest(
        async () => {
            const result = await tenantClient.admin.workoutSourceAdminControllerGetWorkoutSources();
            result.data.content = result.data.content.map((source) => ({
                ...source,
                id: source.name
            }));

            return {
                content: result.data.content || [],
                number: result.data.size,
                size: 60,
                totalElement: result.data.totalElements
            };
        },
        { defaultData: { content: [], number: 0, size: 0, totalElement: 0 } }
    );

    React.useEffect(() => {
        currentWorkoutRules.request();
        workoutSources.request();
    }, []);

    return (
        <Container maxWidth="md">
            <Paper>
                <PageTitle>Global workout rules</PageTitle>
                {currentWorkoutRules.match({
                    fetched: (currentWorkoutRules) => (
                        <GeneralWorkoutRules
                            currentWorkoutRules={currentWorkoutRules}
                        />
                    )
                })}
            </Paper>
        </Container>
    );
});
