import { Accordion, AccordionDetails, AccordionSummary, Stack, SvgIcon, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { I18n } from '../../../core/i18n';
import { injectTSDI } from '../../../core/tsdi';
import { ReactComponent as Step } from '../../../../assets/svg/icons/steps.svg';
import { ReactComponent as Studio } from '../../../../assets/svg/icons/target.svg';
import { ReactComponent as Workout } from '../../../../assets/svg/icons/workout.svg';
import { ActivityPointsDetails } from './activity-points-details';
import { ActivityPointsDto } from '../../../core/api/FitnessActivityClient';

interface ActivityPointsProps {
    activityPoints: ActivityPointsDto;
}

function getIconType(activityType: ActivityPointsDto['activityType']) {
    switch (activityType) {
        case 'DAILY_STEPS':
            return { icon: Step, viewBox: '0 0 13 22' };
        case 'FACILITY_VISIT':
            return { icon: Studio, viewBox: '0 0 22 22' };
        default:
            return { icon: Workout, viewBox: '0 0 20 20' };
    }
}

const ListItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
`;

export const ActivityPoints = ({ activityPoints }: ActivityPointsProps) => {
    const { formatDateTime } = injectTSDI(I18n);
    const { icon: Icon, viewBox } = getIconType(activityPoints.activityType);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    return (
        <Accordion
            expanded={expanded}
            onChange={(_, isExpanded) => setExpanded(isExpanded)}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ListItem>
                    <Typography flex="3">
                        {formatDateTime(activityPoints.timestamp)}
                    </Typography>
                    <Typography flex="3" variant="h6">
                        {activityPoints.value}{' '}
                        <Typography flex="3" variant="body2" component="span">
                            pts
                        </Typography>
                    </Typography>
                    <Stack direction="row" justifyContent="flex-start" flex="2">
                        <SvgIcon component={Icon} viewBox={viewBox} />
                        <Typography
                            component="span"
                            variant="body2"
                            ml={2}
                            color="GrayText"
                        >
                            {activityPoints.activityType}
                        </Typography>
                    </Stack>
                </ListItem>
            </AccordionSummary>
            <AccordionDetails>
                {activityPoints && activityPoints.id && expanded && (
                    <ActivityPointsDetails activityPoints={activityPoints} />
                )}
            </AccordionDetails>
        </Accordion>
    );
};
