import {
    Box,
    FilledInputProps,
    TextField as MaterialTextField,
    StandardTextFieldProps,
    Typography
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '../tooltip';

interface InputPros extends Omit<Partial<FilledInputProps>, 'color'> {
    color?: 'primary' | 'secondary' | undefined;
}
interface TextFieldProps {
    className?: string;
    label?: string;
    type: 'email' | 'text' | 'number' | 'password';
    value: string | number;
    error?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    multiline?: boolean;
    maxRows?: number;
    size?: StandardTextFieldProps['size'];
    InputProps?: InputPros;
    InputLabelShrink?: boolean;
    suffix?: string;
    onKeyUp?: React.KeyboardEventHandler;
    onKeyDown?: React.KeyboardEventHandler;
    tooltipText?: string;
    helperText?: boolean;
    onChange(value: string | number): void;
    onBlur?(
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void | undefined;
}
interface LabelWithTooltipProps {
    tooltipText: string;
    label?: string;
}

const StyleTextField = styled(MaterialTextField)`
    .MuiFormLabel-root {
        display: flex;
    }

    .MuiInputLabel-shrink {
        transform: translate(14px, 10px) scale(0.75);
    }

    fieldset {
        top: 0;
        legend {
            display: none;
        }
    }

    .MuiInputBase-input {
        padding: 26px 12px 8px;
        height: 1.9375em;
    }
`;

const LabelWithTooltip = ({ tooltipText, label }: LabelWithTooltipProps) => (
    <Box display="inline-flex" alignItems="center">
        <Tooltip title={tooltipText} />
        <Typography color="textSecondary">{label}</Typography>
    </Box>
);

export const TextField = React.forwardRef(
    (props: TextFieldProps, ref: React.Ref<HTMLInputElement>) => {
        const { suffix, type = 'text', InputProps = {} } = props;

        return (
            <StyleTextField
                fullWidth
                className={props.className}
                required={props.required}
                variant="outlined"
                label={
                    props.tooltipText ? (
                        <LabelWithTooltip
                            label={props.label}
                            tooltipText={props.tooltipText}
                        />
                    ) : (
                        props.label
                    )
                }
                InputLabelProps={{
                    shrink: props.InputLabelShrink || true
                }}
                size={props.size}
                type={type}
                value={props.value}
                disabled={props.disabled}
                autoComplete="off"
                error={Boolean(props.error)}
                onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => props.onChange(e.target.value)}
                onBlur={props.onBlur}
                inputRef={ref}
                onKeyUp={props.onKeyUp}
                onKeyDown={props.onKeyDown}
                inputProps={{ suffix }}
                placeholder={props.placeholder}
                helperText={props.helperText && props.error}
                InputProps={InputProps}
                multiline={props.multiline}
                maxRows={props.maxRows}
            />
        );
    }
);

export const EmptyTextField = React.forwardRef(
    (props: TextFieldProps, ref: React.Ref<HTMLInputElement>) => (
        <MaterialTextField
            hiddenLabel
            variant="outlined"
            size="small"
            sx={{
                marginTop: 1,
                '& .MuiFilledInput-root': {
                    background: 'transparent'
                },
                '& .MuiFilledInput-input': {
                    padding: '6px 0'
                }
            }}
            type={props.type}
            className={props.className}
            required={props.required}
            error={Boolean(props.error)}
            onChange={(e) => props.onChange(e.target.value)}
            placeholder={props.placeholder}
            value={props.value}
            inputRef={ref}
            InputProps={props.InputProps}
            fullWidth
        />
    )
);
