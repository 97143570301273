import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useWrapRequest } from 'use-wrap-request';
import { observer } from 'mobx-react';
import { TenantCorporateContext } from '../../index';
import { Label } from '../../../components/label';

interface ActivityDetailsProps {
    activityId: number;
}

export const ActivityDetails = observer(
    ({ activityId }: ActivityDetailsProps) => {
        const corporateClient = React.useContext(TenantCorporateContext);

        const activityDetailsRequest = useWrapRequest(
            async (activityId) => {
                const result = await corporateClient.support.activitySupportControllerGetActivity(
                    activityId
                );
                return result.data;
            },
            {
                defaultData: [],
                deps: [activityId]
            }
        );

        return (
            <>
                {activityDetailsRequest.match({
                    loading: () => (
                        <Stack direction="row" justifyContent="center">
                            <CircularProgress disableShrink />
                        </Stack>
                    ),
                    fetched: (properties) => (
                        <Grid container spacing={2}>
                            {properties
                                .filter((property) => property.value)
                                .map((property) => (
                                    <Grid item xs={4} key={property.key}>
                                        <Label>{property.key}</Label>
                                        <Typography>
                                            {JSON.stringify(property.value)}
                                        </Typography>
                                    </Grid>
                                ))}
                        </Grid>
                    )
                })}
            </>
        );
    }
);
