import { createSvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export const MSLogoIcon = createSvgIcon(
    <g id="Gruppe_21" data-name="Gruppe 21">
        <path
            d="M17.0322 35.7189L34.0616 71.0013H0L17.0322 35.7189Z"
            fill="#fff"
        />
        <path
            d="M13.9918 0L47.6227 71.0013H72.0364L37.423 0H13.9918Z"
            fill="#fff"
        />
        <path
            d="M51.9525 0L85.5834 71.0013H110L75.3838 0H51.9525Z"
            fill="#fff"
        />
    </g>,
    'MSLogo'
);

export const MSLogo = styled(MSLogoIcon).attrs<{}, SvgIconProps>({
    viewBox: '0 0 110 71'
    // fontSizeAdjust: 50
    // sx: { fontSize: 60 }
})`
    margin-bottom: ${({ theme }) => theme.spacing(1)};
    circle {
        fill: #139dd9;
    }
`;
