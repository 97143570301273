import { Box, Chip, Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import { useWrapRequest } from '../../components/hooks/use-wrap-request';
import { Spinner } from '../../components/spinner';
import { Api } from '../../core/api';
import { CustomFacilityDetailsDto } from '../../core/api/StudioClient';
import { I18n } from '../../core/i18n';
import { links } from '../../core/router';
import { injectTSDI } from '../../core/tsdi';

interface EmployeeDataProps {
    facility: CustomFacilityDetailsDto;
}

export const EmployeeData = observer(({ facility }: EmployeeDataProps) => {
    const {
        generateCorporateClient,
        gatewayApiUrl,
        generateApiConfig
    } = injectTSDI(Api);
    const { formatDate } = injectTSDI(I18n);
    const navigate = useNavigate();

    const {
        internalData: { origin, creationDate }
    } = facility;

    const corporateClient = (tenantName: string) =>
        generateCorporateClient(
            generateApiConfig(gatewayApiUrl('corporate-api'), tenantName)
        );

    const employeeData = useWrapRequest(
        async (mySportsId: string, tenant: string) => {
            const client = corporateClient(tenant);
            const result = await client.support.employeeSupportControllerGetPage(
                { mySportsId }
            );
            if (result.data.content.length) {
                return result.data.content[0];
            }
            return undefined;
        }
    );

    React.useEffect(() => {
        if (
            facility.internalData.origin.mySportsId &&
            facility.internalData.origin.tenant
        ) {
            employeeData.request([
                facility.internalData.origin.mySportsId,
                facility.internalData.origin.tenant
            ]);
        }
    }, [facility, employeeData]);

    return (
        <Stack direction="row" spacing={1} mt={1} mb={2}>
            {employeeData.match({
                loading: () => (
                    <Box
                        display="flex"
                        justifyContent="center"
                        sx={{
                            width: '100%',
                            height: '24px',
                            paddingBottom: 2
                        }}
                    >
                        <Spinner />
                    </Box>
                ),
                fetched: (employee) =>
                    employee && (
                        <>
                            <Chip
                                icon={<EmailIcon />}
                                label={origin.email}
                                onClick={() => {
                                    if (origin.tenant && employee.id) {
                                        navigate(
                                            links.tenantEmployeeDetails(
                                                origin.tenant,
                                                employee.id.toString()
                                            )
                                        );
                                    }
                                }}
                                size="small"
                            />
                            {origin && (
                                <>
                                    <Chip
                                        icon={<EventIcon />}
                                        label={formatDate(creationDate)}
                                        size="small"
                                    />
                                </>
                            )}
                            {origin.tenant && (
                                <Chip
                                    icon={<HomeIcon />}
                                    label={origin.tenant}
                                    onClick={() => {
                                        if (origin.tenant) {
                                            navigate(
                                                links.tenantDetails(
                                                    origin.tenant
                                                )
                                            );
                                        }
                                    }}
                                    size="small"
                                />
                            )}
                        </>
                    )
            })}
        </Stack>
    );
});
