import React from 'react';
import { Alert, Box, Container, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { injectTSDI } from '../core/tsdi';
import { Paper } from '../components/paper';
import { Option, Select } from '../components/select';
import { TextField } from '../components/textfield';
import { Button } from '../components/button';
import { InitialData } from '../core/initial-data';
import { PageTitle } from '../components/page-title/index';
import { CreateTeamsTenantDto, TenantType } from '../core/api/TenantClient';
import { useStore } from './store';

function getTenantTypeOption(): Option<CreateTeamsTenantDto['tenantType']>[] {
    return [
        { value: TenantType.CLIENT, label: 'Client' },
        { value: TenantType.DEMO, label: 'Demo' },
        { value: TenantType.PARTNER, label: 'Partner' },
        { value: TenantType.UNMANAGED, label: 'Unmanaged' }
    ];
}

export const TenantOnboarding = observer(() => {
    const { fields, onSubmitHandler, errors, tenantRequest, onCancelHandler } =
        useStore();
    const { availableCountries } = injectTSDI(InitialData);

    return (
        <Container maxWidth="md">
            <PageTitle>Tenant Onboarding</PageTitle>
            <Paper>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            label="Tenant Id"
                            value={fields.tenantId.value}
                            onChange={fields.tenantId.onChange}
                            error={fields.tenantId.error}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select
                            label="Tenant Type"
                            value={fields.tenantType.value}
                            onChange={fields.tenantType.onChange}
                            error={fields.tenantType.error}
                            options={getTenantTypeOption()}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            type="number"
                            label="Licenses"
                            value={fields.licensesCount.value}
                            onChange={fields.licensesCount.onChange}
                            error={fields.licensesCount.error}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            label="Company Name"
                            value={fields.company.name.value}
                            onChange={fields.company.name.onChange}
                            error={fields.company.name.error}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} mb={-2}>
                        <Typography variant="h6">Address</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            type="text"
                            label="Street"
                            required
                            value={fields.company.address.street.value}
                            onChange={fields.company.address.street.onChange}
                            error={fields.company.address.street.error}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            type="text"
                            label="Number"
                            required
                            value={fields.company.address.houseNumber.value}
                            onChange={
                                fields.company.address.houseNumber.onChange
                            }
                            error={fields.company.address.houseNumber.error}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            type="text"
                            label="Post Code"
                            required
                            value={fields.company.address.zip.value}
                            onChange={fields.company.address.zip.onChange}
                            error={fields.company.address.zip.error}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            type="text"
                            label="City"
                            value={fields.company.address.city.value}
                            onChange={fields.company.address.city.onChange}
                            error={fields.company.address.city.error}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            label="Country"
                            value={fields.company.address.country.value}
                            onChange={fields.company.address.country.onChange}
                            error={fields.company.address.country.error}
                            options={availableCountries.$}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} mb={-2}>
                        <Typography variant="h6">Configuration</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            label="Accept Domains"
                            placeholder="Seperated by commas"
                            value={
                                fields.company.companyConfiguration
                                    .acceptedDomains.value
                            }
                            onChange={
                                fields.company.companyConfiguration
                                    .acceptedDomains.onChange
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            label="Authentication Token"
                            value={
                                fields.company.companyConfiguration
                                    .authenticationToken.value
                            }
                            onChange={
                                fields.company.companyConfiguration
                                    .authenticationToken.onChange
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="number"
                            label="Declared employees count"
                            value={
                                fields.company.companyConfiguration
                                    .declaredEmployeesCount.value
                            }
                            onChange={
                                fields.company.companyConfiguration
                                    .declaredEmployeesCount.onChange
                            }
                        />
                    </Grid>
                    <Grid item xs={12} mb={-2}>
                        <Typography variant="h6">Company Admin</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            label="First Name"
                            value={fields.companyAdmin.firstName.value}
                            onChange={fields.companyAdmin.firstName.onChange}
                            error={fields.companyAdmin.firstName.error}
                            helperText
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            label="Last Name"
                            value={fields.companyAdmin.lastName.value}
                            onChange={fields.companyAdmin.lastName.onChange}
                            error={fields.companyAdmin.firstName.error}
                            helperText
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            label="Email"
                            value={fields.companyAdmin.email.value}
                            onChange={fields.companyAdmin.email.onChange}
                            error={fields.companyAdmin.email.error}
                            helperText
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            label="Lexoffice Link"
                            value={fields.lexofficeLink.value}
                            onChange={fields.lexofficeLink.onChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            label="Hubspot Link"
                            value={fields.hubspotLink.value}
                            onChange={fields.hubspotLink.onChange}
                        />
                    </Grid>

                    <Grid item xs={12} mt={3}>
                        <Box mb={2}>
                            {tenantRequest.error?.message && (
                                <Alert severity="error">
                                    {tenantRequest.error?.message}
                                </Alert>
                            )}
                        </Box>

                        <Box display="flex" justifyContent="space-between">
                            <Button onClick={onCancelHandler} variant="text">
                                Cancel
                            </Button>
                            <Button
                                loading={tenantRequest.loading}
                                onClick={onSubmitHandler}
                                disabled={
                                    Boolean(errors) || tenantRequest.loading
                                }
                            >
                                Submit
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
});
