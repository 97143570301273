import { Box, Button, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { TenantFeaturesContext } from '..';
import { Label } from '../../components/label';
import { Spinner } from '../../components/spinner';
import { injectTSDI } from '../../core/tsdi';
import { Api } from '../../core/api';
import { CommunityDto } from '@api/ChallengeClient';
import { useParams } from 'react-router';
import { CommunityCreateModal } from '../../community/modal/community-create-modal';
import {useError} from "../../components/hooks/useError";

const TenantCommunity = observer(() => {
    const tenantFeatures = React.useContext(TenantFeaturesContext);
    const { challengeClient } = injectTSDI(Api);
    const { tenantName } = useParams();

    const [, setState] = React.useState<string | Error>();
    const showError = useError();

    const [communityDetails, setCommunityDetails] =
        React.useState<CommunityDto | null>(null);
    const [notCreated, setCommunityCreateView] = React.useState<boolean>(false);

    const openActivateState = React.useState(false);
    const [, setCreateModalOpen] = openActivateState;

    const getCommunityDetails = async () => {
        try {
            const response =
                await challengeClient.support.communitySupportControllerGet(
                    tenantName
                );
            setCommunityDetails(response.data);
            setCommunityCreateView(false);
        } catch (e: any) {
            if (e.status === 404) {
                setCommunityCreateView(true);
            } else {
                setState(e);
                showError(e);
            }
        }
    };

    React.useEffect(() => {
        getCommunityDetails();
    }, []);

    return (
        <>
            {tenantFeatures.match({
                loading: () => (
                    <Box display="flex" justifyContent="center" minHeight={62}>
                        <Spinner />
                    </Box>
                ),
                fetched: ({ company, tenant }) => (
                    <Grid container spacing={2}>
                        <CommunityCreateModal
                            openState={openActivateState}
                            tenantName={tenantName}
                            communityName={company.name}
                            logoBucket={tenant.company.logo?.bucketName}
                            logoKey={tenant.company.logo?.objectKey}
                            onSuccess={() => void getCommunityDetails()}
                        />

                        {notCreated ? (
                            <Grid item xs={12}>
                                <Box mb={2} mt={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                            void setCreateModalOpen(true)
                                        }
                                    >
                                        Create community
                                    </Button>
                                </Box>
                            </Grid>
                        ) : (
                            communityDetails && (
                                <Grid item xs={12}>
                                    <Box mb={2}>
                                        <Label>Name</Label>
                                        <Typography>
                                            {communityDetails.name}
                                        </Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <Label>Description</Label>
                                        <Typography>
                                            {communityDetails.description}
                                        </Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <Label>Visibility</Label>
                                        <Typography>
                                            {communityDetails.visibility}
                                        </Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <Label>Approved</Label>
                                        <Typography>
                                            {communityDetails.approved.toString()}
                                        </Typography>
                                    </Box>
                                </Grid>
                            )
                        )}
                    </Grid>
                )
            })}
        </>
    );
});

export default TenantCommunity;
