import { Checkbox, CheckboxProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const areEqual = (prevProps: any, nextProps: any) =>
    prevProps.checked === nextProps.checked &&
    prevProps.indeterminate === nextProps.indeterminate;

export const SelectHeaderCheckbox = React.memo(
    styled(Checkbox)<CheckboxProps>({
        fontSize: '1rem',
        margin: '-8px 0 -8px -15px',
        padding: '8px 9px',
        '& svg': {
            width: '24px',
            height: '24px'
        },
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }),
    areEqual
);

export const SelectRowCheckbox = React.memo(
    styled(Checkbox)<CheckboxProps>({
        fontSize: '14px',
        margin: '-9px 0 -8px -15px',
        padding: '8px 9px 9px 9px',
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '& svg': {
            width: 24,
            height: 24
        }
    }),
    areEqual
);
