import { Alert, Box, Grid, Switch, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { TenantFeaturesContext } from '..';
import { Button } from '../../components/button';
import { ConfirmationModal } from '../../components/confirmation-modal';
import { Label } from '../../components/label';
import { Option, Select } from '../../components/select';
import { Spinner } from '../../components/spinner';
import { EmptyTextField } from '../../components/textfield';
import { SizeRange, TenantCompanyUpdateDto } from '../../core/api/TenantClient';
import { useCorporateStore } from './corporate-store';
import { useTeamsStore } from './teams-store';

const TenantContract = observer(() => {
    const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

    const tenantFeatures = React.useContext(TenantFeaturesContext);

    const {
        licenseModel: {
            fields: { licensesCount, flexLicense },
            form: licenseForm
        },
        currentLicenseCount,
        currentFlexEnabled,
        tenantUpdateHandler,
        licenseSubmitHandler
    } = useTeamsStore();

    const {
        contractModel,
        tenantContract,
        setContractModel,
        contractChangeHandler
    } = useCorporateStore();

    return (
        <>
            {tenantFeatures.match({
                loading: () => (
                    <Box display="flex" justifyContent="center" minHeight={62}>
                        <Spinner />
                    </Box>
                ),
                fetched: ({ company, tenant }) => (
                    <Grid container spacing={2}>
                        {tenant.productType === 'TEAMS' && (
                            <>
                                <Grid item xs={12}>
                                    <Label>Flex License</Label>
                                    <Switch
                                        checked={flexLicense.value}
                                        onChange={(__, checked) => {
                                            flexLicense.onChange(checked);
                                        }}
                                        name="license"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {!flexLicense.value && (
                                        <Box>
                                            <Label>License count</Label>
                                            <EmptyTextField
                                                type="number"
                                                value={licensesCount.value}
                                                InputProps={{
                                                    inputProps: { min: 0 }
                                                }}
                                                error={licensesCount.error}
                                                onChange={(val: number) => {
                                                    licensesCount.onChange(val);
                                                }}
                                            />
                                        </Box>
                                    )}

                                    <Box mt={2} textAlign="right">
                                        <Button
                                            onClick={async () => {
                                                const formValidation =
                                                    await licenseForm.validate();
                                                if (!formValidation.hasError) {
                                                    setConfirmModalOpen(true);
                                                }
                                            }}
                                            size="small"
                                            disabled={
                                                tenantUpdateHandler.loading
                                            }
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                    <ConfirmationModal
                                        open={confirmModalOpen}
                                        submitHandler={licenseSubmitHandler}
                                        onModalClose={() =>
                                            void setConfirmModalOpen(false)
                                        }
                                    >
                                        {currentFlexEnabled !==
                                        flexLicense.value ? (
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Are you sure you want to switch
                                                to flex license model?
                                            </Typography>
                                        ) : (
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Are you sure you want to change
                                                the license count from{' '}
                                                {currentLicenseCount} to{' '}
                                                {licensesCount.value}
                                            </Typography>
                                        )}

                                        <Alert severity="warning">
                                            Changes have a severe impact on
                                            finances
                                        </Alert>
                                    </ConfirmationModal>
                                </Grid>
                            </>
                        )}
                        {tenant.productType === 'CORPORATE' && (
                            <Grid item xs={3}>
                                <Label>Contract Model</Label>
                                <Select
                                    label="Contract Model"
                                    value={
                                        tenantContract.$?.contractModel ||
                                        contractModel
                                    }
                                    onChange={(val) => {
                                        setContractModel(val);
                                        setConfirmModalOpen(true);
                                    }}
                                    options={getContractModelOptions()}
                                    noLabel
                                    fullWidth
                                />
                                <ConfirmationModal
                                    open={confirmModalOpen}
                                    submitHandler={contractChangeHandler}
                                    onModalClose={() =>
                                        void setConfirmModalOpen(false)
                                    }
                                >
                                    <Typography variant="h6" gutterBottom>
                                        Are you sure you want to change the
                                        contract model?
                                    </Typography>
                                </ConfirmationModal>
                            </Grid>
                        )}

                        <Grid item xs={6} md={3}>
                            <Label>Participants</Label>
                            <Typography>{company.employeeCount}</Typography>
                        </Grid>
                    </Grid>
                )
            })}
        </>
    );
});

export default TenantContract;

export function getContractModelOptions(): Option<
    TenantCompanyUpdateDto['contractModel']
>[] {
    return [
        { value: SizeRange.MICRO, label: 'Micro' },
        { value: SizeRange.SMALL, label: 'Small' },
        { value: SizeRange.MEDIUM, label: 'Medium' },
        { value: SizeRange.LARGE, label: 'Large' },
        { value: SizeRange.X_LARGE, label: 'X Large' },
        { value: SizeRange.ENTERPRISE, label: 'Enterprise' }
    ];
}
