/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddressDto {
    /** @format int64 */
    id?: number;
    street: string;
    houseNumber: string;
    zipCode: string;
    city: string;
    country:
        | 'UNDEFINED'
        | 'AC'
        | 'AD'
        | 'AE'
        | 'AF'
        | 'AG'
        | 'AI'
        | 'AL'
        | 'AM'
        | 'AN'
        | 'AO'
        | 'AQ'
        | 'AR'
        | 'AS'
        | 'AT'
        | 'AU'
        | 'AW'
        | 'AX'
        | 'AZ'
        | 'BA'
        | 'BB'
        | 'BD'
        | 'BE'
        | 'BF'
        | 'BG'
        | 'BH'
        | 'BI'
        | 'BJ'
        | 'BL'
        | 'BM'
        | 'BN'
        | 'BO'
        | 'BQ'
        | 'BR'
        | 'BS'
        | 'BT'
        | 'BU'
        | 'BV'
        | 'BW'
        | 'BY'
        | 'BZ'
        | 'CA'
        | 'CC'
        | 'CD'
        | 'CF'
        | 'CG'
        | 'CH'
        | 'CI'
        | 'CK'
        | 'CL'
        | 'CM'
        | 'CN'
        | 'CO'
        | 'CP'
        | 'CR'
        | 'CS'
        | 'CU'
        | 'CV'
        | 'CW'
        | 'CX'
        | 'CY'
        | 'CZ'
        | 'DE'
        | 'DG'
        | 'DJ'
        | 'DK'
        | 'DM'
        | 'DO'
        | 'DZ'
        | 'EA'
        | 'EC'
        | 'EE'
        | 'EG'
        | 'EH'
        | 'ER'
        | 'ES'
        | 'ET'
        | 'EU'
        | 'EZ'
        | 'FI'
        | 'FJ'
        | 'FK'
        | 'FM'
        | 'FO'
        | 'FR'
        | 'FX'
        | 'GA'
        | 'GB'
        | 'GD'
        | 'GE'
        | 'GF'
        | 'GG'
        | 'GH'
        | 'GI'
        | 'GL'
        | 'GM'
        | 'GN'
        | 'GP'
        | 'GQ'
        | 'GR'
        | 'GS'
        | 'GT'
        | 'GU'
        | 'GW'
        | 'GY'
        | 'HK'
        | 'HM'
        | 'HN'
        | 'HR'
        | 'HT'
        | 'HU'
        | 'IC'
        | 'ID'
        | 'IE'
        | 'IL'
        | 'IM'
        | 'IN'
        | 'IO'
        | 'IQ'
        | 'IR'
        | 'IS'
        | 'IT'
        | 'JE'
        | 'JM'
        | 'JO'
        | 'JP'
        | 'KE'
        | 'KG'
        | 'KH'
        | 'KI'
        | 'KM'
        | 'KN'
        | 'KP'
        | 'KR'
        | 'KW'
        | 'KY'
        | 'KZ'
        | 'LA'
        | 'LB'
        | 'LC'
        | 'LI'
        | 'LK'
        | 'LR'
        | 'LS'
        | 'LT'
        | 'LU'
        | 'LV'
        | 'LY'
        | 'MA'
        | 'MC'
        | 'MD'
        | 'ME'
        | 'MF'
        | 'MG'
        | 'MH'
        | 'MK'
        | 'ML'
        | 'MM'
        | 'MN'
        | 'MO'
        | 'MP'
        | 'MQ'
        | 'MR'
        | 'MS'
        | 'MT'
        | 'MU'
        | 'MV'
        | 'MW'
        | 'MX'
        | 'MY'
        | 'MZ'
        | 'NA'
        | 'NC'
        | 'NE'
        | 'NF'
        | 'NG'
        | 'NI'
        | 'NL'
        | 'NO'
        | 'NP'
        | 'NR'
        | 'NT'
        | 'NU'
        | 'NZ'
        | 'OM'
        | 'PA'
        | 'PE'
        | 'PF'
        | 'PG'
        | 'PH'
        | 'PK'
        | 'PL'
        | 'PM'
        | 'PN'
        | 'PR'
        | 'PS'
        | 'PT'
        | 'PW'
        | 'PY'
        | 'QA'
        | 'RE'
        | 'RO'
        | 'RS'
        | 'RU'
        | 'RW'
        | 'SA'
        | 'SB'
        | 'SC'
        | 'SD'
        | 'SE'
        | 'SF'
        | 'SG'
        | 'SH'
        | 'SI'
        | 'SJ'
        | 'SK'
        | 'SL'
        | 'SM'
        | 'SN'
        | 'SO'
        | 'SR'
        | 'SS'
        | 'ST'
        | 'SU'
        | 'SV'
        | 'SX'
        | 'SY'
        | 'SZ'
        | 'TA'
        | 'TC'
        | 'TD'
        | 'TF'
        | 'TG'
        | 'TH'
        | 'TJ'
        | 'TK'
        | 'TL'
        | 'TM'
        | 'TN'
        | 'TO'
        | 'TP'
        | 'TR'
        | 'TT'
        | 'TV'
        | 'TW'
        | 'TZ'
        | 'UA'
        | 'UG'
        | 'UK'
        | 'UM'
        | 'US'
        | 'UY'
        | 'UZ'
        | 'VA'
        | 'VC'
        | 'VE'
        | 'VG'
        | 'VI'
        | 'VN'
        | 'VU'
        | 'WF'
        | 'WS'
        | 'XI'
        | 'XU'
        | 'XK'
        | 'YE'
        | 'YT'
        | 'YU'
        | 'ZA'
        | 'ZM'
        | 'ZR'
        | 'ZW';
}

export interface CdnImageDto {
    bucketName: string;
    objectKey: string;
}

export interface CompanyAccountDto {
    /** @format int64 */
    id?: number;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified?: boolean;
    state?:
        | 'INACTIVE'
        | 'ACTIVE'
        | 'BLOCKED'
        | 'INVITED'
        | 'AUTOMATICALLY_CREATED'
        | 'DELETED';
    role:
        | 'EMPLOYEE'
        | 'EXTERNAL_SUPPORT'
        | 'USER'
        | 'ADMIN'
        | 'ANALYST'
        | 'MANAGER';
    /** @format int64 */
    companyId?: number;
    source?: 'ADMIN' | 'SIGNUP' | 'PORTAL';
}

export interface CurrencyDto {
    /** @format int64 */
    id?: number;
    /**
     * @minLength 3
     * @maxLength 20
     */
    name: string;
    /**
     * @minLength 3
     * @maxLength 3
     */
    code: string;
    symbol: string;
    /** @format int32 */
    precision: number;
}

export interface PromoterConfigurationDto {
    /** @format int64 */
    id?: number;
    authenticationToken?: string;
    qrCodeUrl?: string;
    connectUrl?: string;
    /** @format int32 */
    declaredEmployeesCount?: number;
    participateInPublicChallenges: boolean;
    /** @uniqueItems true */
    acceptedDomains?: string[];
}

export interface PromoterDto {
    /** @format int64 */
    id?: number;
    name: string;
    address?: AddressDto;
    currencyCode?: string;
    promoterType?: 'COMPANY' | 'SPORT_ASSOCIATION';
    currency?: CurrencyDto;
    companyConfiguration?: PromoterConfigurationDto;
    logo?: CdnImageDto;
    /**
     * @deprecated
     * @format int64
     */
    defaultSponsorshipProgramId?: number;
    timeZone: string;
    localeCode: string;
    /** @format int32 */
    employeeCount: number;
}

export interface ServicePackageSetup {
    name: string;
    /** @format int32 */
    dailyGoal?: number;
    /** @format int32 */
    dailyReward?: number;
    /** @format int32 */
    maxMonthlyDailyGoals?: number;
    currencyCode?: string;
    /** @deprecated */
    main?: boolean;
    autoLicenseObtain?: boolean;
}

export interface TenantOnboardingDto {
    company: PromoterDto;
    creatorEmail?: string;
    customServicePackages?: ServicePackageSetup[];
    companyAdmin?: CompanyAccountDto;
}

export interface RedirectDto {
    redirectUrl: string;
}

export interface I18NText {
    en?: string;
    de?: string;
    pl?: string;
    es?: string;
}

export interface NewsDataDto {
    title: I18NText;
    content: I18NText;
    icon: string;
    shortDescription: I18NText;
    image: CdnImageDto;
    tags: string[];
    /** @format date-time */
    publicationDateTime?: string;
}

export interface NewsUpdateSupportDto {
    data: NewsDataDto;
    forceUpdate: boolean;
}

export interface NewsDto {
    title: I18NText;
    content: I18NText;
    icon: string;
    shortDescription: I18NText;
    image: CdnImageDto;
    tags: string[];
    /** @format date-time */
    publicationDateTime?: string;
    /** @format int64 */
    id: number;
    published: boolean;
}

export interface ApprovedMoneyTopUpDto {
    tenantId: string;
    /** @format int64 */
    entityId: number;
    entityType: 'CHALLENGE';
    moneyValue: number;
    currencyCode: string;
    userEmails: string[];
}

export interface ApprovedMoneyTopUpSummaryDto {
    sent: string[];
    undelivered: string[];
}

export interface ServicePackageCreateDto {
    /** @format int64 */
    id?: number;
    name: string;
    /** @deprecated */
    main: boolean;
    businessUnitDefault: boolean;
    /** @format int64 */
    achievementConfigId: number;
    /** @format int64 */
    rewardConfigId?: number;
    /** @uniqueItems true */
    employeeIds: number[];
    /** @format int64 */
    businessUnitId: number;
}

export interface DictionaryEntryDto {
    /** @format int64 */
    id: number;
    name: string;
}

export interface ServicePackageDetailsDto {
    /** @format int64 */
    id?: number;
    name: string;
    /** @deprecated */
    main: boolean;
    businessUnitDefault: boolean;
    achievementConfig: DictionaryEntryDto;
    rewardConfig: DictionaryEntryDto;
    businessUnit: DictionaryEntryDto;
    /** @uniqueItems true */
    employeeIds: number[];
}

export interface ServicePackageAssignDto {
    /** @uniqueItems true */
    employeeIds: number[];
}

export interface MoveMessagesDto {
    sourceQueueName: string;
    targetQueueName: string;
}

export interface NewsCreateSupportDto {
    templateId: string;
    data: NewsDataDto;
}

export interface SupportJobDto {
    jobName: string;
    tenantId?: string;
}

export interface CorporateFeatureDto {
    feature:
        | 'HYBRID_REWARDING_MODEL'
        | 'TYPED_STARS'
        | 'PAYOUT_RUN_GENERATION'
        | 'MANUAL_CHECK_IN'
        | 'CYCLING'
        | 'WORKOUT_IN_APP_TRACKING_ANDROID'
        | 'WORKOUT_IN_APP_TRACKING_IOS'
        | 'EMPLOYEE_GROUPS'
        | 'STATS'
        | 'TOUR_GUIDE'
        | 'LIVE_DATA_STATS'
        | 'SPONSOR'
        | 'ORGANIZER'
        | 'NEWS_TEMPLATE'
        | 'LEAGUES_AND_TEAMS'
        | 'ACTIVITY_POINTS'
        | 'CORPORATE_APP_NAVBAR'
        | 'MYSPORTS_TEAMS_APP_MIGRATION'
        | 'MYSPORTS_TEAMS_COURSE_BANNER'
        | 'AI_ASSISTANT'
        | 'COMMUNITIES'
        | 'NEW_PARTICIPANT_EMAIL'
        | 'PARTICIPANT_LEFT_EMAIL'
        | 'CHALLENGES'
        | 'FITNESS_APPS'
        | 'STRAVA'
        | 'GARMIN'
        | 'FITBIT'
        | 'POLAR'
        | 'HEALTH_CONNECT';
    name: string;
    enabled: boolean;
}

export interface CorporateFeatureGroupDto {
    group:
        | 'REWARDING'
        | 'ACTIVITIES'
        | 'CORPORATE_PORTAL'
        | 'NOTIFICATIONS'
        | 'OTHER'
        | 'FEATURES';
    name: string;
    features: CorporateFeatureDto[];
}

export interface BusinessUnitCreateDto {
    /** @format int64 */
    id?: number;
    market:
        | 'UNDEFINED'
        | 'AC'
        | 'AD'
        | 'AE'
        | 'AF'
        | 'AG'
        | 'AI'
        | 'AL'
        | 'AM'
        | 'AN'
        | 'AO'
        | 'AQ'
        | 'AR'
        | 'AS'
        | 'AT'
        | 'AU'
        | 'AW'
        | 'AX'
        | 'AZ'
        | 'BA'
        | 'BB'
        | 'BD'
        | 'BE'
        | 'BF'
        | 'BG'
        | 'BH'
        | 'BI'
        | 'BJ'
        | 'BL'
        | 'BM'
        | 'BN'
        | 'BO'
        | 'BQ'
        | 'BR'
        | 'BS'
        | 'BT'
        | 'BU'
        | 'BV'
        | 'BW'
        | 'BY'
        | 'BZ'
        | 'CA'
        | 'CC'
        | 'CD'
        | 'CF'
        | 'CG'
        | 'CH'
        | 'CI'
        | 'CK'
        | 'CL'
        | 'CM'
        | 'CN'
        | 'CO'
        | 'CP'
        | 'CR'
        | 'CS'
        | 'CU'
        | 'CV'
        | 'CW'
        | 'CX'
        | 'CY'
        | 'CZ'
        | 'DE'
        | 'DG'
        | 'DJ'
        | 'DK'
        | 'DM'
        | 'DO'
        | 'DZ'
        | 'EA'
        | 'EC'
        | 'EE'
        | 'EG'
        | 'EH'
        | 'ER'
        | 'ES'
        | 'ET'
        | 'EU'
        | 'EZ'
        | 'FI'
        | 'FJ'
        | 'FK'
        | 'FM'
        | 'FO'
        | 'FR'
        | 'FX'
        | 'GA'
        | 'GB'
        | 'GD'
        | 'GE'
        | 'GF'
        | 'GG'
        | 'GH'
        | 'GI'
        | 'GL'
        | 'GM'
        | 'GN'
        | 'GP'
        | 'GQ'
        | 'GR'
        | 'GS'
        | 'GT'
        | 'GU'
        | 'GW'
        | 'GY'
        | 'HK'
        | 'HM'
        | 'HN'
        | 'HR'
        | 'HT'
        | 'HU'
        | 'IC'
        | 'ID'
        | 'IE'
        | 'IL'
        | 'IM'
        | 'IN'
        | 'IO'
        | 'IQ'
        | 'IR'
        | 'IS'
        | 'IT'
        | 'JE'
        | 'JM'
        | 'JO'
        | 'JP'
        | 'KE'
        | 'KG'
        | 'KH'
        | 'KI'
        | 'KM'
        | 'KN'
        | 'KP'
        | 'KR'
        | 'KW'
        | 'KY'
        | 'KZ'
        | 'LA'
        | 'LB'
        | 'LC'
        | 'LI'
        | 'LK'
        | 'LR'
        | 'LS'
        | 'LT'
        | 'LU'
        | 'LV'
        | 'LY'
        | 'MA'
        | 'MC'
        | 'MD'
        | 'ME'
        | 'MF'
        | 'MG'
        | 'MH'
        | 'MK'
        | 'ML'
        | 'MM'
        | 'MN'
        | 'MO'
        | 'MP'
        | 'MQ'
        | 'MR'
        | 'MS'
        | 'MT'
        | 'MU'
        | 'MV'
        | 'MW'
        | 'MX'
        | 'MY'
        | 'MZ'
        | 'NA'
        | 'NC'
        | 'NE'
        | 'NF'
        | 'NG'
        | 'NI'
        | 'NL'
        | 'NO'
        | 'NP'
        | 'NR'
        | 'NT'
        | 'NU'
        | 'NZ'
        | 'OM'
        | 'PA'
        | 'PE'
        | 'PF'
        | 'PG'
        | 'PH'
        | 'PK'
        | 'PL'
        | 'PM'
        | 'PN'
        | 'PR'
        | 'PS'
        | 'PT'
        | 'PW'
        | 'PY'
        | 'QA'
        | 'RE'
        | 'RO'
        | 'RS'
        | 'RU'
        | 'RW'
        | 'SA'
        | 'SB'
        | 'SC'
        | 'SD'
        | 'SE'
        | 'SF'
        | 'SG'
        | 'SH'
        | 'SI'
        | 'SJ'
        | 'SK'
        | 'SL'
        | 'SM'
        | 'SN'
        | 'SO'
        | 'SR'
        | 'SS'
        | 'ST'
        | 'SU'
        | 'SV'
        | 'SX'
        | 'SY'
        | 'SZ'
        | 'TA'
        | 'TC'
        | 'TD'
        | 'TF'
        | 'TG'
        | 'TH'
        | 'TJ'
        | 'TK'
        | 'TL'
        | 'TM'
        | 'TN'
        | 'TO'
        | 'TP'
        | 'TR'
        | 'TT'
        | 'TV'
        | 'TW'
        | 'TZ'
        | 'UA'
        | 'UG'
        | 'UK'
        | 'UM'
        | 'US'
        | 'UY'
        | 'UZ'
        | 'VA'
        | 'VC'
        | 'VE'
        | 'VG'
        | 'VI'
        | 'VN'
        | 'VU'
        | 'WF'
        | 'WS'
        | 'XI'
        | 'XU'
        | 'XK'
        | 'YE'
        | 'YT'
        | 'YU'
        | 'ZA'
        | 'ZM'
        | 'ZR'
        | 'ZW';
    marketDefault: boolean;
    name: I18NText;
    /** @format int64 */
    sourceBusinessUnitId?: number;
}

export interface BusinessUnitWithServicePackagesDto {
    /** @format int64 */
    id?: number;
    market:
        | 'UNDEFINED'
        | 'AC'
        | 'AD'
        | 'AE'
        | 'AF'
        | 'AG'
        | 'AI'
        | 'AL'
        | 'AM'
        | 'AN'
        | 'AO'
        | 'AQ'
        | 'AR'
        | 'AS'
        | 'AT'
        | 'AU'
        | 'AW'
        | 'AX'
        | 'AZ'
        | 'BA'
        | 'BB'
        | 'BD'
        | 'BE'
        | 'BF'
        | 'BG'
        | 'BH'
        | 'BI'
        | 'BJ'
        | 'BL'
        | 'BM'
        | 'BN'
        | 'BO'
        | 'BQ'
        | 'BR'
        | 'BS'
        | 'BT'
        | 'BU'
        | 'BV'
        | 'BW'
        | 'BY'
        | 'BZ'
        | 'CA'
        | 'CC'
        | 'CD'
        | 'CF'
        | 'CG'
        | 'CH'
        | 'CI'
        | 'CK'
        | 'CL'
        | 'CM'
        | 'CN'
        | 'CO'
        | 'CP'
        | 'CR'
        | 'CS'
        | 'CU'
        | 'CV'
        | 'CW'
        | 'CX'
        | 'CY'
        | 'CZ'
        | 'DE'
        | 'DG'
        | 'DJ'
        | 'DK'
        | 'DM'
        | 'DO'
        | 'DZ'
        | 'EA'
        | 'EC'
        | 'EE'
        | 'EG'
        | 'EH'
        | 'ER'
        | 'ES'
        | 'ET'
        | 'EU'
        | 'EZ'
        | 'FI'
        | 'FJ'
        | 'FK'
        | 'FM'
        | 'FO'
        | 'FR'
        | 'FX'
        | 'GA'
        | 'GB'
        | 'GD'
        | 'GE'
        | 'GF'
        | 'GG'
        | 'GH'
        | 'GI'
        | 'GL'
        | 'GM'
        | 'GN'
        | 'GP'
        | 'GQ'
        | 'GR'
        | 'GS'
        | 'GT'
        | 'GU'
        | 'GW'
        | 'GY'
        | 'HK'
        | 'HM'
        | 'HN'
        | 'HR'
        | 'HT'
        | 'HU'
        | 'IC'
        | 'ID'
        | 'IE'
        | 'IL'
        | 'IM'
        | 'IN'
        | 'IO'
        | 'IQ'
        | 'IR'
        | 'IS'
        | 'IT'
        | 'JE'
        | 'JM'
        | 'JO'
        | 'JP'
        | 'KE'
        | 'KG'
        | 'KH'
        | 'KI'
        | 'KM'
        | 'KN'
        | 'KP'
        | 'KR'
        | 'KW'
        | 'KY'
        | 'KZ'
        | 'LA'
        | 'LB'
        | 'LC'
        | 'LI'
        | 'LK'
        | 'LR'
        | 'LS'
        | 'LT'
        | 'LU'
        | 'LV'
        | 'LY'
        | 'MA'
        | 'MC'
        | 'MD'
        | 'ME'
        | 'MF'
        | 'MG'
        | 'MH'
        | 'MK'
        | 'ML'
        | 'MM'
        | 'MN'
        | 'MO'
        | 'MP'
        | 'MQ'
        | 'MR'
        | 'MS'
        | 'MT'
        | 'MU'
        | 'MV'
        | 'MW'
        | 'MX'
        | 'MY'
        | 'MZ'
        | 'NA'
        | 'NC'
        | 'NE'
        | 'NF'
        | 'NG'
        | 'NI'
        | 'NL'
        | 'NO'
        | 'NP'
        | 'NR'
        | 'NT'
        | 'NU'
        | 'NZ'
        | 'OM'
        | 'PA'
        | 'PE'
        | 'PF'
        | 'PG'
        | 'PH'
        | 'PK'
        | 'PL'
        | 'PM'
        | 'PN'
        | 'PR'
        | 'PS'
        | 'PT'
        | 'PW'
        | 'PY'
        | 'QA'
        | 'RE'
        | 'RO'
        | 'RS'
        | 'RU'
        | 'RW'
        | 'SA'
        | 'SB'
        | 'SC'
        | 'SD'
        | 'SE'
        | 'SF'
        | 'SG'
        | 'SH'
        | 'SI'
        | 'SJ'
        | 'SK'
        | 'SL'
        | 'SM'
        | 'SN'
        | 'SO'
        | 'SR'
        | 'SS'
        | 'ST'
        | 'SU'
        | 'SV'
        | 'SX'
        | 'SY'
        | 'SZ'
        | 'TA'
        | 'TC'
        | 'TD'
        | 'TF'
        | 'TG'
        | 'TH'
        | 'TJ'
        | 'TK'
        | 'TL'
        | 'TM'
        | 'TN'
        | 'TO'
        | 'TP'
        | 'TR'
        | 'TT'
        | 'TV'
        | 'TW'
        | 'TZ'
        | 'UA'
        | 'UG'
        | 'UK'
        | 'UM'
        | 'US'
        | 'UY'
        | 'UZ'
        | 'VA'
        | 'VC'
        | 'VE'
        | 'VG'
        | 'VI'
        | 'VN'
        | 'VU'
        | 'WF'
        | 'WS'
        | 'XI'
        | 'XU'
        | 'XK'
        | 'YE'
        | 'YT'
        | 'YU'
        | 'ZA'
        | 'ZM'
        | 'ZR'
        | 'ZW';
    marketDefault: boolean;
    promoterDefault: boolean;
    name: string;
    servicePackages: ServicePackageDetailsDto[];
}

export interface AchievementConfigDto {
    /** @format int64 */
    id?: number;
    name: string;
    main: boolean;
    rules: AchievementRules;
}

export interface AchievementRules {
    /** @format int32 */
    pointsGoal: number;
    stepsEnabled: boolean;
    cyclingEnabled: boolean;
    studioVisitEnabled: boolean;
}

export interface AchievementConfigDetailsDto {
    /** @format int64 */
    id?: number;
    name: string;
    main: boolean;
    rules: AchievementRules;
    groups: DictionaryEntryDto[];
}

export interface ChallengeGenerateRequestDto {
    winnerUserIds: string[];
    qualifiedForWinUserIds: string[];
    loserUserIds: string[];
    type: 'TARGET' | 'RACE' | 'HIGH_SCORE';
}

export interface ChallengeGenerateResponseDto {
    /** @format int64 */
    challengeId: number;
}

export interface ChallengeDrawRequestDto {
    /** @format int32 */
    winnersCount: number;
    /** @max 10000 */
    starsReward: number;
}

export interface ChallengeLeaderboardEntryDto {
    participantId?: string;
    name: string;
    email: string;
    /** @format int32 */
    position?: number;
    /** @format int32 */
    activityPoints: number;
    /** @format double */
    progress?: number;
    winner: boolean;
    qualifiedForWin: boolean;
    /** @format date-time */
    wonDate: string;
    tags?: ChallengeParticipantTagResponseDto[];
    reward?: number;
}

export interface ChallengeParticipantTagResponseDto {
    tag: 'INTERNAL' | 'ATHLETE' | 'MANUAL_WINNER';
    scope: 'PARTICIPANT' | 'TENANT';
}

export interface ServicePackageSupportDetailsDto {
    /** @format int64 */
    id?: number;
    name: string;
    /** @deprecated */
    main: boolean;
    businessUnitDefault: boolean;
    achievementConfig: DictionaryEntryDto;
    rewardConfig: DictionaryEntryDto;
    businessUnit: DictionaryEntryDto;
}

export interface SupportPromoterDto {
    /** @format int64 */
    id?: number;
    name: string;
    address?: AddressDto;
    currencyCode?: string;
    promoterType?: 'COMPANY' | 'SPORT_ASSOCIATION';
    currency?: CurrencyDto;
    companyConfiguration?: PromoterConfigurationDto;
    logo?: CdnImageDto;
    /**
     * @deprecated
     * @format int64
     */
    defaultSponsorshipProgramId?: number;
    timeZone: string;
    localeCode: string;
    /** @format int32 */
    employeeCount: number;
    connectionLink: string;
}

export interface PayoutRunSupportDetailsDto {
    /** @format int64 */
    id?: number;
    status: 'IN_GENERATION' | 'GENERATED' | 'PAID' | 'DELETED';
}

export interface MoneyDto {
    value: number;
    /** @format int32 */
    precision: number;
    currency: string;
    currencySymbol?: string;
}

export interface PayoutEntryDto {
    /** @format int64 */
    id?: number;
    employee: PayoutEntryEmployeeDto;
    aggregations: SimplePayoutAggregationsDto;
    /** @format int64 */
    payoutRunId: number;
    newEmployee?: boolean;
}

export interface PayoutEntryEmployeeDto {
    /** @format int64 */
    id?: number;
    firstName: string;
    lastName: string;
    /**
     * @format date
     * @example "2025-01-22"
     */
    dateOfBirth?: string;
    personalNumber?: string;
    employeeGroup?: DictionaryEntryDto;
}

export interface SimplePayoutAggregationsDto {
    sumOfPayout: MoneyDto;
    /** @format int32 */
    employeeCount: number;
}

export interface BusinessUnitDto {
    /** @format int64 */
    id?: number;
    market:
        | 'UNDEFINED'
        | 'AC'
        | 'AD'
        | 'AE'
        | 'AF'
        | 'AG'
        | 'AI'
        | 'AL'
        | 'AM'
        | 'AN'
        | 'AO'
        | 'AQ'
        | 'AR'
        | 'AS'
        | 'AT'
        | 'AU'
        | 'AW'
        | 'AX'
        | 'AZ'
        | 'BA'
        | 'BB'
        | 'BD'
        | 'BE'
        | 'BF'
        | 'BG'
        | 'BH'
        | 'BI'
        | 'BJ'
        | 'BL'
        | 'BM'
        | 'BN'
        | 'BO'
        | 'BQ'
        | 'BR'
        | 'BS'
        | 'BT'
        | 'BU'
        | 'BV'
        | 'BW'
        | 'BY'
        | 'BZ'
        | 'CA'
        | 'CC'
        | 'CD'
        | 'CF'
        | 'CG'
        | 'CH'
        | 'CI'
        | 'CK'
        | 'CL'
        | 'CM'
        | 'CN'
        | 'CO'
        | 'CP'
        | 'CR'
        | 'CS'
        | 'CU'
        | 'CV'
        | 'CW'
        | 'CX'
        | 'CY'
        | 'CZ'
        | 'DE'
        | 'DG'
        | 'DJ'
        | 'DK'
        | 'DM'
        | 'DO'
        | 'DZ'
        | 'EA'
        | 'EC'
        | 'EE'
        | 'EG'
        | 'EH'
        | 'ER'
        | 'ES'
        | 'ET'
        | 'EU'
        | 'EZ'
        | 'FI'
        | 'FJ'
        | 'FK'
        | 'FM'
        | 'FO'
        | 'FR'
        | 'FX'
        | 'GA'
        | 'GB'
        | 'GD'
        | 'GE'
        | 'GF'
        | 'GG'
        | 'GH'
        | 'GI'
        | 'GL'
        | 'GM'
        | 'GN'
        | 'GP'
        | 'GQ'
        | 'GR'
        | 'GS'
        | 'GT'
        | 'GU'
        | 'GW'
        | 'GY'
        | 'HK'
        | 'HM'
        | 'HN'
        | 'HR'
        | 'HT'
        | 'HU'
        | 'IC'
        | 'ID'
        | 'IE'
        | 'IL'
        | 'IM'
        | 'IN'
        | 'IO'
        | 'IQ'
        | 'IR'
        | 'IS'
        | 'IT'
        | 'JE'
        | 'JM'
        | 'JO'
        | 'JP'
        | 'KE'
        | 'KG'
        | 'KH'
        | 'KI'
        | 'KM'
        | 'KN'
        | 'KP'
        | 'KR'
        | 'KW'
        | 'KY'
        | 'KZ'
        | 'LA'
        | 'LB'
        | 'LC'
        | 'LI'
        | 'LK'
        | 'LR'
        | 'LS'
        | 'LT'
        | 'LU'
        | 'LV'
        | 'LY'
        | 'MA'
        | 'MC'
        | 'MD'
        | 'ME'
        | 'MF'
        | 'MG'
        | 'MH'
        | 'MK'
        | 'ML'
        | 'MM'
        | 'MN'
        | 'MO'
        | 'MP'
        | 'MQ'
        | 'MR'
        | 'MS'
        | 'MT'
        | 'MU'
        | 'MV'
        | 'MW'
        | 'MX'
        | 'MY'
        | 'MZ'
        | 'NA'
        | 'NC'
        | 'NE'
        | 'NF'
        | 'NG'
        | 'NI'
        | 'NL'
        | 'NO'
        | 'NP'
        | 'NR'
        | 'NT'
        | 'NU'
        | 'NZ'
        | 'OM'
        | 'PA'
        | 'PE'
        | 'PF'
        | 'PG'
        | 'PH'
        | 'PK'
        | 'PL'
        | 'PM'
        | 'PN'
        | 'PR'
        | 'PS'
        | 'PT'
        | 'PW'
        | 'PY'
        | 'QA'
        | 'RE'
        | 'RO'
        | 'RS'
        | 'RU'
        | 'RW'
        | 'SA'
        | 'SB'
        | 'SC'
        | 'SD'
        | 'SE'
        | 'SF'
        | 'SG'
        | 'SH'
        | 'SI'
        | 'SJ'
        | 'SK'
        | 'SL'
        | 'SM'
        | 'SN'
        | 'SO'
        | 'SR'
        | 'SS'
        | 'ST'
        | 'SU'
        | 'SV'
        | 'SX'
        | 'SY'
        | 'SZ'
        | 'TA'
        | 'TC'
        | 'TD'
        | 'TF'
        | 'TG'
        | 'TH'
        | 'TJ'
        | 'TK'
        | 'TL'
        | 'TM'
        | 'TN'
        | 'TO'
        | 'TP'
        | 'TR'
        | 'TT'
        | 'TV'
        | 'TW'
        | 'TZ'
        | 'UA'
        | 'UG'
        | 'UK'
        | 'UM'
        | 'US'
        | 'UY'
        | 'UZ'
        | 'VA'
        | 'VC'
        | 'VE'
        | 'VG'
        | 'VI'
        | 'VN'
        | 'VU'
        | 'WF'
        | 'WS'
        | 'XI'
        | 'XU'
        | 'XK'
        | 'YE'
        | 'YT'
        | 'YU'
        | 'ZA'
        | 'ZM'
        | 'ZR'
        | 'ZW';
    marketDefault: boolean;
    name: I18NText;
}

export interface OpenZonedDateTimeRangeDto {
    /** @format date-time */
    from: string;
    /** @format date-time */
    to?: string;
}

export interface PageParticipantDto {
    content: ParticipantDto[];
    /** @format int32 */
    number?: number;
    /** @format int32 */
    size?: number;
    /** @format int32 */
    totalPages?: number;
    /** @format int64 */
    totalElements?: number;
}

export interface ParticipantDto {
    /** @format int64 */
    id?: number;
    mySportsId?: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified?: boolean;
    /**
     * @format date
     * @example "2025-01-22"
     */
    dateOfBirth?: string;
    personalNumber?: string;
    state?:
        | 'INACTIVE'
        | 'ACTIVE'
        | 'BLOCKED'
        | 'INVITED'
        | 'AUTOMATICALLY_CREATED'
        | 'DELETED';
    /** @format int64 */
    companyId?: number;
    employeeGroup: DictionaryEntryDto;
    servicePackage: DictionaryEntryDto;
    businessUnit: BusinessUnitDto;
    redirectUri?: string;
    /** @format date-time */
    connectedDate?: string;
    /** @format date-time */
    lastSyncDate?: string;
    activityGroup:
        | 'ACTIVE_LAST_2_WEEKS'
        | 'ACTIVE_LAST_4_WEEKS'
        | 'ACTIVE_LAST_8_WEEKS'
        | 'INACTIVE'
        | 'NEVER_ACTIVE';
    /** @deprecated */
    recentlySynchronized?: boolean;
    participantLicense?: ParticipantLicenseDto;
}

export interface ParticipantLicenseDto {
    /** @format int64 */
    id: number;
    activeNow: boolean;
    validRange: OpenZonedDateTimeRangeDto;
    changeReason?: string;
}

export interface EmployeeDto {
    /** @format int64 */
    id?: number;
    mySportsId?: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified?: boolean;
    /**
     * @format date
     * @example "2025-01-22"
     */
    dateOfBirth?: string;
    personalNumber?: string;
    state?:
        | 'INACTIVE'
        | 'ACTIVE'
        | 'BLOCKED'
        | 'INVITED'
        | 'AUTOMATICALLY_CREATED'
        | 'DELETED';
    /** @format int64 */
    companyId?: number;
    employeeGroup: DictionaryEntryDto;
    redirectUri?: string;
    /** @format date-time */
    connectedDate?: string;
    /** @format date-time */
    lastSyncDate?: string;
    licensed: boolean;
}

export interface PageEmployeeDto {
    content: EmployeeDto[];
    /** @format int32 */
    number?: number;
    /** @format int32 */
    size?: number;
    /** @format int32 */
    totalPages?: number;
    /** @format int64 */
    totalElements?: number;
}

export interface PageCompanyAccountDto {
    content: CompanyAccountDto[];
    /** @format int32 */
    number?: number;
    /** @format int32 */
    size?: number;
    /** @format int32 */
    totalPages?: number;
    /** @format int64 */
    totalElements?: number;
}

export interface BusinessUnitPageDto {
    /** @format int64 */
    id?: number;
    market:
        | 'UNDEFINED'
        | 'AC'
        | 'AD'
        | 'AE'
        | 'AF'
        | 'AG'
        | 'AI'
        | 'AL'
        | 'AM'
        | 'AN'
        | 'AO'
        | 'AQ'
        | 'AR'
        | 'AS'
        | 'AT'
        | 'AU'
        | 'AW'
        | 'AX'
        | 'AZ'
        | 'BA'
        | 'BB'
        | 'BD'
        | 'BE'
        | 'BF'
        | 'BG'
        | 'BH'
        | 'BI'
        | 'BJ'
        | 'BL'
        | 'BM'
        | 'BN'
        | 'BO'
        | 'BQ'
        | 'BR'
        | 'BS'
        | 'BT'
        | 'BU'
        | 'BV'
        | 'BW'
        | 'BY'
        | 'BZ'
        | 'CA'
        | 'CC'
        | 'CD'
        | 'CF'
        | 'CG'
        | 'CH'
        | 'CI'
        | 'CK'
        | 'CL'
        | 'CM'
        | 'CN'
        | 'CO'
        | 'CP'
        | 'CR'
        | 'CS'
        | 'CU'
        | 'CV'
        | 'CW'
        | 'CX'
        | 'CY'
        | 'CZ'
        | 'DE'
        | 'DG'
        | 'DJ'
        | 'DK'
        | 'DM'
        | 'DO'
        | 'DZ'
        | 'EA'
        | 'EC'
        | 'EE'
        | 'EG'
        | 'EH'
        | 'ER'
        | 'ES'
        | 'ET'
        | 'EU'
        | 'EZ'
        | 'FI'
        | 'FJ'
        | 'FK'
        | 'FM'
        | 'FO'
        | 'FR'
        | 'FX'
        | 'GA'
        | 'GB'
        | 'GD'
        | 'GE'
        | 'GF'
        | 'GG'
        | 'GH'
        | 'GI'
        | 'GL'
        | 'GM'
        | 'GN'
        | 'GP'
        | 'GQ'
        | 'GR'
        | 'GS'
        | 'GT'
        | 'GU'
        | 'GW'
        | 'GY'
        | 'HK'
        | 'HM'
        | 'HN'
        | 'HR'
        | 'HT'
        | 'HU'
        | 'IC'
        | 'ID'
        | 'IE'
        | 'IL'
        | 'IM'
        | 'IN'
        | 'IO'
        | 'IQ'
        | 'IR'
        | 'IS'
        | 'IT'
        | 'JE'
        | 'JM'
        | 'JO'
        | 'JP'
        | 'KE'
        | 'KG'
        | 'KH'
        | 'KI'
        | 'KM'
        | 'KN'
        | 'KP'
        | 'KR'
        | 'KW'
        | 'KY'
        | 'KZ'
        | 'LA'
        | 'LB'
        | 'LC'
        | 'LI'
        | 'LK'
        | 'LR'
        | 'LS'
        | 'LT'
        | 'LU'
        | 'LV'
        | 'LY'
        | 'MA'
        | 'MC'
        | 'MD'
        | 'ME'
        | 'MF'
        | 'MG'
        | 'MH'
        | 'MK'
        | 'ML'
        | 'MM'
        | 'MN'
        | 'MO'
        | 'MP'
        | 'MQ'
        | 'MR'
        | 'MS'
        | 'MT'
        | 'MU'
        | 'MV'
        | 'MW'
        | 'MX'
        | 'MY'
        | 'MZ'
        | 'NA'
        | 'NC'
        | 'NE'
        | 'NF'
        | 'NG'
        | 'NI'
        | 'NL'
        | 'NO'
        | 'NP'
        | 'NR'
        | 'NT'
        | 'NU'
        | 'NZ'
        | 'OM'
        | 'PA'
        | 'PE'
        | 'PF'
        | 'PG'
        | 'PH'
        | 'PK'
        | 'PL'
        | 'PM'
        | 'PN'
        | 'PR'
        | 'PS'
        | 'PT'
        | 'PW'
        | 'PY'
        | 'QA'
        | 'RE'
        | 'RO'
        | 'RS'
        | 'RU'
        | 'RW'
        | 'SA'
        | 'SB'
        | 'SC'
        | 'SD'
        | 'SE'
        | 'SF'
        | 'SG'
        | 'SH'
        | 'SI'
        | 'SJ'
        | 'SK'
        | 'SL'
        | 'SM'
        | 'SN'
        | 'SO'
        | 'SR'
        | 'SS'
        | 'ST'
        | 'SU'
        | 'SV'
        | 'SX'
        | 'SY'
        | 'SZ'
        | 'TA'
        | 'TC'
        | 'TD'
        | 'TF'
        | 'TG'
        | 'TH'
        | 'TJ'
        | 'TK'
        | 'TL'
        | 'TM'
        | 'TN'
        | 'TO'
        | 'TP'
        | 'TR'
        | 'TT'
        | 'TV'
        | 'TW'
        | 'TZ'
        | 'UA'
        | 'UG'
        | 'UK'
        | 'UM'
        | 'US'
        | 'UY'
        | 'UZ'
        | 'VA'
        | 'VC'
        | 'VE'
        | 'VG'
        | 'VI'
        | 'VN'
        | 'VU'
        | 'WF'
        | 'WS'
        | 'XI'
        | 'XU'
        | 'XK'
        | 'YE'
        | 'YT'
        | 'YU'
        | 'ZA'
        | 'ZM'
        | 'ZR'
        | 'ZW';
    marketDefault: boolean;
    promoterDefault: boolean;
    name: string;
}

export interface PageBusinessUnitPageDto {
    content: BusinessUnitPageDto[];
    /** @format int32 */
    number?: number;
    /** @format int32 */
    size?: number;
    /** @format int32 */
    totalPages?: number;
    /** @format int64 */
    totalElements?: number;
}

export interface PageDictionaryEntryDto {
    content: DictionaryEntryDto[];
    /** @format int32 */
    number?: number;
    /** @format int32 */
    size?: number;
    /** @format int32 */
    totalPages?: number;
    /** @format int64 */
    totalElements?: number;
}

export interface ActivitySimpleDto {
    /** @format int64 */
    id?: number;
    type: 'STEPS' | 'CYCLING' | 'STUDIO_VISIT' | 'WORKOUT';
    /** @format date-time */
    timestamp: string;
    rejected: boolean;
    points: number;
}

export interface PageActivitySimpleDto {
    content: ActivitySimpleDto[];
    /** @format int32 */
    number?: number;
    /** @format int32 */
    size?: number;
    /** @format int32 */
    totalPages?: number;
    /** @format int64 */
    totalElements?: number;
}

export interface KeyValuePairStringObject {
    key?: string;
    value?: object;
}

export interface ActivityDto {
    /** @format int64 */
    id?: number;
    /** @format date-time */
    timestamp: string;
    activityType: 'STEPS' | 'CYCLING' | 'STUDIO_VISIT' | 'WORKOUT';
    studioName?: string;
    payoutInfo: ActivityPayoutInfoDto;
}

export interface ActivityPayoutInfoDto {
    paid: boolean;
    payout?: MoneyDto;
    rejectReason?:
        | 'MIN_STEP_COUNT_NOT_REACHED'
        | 'MIN_STUDIO_VISIT_DURATION_NOT_REACHED'
        | 'MAX_CONTRIBUTIONS_REACHED'
        | 'MAX_PAYOUT_REACHED'
        | 'HIGHER_REWARD_REACHED'
        | 'UNKNOWN';
    sponsoringGoalReached: boolean;
}

export interface AutomaticRewardsConfigurationDto {
    rewards?: WinningPlaceReward[];
}

export interface ChallengeConfigurationDto {
    stepStatus?: 'EMPTY' | 'INCOMPLETE' | 'COMPLETE';
    pointsCountingType: 'ABSOLUTE' | 'AVERAGE';
    /** @format int32 */
    allowedWinnersCount?: number;
    pointsGoal?: number;
    leaderboardEnabled?: boolean;
}

export interface ChallengeDetailsDto {
    nameStep: ChallengeNameStep;
    rulesStep: ChallengeRulesStep;
    presentationStep: ChallengePresentationStep;
    participantsStep: ChallengeParticipantsStep;
    sponsorsStep: ChallengeSponsorsStep;
    organizerStep?: ChallengeOrganizerStep;
    /** @format int64 */
    id: number;
    challengeStatus:
        | 'DRAFT'
        | 'SCHEDULED'
        | 'IN_PROGRESS'
        | 'FINISHED'
        | 'CANCELED';
    participantsInfo: ChallengeParticipantsInfoDto;
    /** @format date-time */
    publicationDateTime: string;
    published: boolean;
}

export interface ChallengeExclusionDto {
    programs: ChallengeProgramExclusionDto[];
}

export interface ChallengeNameStep {
    stepStatus?: 'EMPTY' | 'INCOMPLETE' | 'COMPLETE';
    name: string;
    nameI18n?: I18NText;
}

export interface ChallengeOrganizerStep {
    organizer?: OrganizerPageElementDto;
}

export interface ChallengeParticipantsInfoDto {
    /** @format int32 */
    activeParticipants: number;
    /** @format int32 */
    availableParticipants: number;
}

export interface ChallengeParticipantsStep {
    stepStatus?: 'EMPTY' | 'INCOMPLETE' | 'COMPLETE';
    groupsSelection?: 'ALL' | 'SELECTED';
    /** @uniqueItems true */
    employeeGroupIds?: number[];
    challengeExclusion?: ChallengeExclusionDto;
}

export interface ChallengePresentationStep {
    stepStatus?: 'EMPTY' | 'INCOMPLETE' | 'COMPLETE';
    photo?: CdnImageDto;
    /** @deprecated */
    description?: string;
    descriptionI18n?: I18NText;
}

export interface ChallengeProgramExclusionDto {
    types: ('INTERNAL' | 'ATHLETE')[];
    tenantName: string;
    servicePackages?: ServicePackageExclusion[];
}

export interface ChallengeRulesStep {
    stepStatus?: 'EMPTY' | 'INCOMPLETE' | 'COMPLETE';
    autoRenew?: boolean;
    dateTimeRange?: ZonedDateTimeRangeDto;
    /** @uniqueItems true */
    supportedActivityTypes?: ('STEPS' | 'CYCLING' | 'STUDIO_VISIT')[];
    /** @deprecated */
    prizeDescription?: string;
    prizeDescriptionI18n?: I18NText;
    challengeType?: 'TARGET' | 'RACE' | 'HIGH_SCORE';
    qualificationThreshold?: number;
    participantConfiguration?: ChallengeConfigurationDto;
    promoterConfiguration?: ChallengeConfigurationDto;
    automaticRewardsConfiguration?: AutomaticRewardsConfigurationDto;
    /** @format date-time */
    publicationDateTime?: string;
    published?: boolean;
}

export interface ChallengeSponsorsStep {
    sponsors: SponsorPageElementDto[];
}

export interface OrganizerPageElementDto {
    /** @format int64 */
    id: number;
    name: string;
    logo: CdnImageDto;
}

export interface ServicePackageExclusion {
    type?: 'INTERNAL' | 'ATHLETE';
    /** @format int64 */
    servicePackageId: number;
}

export interface SponsorPageElementDto {
    /** @format int64 */
    id: number;
    name: string;
    logo: CdnImageDto;
}

export interface WinningPlaceReward {
    /**
     * @format int32
     * @max 100
     */
    place: number;
    /** @max 10000 */
    starsCount: number;
}

export interface ZonedDateTimeRangeDto {
    /** @format date-time */
    from: string;
    /** @format date-time */
    to: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<
    FullRequestParams,
    'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
    extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '/fitness-activity-api';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
        fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(
            (key) => 'undefined' !== typeof query[key]
        );
        return keys
            .map((key) =>
                Array.isArray(query[key])
                    ? this.addArrayQueryParam(query, key)
                    : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null &&
            (typeof input === 'object' || typeof input === 'string')
                ? JSON.stringify(input)
                : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string'
                ? JSON.stringify(input)
                : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                          ? JSON.stringify(property)
                          : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
    };

    protected mergeRequestParams(
        params1: RequestParams,
        params2?: RequestParams
    ): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected createAbortSignal = (
        cancelToken: CancelToken
    ): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean'
                ? secure
                : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter =
            this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(
            `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
            {
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData
                        ? { 'Content-Type': type }
                        : {})
                },
                signal:
                    (cancelToken
                        ? this.createAbortSignal(cancelToken)
                        : requestParams.signal) || null,
                body:
                    typeof body === 'undefined' || body === null
                        ? null
                        : payloadFormatter(body)
            }
        ).then(async (response) => {
            const r = response.clone() as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then((data) => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch((e) => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title MySports Corporate API
 * @version v1
 * @license MySports License (https://www.mysports.com)
 * @baseUrl /fitness-activity-api
 * @contact Move Republic <move@mysports-rewards.com>
 *
 * API handling MySports Corporate use cases
 */
export class Api<
    SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
    support = {
        /**
         * No description
         *
         * @tags tenant-support-v-2-controller
         * @name TenantSupportV2ControllerOnboardTenant
         * @request PUT:/support/v2/tenants/{tenantId}
         * @secure
         */
        tenantSupportV2ControllerOnboardTenant: (
            tenantId: string,
            data: TenantOnboardingDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v2/tenants/${tenantId}`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-support-v-2-controller
         * @name TenantSupportV2ControllerDeactivateTenant
         * @request PUT:/support/v2/tenants/{tenantId}/deactivate
         * @secure
         */
        tenantSupportV2ControllerDeactivateTenant: (
            tenantId: string,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v2/tenants/${tenantId}/deactivate`,
                method: 'PUT',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-support-v-2-controller
         * @name TenantSupportV2ControllerActivateTenant
         * @request PUT:/support/v2/tenants/{tenantId}/activate
         * @secure
         */
        tenantSupportV2ControllerActivateTenant: (
            tenantId: string,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v2/tenants/${tenantId}/activate`,
                method: 'PUT',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-support-v-2-controller
         * @name TenantSupportV2ControllerUpdateCurrentTenant
         * @request PUT:/support/v2/tenants/current
         * @secure
         */
        tenantSupportV2ControllerUpdateCurrentTenant: (
            data: TenantOnboardingDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v2/tenants/current`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags corporate-support-controller
         * @name CorporateSupportControllerCreateSession
         * @request PUT:/support/v1/sessions
         * @secure
         */
        corporateSupportControllerCreateSession: (
            query?: {
                email?: string;
                firstName?: string;
                lastName?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<RedirectDto, any>({
                path: `/support/v1/sessions`,
                method: 'PUT',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags news-support-controller
         * @name NewsSupportControllerUpdate
         * @request PUT:/support/v1/news/{id}
         * @secure
         */
        newsSupportControllerUpdate: (
            id: number,
            data: NewsUpdateSupportDto,
            params: RequestParams = {}
        ) =>
            this.request<NewsDto, any>({
                path: `/support/v1/news/${id}`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags currency-support-controller
         * @name CurrencySupportControllerGetOne
         * @request GET:/support/v1/currencies/{id}
         * @secure
         */
        currencySupportControllerGetOne: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<CurrencyDto, any>({
                path: `/support/v1/currencies/${id}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags currency-support-controller
         * @name CurrencySupportControllerUpdate
         * @request PUT:/support/v1/currencies/{id}
         * @secure
         */
        currencySupportControllerUpdate: (
            id: number,
            data: CurrencyDto,
            params: RequestParams = {}
        ) =>
            this.request<CurrencyDto, any>({
                path: `/support/v1/currencies/${id}`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags currency-support-controller
         * @name CurrencySupportControllerDelete
         * @request DELETE:/support/v1/currencies/{id}
         * @secure
         */
        currencySupportControllerDelete: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/currencies/${id}`,
                method: 'DELETE',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-support-v-2-controller
         * @name TenantSupportV2ControllerCreateTenant
         * @request POST:/support/v2/tenants
         * @secure
         */
        tenantSupportV2ControllerCreateTenant: (
            data: TenantOnboardingDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v2/tenants`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-support-v-2-controller
         * @name TenantSupportV2ControllerConfirmTenantOnboarding
         * @request POST:/support/v2/tenants/{tenantId}/confirmation
         * @secure
         */
        tenantSupportV2ControllerConfirmTenantOnboarding: (
            tenantId: string,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v2/tenants/${tenantId}/confirmation`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags wallet-support-controller
         * @name WalletSupportControllerTopUpWalletAccounts
         * @request POST:/support/v1/wallet-account-top-ups
         * @secure
         */
        walletSupportControllerTopUpWalletAccounts: (
            data: ApprovedMoneyTopUpDto,
            params: RequestParams = {}
        ) =>
            this.request<ApprovedMoneyTopUpSummaryDto, any>({
                path: `/support/v1/wallet-account-top-ups`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags service-package-support-controller
         * @name ServicePackageSupportControllerFindAllServicePackagesDetails
         * @request GET:/support/v1/service-packages
         * @secure
         */
        servicePackageSupportControllerFindAllServicePackagesDetails: (
            params: RequestParams = {}
        ) =>
            this.request<ServicePackageSupportDetailsDto[], any>({
                path: `/support/v1/service-packages`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags service-package-support-controller
         * @name ServicePackageSupportControllerCreate
         * @request POST:/support/v1/service-packages
         * @secure
         */
        servicePackageSupportControllerCreate: (
            data: ServicePackageCreateDto,
            params: RequestParams = {}
        ) =>
            this.request<ServicePackageDetailsDto, any>({
                path: `/support/v1/service-packages`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags service-package-support-controller
         * @name ServicePackageSupportControllerAssign
         * @request POST:/support/v1/service-packages/{servicePackageId}/employees
         * @secure
         */
        servicePackageSupportControllerAssign: (
            servicePackageId: number,
            data: ServicePackageAssignDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/service-packages/${servicePackageId}/employees`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags corporate-support-controller
         * @name CorporateSupportControllerMoveMessages
         * @request POST:/support/v1/rabbitmq/movemessages
         * @secure
         */
        corporateSupportControllerMoveMessages: (
            data: MoveMessagesDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/rabbitmq/movemessages`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags payout-run-support-controller
         * @name PayoutRunSupportControllerGeneratePayoutRun
         * @request POST:/support/v1/payoutruns
         * @secure
         */
        payoutRunSupportControllerGeneratePayoutRun: (
            query: {
                tenantId: string;
                yearMonth: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/payoutruns`,
                method: 'POST',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags payout-run-support-controller
         * @name PayoutRunSupportControllerForcePayoutRunReportRegeneration
         * @request POST:/support/v1/payoutruns/{id}/generate-report
         * @secure
         */
        payoutRunSupportControllerForcePayoutRunReportRegeneration: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/payoutruns/${id}/generate-report`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags payout-run-support-controller
         * @name PayoutRunSupportControllerAddEmployeeToPayoutRun
         * @request POST:/support/v1/payoutrun/{payoutRunId}/entries
         * @secure
         */
        payoutRunSupportControllerAddEmployeeToPayoutRun: (
            payoutRunId: number,
            query: {
                /** @format int64 */
                employeeId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/payoutrun/${payoutRunId}/entries`,
                method: 'POST',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags payout-run-support-controller
         * @name PayoutRunSupportControllerApproveEntriesInPayoutRun
         * @request POST:/support/v1/payoutrun/{payoutRunId}/approve-entries
         * @secure
         */
        payoutRunSupportControllerApproveEntriesInPayoutRun: (
            payoutRunId: number,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/payoutrun/${payoutRunId}/approve-entries`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags payout-run-support-controller
         * @name PayoutRunSupportControllerRecalculatePayoutEntries
         * @request POST:/support/v1/payoutentries/recalculate
         * @secure
         */
        payoutRunSupportControllerRecalculatePayoutEntries: (
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/payoutentries/recalculate`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags news-support-controller
         * @name NewsSupportControllerCreate
         * @request POST:/support/v1/news
         * @secure
         */
        newsSupportControllerCreate: (
            data: NewsCreateSupportDto,
            params: RequestParams = {}
        ) =>
            this.request<NewsDto, any>({
                path: `/support/v1/news`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags master-database-synchronization-support-controller
         * @name MasterDatabaseSynchronizationSupportControllerSynchronizePromoters
         * @request POST:/support/v1/master-db-syncs/promoters
         * @secure
         */
        masterDatabaseSynchronizationSupportControllerSynchronizePromoters: (
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/master-db-syncs/promoters`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags master-database-synchronization-support-controller
         * @name MasterDatabaseSynchronizationSupportControllerSynchronizeEmployeeCount
         * @request POST:/support/v1/master-db-syncs/employee-count
         * @secure
         */
        masterDatabaseSynchronizationSupportControllerSynchronizeEmployeeCount:
            (params: RequestParams = {}) =>
                this.request<void, any>({
                    path: `/support/v1/master-db-syncs/employee-count`,
                    method: 'POST',
                    secure: true,
                    ...params
                }),

        /**
         * No description
         *
         * @tags corporate-jobs-controller
         * @name CorporateJobsControllerRunJob
         * @request POST:/support/v1/jobs/run
         * @secure
         */
        corporateJobsControllerRunJob: (
            data: SupportJobDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/jobs/run`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags payout-run-support-controller
         * @name PayoutRunSupportControllerAddEmployeeToPayoutRunDeprecated
         * @request POST:/support/v1/employeegrouppayoutruns/{servicePackagePayoutRunId}/entries
         * @deprecated
         * @secure
         */
        payoutRunSupportControllerAddEmployeeToPayoutRunDeprecated: (
            servicePackagePayoutRunId: number,
            query: {
                /** @format int64 */
                employeeId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/employeegrouppayoutruns/${servicePackagePayoutRunId}/entries`,
                method: 'POST',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags daily-goal-support-controller
         * @name DailyGoalSupportControllerRecalculateDailyGoals
         * @request POST:/support/v1/daily-goals/recalculate
         * @secure
         */
        dailyGoalSupportControllerRecalculateDailyGoals: (
            query: {
                /** @format date */
                from: string;
                /** @format date */
                to: string;
                /** @format int64 */
                employeeId: number;
                grantRewardsForPastDailyGoals?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/daily-goals/recalculate`,
                method: 'POST',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags daily-goal-support-controller
         * @name DailyGoalSupportControllerInitDailyGoals
         * @request POST:/support/v1/daily-goals/init
         * @secure
         */
        dailyGoalSupportControllerInitDailyGoals: (
            query: {
                /** @format date */
                from: string;
                /** @format date */
                to: string;
                /** @format int64 */
                participantId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/daily-goals/init`,
                method: 'POST',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags currency-support-controller
         * @name CurrencySupportControllerGetList
         * @request GET:/support/v1/currencies
         * @secure
         */
        currencySupportControllerGetList: (params: RequestParams = {}) =>
            this.request<CurrencyDto[], any>({
                path: `/support/v1/currencies`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags currency-support-controller
         * @name CurrencySupportControllerInsert
         * @request POST:/support/v1/currencies
         * @secure
         */
        currencySupportControllerInsert: (
            data: CurrencyDto,
            params: RequestParams = {}
        ) =>
            this.request<CurrencyDto, any>({
                path: `/support/v1/currencies`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags corporate-feature-controller
         * @name CorporateFeatureControllerEnableFeature
         * @request POST:/support/v1/corporatefeatures/{feature}
         * @secure
         */
        corporateFeatureControllerEnableFeature: (
            feature:
                | 'HYBRID_REWARDING_MODEL'
                | 'TYPED_STARS'
                | 'PAYOUT_RUN_GENERATION'
                | 'MANUAL_CHECK_IN'
                | 'CYCLING'
                | 'WORKOUT_IN_APP_TRACKING_ANDROID'
                | 'WORKOUT_IN_APP_TRACKING_IOS'
                | 'EMPLOYEE_GROUPS'
                | 'STATS'
                | 'TOUR_GUIDE'
                | 'LIVE_DATA_STATS'
                | 'SPONSOR'
                | 'ORGANIZER'
                | 'NEWS_TEMPLATE'
                | 'LEAGUES_AND_TEAMS'
                | 'ACTIVITY_POINTS'
                | 'CORPORATE_APP_NAVBAR'
                | 'MYSPORTS_TEAMS_APP_MIGRATION'
                | 'MYSPORTS_TEAMS_COURSE_BANNER'
                | 'AI_ASSISTANT'
                | 'COMMUNITIES'
                | 'NEW_PARTICIPANT_EMAIL'
                | 'PARTICIPANT_LEFT_EMAIL'
                | 'CHALLENGES'
                | 'FITNESS_APPS'
                | 'STRAVA'
                | 'GARMIN'
                | 'FITBIT'
                | 'POLAR'
                | 'HEALTH_CONNECT',
            params: RequestParams = {}
        ) =>
            this.request<CorporateFeatureGroupDto[], any>({
                path: `/support/v1/corporatefeatures/${feature}`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags corporate-feature-controller
         * @name CorporateFeatureControllerDisableFeature
         * @request DELETE:/support/v1/corporatefeatures/{feature}
         * @secure
         */
        corporateFeatureControllerDisableFeature: (
            feature:
                | 'HYBRID_REWARDING_MODEL'
                | 'TYPED_STARS'
                | 'PAYOUT_RUN_GENERATION'
                | 'MANUAL_CHECK_IN'
                | 'CYCLING'
                | 'WORKOUT_IN_APP_TRACKING_ANDROID'
                | 'WORKOUT_IN_APP_TRACKING_IOS'
                | 'EMPLOYEE_GROUPS'
                | 'STATS'
                | 'TOUR_GUIDE'
                | 'LIVE_DATA_STATS'
                | 'SPONSOR'
                | 'ORGANIZER'
                | 'NEWS_TEMPLATE'
                | 'LEAGUES_AND_TEAMS'
                | 'ACTIVITY_POINTS'
                | 'CORPORATE_APP_NAVBAR'
                | 'MYSPORTS_TEAMS_APP_MIGRATION'
                | 'MYSPORTS_TEAMS_COURSE_BANNER'
                | 'AI_ASSISTANT'
                | 'COMMUNITIES'
                | 'NEW_PARTICIPANT_EMAIL'
                | 'PARTICIPANT_LEFT_EMAIL'
                | 'CHALLENGES'
                | 'FITNESS_APPS'
                | 'STRAVA'
                | 'GARMIN'
                | 'FITBIT'
                | 'POLAR'
                | 'HEALTH_CONNECT',
            params: RequestParams = {}
        ) =>
            this.request<CorporateFeatureGroupDto[], any>({
                path: `/support/v1/corporatefeatures/${feature}`,
                method: 'DELETE',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags company-account-support-controller
         * @name CompanyAccountSupportControllerCreateCompanyAccount
         * @request POST:/support/v1/companyaccounts/verified
         * @secure
         */
        companyAccountSupportControllerCreateCompanyAccount: (
            query: {
                password: string;
            },
            data: CompanyAccountDto,
            params: RequestParams = {}
        ) =>
            this.request<CompanyAccountDto, any>({
                path: `/support/v1/companyaccounts/verified`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags business-unit-support-controller
         * @name BusinessUnitSupportControllerGetPage
         * @request GET:/support/v1/business-units
         * @secure
         */
        businessUnitSupportControllerGetPage: (
            query?: {
                /** @format int64 */
                employeeGroupId?: number;
                /**
                 * @format int32
                 * @min 0
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 1000
                 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageBusinessUnitPageDto, any>({
                path: `/support/v1/business-units`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags business-unit-support-controller
         * @name BusinessUnitSupportControllerCreate
         * @request POST:/support/v1/business-units
         * @secure
         */
        businessUnitSupportControllerCreate: (
            data: BusinessUnitCreateDto,
            params: RequestParams = {}
        ) =>
            this.request<BusinessUnitWithServicePackagesDto, any>({
                path: `/support/v1/business-units`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags achievement-config-support-controller
         * @name AchievementConfigSupportControllerCreate
         * @request POST:/support/v1/achievement-configs
         * @secure
         */
        achievementConfigSupportControllerCreate: (
            data: AchievementConfigDto,
            params: RequestParams = {}
        ) =>
            this.request<AchievementConfigDetailsDto, any>({
                path: `/support/v1/achievement-configs`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-e-2-e-controller
         * @name ChallengeE2EControllerGenerateChallenge
         * @request POST:/support/e2e/v1/challenges
         * @secure
         */
        challengeE2EControllerGenerateChallenge: (
            data: ChallengeGenerateRequestDto,
            params: RequestParams = {}
        ) =>
            this.request<ChallengeGenerateResponseDto, any>({
                path: `/support/e2e/v1/challenges`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-e-2-e-controller
         * @name ChallengeE2EControllerDraw
         * @request POST:/support/e2e/v1/challenges/{id}/draw
         * @secure
         */
        challengeE2EControllerDraw: (
            id: number,
            data: ChallengeDrawRequestDto,
            params: RequestParams = {}
        ) =>
            this.request<ChallengeLeaderboardEntryDto[], any>({
                path: `/support/e2e/v1/challenges/${id}/draw`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags promoter-support-controller
         * @name PromoterSupportControllerGetPromoter
         * @request GET:/support/v1/promoters/current
         * @secure
         */
        promoterSupportControllerGetPromoter: (params: RequestParams = {}) =>
            this.request<SupportPromoterDto, any>({
                path: `/support/v1/promoters/current`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags payout-run-support-controller
         * @name PayoutRunSupportControllerGetPayoutRun
         * @request GET:/support/v1/payoutruns/{yearMonthString}
         * @secure
         */
        payoutRunSupportControllerGetPayoutRun: (
            yearMonthString: string,
            params: RequestParams = {}
        ) =>
            this.request<PayoutRunSupportDetailsDto[], any>({
                path: `/support/v1/payoutruns/${yearMonthString}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags payout-run-support-controller
         * @name PayoutRunSupportControllerGetPayoutEntries
         * @request GET:/support/v1/payoutruns/{payoutRunId}/entries
         * @secure
         */
        payoutRunSupportControllerGetPayoutEntries: (
            payoutRunId: number,
            params: RequestParams = {}
        ) =>
            this.request<PayoutEntryDto[], any>({
                path: `/support/v1/payoutruns/${payoutRunId}/entries`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags participant-support-controller
         * @name ParticipantSupportControllerGetPage
         * @request GET:/support/v1/participants
         * @secure
         */
        participantSupportControllerGetPage: (
            query?: {
                /** @format int64 */
                employeeGroupId?: number;
                /** @format int64 */
                businessUnitId?: number;
                /** @format int64 */
                marketId?: number;
                /** @format int64 */
                prioritizeGroupWithId?: number;
                showBlocked?: boolean;
                mySportsId?: string;
                excludeMySportsIds?: string;
                /**
                 * @format int32
                 * @min 0
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 1000
                 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageParticipantDto, any>({
                path: `/support/v1/participants`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags participant-support-controller
         * @name ParticipantSupportControllerGetParticipant
         * @request GET:/support/v1/participants/{id}
         * @secure
         */
        participantSupportControllerGetParticipant: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<ParticipantDto, any>({
                path: `/support/v1/participants/${id}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags participant-support-controller
         * @name ParticipantSupportControllerGetParticipantByMySportsId
         * @request GET:/support/v1/participants/by-mysports-id/{mySportsId}
         * @secure
         */
        participantSupportControllerGetParticipantByMySportsId: (
            mySportsId: string,
            params: RequestParams = {}
        ) =>
            this.request<ParticipantDto, any>({
                path: `/support/v1/participants/by-mysports-id/${mySportsId}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags corporate-jobs-controller
         * @name CorporateJobsControllerGetAvailableJobs
         * @request GET:/support/v1/jobs
         * @secure
         */
        corporateJobsControllerGetAvailableJobs: (params: RequestParams = {}) =>
            this.request<SupportJobDto[], any>({
                path: `/support/v1/jobs`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags employee-support-controller
         * @name EmployeeSupportControllerGetPage
         * @request GET:/support/v1/employees
         * @secure
         */
        employeeSupportControllerGetPage: (
            query?: {
                /** @format int64 */
                employeeGroupId?: number;
                /** @format int64 */
                prioritizeGroupWithId?: number;
                showBlocked?: boolean;
                mySportsId?: string;
                /**
                 * @format int32
                 * @min 0
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 1000
                 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageEmployeeDto, any>({
                path: `/support/v1/employees`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags employee-support-controller
         * @name EmployeeSupportControllerGetEmployee
         * @request GET:/support/v1/employees/{id}
         * @secure
         */
        employeeSupportControllerGetEmployee: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<EmployeeDto, any>({
                path: `/support/v1/employees/${id}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags corporate-feature-group-controller
         * @name CorporateFeatureGroupControllerGetFeatureGroups
         * @request GET:/support/v1/corporate-feature-groups
         * @secure
         */
        corporateFeatureGroupControllerGetFeatureGroups: (
            params: RequestParams = {}
        ) =>
            this.request<CorporateFeatureGroupDto[], any>({
                path: `/support/v1/corporate-feature-groups`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags company-account-support-controller
         * @name CompanyAccountSupportControllerGetPage
         * @request GET:/support/v1/companyaccounts
         * @secure
         */
        companyAccountSupportControllerGetPage: (
            query?: {
                /**
                 * @format int32
                 * @min 0
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 1000
                 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageCompanyAccountDto, any>({
                path: `/support/v1/companyaccounts`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags company-support-controller
         * @name CompanySupportControllerGetCompanies
         * @request GET:/support/v1/companies/current
         * @deprecated
         * @secure
         */
        companySupportControllerGetCompanies: (params: RequestParams = {}) =>
            this.request<PromoterDto, any>({
                path: `/support/v1/companies/current`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags business-unit-support-controller
         * @name BusinessUnitSupportControllerGetDictionary
         * @request GET:/support/v1/business-units-dictionaries
         * @secure
         */
        businessUnitSupportControllerGetDictionary: (
            params: RequestParams = {}
        ) =>
            this.request<PageDictionaryEntryDto, any>({
                path: `/support/v1/business-units-dictionaries`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-support-controller
         * @name ActivitySupportControllerGetEmployeeActivities
         * @request GET:/support/v1/activities
         * @secure
         */
        activitySupportControllerGetEmployeeActivities: (
            query: {
                /** @format int64 */
                employeeId: number;
                /**
                 * @format int32
                 * @min 0
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 1000
                 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageActivitySimpleDto, any>({
                path: `/support/v1/activities`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-support-controller
         * @name ActivitySupportControllerGetActivity
         * @request GET:/support/v1/activities/{id}
         * @secure
         */
        activitySupportControllerGetActivity: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<KeyValuePairStringObject[], any>({
                path: `/support/v1/activities/${id}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags nox-support-controller
         * @name NoxSupportControllerGetActivitiesHistory
         * @request GET:/support/v1/activities/history
         * @secure
         */
        noxSupportControllerGetActivitiesHistory: (
            query: {
                /** @format int32 */
                year: number;
                /** @format int32 */
                month: number;
                /** @format int64 */
                employeeId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<ActivityDto[], any>({
                path: `/support/v1/activities/history`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-e-2-e-controller
         * @name ChallengeE2EControllerGetChallengeConfiguration
         * @request GET:/support/e2e/v1/challenges/{id}
         * @secure
         */
        challengeE2EControllerGetChallengeConfiguration: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<ChallengeDetailsDto, any>({
                path: `/support/e2e/v1/challenges/${id}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-support-controller
         * @name TenantSupportControllerDeleteTenantsFromSource
         * @request DELETE:/support/v1/tenants
         * @secure
         */
        tenantSupportControllerDeleteTenantsFromSource: (
            query: {
                tenantSource:
                    | 'ADMIN_WEBCLIENT'
                    | 'TRY_CORPORATE_WEBCLIENT'
                    | 'TRY_REWARDS_WEBCLIENT'
                    | 'NOX_API'
                    | 'OPEN_DEMO'
                    | 'E2E';
                removeAll: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/tenants`,
                method: 'DELETE',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-support-controller
         * @name TenantSupportControllerDeleteTenant
         * @request DELETE:/support/v1/tenants/{tenantId}
         * @secure
         */
        tenantSupportControllerDeleteTenant: (
            tenantId: string,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support/v1/tenants/${tenantId}`,
                method: 'DELETE',
                secure: true,
                ...params
            })
    };
}
