import { Box, Grid, InputAdornment } from '@mui/material';
import React from 'react';
import { TextField } from '../../../../../components/textfield';
import { Button } from '../../../../../components/button';
import { useStore } from './store';
import { Select } from '../../../../../components/select';
import { injectTSDI } from '../../../../../core/tsdi';
import { I18n } from '../../../../../core/i18n';
import { observer } from 'mobx-react';

export const AddSteps = observer(() => {
    const {
        fields,
        errors,
        onSubmitHandler,
        addStepsRequest
    } = useStore();
    const { __ } = injectTSDI(I18n);

    const {
        stepCount,
        userDay
    } = fields;


    return (
        <Grid container spacing={3}>
            <Grid item md={8} xs={12}>
                <TextField
                    value={userDay.value}
                    onChange={userDay.onChange}
                    type='text'
                    label='Day'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='start'>
                                YYYY-MM-DD
                            </InputAdornment>
                        )
                    }}
                    required
                    error={
                        userDay.error &&
                        __(
                            userDay.error as I18nKey,
                            0
                        )
                    }
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <Select label={'timezone'}
                        value={'Europe/Berlin'}
                        options={[
                            { value: 'Europe/Berlin', label: 'Europe/Berlin' }
                        ]}
                        onChange={() => null} />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={stepCount.value}
                    onChange={stepCount.onChange}
                    type='number'
                    label='Step count'
                    required
                    error={
                        stepCount.error &&
                        __(
                            stepCount.error as I18nKey,
                            0
                        )
                    }
                    helperText
                />
            </Grid>
            <Grid item xs={12}>
                <Box display='flex' justifyContent='center'>
                    <Button
                        onClick={onSubmitHandler}
                        disabled={
                            Boolean(errors) || addStepsRequest.loading
                        }
                    >
                        Save
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
});
