import { Box, Typography } from '@mui/material';
import React from 'react';

interface PageTitleProps {
    children: React.ReactNode;
}

export const PageTitle = ({ children }: PageTitleProps) => (
    <Box marginBottom={4}>
        <Typography variant="h5" component="h2" gutterBottom>
            {children}
        </Typography>
    </Box>
);
