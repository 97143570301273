import React from 'react';
import { useWrapRequest } from 'use-wrap-request';
import { useLocation, useNavigate } from 'react-router';
import { FieldState, FormState } from 'formstate';
import { insertElementIf } from '../components/utils/objects';
import { Api } from '../core/api';
import {
    required,
    useModel,
    validateEmail,
    wrapInFormField
} from '../core/forms';
import { injectTSDI } from '../core/tsdi';
import { links } from '../core/router';
import {
    AddressDto,
    CountryEnum,
    CreateTeamsTenantDto,
    TenantProductType,
    TenantSimpleDto
} from '../core/api/TenantClient';

export function useStore() {
    const { tenantClient } = injectTSDI(Api);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [productType, setProductType] = React.useState<
        TenantSimpleDto['productType'] | undefined
    >(undefined);

    React.useEffect(() => {
        if (pathname === links.tenantOnboarding()) {
            setProductType(TenantProductType.TEAMS);
        } else {
            setProductType(TenantProductType.CORPORATE);
        }
    }, [pathname]);

    const tenantRequest = useWrapRequest(
        async (data: CreateTeamsTenantDto) => {
            const result =
                await tenantClient.admin.tenantAdminV2ControllerCreateTeamsTenant(
                    data
                );
            return result;
        },
        {
            successMessage: ([data]) =>
                `${data.tenantId} was created successfully`
        }
    );

    const model = useModel(
        () => {
            const form = new FormState({
                tenantId: new FieldState<string>('').validators(required()),
                /* eslinecamelcase */
                lexofficeLink: new FieldState<string>(''),
                tenantType: new FieldState<
                    CreateTeamsTenantDto['tenantType'] | undefined
                >(undefined).validators(required()),
                hubspotLink: new FieldState<string>(''),
                licensesCount: new FieldState<number | string>(''),
                company: new FormState({
                    name: new FieldState<string>('').validators(required()),
                    address: new FormState({
                        country: new FieldState<AddressDto['country']>(
                            CountryEnum.DE
                        ).validators(required()),
                        city: new FieldState<string>('').validators(required()),

                        street: new FieldState<string>('').validators(
                            required()
                        ),
                        houseNumber: new FieldState<string>('').validators(
                            required()
                        ),

                        zip: new FieldState<string>('').validators(required())
                    }),
                    companyConfiguration: new FormState({
                        authenticationToken: new FieldState<string>(''),
                        acceptedDomains: new FieldState<string>(''),
                        declaredEmployeesCount: new FieldState<string>('')
                    })
                }),
                companyAdmin: new FormState({
                    email: new FieldState<string>('').validators(validateEmail),
                    firstName: new FieldState<string>('').validators(
                        required()
                    ),
                    lastName: new FieldState<string>('').validators(required())
                })
            });

            const tenantId = wrapInFormField(form.$.tenantId);
            const licensesCount = wrapInFormField(form.$.licensesCount);
            const tenantType = wrapInFormField(form.$.tenantType);
            const lexofficeLink = wrapInFormField(form.$.lexofficeLink);
            const hubspotLink = wrapInFormField(form.$.hubspotLink);

            const company = {
                name: wrapInFormField(form.$.company.$.name),
                address: {
                    country: wrapInFormField(
                        form.$.company.$.address.$.country
                    ),
                    city: wrapInFormField(form.$.company.$.address.$.city),
                    street: wrapInFormField(form.$.company.$.address.$.street),
                    houseNumber: wrapInFormField(
                        form.$.company.$.address.$.houseNumber
                    ),
                    zip: wrapInFormField(form.$.company.$.address.$.zip)
                },
                companyConfiguration: {
                    authenticationToken: wrapInFormField(
                        form.$.company.$.companyConfiguration.$
                            .authenticationToken
                    ),
                    acceptedDomains: wrapInFormField(
                        form.$.company.$.companyConfiguration.$.acceptedDomains
                    ),
                    declaredEmployeesCount: wrapInFormField(
                        form.$.company.$.companyConfiguration.$
                            .declaredEmployeesCount
                    )
                }
            };

            const companyAdmin = {
                email: wrapInFormField(form.$.companyAdmin.$.email),
                firstName: wrapInFormField(form.$.companyAdmin.$.firstName),
                lastName: wrapInFormField(form.$.companyAdmin.$.lastName)
            };

            return {
                form,
                fields: {
                    company,
                    companyAdmin,
                    lexofficeLink,
                    hubspotLink,
                    tenantId,
                    tenantType,
                    licensesCount
                }
            };
        },
        undefined,
        [productType]
    );
    const onSubmitHandler = async () => {
        const validation = await model.form.validate();
        const {
            lexofficeLink,
            hubspotLink,
            licensesCount,
            tenantId,
            tenantType,
            company,
            companyAdmin
        } = model.fields;

        if (validation.hasError) {
            throw new Error(model.form.error as string);
        }
        const acceptedDomains =
            !!company.companyConfiguration.acceptedDomains.value &&
            company.companyConfiguration.acceptedDomains.value.split(',');
        const licensesCountInt = Number(licensesCount.value);

        try {
            const request = await tenantRequest.request([
                {
                    tenantId: tenantId.value,
                    tenantType: tenantType.value,
                    ...(lexofficeLink && {
                        lexofficeLink: lexofficeLink.value
                    }),
                    ...(hubspotLink && {
                        hubspotLink: hubspotLink.value
                    }),
                    licensesCount:
                        licensesCountInt > 0 ? licensesCountInt : undefined,
                    tenantCreateDto: {
                        company: {
                            name: company.name.value,
                            address: {
                                city: company.address.city.value,
                                country: company.address.country.value,
                                houseNumber: company.address.houseNumber.value,
                                street: company.address.street.value,
                                zipCode: company.address.zip.value
                            },
                            companyConfiguration: {
                                ...insertElementIf(
                                    acceptedDomains,
                                    'acceptedDomains'
                                ),
                                ...insertElementIf(
                                    !!company.companyConfiguration
                                        .authenticationToken.value &&
                                        company.companyConfiguration
                                            .authenticationToken.value,
                                    'authenticationToken'
                                ),
                                ...insertElementIf(
                                    company.companyConfiguration
                                        .declaredEmployeesCount.value,
                                    'declaredEmployeesCount'
                                )
                            },
                            timeZone: 'Europe/Berlin',
                            localeCode: 'de_DE'
                        },
                        companyAdmin: {
                            email: companyAdmin.email.value,
                            role: productType === 'TEAMS' ? 'MANAGER' : 'ADMIN',
                            firstName: companyAdmin.firstName.value,
                            lastName: companyAdmin.lastName.value,
                            source: 'ADMIN'
                        }
                    }
                },
                productType
            ]);

            if (request) {
                if (productType === 'TEAMS') {
                    return navigate(links.tenants());
                }
                return navigate(links.tenantsCorporate());
            }
        } catch (err) {
            alert(err);
        }
    };

    const onCancelHandler = () => {
        navigate(links.tenants());
    };

    return {
        fields: model.fields,
        errors: model.form.error,
        isCorporateProductType: productType === 'CORPORATE',
        isTeamsProductType: productType === 'TEAMS',
        onSubmitHandler,
        onCancelHandler,
        tenantRequest
    };
}
