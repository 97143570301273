import { Container } from '@mui/material';
import { PageTitle } from '../../components/page-title';
import { Paper } from '../../components/paper';

import { observer } from 'mobx-react';
import React from 'react';
import { CreateFacilityForm } from './form';
import { injectTSDI } from '../../core/tsdi';
import { InitialData } from '../../core/initial-data';
import { Option } from '../../components/select';

export const FacilityCreate = observer(() => {
    const { studioCategories, availableCountries } = injectTSDI(InitialData);

    return (
        <Container maxWidth="md">
            <Paper>
                <PageTitle>Create new facility</PageTitle>

                <CreateFacilityForm
                    studioCategories={
                        studioCategories.$.map((entry) => (
                            {
                                label: entry.label,
                                value: entry.value
                            } as Option<string>
                        ))
                    }
                    countryOptions={availableCountries.$}
                />
            </Paper>
        </Container>
    );
});
