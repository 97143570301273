import { bind } from 'lodash-decorators';
import { makeAutoObservable, observable, reaction } from 'mobx';
import { component, initialize } from 'tsdi';
import { wrapRequest } from 'wrap-request';

type Manifest = { path: string; hashedPath: string }[];

type TranslateFn = (
    key: I18nKey | null | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...params: any[]
) => string;

@component
export class I18n {
    public currentLocale = navigator.language;

    private version = 0;

    private manifest: Manifest | undefined;

    public translationBundle = wrapRequest(async (localeParam: string) => {
        if (typeof localeParam !== 'string') {
            return;
        }
        const locale = localeParam.replace(/"/g, '');
        // locale results in en-GB, en-US, etc.
        // i want to get the first two characters of the locale
        const localeShort = locale.substring(0, 2);

        if (this.manifest) {
            let entry = this.manifest.find((entry) => {
                // check if the localeShort is in first two characters of the path after the last slash
                const path = entry.path.split('/');
                const lastPath = path[path.length - 1];
                if (lastPath.substring(0, 2) === localeShort) {
                    return entry;
                }
                return null;
            });

            if (!entry) {
                /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-non-null-assertion  */
                entry = this.manifest.find(
                    (entry) => entry.path === '/en-GB.json'
                )!;
            }
            /* eslint-enable @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-non-null-assertion */

            const data = await (
                await fetch(`/assets/localizations${entry.hashedPath}`)
            ).json();

            this.__ = this.wrap(data);
            this.version++;
        }
    });

    @bind
    public formatNumber(num: number) {
        const formatter = new Intl.NumberFormat(this.currentLocale, {});

        return formatter.format(num);
    }

    public formatYearMonth(yearMonth: string) {
        const formatter = new Intl.DateTimeFormat(this.currentLocale, {
            month: 'long'
        });
        const [year, month] = yearMonth.split('-');
        const monthFormatted = formatter.format(
            new Date(1970, Number(month) - 1)
        );

        return `${monthFormatted} ${year}`;
    }

    public formatDate = (date?: string) => {
        if (!date) {
            return '-';
        }

        const formatter = this.dateFormatter;

        return formatter.format(new Date(date));
    };

    private get dateFormatter(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.currentLocale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    public formatDateTime = (date?: string) => {
        if (!date) {
            return '-';
        }

        const formatter = this.dateTimeFormat;

        return formatter.format(new Date(date));
    };

    private get dateTimeFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.currentLocale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    }

    public formatLongDate = (date?: string): string => {
        if (!date) {
            return '-';
        }

        const formatter = this.longDateFormat;

        return formatter.format(new Date(date));
    };

    private get longDateFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.currentLocale, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour12: false
        });
    }

    public formatShortDate = (date?: string): string => {
        if (!date) {
            return '-';
        }

        const formatter = this.shortDateFormat;

        return formatter.format(new Date(date));
    };

    private get shortDateFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.currentLocale, {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour12: false
        });
    }

    public __: TranslateFn = () => '';

    @initialize
    protected init(): void {
        makeAutoObservable(this, { __: observable });

        reaction(
            () => ({
                locale: this.currentLocale,
                manifest: this.manifest
            }),
            ({ locale, manifest }) => {
                if (manifest && locale) {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    this.translationBundle.request(locale);
                }
            },
            {
                name: 'I18n#init',
                fireImmediately: true
            }
        );
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.loadManifest();
    }

    private async loadManifest(): Promise<void> {
        const data = await fetch('/assets/localizations/manifest.json', {
            headers: new Headers({
                'cache-control': 'no-cache'
            })
        });
        this.manifest = (await data.json()) as Manifest;
    }

    public get translationsReady(): boolean {
        return this.version > 0;
    }

    private wrap(
        data: {
            key: I18nKey;
            value: string;
        }[]
    ): TranslateFn {
        const fn: TranslateFn = (
            key: I18nKey | null | undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...params: any[]
        ) => {
            const entry = data.find((entry) => entry.key === key);

            if (entry) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return params.reduce(
                    (memo, value, i) =>
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,  @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
                        memo.replace(`{${String.fromCharCode(97 + i)}}`, value),
                    entry.value
                );
            }

            if (key) {
                return key;
            }

            return '';
        };

        return (key, ...params) => {
            if (key === null || key === undefined || key === '') {
                return '';
            }

            const value = fn(key, ...params);

            if (key === value) {
                console.warn(
                    `%ci18n missing: %c${value}`,
                    'color:#94999d',
                    'color:#000000'
                );
            }

            return value;
        };
    }
}
