/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ErrorMessage {
    code: string;
    arguments: object[];
    defaultMessage: string;
}

export interface ErrorResponse {
    code: string;
    message: ErrorMessage;
    traceId: string;
    /** @deprecated */
    errorMessage?: string;
    defaultMessage?: string;
}

export interface DefaultSuccessDto {
    success: boolean;
}

export interface MoveMessagesDto {
    from: string;
    to: string;
}

export interface GarminBackfillRequestDto {
    dateTimeRangeDto: ZonedDateTimeRangeDto;
    /** @format int64 */
    userId?: number;
    epochs: boolean;
    activities: boolean;
}

export interface ZonedDateTimeRangeDto {
    /** @format date-time */
    from: string;
    /** @format date-time */
    to: string;
}

export interface FitbitSupportSyncRequestDto {
    /** @format date */
    date: string;
    /** @format int64 */
    userId?: number;
}

export interface FitbitRevokeResultDto {
    revoked: string[];
    notRevoked: string[];
    failed: string[];
}

export interface MsPageUserSearchSupportDto {
    content: UserSearchSupportDto[];
    /** @format int32 */
    number: number;
    /** @format int32 */
    size: number;
    /** @format int32 */
    totalPages: number;
    /** @format int64 */
    totalElements: number;
}

export interface UserEmailDto {
    value: string;
    main: boolean;
    verified: boolean;
}

export interface UserSearchSupportDto {
    /** @format int64 */
    id: number;
    /** @uniqueItems true */
    emails: UserEmailDto[];
    profileId: string;
    firstName: string;
    lastName: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<
    FullRequestParams,
    'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
    extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '/mobile-api';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
        fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(
            (key) => 'undefined' !== typeof query[key]
        );
        return keys
            .map((key) =>
                Array.isArray(query[key])
                    ? this.addArrayQueryParam(query, key)
                    : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null &&
            (typeof input === 'object' || typeof input === 'string')
                ? JSON.stringify(input)
                : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string'
                ? JSON.stringify(input)
                : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                          ? JSON.stringify(property)
                          : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
    };

    protected mergeRequestParams(
        params1: RequestParams,
        params2?: RequestParams
    ): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected createAbortSignal = (
        cancelToken: CancelToken
    ): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean'
                ? secure
                : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter =
            this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(
            `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
            {
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData
                        ? { 'Content-Type': type }
                        : {})
                },
                signal:
                    (cancelToken
                        ? this.createAbortSignal(cancelToken)
                        : requestParams.signal) || null,
                body:
                    typeof body === 'undefined' || body === null
                        ? null
                        : payloadFormatter(body)
            }
        ).then(async (response) => {
            const r = response.clone() as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then((data) => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch((e) => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title msr-mobile-api
 * @version 3.1.1712
 * @baseUrl /mobile-api
 */
export class Api<
    SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
    logout = {
        /**
         * No description
         *
         * @tags authentication-controller
         * @name AuthenticationControllerLogout
         * @request POST:/logout
         * @secure
         */
        authenticationControllerLogout: (params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/logout`,
                method: 'POST',
                secure: true,
                ...params
            })
    };
    supportApi = {
        /**
         * No description
         *
         * @tags strava-support-v-2-controller
         * @name StravaSupportV2ControllerSetupWebhooksSubscription
         * @request POST:/support-api/v2/fitness-apps/strava/webhooks/{publicFacilityGroup}
         * @secure
         */
        stravaSupportV2ControllerSetupWebhooksSubscription: (
            publicFacilityGroup: string,
            params: RequestParams = {}
        ) =>
            this.request<DefaultSuccessDto, ErrorResponse>({
                path: `/support-api/v2/fitness-apps/strava/webhooks/${publicFacilityGroup}`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags redis-session-index-cleanup-jobs-controller
         * @name RedisSessionIndexCleanupJobsControllerCleanUserAccountSessionIndex
         * @request POST:/support-api/v1/redis-session-index-cleanup
         * @secure
         */
        redisSessionIndexCleanupJobsControllerCleanUserAccountSessionIndex: (
            query: {
                /** @format int64 */
                userAccountId: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, ErrorResponse>({
                path: `/support-api/v1/redis-session-index-cleanup`,
                method: 'POST',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags support-controller
         * @name SupportControllerMoveMessages
         * @request POST:/support-api/v1/rabbitmq/movemessages
         * @secure
         */
        supportControllerMoveMessages: (
            data: MoveMessagesDto,
            params: RequestParams = {}
        ) =>
            this.request<void, ErrorResponse>({
                path: `/support-api/v1/rabbitmq/movemessages`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags promoter-connect-support-controller
         * @name PromoterConnectSupportControllerRestore
         * @request POST:/support-api/v1/promoter-connects/{id}/recover
         * @secure
         */
        promoterConnectSupportControllerRestore: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<void, ErrorResponse>({
                path: `/support-api/v1/promoter-connects/${id}/recover`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags jobs-controller
         * @name JobsControllerTriggerJob
         * @request POST:/support-api/v1/jobs
         * @secure
         */
        jobsControllerTriggerJob: (
            query: {
                jobname: string;
                /** @default true */
                transactional?: boolean;
                tenant?: string;
                /** @format int32 */
                pageSize?: number;
                /** @format int32 */
                startPageInclusive?: number;
                /** @format int32 */
                endPageInclusive?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<string, ErrorResponse>({
                path: `/support-api/v1/jobs`,
                method: 'POST',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags garmin-support-controller
         * @name GarminSupportControllerBackfillEpochs
         * @request POST:/support-api/v1/garmin-backfills
         * @secure
         */
        garminSupportControllerBackfillEpochs: (
            data: GarminBackfillRequestDto,
            params: RequestParams = {}
        ) =>
            this.request<void, ErrorResponse>({
                path: `/support-api/v1/garmin-backfills`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags strava-support-controller
         * @name StravaSupportControllerSetupWebhooksSubscription
         * @request POST:/support-api/v1/fitness-apps/strava/webhooks
         * @secure
         */
        stravaSupportControllerSetupWebhooksSubscription: (
            params: RequestParams = {}
        ) =>
            this.request<DefaultSuccessDto, ErrorResponse>({
                path: `/support-api/v1/fitness-apps/strava/webhooks`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags fitbit-support-controller
         * @name FitbitSupportControllerSyncActivities
         * @request POST:/support-api/v1/fitness-apps/fitbit/sync
         * @secure
         */
        fitbitSupportControllerSyncActivities: (
            data: FitbitSupportSyncRequestDto,
            params: RequestParams = {}
        ) =>
            this.request<void, ErrorResponse>({
                path: `/support-api/v1/fitness-apps/fitbit/sync`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags fitbit-support-controller
         * @name FitbitSupportControllerRevokeUserTokens
         * @request POST:/support-api/v1/fitness-apps/fitbit/revoke-user-tokens
         * @secure
         */
        fitbitSupportControllerRevokeUserTokens: (
            data: string[],
            params: RequestParams = {}
        ) =>
            this.request<FitbitRevokeResultDto, ErrorResponse>({
                path: `/support-api/v1/fitness-apps/fitbit/revoke-user-tokens`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags fill-additional-emails-jobs-controller
         * @name FillAdditionalEmailsJobsControllerTriggerJob
         * @request POST:/support-api/v1/fill-additional-emails-from-corporate
         * @secure
         */
        fillAdditionalEmailsJobsControllerTriggerJob: (
            params: RequestParams = {}
        ) =>
            this.request<void, ErrorResponse>({
                path: `/support-api/v1/fill-additional-emails-from-corporate`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags delete-redis-sessions-without-user-jobs-controller
         * @name DeleteRedisSessionsWithoutUserJobsControllerTriggerJob
         * @request POST:/support-api/v1/delete-redis-sessions-without-user/start
         * @secure
         */
        deleteRedisSessionsWithoutUserJobsControllerTriggerJob: (
            query: {
                /** @format int32 */
                scanSize: number;
                /** @format int32 */
                taskDelayInSeconds: number;
                dryRun: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, ErrorResponse>({
                path: `/support-api/v1/delete-redis-sessions-without-user/start`,
                method: 'POST',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags delete-redis-sessions-without-user-jobs-controller
         * @name DeleteRedisSessionsWithoutUserJobsControllerCancelJob
         * @request POST:/support-api/v1/delete-redis-sessions-without-user/cancel
         * @secure
         */
        deleteRedisSessionsWithoutUserJobsControllerCancelJob: (
            params: RequestParams = {}
        ) =>
            this.request<void, ErrorResponse>({
                path: `/support-api/v1/delete-redis-sessions-without-user/cancel`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags delete-old-redis-sessions-jobs-controller
         * @name DeleteOldRedisSessionsJobsControllerTriggerJob
         * @request POST:/support-api/v1/delete-old-redis-sessions/start
         * @secure
         */
        deleteOldRedisSessionsJobsControllerTriggerJob: (
            query: {
                /** @format int32 */
                scanSize: number;
                /** @format int32 */
                taskDelayInSeconds: number;
                dryRun: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, ErrorResponse>({
                path: `/support-api/v1/delete-old-redis-sessions/start`,
                method: 'POST',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags delete-old-redis-sessions-jobs-controller
         * @name DeleteOldRedisSessionsJobsControllerCancelJob
         * @request POST:/support-api/v1/delete-old-redis-sessions/cancel
         * @secure
         */
        deleteOldRedisSessionsJobsControllerCancelJob: (
            params: RequestParams = {}
        ) =>
            this.request<void, ErrorResponse>({
                path: `/support-api/v1/delete-old-redis-sessions/cancel`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags delete-invalid-redis-session-indexes-jobs-controller
         * @name DeleteInvalidRedisSessionIndexesJobsControllerTriggerJob
         * @request POST:/support-api/v1/delete-invalid-redis-sessions-indexes/start
         * @secure
         */
        deleteInvalidRedisSessionIndexesJobsControllerTriggerJob: (
            query: {
                /** @format int32 */
                scanSize: number;
                /** @format int32 */
                taskDelayInSeconds: number;
                dryRun: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, ErrorResponse>({
                path: `/support-api/v1/delete-invalid-redis-sessions-indexes/start`,
                method: 'POST',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags delete-invalid-redis-session-indexes-jobs-controller
         * @name DeleteInvalidRedisSessionIndexesJobsControllerCancelJob
         * @request POST:/support-api/v1/delete-invalid-redis-session-indexes/cancel
         * @secure
         */
        deleteInvalidRedisSessionIndexesJobsControllerCancelJob: (
            params: RequestParams = {}
        ) =>
            this.request<void, ErrorResponse>({
                path: `/support-api/v1/delete-invalid-redis-session-indexes/cancel`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags support-controller
         * @name SupportControllerGetActiveSessions
         * @request POST:/support-api/v1/active-user-sessions
         * @secure
         */
        supportControllerGetActiveSessions: (
            query?: {
                /**
                 * @format int32
                 * @default 100
                 */
                size?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<string[], ErrorResponse>({
                path: `/support-api/v1/active-user-sessions`,
                method: 'POST',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags user-search-support-controller
         * @name UserSearchSupportControllerSearchUsers
         * @request GET:/support-api/v1/users
         * @secure
         */
        userSearchSupportControllerSearchUsers: (
            query?: {
                search?: string;
                /**
                 * @format int32
                 * @min 0
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 100
                 */
                size?: number;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<MsPageUserSearchSupportDto, ErrorResponse>({
                path: `/support-api/v1/users`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags user-search-support-controller
         * @name UserSearchSupportControllerGetUser
         * @request GET:/support-api/v1/users/{id}
         * @secure
         */
        userSearchSupportControllerGetUser: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<UserSearchSupportDto, ErrorResponse>({
                path: `/support-api/v1/users/${id}`,
                method: 'GET',
                secure: true,
                ...params
            })
    };
    internal = {
        /**
         * No description
         *
         * @tags garmin-backfill-internal-controller
         * @name GarminBackfillInternalControllerBackfill
         * @request POST:/internal/garmin-backfills
         * @secure
         */
        garminBackfillInternalControllerBackfill: (
            data: GarminBackfillRequestDto,
            params: RequestParams = {}
        ) =>
            this.request<void, ErrorResponse>({
                path: `/internal/garmin-backfills`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            })
    };
}
