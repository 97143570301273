import { debounce } from 'lodash';
import React from 'react';

interface UseInfiniteScrollProps {
    hasMore?: boolean;
    reset?: boolean;
    distance?: number;
    loading?: boolean;
}

export default function useInfiniteScroll({
    hasMore = true,
    reset = false,
    distance = 250,
    loading = false
}: UseInfiniteScrollProps) {
    const [page, setPage] = React.useState<number>(0);

    if (reset && page !== 0) {
        setPage(0);
    }

    React.useLayoutEffect(() => {
        if (loading || !hasMore) {
            return;
        }
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [loading]);

    const handleScroll = debounce(() => {
        const {
            scrollTop,
            scrollHeight,
            clientHeight
        } = document.documentElement;

        if (!loading && scrollTop + clientHeight >= scrollHeight - distance) {
            setPage((prevPage) => prevPage + 1);
        }
    }, 50);

    return page;
}
