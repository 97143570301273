// import MapboxDraw from '@mapbox/mapbox-gl-draw/';
import { FeatureCollection, Polygon } from 'geojson';
import { DrawControlProps, MapBoxButton } from './draw-controls';

import MapboxDraw from '@mapbox/mapbox-gl-draw';

type Map = Parameters<MapboxDraw['onAdd']>[0];
interface ExtendDrawBarProps extends DrawControlProps {
    draw: MapboxDraw;
    buttons: MapBoxButton[];
}

export class ExtendDrawBar {
    public draw: MapboxDraw;
    public map: Map | undefined;
    private buttons: MapBoxButton[];
    private elContainer: HTMLElement | undefined;
    // private onAddOrig: (map: mapboxgl.Map) => HTMLElement;
    // private onAddOrig: (map: MapboxMap) => HTMLElement;
    private onAddOrig: MapboxDraw['onAdd'];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private onRemoveOrig: MapboxDraw['onRemove'];

    constructor({ buttons, draw }: ExtendDrawBarProps) {
        this.draw = draw;
        this.buttons = buttons || [];
        this.onAddOrig = draw.onAdd;
        this.onRemoveOrig = draw.onRemove;
    }

    public onAdd(map: Parameters<MapboxDraw['onAdd']>[0]) {
        this.map = map;
        this.elContainer = this.onAddOrig(map);
        this.buttons.forEach((b) => {
            this.addButton(b);
        });
        return this.elContainer;
    }

    public drawAreas(area: FeatureCollection<Polygon>) {
        this.draw.set(area);
    }

    public onRemove(map: Map) {
        this.buttons.forEach((b) => {
            this.removeButton(b);
        });
        this.onRemoveOrig(map);
    }

    public addButton(opt: MapBoxButton) {
        const elButton = document.createElement('button');
        elButton.className = 'mapbox-gl-draw_ctrl-draw-btn';
        if (opt.classes instanceof Array) {
            opt.classes.forEach((c: string) => {
                elButton.classList.add(c);
                elButton.title = opt.title;
            });
        }
        elButton.addEventListener(opt.on, opt.action);
        this.elContainer?.appendChild(elButton);
        opt.elButton = elButton;
    }

    public disableDrag() {
        this.draw?.changeMode('simple_select');
    }

    public removeButton(opt: MapBoxButton) {
        opt.elButton?.removeEventListener(opt.on, opt.action);
        opt.elButton?.remove();
    }
}
