/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum ProductState {
    DISABLED = 'DISABLED',
    TEST = 'TEST',
    PRODUCTION = 'PRODUCTION'
}

export interface ProductStateChangeRequestDto {
    state: ProductState;
}

export interface CdnObjectDto {
    objectKey: string;
    bucketName: string;
}

export interface CurrencyDto {
    /**
     * @minLength 3
     * @maxLength 20
     */
    name: string;
    /**
     * @minLength 3
     * @maxLength 3
     */
    code: string;
    symbol: string;
    /** @format int32 */
    precision: number;
}

export interface MoneyDto {
    value: number;
    currency: CurrencyDto;
}

export interface ProductSupportDto {
    /** @format int64 */
    id: number;
    price: MoneyDto;
    value: MoneyDto;
    productType: ProductType;
    internalState?: ProductState;
    externalState?: ProductState;
    shopState: ProductState;
    image: CdnObjectDto;
}

export enum ProductType {
    VOUCHER = 'VOUCHER',
    DONATION = 'DONATION'
}

export enum CountryCode {
    UNDEFINED = 'UNDEFINED',
    AC = 'AC',
    AD = 'AD',
    AE = 'AE',
    AF = 'AF',
    AG = 'AG',
    AI = 'AI',
    AL = 'AL',
    AM = 'AM',
    AN = 'AN',
    AO = 'AO',
    AQ = 'AQ',
    AR = 'AR',
    AS = 'AS',
    AT = 'AT',
    AU = 'AU',
    AW = 'AW',
    AX = 'AX',
    AZ = 'AZ',
    BA = 'BA',
    BB = 'BB',
    BD = 'BD',
    BE = 'BE',
    BF = 'BF',
    BG = 'BG',
    BH = 'BH',
    BI = 'BI',
    BJ = 'BJ',
    BL = 'BL',
    BM = 'BM',
    BN = 'BN',
    BO = 'BO',
    BQ = 'BQ',
    BR = 'BR',
    BS = 'BS',
    BT = 'BT',
    BU = 'BU',
    BV = 'BV',
    BW = 'BW',
    BY = 'BY',
    BZ = 'BZ',
    CA = 'CA',
    CC = 'CC',
    CD = 'CD',
    CF = 'CF',
    CG = 'CG',
    CH = 'CH',
    CI = 'CI',
    CK = 'CK',
    CL = 'CL',
    CM = 'CM',
    CN = 'CN',
    CO = 'CO',
    CP = 'CP',
    CR = 'CR',
    CS = 'CS',
    CU = 'CU',
    CV = 'CV',
    CW = 'CW',
    CX = 'CX',
    CY = 'CY',
    CZ = 'CZ',
    DE = 'DE',
    DG = 'DG',
    DJ = 'DJ',
    DK = 'DK',
    DM = 'DM',
    DO = 'DO',
    DZ = 'DZ',
    EA = 'EA',
    EC = 'EC',
    EE = 'EE',
    EG = 'EG',
    EH = 'EH',
    ER = 'ER',
    ES = 'ES',
    ET = 'ET',
    EU = 'EU',
    EZ = 'EZ',
    FI = 'FI',
    FJ = 'FJ',
    FK = 'FK',
    FM = 'FM',
    FO = 'FO',
    FR = 'FR',
    FX = 'FX',
    GA = 'GA',
    GB = 'GB',
    GD = 'GD',
    GE = 'GE',
    GF = 'GF',
    GG = 'GG',
    GH = 'GH',
    GI = 'GI',
    GL = 'GL',
    GM = 'GM',
    GN = 'GN',
    GP = 'GP',
    GQ = 'GQ',
    GR = 'GR',
    GS = 'GS',
    GT = 'GT',
    GU = 'GU',
    GW = 'GW',
    GY = 'GY',
    HK = 'HK',
    HM = 'HM',
    HN = 'HN',
    HR = 'HR',
    HT = 'HT',
    HU = 'HU',
    IC = 'IC',
    ID = 'ID',
    IE = 'IE',
    IL = 'IL',
    IM = 'IM',
    IN = 'IN',
    IO = 'IO',
    IQ = 'IQ',
    IR = 'IR',
    IS = 'IS',
    IT = 'IT',
    JE = 'JE',
    JM = 'JM',
    JO = 'JO',
    JP = 'JP',
    KE = 'KE',
    KG = 'KG',
    KH = 'KH',
    KI = 'KI',
    KM = 'KM',
    KN = 'KN',
    KP = 'KP',
    KR = 'KR',
    KW = 'KW',
    KY = 'KY',
    KZ = 'KZ',
    LA = 'LA',
    LB = 'LB',
    LC = 'LC',
    LI = 'LI',
    LK = 'LK',
    LR = 'LR',
    LS = 'LS',
    LT = 'LT',
    LU = 'LU',
    LV = 'LV',
    LY = 'LY',
    MA = 'MA',
    MC = 'MC',
    MD = 'MD',
    ME = 'ME',
    MF = 'MF',
    MG = 'MG',
    MH = 'MH',
    MK = 'MK',
    ML = 'ML',
    MM = 'MM',
    MN = 'MN',
    MO = 'MO',
    MP = 'MP',
    MQ = 'MQ',
    MR = 'MR',
    MS = 'MS',
    MT = 'MT',
    MU = 'MU',
    MV = 'MV',
    MW = 'MW',
    MX = 'MX',
    MY = 'MY',
    MZ = 'MZ',
    NA = 'NA',
    NC = 'NC',
    NE = 'NE',
    NF = 'NF',
    NG = 'NG',
    NI = 'NI',
    NL = 'NL',
    NO = 'NO',
    NP = 'NP',
    NR = 'NR',
    NT = 'NT',
    NU = 'NU',
    NZ = 'NZ',
    OM = 'OM',
    PA = 'PA',
    PE = 'PE',
    PF = 'PF',
    PG = 'PG',
    PH = 'PH',
    PK = 'PK',
    PL = 'PL',
    PM = 'PM',
    PN = 'PN',
    PR = 'PR',
    PS = 'PS',
    PT = 'PT',
    PW = 'PW',
    PY = 'PY',
    QA = 'QA',
    RE = 'RE',
    RO = 'RO',
    RS = 'RS',
    RU = 'RU',
    RW = 'RW',
    SA = 'SA',
    SB = 'SB',
    SC = 'SC',
    SD = 'SD',
    SE = 'SE',
    SF = 'SF',
    SG = 'SG',
    SH = 'SH',
    SI = 'SI',
    SJ = 'SJ',
    SK = 'SK',
    SL = 'SL',
    SM = 'SM',
    SN = 'SN',
    SO = 'SO',
    SR = 'SR',
    SS = 'SS',
    ST = 'ST',
    SU = 'SU',
    SV = 'SV',
    SX = 'SX',
    SY = 'SY',
    SZ = 'SZ',
    TA = 'TA',
    TC = 'TC',
    TD = 'TD',
    TF = 'TF',
    TG = 'TG',
    TH = 'TH',
    TJ = 'TJ',
    TK = 'TK',
    TL = 'TL',
    TM = 'TM',
    TN = 'TN',
    TO = 'TO',
    TP = 'TP',
    TR = 'TR',
    TT = 'TT',
    TV = 'TV',
    TW = 'TW',
    TZ = 'TZ',
    UA = 'UA',
    UG = 'UG',
    UK = 'UK',
    UM = 'UM',
    US = 'US',
    UY = 'UY',
    UZ = 'UZ',
    VA = 'VA',
    VC = 'VC',
    VE = 'VE',
    VG = 'VG',
    VI = 'VI',
    VN = 'VN',
    VU = 'VU',
    WF = 'WF',
    WS = 'WS',
    XI = 'XI',
    XU = 'XU',
    XK = 'XK',
    YE = 'YE',
    YT = 'YT',
    YU = 'YU',
    ZA = 'ZA',
    ZM = 'ZM',
    ZR = 'ZR',
    ZW = 'ZW'
}

export enum VendorSource {
    CADOOZ = 'CADOOZ',
    GOGIFT = 'GOGIFT',
    MULTIVOUCHER = 'MULTIVOUCHER',
    CUSTOM = 'CUSTOM'
}

export interface VendorSupportDto {
    /** @format int64 */
    id: number;
    name: string;
    image: CdnObjectDto;
    description?: string;
    shortDescription?: string;
    source: VendorSource;
    internalState?: ProductState;
    externalState?: ProductState;
    shopState: ProductState;
    countries: CountryCode[];
}

export enum LocaleCode {
    UndefinedUNDEFINED = 'undefined-UNDEFINED',
    Ar = 'ar',
    ArAE = 'ar-AE',
    ArBH = 'ar-BH',
    ArDZ = 'ar-DZ',
    ArEG = 'ar-EG',
    ArIQ = 'ar-IQ',
    ArJO = 'ar-JO',
    ArKW = 'ar-KW',
    ArLB = 'ar-LB',
    ArLY = 'ar-LY',
    ArMA = 'ar-MA',
    ArOM = 'ar-OM',
    ArQA = 'ar-QA',
    ArSA = 'ar-SA',
    ArSD = 'ar-SD',
    ArSY = 'ar-SY',
    ArTN = 'ar-TN',
    ArYE = 'ar-YE',
    Be = 'be',
    BeBY = 'be-BY',
    Bg = 'bg',
    BgBG = 'bg-BG',
    Ca = 'ca',
    CaES = 'ca-ES',
    Cs = 'cs',
    CsCZ = 'cs-CZ',
    Da = 'da',
    DaDK = 'da-DK',
    De = 'de',
    DeAT = 'de-AT',
    DeCH = 'de-CH',
    DeDE = 'de-DE',
    DeLU = 'de-LU',
    El = 'el',
    ElCY = 'el-CY',
    ElGR = 'el-GR',
    En = 'en',
    EnAU = 'en-AU',
    EnCA = 'en-CA',
    EnGB = 'en-GB',
    EnHK = 'en-HK',
    EnIE = 'en-IE',
    EnIN = 'en-IN',
    EnMT = 'en-MT',
    EnNZ = 'en-NZ',
    EnPH = 'en-PH',
    EnSG = 'en-SG',
    EnUS = 'en-US',
    EnZA = 'en-ZA',
    Es = 'es',
    EsAR = 'es-AR',
    EsBO = 'es-BO',
    EsCL = 'es-CL',
    EsCO = 'es-CO',
    EsCR = 'es-CR',
    EsDO = 'es-DO',
    EsEC = 'es-EC',
    EsES = 'es-ES',
    EsGT = 'es-GT',
    EsHN = 'es-HN',
    EsMX = 'es-MX',
    EsNI = 'es-NI',
    EsPA = 'es-PA',
    EsPE = 'es-PE',
    EsPR = 'es-PR',
    EsPY = 'es-PY',
    EsSV = 'es-SV',
    EsUS = 'es-US',
    EsUY = 'es-UY',
    EsVE = 'es-VE',
    Et = 'et',
    EtEE = 'et-EE',
    Fa = 'fa',
    FaIR = 'fa-IR',
    Fi = 'fi',
    FiFI = 'fi-FI',
    Fr = 'fr',
    FrBE = 'fr-BE',
    FrCA = 'fr-CA',
    FrCH = 'fr-CH',
    FrFR = 'fr-FR',
    FrLU = 'fr-LU',
    Ga = 'ga',
    GaIE = 'ga-IE',
    He = 'he',
    HeIL = 'he-IL',
    HiIN = 'hi-IN',
    Hr = 'hr',
    HrHR = 'hr-HR',
    Hu = 'hu',
    HuHU = 'hu-HU',
    Id = 'id',
    IdID = 'id-ID',
    Is = 'is',
    IsIS = 'is-IS',
    It = 'it',
    ItCH = 'it-CH',
    ItIT = 'it-IT',
    Ja = 'ja',
    JaJP = 'ja-JP',
    KkKZ = 'kk-KZ',
    Ko = 'ko',
    KoKR = 'ko-KR',
    Lt = 'lt',
    LtLT = 'lt-LT',
    Lv = 'lv',
    LvLV = 'lv-LV',
    Mk = 'mk',
    MkMK = 'mk-MK',
    Ms = 'ms',
    MsMY = 'ms-MY',
    Mt = 'mt',
    MtMT = 'mt-MT',
    Nb = 'nb',
    NbNO = 'nb-NO',
    Nl = 'nl',
    NlBE = 'nl-BE',
    NlNL = 'nl-NL',
    NnNO = 'nn-NO',
    No = 'no',
    NoNO = 'no-NO',
    Pl = 'pl',
    PlPL = 'pl-PL',
    Pt = 'pt',
    PtBR = 'pt-BR',
    PtPT = 'pt-PT',
    Ro = 'ro',
    RoMD = 'ro-MD',
    RoRO = 'ro-RO',
    Ru = 'ru',
    RuKZ = 'ru-KZ',
    RuRU = 'ru-RU',
    Se = 'se',
    SeNO = 'se-NO',
    Sk = 'sk',
    SkSK = 'sk-SK',
    Sl = 'sl',
    SlSI = 'sl-SI',
    Sq = 'sq',
    SqAL = 'sq-AL',
    Sr = 'sr',
    SrBA = 'sr-BA',
    SrCS = 'sr-CS',
    SrME = 'sr-ME',
    SrRS = 'sr-RS',
    Sv = 'sv',
    SvSE = 'sv-SE',
    Th = 'th',
    ThTH = 'th-TH',
    Tr = 'tr',
    TrTR = 'tr-TR',
    Uk = 'uk',
    UkUA = 'uk-UA',
    Vi = 'vi',
    ViVN = 'vi-VN',
    Zh = 'zh',
    ZhCN = 'zh-CN',
    ZhHK = 'zh-HK',
    ZhSG = 'zh-SG',
    ZhTW = 'zh-TW'
}

export interface PurchaseCreatedEvent {
    /** @format int64 */
    purchaseId: number;
    userEmail: string;
    userLocale: LocaleCode;
    userFullName: string;
    promoters: string[];
}

export interface MailSupportDto {
    /** @format int64 */
    productOrderId: number;
    pdfLink?: string;
}

export interface AccountOperationContext {
    tenantId?: string;
    userId?: string;
    promoterName?: string;
    vendorName?: string;
    yearMonth?: string;
    /** @format int32 */
    dailyGoal?: number;
    createdBy?: string;
    comment?: string;
}

export interface AccountOperationDto {
    /** @format int64 */
    id: number;
    value: MoneyDto;
    /** @format date-time */
    timestamp: string;
    type: AccountOperationType;
    moneyStatus: MoneyStatus;
    moneyEventSource: MoneyEventSource;
    context?: AccountOperationContext;
    message?: string;
    challengeName?: string;
    productOrder?: ProductOrderDto;
    deleted: boolean;
}

export enum AccountOperationType {
    PURCHASE = 'PURCHASE',
    REWARD_CLAIM = 'REWARD_CLAIM',
    TRANSFER_OUT = 'TRANSFER_OUT',
    PAYOUT_RUN = 'PAYOUT_RUN',
    PAYOUT_ENTRY_UPDATE = 'PAYOUT_ENTRY_UPDATE',
    PAYOUT_ENTRY_DELETION = 'PAYOUT_ENTRY_DELETION',
    APPROVED_MONEY = 'APPROVED_MONEY',
    PENDING_MONEY = 'PENDING_MONEY',
    REJECTED_MONEY = 'REJECTED_MONEY',
    TRANSFER_IN = 'TRANSFER_IN'
}

export enum MoneyEventSource {
    PAYOUT_RUN = 'PAYOUT_RUN',
    DAILY_GOAL_REACHED = 'DAILY_GOAL_REACHED',
    LICENSE_RELEASE = 'LICENSE_RELEASE',
    TENANT_DEACTIVATION = 'TENANT_DEACTIVATION',
    REWARD_REVOKED = 'REWARD_REVOKED',
    CHALLENGE = 'CHALLENGE',
    GUARANTEED_PAYOUT = 'GUARANTEED_PAYOUT',
    OTHER = 'OTHER'
}

export enum MoneyStatus {
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED'
}

export interface ProductOrderDto {
    /** @format int64 */
    id: number;
    status: ProductOrderState;
}

export enum ProductOrderState {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    CANCELED = 'CANCELED',
    ERROR = 'ERROR'
}

export interface PurchaseSupportDto {
    userId: string;
    /** @format int64 */
    productId: number;
}

export enum Job {
    INIT_ACCOUNT_OPERATION_GROUPS = 'INIT_ACCOUNT_OPERATION_GROUPS',
    FILL_ACCOUNT_OPERATION_CONTEXT_TENANT_IDS = 'FILL_ACCOUNT_OPERATION_CONTEXT_TENANT_IDS',
    DELETE_ORPHAN_ACCOUNT_OPERATION_GROUPS = 'DELETE_ORPHAN_ACCOUNT_OPERATION_GROUPS',
    INIT_DEMO_STARS_ACCOUNT = 'INIT_DEMO_STARS_ACCOUNT',
    RECALCULATE_PENDING_STARS = 'RECALCULATE_PENDING_STARS',
    SOFT_DELETE_PREVIOUS_MONTH_PENDING_STARS = 'SOFT_DELETE_PREVIOUS_MONTH_PENDING_STARS',
    APPROVE_PREVIOUS_MONTHS_PENDING_OPERATIONS = 'APPROVE_PREVIOUS_MONTHS_PENDING_OPERATIONS',
    FILL_CHALLENGE_ACCOUNT_OPERATION_CONTEXT = 'FILL_CHALLENGE_ACCOUNT_OPERATION_CONTEXT'
}

export interface CadoozVoucherPurchaseRequest {
    testOrder: boolean;
    customerOrderId?: string;
    productNumber: string;
    voucherValue: number;
    firstname: string;
    lastname: string;
    email: string;
}

export interface DateTimeRangeRequestDto {
    /** @format date-time */
    from: string;
    /** @format date-time */
    to: string;
}

export interface ProductOrderCountByOperationAndState {
    empty?: boolean;
    [key: string]: any;
}

export interface ProductOrderCountByState {
    empty?: boolean;
    [key: string]: any;
}

export interface ProductOrderSummaryStats {
    /** @format date-time */
    from?: string;
    /** @format date-time */
    to?: string;
    empty?: boolean;
    [key: string]: any;
}

export interface DateTimeFromRequestDto {
    /** @format date-time */
    from: string;
}

export enum CurrencyCode {
    UNDEFINED = 'UNDEFINED',
    AED = 'AED',
    AFN = 'AFN',
    ALL = 'ALL',
    AMD = 'AMD',
    ANG = 'ANG',
    AOA = 'AOA',
    ARS = 'ARS',
    AUD = 'AUD',
    AWG = 'AWG',
    AZN = 'AZN',
    BAM = 'BAM',
    BBD = 'BBD',
    BDT = 'BDT',
    BGN = 'BGN',
    BHD = 'BHD',
    BIF = 'BIF',
    BMD = 'BMD',
    BND = 'BND',
    BOB = 'BOB',
    BOV = 'BOV',
    BRL = 'BRL',
    BSD = 'BSD',
    BTN = 'BTN',
    BWP = 'BWP',
    BYN = 'BYN',
    BYR = 'BYR',
    BZD = 'BZD',
    CAD = 'CAD',
    CDF = 'CDF',
    CHE = 'CHE',
    CHF = 'CHF',
    CHW = 'CHW',
    CLF = 'CLF',
    CLP = 'CLP',
    CNY = 'CNY',
    COP = 'COP',
    COU = 'COU',
    CRC = 'CRC',
    CUC = 'CUC',
    CUP = 'CUP',
    CVE = 'CVE',
    CZK = 'CZK',
    DJF = 'DJF',
    DKK = 'DKK',
    DOP = 'DOP',
    DZD = 'DZD',
    EGP = 'EGP',
    ERN = 'ERN',
    ETB = 'ETB',
    EUR = 'EUR',
    FJD = 'FJD',
    FKP = 'FKP',
    GBP = 'GBP',
    GEL = 'GEL',
    GHS = 'GHS',
    GIP = 'GIP',
    GMD = 'GMD',
    GNF = 'GNF',
    GTQ = 'GTQ',
    GYD = 'GYD',
    HKD = 'HKD',
    HNL = 'HNL',
    HRK = 'HRK',
    HTG = 'HTG',
    HUF = 'HUF',
    IDR = 'IDR',
    ILS = 'ILS',
    INR = 'INR',
    IQD = 'IQD',
    IRR = 'IRR',
    ISK = 'ISK',
    JMD = 'JMD',
    JOD = 'JOD',
    JPY = 'JPY',
    KES = 'KES',
    KGS = 'KGS',
    KHR = 'KHR',
    KMF = 'KMF',
    KPW = 'KPW',
    KRW = 'KRW',
    KWD = 'KWD',
    KYD = 'KYD',
    KZT = 'KZT',
    LAK = 'LAK',
    LBP = 'LBP',
    LKR = 'LKR',
    LRD = 'LRD',
    LSL = 'LSL',
    LTL = 'LTL',
    LYD = 'LYD',
    MAD = 'MAD',
    MDL = 'MDL',
    MGA = 'MGA',
    MKD = 'MKD',
    MMK = 'MMK',
    MNT = 'MNT',
    MOP = 'MOP',
    MRO = 'MRO',
    MRU = 'MRU',
    MUR = 'MUR',
    MVR = 'MVR',
    MWK = 'MWK',
    MXN = 'MXN',
    MXV = 'MXV',
    MYR = 'MYR',
    MZN = 'MZN',
    NAD = 'NAD',
    NGN = 'NGN',
    NIO = 'NIO',
    NOK = 'NOK',
    NPR = 'NPR',
    NZD = 'NZD',
    OMR = 'OMR',
    PAB = 'PAB',
    PEN = 'PEN',
    PGK = 'PGK',
    PHP = 'PHP',
    PKR = 'PKR',
    PLN = 'PLN',
    PYG = 'PYG',
    QAR = 'QAR',
    RON = 'RON',
    RSD = 'RSD',
    RUB = 'RUB',
    RUR = 'RUR',
    RWF = 'RWF',
    SAR = 'SAR',
    SBD = 'SBD',
    SCR = 'SCR',
    SDG = 'SDG',
    SEK = 'SEK',
    SGD = 'SGD',
    SHP = 'SHP',
    SLL = 'SLL',
    SOS = 'SOS',
    SRD = 'SRD',
    SSP = 'SSP',
    STD = 'STD',
    STN = 'STN',
    SVC = 'SVC',
    SYP = 'SYP',
    SZL = 'SZL',
    THB = 'THB',
    TJS = 'TJS',
    TMT = 'TMT',
    TND = 'TND',
    TOP = 'TOP',
    TRY = 'TRY',
    TTD = 'TTD',
    TWD = 'TWD',
    TZS = 'TZS',
    UAH = 'UAH',
    UGX = 'UGX',
    USD = 'USD',
    USN = 'USN',
    USS = 'USS',
    UYI = 'UYI',
    UYU = 'UYU',
    UZS = 'UZS',
    VEF = 'VEF',
    VES = 'VES',
    VND = 'VND',
    VUV = 'VUV',
    WST = 'WST',
    XAF = 'XAF',
    XAG = 'XAG',
    XAU = 'XAU',
    XBA = 'XBA',
    XBB = 'XBB',
    XBC = 'XBC',
    XBD = 'XBD',
    XCD = 'XCD',
    XDR = 'XDR',
    XOF = 'XOF',
    XPD = 'XPD',
    XPF = 'XPF',
    XPT = 'XPT',
    XSU = 'XSU',
    XTS = 'XTS',
    XUA = 'XUA',
    XXX = 'XXX',
    YER = 'YER',
    ZAR = 'ZAR',
    ZMW = 'ZMW',
    ZWL = 'ZWL'
}

export enum Severity {
    INFO = 'INFO',
    WARNING = 'WARNING'
}

export interface ThirdPartyBalanceCheckResult {
    vendorSource: VendorSource;
    currentBalance: number;
    warningThreshold?: number;
    currency: CurrencyCode;
    severity: Severity;
}

export interface AccountCorrectionRequest {
    /** @format int64 */
    accountId: number;
    value: number;
    createdBy: string;
    comment: string;
    tenantId?: string;
}

export interface VendorPageSupportResponseDto {
    content: VendorSupportDto[];
    /** @format int32 */
    number: number;
    /** @format int32 */
    size: number;
    /** @format int32 */
    totalPages: number;
    /** @format int64 */
    totalElements: number;
}

export interface ProductPageSupportResponseDto {
    content: ProductSupportDto[];
    /** @format int32 */
    number: number;
    /** @format int32 */
    size: number;
    /** @format int32 */
    totalPages: number;
    /** @format int64 */
    totalElements: number;
}

export interface AccountDto {
    /** @format int64 */
    id: number;
    currency: CurrencyDto;
    balances: Record<string, UserMoneyDto>;
}

export interface UserMoneyDto {
    value: MoneyDto;
    valueInUserCurrency: MoneyDto;
}

export interface WalletDto {
    taxResidency: CountryCode;
    accounts: AccountDto[];
}

export interface PageAccountOperationDto {
    content: AccountOperationDto[];
    /** @format int32 */
    number: number;
    /** @format int32 */
    size: number;
    /** @format int32 */
    totalPages: number;
    /** @format int64 */
    totalElements: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<
    FullRequestParams,
    'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
    extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '/wallet-api';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
        fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(
            (key) => 'undefined' !== typeof query[key]
        );
        return keys
            .map((key) =>
                Array.isArray(query[key])
                    ? this.addArrayQueryParam(query, key)
                    : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null &&
            (typeof input === 'object' || typeof input === 'string')
                ? JSON.stringify(input)
                : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string'
                ? JSON.stringify(input)
                : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                          ? JSON.stringify(property)
                          : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
    };

    protected mergeRequestParams(
        params1: RequestParams,
        params2?: RequestParams
    ): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected createAbortSignal = (
        cancelToken: CancelToken
    ): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean'
                ? secure
                : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter =
            this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(
            `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
            {
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData
                        ? { 'Content-Type': type }
                        : {})
                },
                signal:
                    (cancelToken
                        ? this.createAbortSignal(cancelToken)
                        : requestParams.signal) || null,
                body:
                    typeof body === 'undefined' || body === null
                        ? null
                        : payloadFormatter(body)
            }
        ).then(async (response) => {
            const r = response.clone() as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then((data) => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch((e) => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl /wallet-api
 */
export class Api<
    SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
    supportApi = {
        /**
         * No description
         *
         * @tags vendor-support-controller
         * @name VendorSupportControllerUpdateProductState
         * @request PUT:/support-api/v1/vendors/{vendorId}/products/{productId}/state
         * @secure
         */
        vendorSupportControllerUpdateProductState: (
            vendorId: number,
            productId: number,
            data: ProductStateChangeRequestDto,
            params: RequestParams = {}
        ) =>
            this.request<ProductSupportDto, any>({
                path: `/support-api/v1/vendors/${vendorId}/products/${productId}/state`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags vendor-support-controller
         * @name VendorSupportControllerUpdateVendorState
         * @request PUT:/support-api/v1/vendors/{id}/state
         * @secure
         */
        vendorSupportControllerUpdateVendorState: (
            id: number,
            data: ProductStateChangeRequestDto,
            params: RequestParams = {}
        ) =>
            this.request<VendorSupportDto, any>({
                path: `/support-api/v1/vendors/${id}/state`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags support-controller
         * @name SupportControllerCreateAccountV2ForUser
         * @request POST:/support-api/v1/users/{userId}/account-v2
         * @secure
         */
        supportControllerCreateAccountV2ForUser: (
            userId: string,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/users/${userId}/account-v2`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags purchase-support-controller
         * @name PurchaseSupportControllerRetryPurchase
         * @request POST:/support-api/v1/third-party-purchases/{purchaseId}/retries
         * @secure
         */
        purchaseSupportControllerRetryPurchase: (
            purchaseId: number,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/third-party-purchases/${purchaseId}/retries`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags purchase-support-controller
         * @name PurchaseSupportControllerSendPurchaseEmail
         * @request POST:/support-api/v1/third-party-purchases/{purchaseId}/email-notifications
         * @secure
         */
        purchaseSupportControllerSendPurchaseEmail: (
            purchaseId: string,
            data: PurchaseCreatedEvent,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/third-party-purchases/${purchaseId}/email-notifications`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags mail-support-controller
         * @name MailSupportControllerResendProductOrderMail
         * @request POST:/support-api/v1/resend-product-order-mail
         * @secure
         */
        mailSupportControllerResendProductOrderMail: (
            data: MailSupportDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/resend-product-order-mail`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags account-operation-support-controller
         * @name AccountOperationSupportControllerApproveRejected
         * @request POST:/support-api/v1/rejected-account-operations/{id}/approve
         * @secure
         */
        accountOperationSupportControllerApproveRejected: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<AccountOperationDto, any>({
                path: `/support-api/v1/rejected-account-operations/${id}/approve`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags purchase-support-controller
         * @name PurchaseSupportControllerPurchase
         * @request POST:/support-api/v1/purchases
         * @secure
         */
        purchaseSupportControllerPurchase: (
            data: PurchaseSupportDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/purchases`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags mail-support-controller
         * @name MailSupportControllerResendProductInstanceMail
         * @request POST:/support-api/v1/product-instances/{id}/resend-mail
         * @secure
         */
        mailSupportControllerResendProductInstanceMail: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/product-instances/${id}/resend-mail`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags account-operation-support-controller
         * @name AccountOperationSupportControllerRejectPending
         * @request POST:/support-api/v1/pending-account-operations/{id}/reject
         * @secure
         */
        accountOperationSupportControllerRejectPending: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<AccountOperationDto, any>({
                path: `/support-api/v1/pending-account-operations/${id}/reject`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags multivoucher-support-controller
         * @name MultivoucherSupportControllerFetchBarcodeAndResentEmail
         * @request POST:/support-api/v1/multivoucher/purchases/{purchaseId}/fetch-barcode
         * @secure
         */
        multivoucherSupportControllerFetchBarcodeAndResentEmail: (
            purchaseId: number,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/multivoucher/purchases/${purchaseId}/fetch-barcode`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags multivoucher-support-controller
         * @name MultivoucherSupportControllerSyncProducts
         * @request POST:/support-api/v1/multivoucher/product-sync-invocations
         * @secure
         */
        multivoucherSupportControllerSyncProducts: (
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/multivoucher/product-sync-invocations`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags job-controller
         * @name JobControllerTriggerJob
         * @request POST:/support-api/v1/jobs/{job}
         * @secure
         */
        jobControllerTriggerJob: (job: Job, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/support-api/v1/jobs/${job}`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags go-gift-support-controller
         * @name GoGiftSupportControllerSyncProducts
         * @request POST:/support-api/v1/gogift/product-sync-invocations
         * @secure
         */
        goGiftSupportControllerSyncProducts: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/support-api/v1/gogift/product-sync-invocations`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags support-controller
         * @name SupportControllerConvertCurrency
         * @request POST:/support-api/v1/convert-account-currency
         * @secure
         */
        supportControllerConvertCurrency: (
            query?: {
                tenantId?: string;
                currencyCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/convert-account-currency`,
                method: 'POST',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags cadooz-support-controller
         * @name CadoozSupportControllerSyncProducts
         * @request POST:/support-api/v1/cadooz/product-sync-invocations
         * @secure
         */
        cadoozSupportControllerSyncProducts: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/support-api/v1/cadooz/product-sync-invocations`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags cadooz-support-controller
         * @name CadoozSupportControllerCreateOrder
         * @request POST:/support-api/v1/cadooz/product-orders
         * @secure
         */
        cadoozSupportControllerCreateOrder: (
            data: CadoozVoucherPurchaseRequest,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/cadooz/product-orders`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags cadooz-support-controller
         * @name CadoozSupportControllerInvokeOrderSummary
         * @request POST:/support-api/v1/cadooz/orders-summary-invocations
         * @secure
         */
        cadoozSupportControllerInvokeOrderSummary: (
            data: DateTimeRangeRequestDto,
            params: RequestParams = {}
        ) =>
            this.request<ProductOrderSummaryStats, any>({
                path: `/support-api/v1/cadooz/orders-summary-invocations`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags cadooz-support-controller
         * @name CadoozSupportControllerSyncOrders
         * @request POST:/support-api/v1/cadooz/order-sync-invocations
         * @secure
         */
        cadoozSupportControllerSyncOrders: (
            data: DateTimeFromRequestDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/cadooz/order-sync-invocations`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags third-party-balance-support-controller
         * @name ThirdPartyBalanceSupportControllerCheckBalance
         * @request POST:/support-api/v1/balance-check-invocations
         * @secure
         */
        thirdPartyBalanceSupportControllerCheckBalance: (
            params: RequestParams = {}
        ) =>
            this.request<ThirdPartyBalanceCheckResult[], any>({
                path: `/support-api/v1/balance-check-invocations`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags wallet-support-controller
         * @name WalletSupportControllerCreateCorrection
         * @request POST:/support-api/v1/account-operations
         * @secure
         */
        walletSupportControllerCreateCorrection: (
            data: AccountCorrectionRequest,
            params: RequestParams = {}
        ) =>
            this.request<AccountOperationDto, any>({
                path: `/support-api/v1/account-operations`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags vendor-support-controller
         * @name VendorSupportControllerGetPage
         * @request GET:/support-api/v1/vendors
         * @secure
         */
        vendorSupportControllerGetPage: (
            query?: {
                country?: CountryCode;
                /** @uniqueItems true */
                shopStates?: ProductState[];
                /** @uniqueItems true */
                internalStates?: ProductState[];
                /** @uniqueItems true */
                externalStates?: ProductState[];
                /** @uniqueItems true */
                sources?: VendorSource[];
                /** @format int32 */
                page?: number;
                /** @format int32 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<VendorPageSupportResponseDto, any>({
                path: `/support-api/v1/vendors`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags vendor-support-controller
         * @name VendorSupportControllerGetOne
         * @request GET:/support-api/v1/vendors/{id}
         * @secure
         */
        vendorSupportControllerGetOne: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<VendorSupportDto, any>({
                path: `/support-api/v1/vendors/${id}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags vendor-support-controller
         * @name VendorSupportControllerGetProductPage
         * @request GET:/support-api/v1/vendors/{id}/products
         * @secure
         */
        vendorSupportControllerGetProductPage: (
            id: number,
            query?: {
                /** @uniqueItems true */
                shopStates?: ProductState[];
                /** @uniqueItems true */
                internalStates?: ProductState[];
                /** @uniqueItems true */
                externalStates?: ProductState[];
                /** @format int32 */
                page?: number;
                /** @format int32 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<ProductPageSupportResponseDto, any>({
                path: `/support-api/v1/vendors/${id}/products`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags wallet-support-controller
         * @name WalletSupportControllerGetUserWalletDetails
         * @request GET:/support-api/v1/users/{userId}/wallet
         * @secure
         */
        walletSupportControllerGetUserWalletDetails: (
            userId: string,
            params: RequestParams = {}
        ) =>
            this.request<WalletDto, any>({
                path: `/support-api/v1/users/${userId}/wallet`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags report-controller
         * @name ReportControllerGetStarsReport
         * @request GET:/support-api/v1/stars-reports/{yearMonth}
         * @secure
         */
        reportControllerGetStarsReport: (
            yearMonth: string,
            params: RequestParams = {}
        ) =>
            this.request<File, any>({
                path: `/support-api/v1/stars-reports/${yearMonth}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags wallet-support-controller
         * @name WalletSupportControllerGetAccountOperations
         * @request GET:/support-api/v1/accounts/{accountId}/account-operations
         * @secure
         */
        walletSupportControllerGetAccountOperations: (
            accountId: number,
            query?: {
                /** @format int32 */
                page?: number;
                /** @format int32 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageAccountOperationDto, any>({
                path: `/support-api/v1/accounts/${accountId}/account-operations`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags support-controller
         * @name SupportControllerDeleteAccount
         * @request DELETE:/support-api/v1/users/{userId}/accounts/{accountId}
         * @secure
         */
        supportControllerDeleteAccount: (
            userId: string,
            accountId: number,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/users/${userId}/accounts/${accountId}`,
                method: 'DELETE',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags account-operation-support-controller
         * @name AccountOperationSupportControllerDeletePayoutRunUpdate
         * @request DELETE:/support-api/v1/payout-run-update-account-operations/{id}
         * @secure
         */
        accountOperationSupportControllerDeletePayoutRunUpdate: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/payout-run-update-account-operations/${id}`,
                method: 'DELETE',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags wallet-support-controller
         * @name WalletSupportControllerDeleteAccountOperation
         * @request DELETE:/support-api/v1/account-operations/{id}
         * @secure
         */
        walletSupportControllerDeleteAccountOperation: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/account-operations/${id}`,
                method: 'DELETE',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags account-operation-group-support-controller
         * @name AccountOperationGroupSupportControllerDeleteGroup
         * @request DELETE:/support-api/v1/account-operation-groups/{id}
         * @secure
         */
        accountOperationGroupSupportControllerDeleteGroup: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/support-api/v1/account-operation-groups/${id}`,
                method: 'DELETE',
                secure: true,
                ...params
            })
    };
}
