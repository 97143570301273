import { component } from 'tsdi';
import { wrapRequest } from 'wrap-request';
import { Api } from '../../core/api';
import { injectTSDI } from '../../core/tsdi';
import { VendorSupportDto } from '@api/WalletClient';

@component
export class VendorDetailsStore {
    private api = injectTSDI(Api);

    public vendor = wrapRequest(async (vendorId: number):Promise<VendorSupportDto> => {
        const result = await this.api.walletClient.supportApi.vendorSupportControllerGetOne(
            vendorId
        );
        return result.data;
    });
}
