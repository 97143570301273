export interface Params {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [param: string]: any;
}

export interface Links {
    indexPrivate(): string;
    indexPublic(): string;
    login(): string;
    vendor(): string;
    vendorDetails(id: number): string;
    facility(): string;
    facilityCreate(): string;
    facilityEdit(id: number): string;
    facilityEditDetailsForm(id: number): string;
    facilityEditLocation(id: number): string;
    tenants(): string;
    tenantsCorporate(): string;
    tenantDetails(id: string): string;
    tenantContract(id: string): string;
    tenantFeatures(id: string): string;
    tenantEmployees(id: string): string;
    tenantEmployee(tenantId: string, employeeId: string): string;
    tenantEmployeeDetails(tenantId: string, employeeId: string): string;
    tenantEmployeeActivities(tenantId: string, employeeId: string): string;
    tenantEmployeeFacilities(tenantId: string, employeeId: string): string;
    tenantOnboarding(): string;
    tenantUsers(id: string): string;
    reports(): string;
    fitnessServices(): string;
    users(): string;
    userDetails(id: string): string;
    userWallet(id: string): string;
    userFitnessActivities(id: string): string;
    userActivityPoints(id: string): string;
}

export const links: Links = {
    indexPrivate: () => '/',
    indexPublic: () => '/',
    vendor: () => '/vendors',
    vendorDetails: (id: number) => `/vendors/${id}`,
    facility: () => '/facility',
    login: () => '/login',
    facilityCreate: () => '/facility/create',
    facilityEdit: (id: number) => `/facility/${id}`,
    facilityEditDetailsForm: (id: number) => `/facility/${id}/details`,
    facilityEditLocation: (id: number) => `/facility/${id}/location`,
    tenants: () => '/tenants',
    tenantsCorporate: () => '/tenants-corporate',
    tenantDetails: (id: string) => `/tenant/${id}`,
    tenantContract: (id: string) => `/tenant/${id}/contract`,
    tenantFeatures: (id: string) => `/tenant/${id}/features`,
    tenantEmployees: (id: string) => `/tenant/${id}/employees`,
    tenantEmployee: (tenantId: string, employeeId: string) =>
        `/tenant/${tenantId}/employees/${employeeId}`,
    tenantEmployeeDetails: (tenantId: string, employeeId: string) =>
        `/tenant/${tenantId}/employees/${employeeId}/details`,
    tenantEmployeeActivities: (tenantId: string, employeeId: string) =>
        `/tenant/${tenantId}/employees/${employeeId}/activities`,
    tenantEmployeeFacilities: (tenantId: string, employeeId: string) =>
        `/tenant/${tenantId}/employees/${employeeId}/facilities`,
    tenantUsers: (id: string) => `/tenant/${id}/users`,
    reports: () => '/reports',
    tenantOnboarding: () => '/tenant-onboarding',
    fitnessServices: () => '/fitness-services',
    users: () => '/users',
    userDetails: (id: string) => `/users/${id}`,
    userWallet: (id: string) => `/users/${id}/wallet`,
    userFitnessActivities: (id: string) => `/users/${id}/fitness-activities`,
    userActivityPoints: (id: string) => `/users/${id}/points`
};
