import { CountryCode, ProductState, VendorSource, VendorSupportDto } from '@api/WalletClient';
import { Box, Stack } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router';
import { CellProps } from 'react-table';
import { useWrapRequest } from 'use-wrap-request';
import { Badge, BadgeType } from '../../components/badge';
import { Filters } from '../../components/filters';
import { userSearchDebounce } from '../../components/hooks/use-search';
import { useSearchReducer } from '../../components/hooks/useSearchReducer';
import { PageTitle } from '../../components/page-title';
import { Paper } from '../../components/paper';
import { Table } from '../../components/table';
import { Api } from '../../core/api';
import { links } from '../../core/router';
import { injectTSDI } from '../../core/tsdi';

function getStatusBadgeType(status: ProductState | undefined): BadgeType {
    switch (status) {
        case ProductState.PRODUCTION:
            return 'green';
        case ProductState.TEST:
            return 'blue';
        case ProductState.DISABLED:
            return 'red';
        case undefined:
            return 'grey';
    }
}

type StateFilterType = 'ENABLED_FOR_USER' | 'POSSIBLE_TO_ENABLE' | 'ALL';
type VendorSourceFilterType = VendorSource | 'ALL';
type CountryCodeFilterType = CountryCode | 'ALL';

export const VendorList = observer(() => {
    const { walletClient } = injectTSDI(Api);
    const navigate = useNavigate();

    const [stateFilter, setStatusFilter] =
        React.useState<StateFilterType>('ENABLED_FOR_USER');
    const [vendorSourceFilter, setVendorSourceFilter] =
        React.useState<VendorSourceFilterType>('ALL');
    const [countriesFilter, setCountriesFilter] =
        React.useState<CountryCodeFilterType>('ALL');

    const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
        size: 30,
        page: 0,
        sort: 'name',
    });

    const { setSearchTerm } = userSearchDebounce(dispatch);

    const vendors = useWrapRequest(
        async (
            searchParams,
            stateFilter,
            vendorSourceFilter,
            countriesFilter
        ) => {
            const result =
                await walletClient.supportApi.vendorSupportControllerGetPage({
                    ...searchParams,
                    ...(stateFilter === 'ENABLED_FOR_USER' && {
                        shopStates: [ProductState.PRODUCTION]
                    }),
                    ...(stateFilter === 'POSSIBLE_TO_ENABLE' && {
                        internalStates: [ProductState.DISABLED, ProductState.TEST],
                        internalStatesIncludeNull: true,
                        externalStates: ProductState.PRODUCTION
                    }),
                    ...(vendorSourceFilter !== 'ALL' && { sources: vendorSourceFilter }),
                    ...(countriesFilter !== 'ALL' && { country: countriesFilter }),
                });

            return { ...result.data, content: result.data.content || [] };
        },
        {
            defaultData: {
                content: [],
                number: 0,
                size: 10,
                totalElements: 0,
                totalPages: 0
            }
        }
    );

    React.useEffect(() => {
        vendors.request([
            searchParams,
            stateFilter,
            vendorSourceFilter,
            countriesFilter
        ]);
    }, [searchParams]);

    React.useEffect(() => {
        dispatch({ type: 'page', page: 0 });
    }, [stateFilter]);

    React.useEffect(() => {
        dispatch({ type: 'page', page: 0 });
    }, [vendorSourceFilter]);
    React.useEffect(() => {
        dispatch({ type: 'page', page: 0 });
    }, [countriesFilter]);

    async function setState(item: VendorSupportDto, state: ProductState) {
        item.internalState = state;

        await walletClient.supportApi.vendorSupportControllerUpdateVendorState(
            item.id,
            {
                state: state
            }
        );
        dispatch({ type: 'reload' });
    }

    return (
        <>
            <PageTitle>Vouchers catalog</PageTitle>
            <Box display="flex" justifyContent="flex-start">
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    spacing={2}
                    pt={2}
                    pb={2}
                    width="100%"
                    maxWidth={400}
                >
                    <Filters<StateFilterType, false>
                        label="Status"
                        value={stateFilter}
                        width={180}
                        options={[
                            { label: 'all', value: 'ALL' },
                            { label: 'enabled for users', value: 'ENABLED_FOR_USER' },
                            { label: 'possible to enable', value: 'POSSIBLE_TO_ENABLE' },
                        ]}
                        onChange={(val) => void setStatusFilter(val)}
                    />
                    <Filters<VendorSourceFilterType, false>
                        label="Source"
                        value={vendorSourceFilter}
                        width={180}
                        options={[
                            { label: 'all', value: 'ALL' },
                            { label: 'Multivoucher', value: 'MULTIVOUCHER' },
                            { label: 'GoGift', value: 'GOGIFT' },
                            { label: 'Cadooz', value: 'CADOOZ' },
                            { label: 'Custom', value: 'CUSTOM' }
                        ]}
                        onChange={(val) => setVendorSourceFilter(val)}
                    />

                    <Filters<CountryCodeFilterType, false>
                        label="Country"
                        value={countriesFilter}
                        width={180}
                        options={[
                            { label: 'all', value: 'ALL' },
                            ...Object.values(CountryCode).map((value) => ({
                                label: value,
                                value: value
                            }))
                        ]}
                        onChange={(val) => setCountriesFilter(val)}
                    />
                </Stack>
            </Box>
            <Paper noPadding>
                <Table<VendorSupportDto[]>
                    emptyText="No entries"
                    items={vendors}
                    contextMenu={[
                        {
                            label: 'Details',
                            action: (value) => {
                                navigate(links.vendorDetails(value.id));
                            }
                        },
                        {
                            label: 'Disable',
                            hidden: (value) =>
                                value.internalState == ProductState.DISABLED,
                            action: (value) =>
                                setState(value, ProductState.DISABLED)
                        },
                        {
                            label: 'Enable for internal testing',
                            hidden: (value) =>
                                value.internalState == ProductState.TEST,
                            action: (value) =>
                                setState(value, ProductState.TEST)
                        },
                        {
                            label: 'Enable for production',
                            hidden: (value) =>
                                value.internalState == ProductState.PRODUCTION,
                            action: (value) =>
                                setState(value, ProductState.PRODUCTION)
                        }
                    ]}
                    columns={[
                        {
                            Header: 'Name',
                            id: 'name',
                            accessor: (value) => value.name
                        },
                        {
                            Header: 'Source',
                            accessor: (value) => value.source
                        },
                        {
                            Header: 'Countries',
                            accessor: (value) => value.countries.join(',')
                        },
                        {
                            Header: 'Internal state',
                            Cell: ({
                                row: {
                                    original: { internalState }
                                }
                            }: CellProps<VendorSupportDto>) => (
                                <Badge type={getStatusBadgeType(internalState)}>
                                    {internalState}
                                </Badge>
                            )
                        },
                        {
                            Header: 'External state',
                            Cell: ({
                                row: {
                                    original: { externalState }
                                }
                            }: CellProps<VendorSupportDto>) => (
                                <Badge type={getStatusBadgeType(externalState)}>
                                    {externalState}
                                </Badge>
                            )
                        }
                    ]}
                    onPageSizeChange={(size) =>
                        dispatch({ type: 'size', size })
                    }
                    onChangePage={(page) =>
                        void dispatch({ type: 'page', page })
                    }
                    onSearchChange={(val) => void setSearchTerm(val)}
                />
            </Paper>
        </>
    );
});

export default VendorList;
