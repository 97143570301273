import { Box, Grid } from '@mui/material';
import React from 'react';
import { TextField } from '../../../../../components/textfield';
import { Button } from '../../../../../components/button';
import { useStore } from './store';
import { injectTSDI } from '../../../../../core/tsdi';
import { I18n } from '../../../../../core/i18n';
import { observer } from 'mobx-react';
import { Select } from '../../../../../components/select';
import { WorkoutType } from '@api/FitnessActivityClient';

export const AddWorkout = observer(() => {
    const {
        fields,
        errors,
        onSubmitHandler,
        addWorkoutRequest
    } = useStore();
    const { __ } = injectTSDI(I18n);

    const {
        workoutType,
        from,
        to,
        distance
    } = fields;


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    value={from.value}
                    onChange={from.onChange}
                    type='text'
                    label='Start (full date time with timezone)'
                    required
                    error={
                        from.error &&
                        __(
                            from.error as I18nKey,
                            0
                        )
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={to.value}
                    onChange={to.onChange}
                    type='text'
                    label='End (full date time with timezone)'
                    required
                    error={
                        from.error &&
                        __(
                            from.error as I18nKey,
                            0
                        )
                    }
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <Select label={'Workout type'}
                        value={workoutType.value}
                        onChange={workoutType.onChange}
                        options={[
                            { value: WorkoutType.CYCLING, label: 'Cycling' },
                            { value: WorkoutType.RUNNING, label: 'Running' },
                            { value: WorkoutType.HIKING, label: 'Hiking' },
                            { value: WorkoutType.OTHER, label: 'Other' }
                        ]}
                    />
            </Grid>
            <Grid item md={8} xs={12}>
                <TextField
                    value={distance.value}
                    onChange={distance.onChange}
                    type='number'
                    label='Distance traveled (in meters)'
                    required
                    error={
                        distance.error &&
                        __(
                            distance.error as I18nKey,
                            0
                        )
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Box display='flex' justifyContent='center'>
                    <Button
                        onClick={onSubmitHandler}
                        disabled={
                            Boolean(errors) || addWorkoutRequest.loading
                        }
                    >
                        Save
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
});
