import React from 'react';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import MapGL, {
    FullscreenControl,
    MapRef,
    NavigationControl
} from 'react-map-gl';
import { observer } from 'mobx-react';
import { MAPBOX_ACCESS_TOKEN } from '../../../core/map';
import { FacilityAreaDto, FacilityDto } from '../../../core/api/StudioClient';
import { useStore } from './store';
import { Markers } from './markres';
import { MarkerLegend } from './legend';
import { NearbyFacilityInfo, Popup } from './popup';
import { DrawControl, DrawingMode, FeatureArea } from './draw-controls';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import './map.css';
import { convertAreaToGeoPolygon } from './utils';

interface MapProps {
    area: FacilityAreaDto;
    externalLongitude?: number;
    externalLatitude?: number;
}

export const Map = observer(
    ({ area, externalLatitude, externalLongitude }: MapProps) => {
        const {
            fields: { center },
            nearByFacilities,
            addArea,
            updateArea,
            deleteArea,
            updateCenter,
            loadInitialData
        } = useStore(area);

        const { longitude, latitude } = center;
        const [popupInfo, setPopupInfo] = React.useState<FacilityDto | null>(
            null
        );
        const [mode, setMode] = React.useState<DrawingMode>('static');
        const [disableDrag, setDisableDrag] = React.useState<boolean>(false);

        const onCreate = React.useCallback(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (feature: FeatureArea, mode: any) => {
                setMode('static');
                if (feature.id) {
                    const points = feature.geometry.coordinates.length
                        ? feature.geometry.coordinates[0]
                        : [];
                    addArea(
                        {
                            id: feature.id?.toString(),
                            points: convertAreaToGeoPolygon(points) || []
                        },
                        mode
                    );
                }
            },
            []
        );

        const onUpdate = React.useCallback((feature: FeatureArea) => {
            setMode('static');
            if (feature?.id) {
                const points = feature.geometry.coordinates.length
                    ? feature.geometry.coordinates[0]
                    : [];

                updateArea(
                    {
                        id: feature.id?.toString(),
                        points: convertAreaToGeoPolygon(points) || []
                    },
                    feature.properties.mode
                );
            }
        }, []);

        const onDelete = React.useCallback((feature: FeatureArea) => {
            if (feature.id) {
                deleteArea(feature.id?.toString(), feature.properties.mode);
            }
            setMode('static');
        }, []);

        const onToolSelect = React.useCallback((mode: DrawingMode) => {
            setMode(mode);
        }, []);

        React.useEffect(() => {
            if (longitude && latitude) {
                nearByFacilities.request([longitude.value, latitude.value]);
            }
        }, [longitude, latitude]);

        const mapRef = React.useRef<HTMLDivElement>(null);
        const mapGlRef = React.useRef<MapRef>(null);

        if (!latitude.value || !longitude.value) {
            return null;
        }

        return (
            <Box
                ref={mapRef}
                sx={{
                    width: '100%',
                    height: 'calc(100vh - 160px)',
                    padding: 0,
                    '&.mode-visit-area': {
                        '& .visit-area-button': {
                            backgroundColor: grey[200]
                        }
                    },
                    '&.mode-checkin-area': {
                        '& .checkin-area-button': {
                            backgroundColor: grey[200]
                        }
                    }
                }}
                className={`mode-${mode}`}
            >
                <MapGL
                    initialViewState={{
                        latitude: center.latitude.value || 51.165691,
                        longitude: center.longitude.value || 10.451526,
                        zoom: 16,
                        bearing: 0,
                        pitch: 0
                    }}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                    keyboard={false}
                    ref={mapGlRef}
                >
                    <Markers
                        longitude={longitude.value}
                        latitude={latitude.value}
                        nearByFacilities={nearByFacilities.$?.content}
                        externalLatitude={externalLatitude}
                        externalLongitude={externalLongitude}
                        setPopupInfo={setPopupInfo}
                        onDragStart={() => {
                            setDisableDrag(true);
                        }}
                        onClick={() => {
                            setDisableDrag(true);
                        }}
                        onMarkerDrag={(longitude, latitude) => {
                            setDisableDrag(false);
                            updateCenter(longitude, latitude);
                            nearByFacilities.request([longitude, latitude]);
                        }}
                    />

                    {popupInfo &&
                        popupInfo.address.longitude &&
                        popupInfo.address.latitude && (
                            <Popup
                                anchor="top"
                                longitude={popupInfo.address.longitude}
                                latitude={popupInfo.address.latitude}
                                onClose={() => void setPopupInfo(null)}
                            >
                                <NearbyFacilityInfo facility={popupInfo} />
                            </Popup>
                        )}

                    <FullscreenControl position="top-left" />
                    <NavigationControl position="top-left" />
                    <MarkerLegend />
                    <DrawControl
                        position="top-left"
                        displayControlsDefault={false}
                        disableDrag={disableDrag}
                        controls={{
                            trash: true
                        }}
                        onCreate={onCreate}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        onInitialLoad={loadInitialData}
                        onToolSelect={onToolSelect}
                        area={area}
                    />
                </MapGL>
            </Box>
        );
    }
);
