import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { observer } from 'mobx-react';
import { Paper } from '../../components/paper';
import { EmployeeDto } from '../../core/api/CorporateClient';
import { useTabs } from '../../components/hooks/use-tabs';
import { TenantCorporateContext } from '..';
import { useWrapRequest } from '../../components/hooks/use-wrap-request';
import { Button } from '../../components/button';
import { links } from '../../core/router';

const pageTabs = [
    { label: 'Details', id: 'details' },
    { label: 'Facilities', id: 'facilities' },
    { label: 'Corporate Activities', id: 'activities' },
    { label: 'Fitness Activities', id: 'fitness-activities' },
    { label: 'Activity Points', id: 'activity-points' }
];

export const EmployeeContext = React.createContext<EmployeeDto>(
    {} as EmployeeDto
);

export default observer(() => {
    const { pathname } = useLocation();
    const { tab, getPageLink, setPageTab } = useTabs(pageTabs);
    const navigate = useNavigate();
    const corporateClient = React.useContext(TenantCorporateContext);

    const { tenantName, employeeId } = useParams();

    const employeeRequest = useWrapRequest(
        async () => {
            const result = await corporateClient.support.employeeSupportControllerGetEmployee(
                Number(employeeId)
            );

            return { ...result.data, content: result.data || [] };
        },
        {
            deps: []
        }
    );

    const changePage = (page: number) => {
        navigate(getPageLink(page));
    };

    React.useEffect(() => {
        setPageTab(pathname, 5);
    }, [pathname]);

    return (
        <>
            {employeeRequest.match({
                fetched: (employee) => (
                    <EmployeeContext.Provider value={employee}>
                        <Stack
                            justifyContent="space-between"
                            flexDirection="row"
                        >
                            <Typography variant="h6">
                                {employee.firstName} {employee.lastName}{' '}
                            </Typography>

                            <Button
                                variant="text"
                                startIcon={<ArrowBackIcon />}
                                onClick={() =>
                                    void navigate(
                                        links.tenantEmployees(tenantName)
                                    )
                                }
                            >
                                Back to Employees
                            </Button>
                        </Stack>
                        <Paper noPadding>
                            <Tabs
                                value={tab}
                                onChange={(__, page: number) =>
                                    void changePage(page)
                                }
                                textColor="inherit"
                            >
                                {pageTabs.map((tab) => (
                                    <Tab
                                        key={tab.id}
                                        label={tab.label}
                                        id={tab.id}
                                    />
                                ))}
                            </Tabs>

                            <Box p={2}>
                                <Outlet />
                            </Box>
                        </Paper>
                    </EmployeeContext.Provider>
                )
            })}
        </>
    );
});
