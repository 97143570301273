import { Container } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react';
import { useWrapRequest } from 'use-wrap-request';
import { PageTitle } from '../components/page-title';
import { injectTSDI } from '../core/tsdi';
import { Api } from '../core/api';
import { Paper } from '../components/paper';
import { GeneralWorkoutRules } from './general-rules';

export const WorkoutRules = observer(() => {
    const { fitnessActivityClient } = injectTSDI(Api);

    const currentWorkoutRules = useWrapRequest(async () => {
        const result = await fitnessActivityClient.admin.workoutRuleAdminControllerGetGlobalWorkoutRules();
        return result.data;
    });

    React.useEffect(() => {
        currentWorkoutRules.request();
    }, []);

    return (
        <Container maxWidth="md">
            <Paper>
                <PageTitle>Global workout rules</PageTitle>
                {currentWorkoutRules.match({
                    fetched: (currentWorkoutRules) => (
                        <GeneralWorkoutRules
                            currentWorkoutRules={currentWorkoutRules}
                        />
                    )
                })}
            </Paper>
        </Container>
    );
});
