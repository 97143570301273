import React from 'react';
import styled from 'styled-components';
import {
    Marker as MapGLMarker,
    MarkerDragEvent,
    MarkerProps as MarkerGlProps
} from 'react-map-gl';
import { FacilityDto } from '../../../core/api/StudioClient';
import { Pin, PinTypes } from './pins';

interface MarkerProps extends MarkerGlProps {
    primary?: boolean;
    type?: PinTypes;
}

const MapGLMarkerComponent = (props: MarkerProps) => <MapGLMarker {...props} />;

export const Marker = styled(MapGLMarkerComponent)`
    z-index: ${({ primary }) => (primary ? 2 : 1)};
    opacity: 0.9;
    cursor: ${({ type }) => type === 'nearby-facility' && 'help !important'};
`;

interface MarkersProps {
    longitude?: number;
    latitude?: number;
    externalLongitude?: number;
    externalLatitude?: number;
    nearByFacilities?: FacilityDto[];
    setPopupInfo?(facility: FacilityDto): void;
    onMarkerDrag?(longitude: number, latitude: number): void;
    onDragStart?(e: MarkerDragEvent): void;
    onClick?: MarkerGlProps['onClick'];
}

export const Markers = ({
    longitude,
    latitude,
    externalLongitude,
    externalLatitude,
    nearByFacilities,
    setPopupInfo,
    onMarkerDrag,
    onDragStart,
    onClick
}: MarkersProps) => {
    const [marker, setMarker] = React.useState({
        latitude: latitude || 51.165691,
        longitude: longitude || 10.451526
    });

    const onMarkerDragEnd = React.useCallback((event: MarkerDragEvent) => {
        const longitude = event.lngLat.lng;
        const latitude = event.lngLat.lat;
        onMarkerDrag?.(longitude, latitude);
        setMarker({
            longitude,
            latitude
        });
    }, []);

    return (
        <>
            <Marker
                longitude={marker.longitude}
                latitude={marker.latitude}
                offset={[-20, -10]}
                onDragEnd={onMarkerDragEnd}
                onDragStart={onDragStart}
                onClick={onClick}
                draggable
                primary
                style={{ zIndex: 2, cursor: 'grab', opacity: 0.8 }}
            >
                <Pin draggable type="primary" />
            </Marker>
            {externalLongitude && externalLatitude && (
                <Marker
                    longitude={externalLongitude}
                    latitude={externalLatitude}
                    offset={[-20, -10]}
                    style={{ opacity: 0.8 }}
                >
                    <Pin type="source" />
                </Marker>
            )}
            {nearByFacilities?.map((facility) =>
                facility.address.latitude && facility.address.longitude ? (
                    <Marker
                        longitude={facility.address.longitude}
                        latitude={facility.address.latitude}
                        offset={[-20, -10]}
                        key={facility.id}
                        onClick={(
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            e: any
                        ) => {
                            e.originalEvent.preventDefault();
                            e.originalEvent.stopPropagation();
                            setPopupInfo?.(facility);
                        }}
                        type="nearby-facility"
                        style={{ cursor: 'help', opacity: 0.8 }}
                    >
                        <Pin type="nearby-facility" />
                    </Marker>
                ) : null
            )}
        </>
    );
};
