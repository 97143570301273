import { CircularProgress, Stack } from '@mui/material';
import React from 'react';
import { Box } from '@mui/system';
import { useParams } from 'react-router';
import { useWrapRequest } from 'use-wrap-request';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { TenantCorporateContext } from '../..';
import { useSearchReducer } from '../../../components/hooks/useSearchReducer';
import { ActivitySimpleDto } from '../../../core/api/CorporateClient';
import useInfiniteScroll from '../../../components/hooks/use-scroll';
import { Activity } from './activity';

const StyledList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const EmployeeActivities = observer(() => {
    const { employeeId } = useParams();
    const corporateClient = React.useContext(TenantCorporateContext);
    const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
        size: 60,
        page: 0
    });

    const [activities, setActivities] = React.useState<ActivitySimpleDto[]>([]);

    const activityRequest = useWrapRequest(
        async (
            searchParams: QueryParamsDto,
            activities: ActivitySimpleDto[]
        ) => {
            const result = await corporateClient.support.activitySupportControllerGetEmployeeActivities(
                { employeeId: Number(employeeId), ...searchParams }
            );
            setActivities([...activities, ...result.data.content]);

            return { ...result.data, content: result.data.content || [] };
        },
        {
            defaultData: { content: [] }
        }
    );

    const page = useInfiniteScroll({
        loading: activityRequest.loading,
        distance: 400
    });

    React.useEffect(() => {
        if (
            page > 0 &&
            activityRequest.$.totalPages &&
            activityRequest.$.totalPages > page
        ) {
            dispatch({ type: 'page', page });
        }
    }, [page]);

    React.useEffect(() => {
        activityRequest.request([searchParams, activities]);
    }, [searchParams]);

    return (
        <>
            <Box>
                <StyledList>
                    {activities.map((activity) => (
                        <Activity key={activity.id} activity={activity} />
                    ))}
                </StyledList>
                {activityRequest?.loading && (
                    <Stack direction="row" justifyContent="center">
                        <CircularProgress disableShrink />
                    </Stack>
                )}
            </Box>
        </>
    );
});

export default EmployeeActivities;
