import { Box, CssBaseline, IconButton, ThemeProvider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { observer } from 'mobx-react';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import React from 'react';
import {
    Navigate,
    PartialRouteObject,
    Route as RouterRoute,
    useLocation,
    useRoutes
} from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import styled, {
    ThemeProvider as StyledThemeProvider
} from 'styled-components';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserTitle } from '../components/browsertitle';
import { Route as RouteType, Route, Routes } from '../core/routes';
import { injectTSDI } from '../core/tsdi';
import { NoMatch } from '../no-match';
import { Api } from '../core/api';
import { ErrorBoundary } from './error-boundary';
import { GlobalStyles } from './global-styles';
import { Nav } from './nav';
import { theme } from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';

const Main = styled.main<{ fullWidth: boolean }>`
    max-width: ${({ fullWidth }) =>
        fullWidth ? '100%' : 'calc(100% - 280px)'};
    flex-grow: 1;
    padding: ${({ theme }) => theme.spacing(3)};
`;
const StyledWrapper = styled.div`
    display: flex;
`;

const privateRoute = (route: Route) =>
    Boolean(route.permissions?.includes('LOGGED_IN'));

const showRoute = (privatePath: boolean, loggedIn: boolean) =>
    !loggedIn && privatePath ? true : false;

const generateRoutes = (
    routes: RouteType[],
    pathname: string,
    parent?: Route
): PartialRouteObject[] => {
    const { loggedIn } = injectTSDI(Api);

    const isLogin = pathname === '/login';

    return routes.map((route) => {
        const redirect =
            !isLogin &&
            showRoute(privateRoute(parent ? parent : route), loggedIn);

        const { element: Element, children, ...rest } = route;
        return {
            ...rest,
            ...(children
                ? { children: generateRoutes(children, pathname, route) }
                : {}),
            element: redirect ? <Navigate to="/login" /> : Element
        };
    });
};

const RouteContent = observer(() => {
    const { routes } = injectTSDI(Routes);
    const { pathname } = useLocation();

    return useRoutes([...generateRoutes(routes, pathname)]);
});

export const App = observer(() => {
    const { loggedIn, googleAuthChecked, googleClientId } = injectTSDI(Api);

    const notiStackRef = React.createRef<SnackbarProvider>();

    const onClickDismiss = (key: SnackbarKey) => () => {
        notiStackRef.current?.closeSnackbar(key);
    };
    return (
        <ErrorBoundary>
            <AuthCheck />
            <GlobalStyles />
            <ThemeProvider theme={theme}>
                <StyledThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CssBaseline />
                        <SnackbarProvider
                            ref={notiStackRef}
                            maxSnack={3}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            action={(key) => (
                                <IconButton
                                    color="secondary"
                                    component="span"
                                    onClick={onClickDismiss(key)}
                                >
                                    <CloseIcon
                                        fontSize="small"
                                        sx={{ color: 'white' }}
                                    />
                                </IconButton>
                            )}
                        >
                            <StyledWrapper>
                                <GoogleOAuthProvider clientId={googleClientId}>
                                    {googleAuthChecked ? (
                                        <>
                                            <BrowserRouter>
                                                <BrowserTitle />
                                                {loggedIn && <Nav />}
                                                <Main fullWidth={!loggedIn}>
                                                    <RouteContent />
                                                </Main>
                                                <RouterRoute path="*">
                                                    <NoMatch />
                                                </RouterRoute>
                                            </BrowserRouter>
                                        </>
                                    ) : (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                width: '100%',
                                                height: '100vh',
                                                paddingBottom: 2
                                            }}
                                        >
                                            Loading...
                                        </Box>
                                    )}
                                </GoogleOAuthProvider>
                            </StyledWrapper>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </StyledThemeProvider>
            </ThemeProvider>
        </ErrorBoundary>
    );
});

const AuthCheck = observer(() => {
    const { checkForPermissions } = injectTSDI(Api);

    React.useEffect(() => {
        checkForPermissions();
    }, [checkForPermissions]);
    return null;
});
