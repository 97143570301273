import { Box, Container, Link } from '@mui/material';
import React from 'react';
import { useWrapRequest } from 'use-wrap-request';
import { observer } from 'mobx-react';
import { CellProps } from 'react-table';
import { useNavigate } from 'react-router';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CheckIcon from '@mui/icons-material/Check';
import { PageTitle } from '../components/page-title';
import { injectTSDI } from '../core/tsdi';
import { Api } from '../core/api';
import { Paper } from '../components/paper';
import { Table } from '../components/table';
import { useSearchReducer } from '../components/hooks/useSearchReducer';
import { userSearchDebounce } from '../components/hooks/use-search';
import { links } from '../core/router';
import { ActivitySourceDto } from '../core/api/FitnessActivityClient';
import { assertDefined } from '../components/utils/error';

export default observer(() => {
    const { fitnessActivityClient } = injectTSDI(Api);
    const navigate = useNavigate();

    const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
        size: 30,
        page: 0,
        sort: '-createdDate'
    });

    const [initialSearch] = React.useState<string | undefined>();

    const { setSearchTerm } = userSearchDebounce(dispatch);

    const activitySourcesRequest = useWrapRequest(
        async (searchParams) => {
            const result =
                await fitnessActivityClient.admin.activitySourceAdminControllerGetActivitySources(
                    searchParams
                );

            return {
                ...result.data,
                content: result.data.content || []
            };
        },
        {
            defaultData: {
                content: [],
                number: 0,
                size: 0,
                totalPages: 0,
                totalElements: 0
            },
            deps: [searchParams]
        }
    );

    const updateActivitySourceRequest = useWrapRequest(
        async (source: ActivitySourceDto) => {
            assertDefined(source.id);
            const result =
                await fitnessActivityClient.admin.activitySourceAdminControllerUpdateActivitySource(
                    source.id,
                    source
                );

            return result.data;
        },
        { successMessage: () => 'Source was changed' }
    );

    const onToggleSteps = async (source: ActivitySourceDto) => {
        try {
            source.stepsAllowed = !source.stepsAllowed;
            await updateActivitySourceRequest.request([source]);
        } catch (err) {
            alert(err);
        }

        activitySourcesRequest.request();
    };

    const onToggleCycling = async (source: ActivitySourceDto) => {
        try {
            source.cyclingAllowed = !source.cyclingAllowed;
            await updateActivitySourceRequest.request([source]);
        } catch (err) {
            alert(err);
        }
        activitySourcesRequest.request();
    };

    return (
        <Container maxWidth="md">
            <Paper>
                <PageTitle>Activity sources</PageTitle>

                <Table<ActivitySourceDto[]>
                    emptyText="No entries"
                    items={activitySourcesRequest}
                    columns={[
                        {
                            Header: 'Source package',
                            accessor: 'applicationPackage',
                            disableSortBy: true,
                            style: {
                                width: 300
                            }
                        },
                        {
                            Header: 'Steps',
                            disableSortBy: true,
                            Cell: ({
                                row: { original: row }
                            }: CellProps<ActivitySourceDto>) => (
                                <>
                                    {row.stepsAllowed ? (
                                        <CheckIcon color="success" />
                                    ) : (
                                        <NotInterestedIcon color="error" />
                                    )}
                                </>
                            )
                        },
                        {
                            Header: 'Cycling',
                            disableSortBy: true,
                            Cell: ({
                                row: { original: row }
                            }: CellProps<ActivitySourceDto>) => (
                                <>
                                    {row.cyclingAllowed ? (
                                        <CheckIcon color="success" />
                                    ) : (
                                        <NotInterestedIcon color="error" />
                                    )}
                                </>
                            )
                        },
                        {
                            Header: 'User',
                            Cell: ({
                                row: { original: row }
                            }: CellProps<ActivitySourceDto>) => {
                                const { origin } = row;
                                return (
                                    <>
                                        {origin?.userId && origin.tenantId && (
                                            <Link
                                                href="#"
                                                onClick={() => {
                                                    if (
                                                        origin.userId &&
                                                        origin.tenantId
                                                    ) {
                                                        navigate(
                                                            links.tenantEmployeeDetails(
                                                                origin.tenantId,
                                                                origin.userId.toString()
                                                            )
                                                        );
                                                    }
                                                }}
                                            >
                                                {origin.userId}
                                            </Link>
                                        )}
                                    </>
                                );
                            }
                        },
                        {
                            Header: 'Actions',
                            Cell: ({
                                row: { original: row }
                            }: CellProps<ActivitySourceDto>) => (
                                <>
                                    <Box>
                                        <Link
                                            href="#"
                                            onClick={() => {
                                                onToggleSteps(row);
                                            }}
                                        >
                                            {row.stepsAllowed
                                                ? 'Reject steps'
                                                : 'Approve steps'}
                                        </Link>
                                    </Box>
                                    <Box>
                                        <Link
                                            href="#"
                                            onClick={() => {
                                                onToggleCycling(row);
                                            }}
                                        >
                                            {row.cyclingAllowed
                                                ? 'Reject cycling'
                                                : 'Approve cycling'}
                                        </Link>
                                    </Box>
                                </>
                            )
                        }
                    ]}
                    onPageSizeChange={(size) =>
                        dispatch({ type: 'size', size })
                    }
                    onChangePage={(page) =>
                        void dispatch({ type: 'page', page })
                    }
                    onSearchChange={(val) => void setSearchTerm(val)}
                    initialSearch={initialSearch}
                    key={initialSearch}
                />
            </Paper>
        </Container>
    );
});
