import { Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import React from 'react';
import { useWrapRequest } from 'use-wrap-request';
import { useSearchReducer } from '../../components/hooks/useSearchReducer';
import { Paper } from '../../components/paper';
import { Spinner } from '../../components/spinner';
import { Table } from '../../components/table';
import { Api } from '../../core/api';
import { CustomFacilitySearchDto, FacilityDto } from '../../core/api/StudioClient';
import { injectTSDI } from '../../core/tsdi';

interface NearbyFacilitiesProps {
    studio: CustomFacilitySearchDto;
}

export const NearbyFacilities = observer(
    ({ studio }: NearbyFacilitiesProps) => {
        const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
            size: 30,
            page: 0
        });

        const { studioClient } = injectTSDI(Api);

        const facilities = useWrapRequest(
            async (searchParams, address, studioId) => {
                address.latitude;
                const result = await studioClient.v1.publicFacilityControllerGetFacilities(
                    {
                        ...searchParams,
                        ...(address.latitude
                            ? { latitude: [address.latitude] }
                            : {}),
                        ...(address.longitude
                            ? { longitude: [address.longitude] }
                            : {}),
                        radius: 500,
                        geoRequestType: 'CIRCLE',
                        ...(studioId ? { excludeId: studioId } : {})
                    }
                );

                return {
                    ...result.data,
                    content: result.data.content || []
                };
            },
            {
                defaultData: { content: [] },
                deps: [searchParams, studio.address, studio.id]
            }
        );

        return (
            <>
                {facilities.match({
                    loading: () => (
                        <Box mt={4} display="flex" justifyContent="center">
                            <Spinner />
                        </Box>
                    ),
                    fetched: (data) =>
                        data.totalElements && data.totalElements > 0 ? (
                            <Box mt={4} mb={8}>
                                <Typography variant="h6" mb={1} pl={3} pr={3}>
                                    Nearby studios
                                </Typography>
                                <Paper noPadding>
                                    <Table<FacilityDto[]>
                                        emptyText="No entries"
                                        items={facilities}
                                        columns={[
                                            {
                                                Header: 'Name',
                                                accessor: 'name'
                                            },
                                            {
                                                Header: 'Category',
                                                accessor: (facility) =>
                                                    facility
                                                        .categoryWithTranslation
                                                        .label
                                            },
                                            {
                                                Header: 'Status',
                                                accessor: 'status'
                                            },
                                            {
                                                Header: 'Facility type',
                                                accessor: 'facilityType'
                                            }
                                        ]}
                                        onPageSizeChange={(size) =>
                                            dispatch({ type: 'size', size })
                                        }
                                        onChangePage={(page) =>
                                            void dispatch({
                                                type: 'page',
                                                page
                                            })
                                        }
                                        renderRowSubComponent={RowDetails}
                                    />
                                </Paper>
                            </Box>
                        ) : null
                })}
            </>
        );
    }
);

const RowDetails = ({ row }: { row: FacilityDto }) => {
    const {
        city,
        street,
        houseNumber,
        latitude,
        longitude,
        zipCode,
        countryWithTranslation
    } = row.address;

    return (
        <Stack>
            <Grid container spacing={2} p={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Address</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack spacing={2}>
                        <InfoBox label="City" text={city} />
                        <InfoBox label="Street" text={street} />
                        <InfoBox label="House Number" text={houseNumber} />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack spacing={2}>
                        <InfoBox
                            label="Country"
                            text={countryWithTranslation?.translation}
                        />
                        <InfoBox label="Zip" text={zipCode} />
                        <InfoBox label="Latitude" text={latitude} />
                        <InfoBox label="Longitude" text={longitude} />
                    </Stack>
                </Grid>
            </Grid>
        </Stack>
    );
};
interface InfoBoxProps {
    label: string;
    text?: string | number;
}
const InfoBox = ({ label, text }: InfoBoxProps) => (
    <Stack>
        <Typography variant="body2" fontWeight={600}>
            {label}
        </Typography>
        <Typography>{text}</Typography>
    </Stack>
);
