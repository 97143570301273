import { observable, makeObservable, reaction } from 'mobx';
import { wrapRequest } from 'wrap-request';
import { component } from 'tsdi';
import { Api } from './api';
import { injectTSDI } from './tsdi';
import { autodisposer } from './reactions';

@component
export class InitialData {
    private get api() {
        return injectTSDI(Api);
    }

    public studioCategories = wrapRequest(
        async () => {
            const result = await this.api.studioClient.v1.publicFacilityControllerGetAvailableCategories();
            return result.data || [];
        },
        { defaultData: [] }
    );

    public availableCountries = wrapRequest(
        async () => {
            const result = await this.api.studioClient.v1.countryControllerGetAvailableCountries();
            return result.data || [];
        },
        {
            defaultData: [],
            transform: (countries) =>
                countries.map((country) => ({
                    value: country.key,
                    label: country.value || ''
                }))
        }
    );

    constructor() {
        makeObservable(this, {
            studioCategories: observable
        });
    }

    @autodisposer.tsdi
    public init() {
        return [
            reaction(
                () => this.api.clientsInitialized,
                (initialiazed) => {
                    if (initialiazed) {
                        this.studioCategories.request();
                        this.availableCountries.request();
                    }
                },
                {
                    fireImmediately: true
                }
            )
        ];
    }
}
