import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import React from 'react';
import { CellProps } from 'react-table';
import { useWrapRequest } from 'use-wrap-request';
import { Button, Typography } from '@mui/material';
import { TenantCorporateContext } from '..';
import { userSearchDebounce } from '../../components/hooks/use-search';
import { useSearchReducer } from '../../components/hooks/useSearchReducer';
import { Table } from '../../components/table';
import { CompanyAccountDto } from '../../core/api/CorporateClient';

export const TenantUsers = () => {
    const corporateClient = React.useContext(TenantCorporateContext);
    const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
        size: 30,
        page: 0
    });
    const { setSearchTerm } = userSearchDebounce(dispatch);

    const users = useWrapRequest(
        async (searchParams) => {
            const result =
                await corporateClient.support.companyAccountSupportControllerGetPage(
                    searchParams
                );

            return { ...result.data, content: result.data.content || [] };
        },
        {
            defaultData: { content: [] },
            deps: [searchParams]
        }
    );

    const { enqueueSnackbar } = useSnackbar();
    const copyEmail = (email: string) => {
        navigator.clipboard.writeText(email);
        enqueueSnackbar(`${email} was copied to the clipboard`, {
            variant: 'success',
            autoHideDuration: 2000
        });
    };

    return (
        <Box m={-2} mt={1}>
            <Table<CompanyAccountDto[]>
                items={users}
                columns={[
                    { Header: 'First Name', accessor: 'firstName' },
                    { Header: 'Last Name', accessor: 'lastName' },
                    { Header: 'Role', accessor: 'role' },
                    {
                        Header: 'Email',
                        Cell: ({
                            row: {
                                original: { email }
                            }
                        }: CellProps<CompanyAccountDto>) => (
                            <Box display="flex">
                                <Tooltip title={email} placement="top-start">
                                    <Button
                                        onClick={() => void copyEmail(email)}
                                    >
                                        <Typography
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            {email}
                                        </Typography>
                                    </Button>
                                </Tooltip>
                            </Box>
                        )
                    }
                ]}
                onSearchChange={(val) => void setSearchTerm(val)}
                onPageSizeChange={(size) => dispatch({ type: 'size', size })}
                onChangePage={(page) => void dispatch({ type: 'page', page })}
            />
        </Box>
    );
};

export default TenantUsers;
