import React from 'react';
import { observer } from 'mobx-react';
import {
    Box,
    Divider,
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import styled, { css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { MSLogo } from '../components/svg-icons/ms-logo';
import { injectTSDI } from '../core/tsdi';
import { Route, Routes } from '../core/routes';

export const drawerWidth = 280;

const StyledDrawer = styled(Drawer)<{ open: boolean }>`
    flex: ${drawerWidth}px 0 0;
    flex-shrink: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.palette.primary.main};

    &.MuiDrawer-docked {
        flex: ${drawerWidth}px 0 0;
    }
    .MuiDrawer-paper {
        padding: 0;
    }

    ${({ open, theme }) =>
        open
            ? css`
                  transition: ${theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.enteringScreen
                  })};
                  .MuiPaper-root {
                      width: ${drawerWidth}px;
                  }
              `
            : css`
                  transition: ${theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen
                  })};
                  overflow-x: 'hidden';
                  width: ${theme.spacing(7) + 1};
                  ${(props) => props.theme.breakpoints.up('sm')} {
                      width: ${theme.spacing(9) + 1};
                  }
              `}
`;

const StyledTitle = styled(Typography)`
    font-weight: 700;
    font-size: 1.15rem;
    margin-top: 4px;
`;
const StyledLogoLink = styled(Link)`
    text-decoration: none;
`;

const StyledListItemIcon = styled(ListItemIcon)``;

const StyledList = styled(List)`
    margin-top: 4px;
    ${StyledListItemIcon} {
        min-width: 40px;
    }
`;
const StyledNavLink = styled(Link)`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.text.primary};
    text-decoration: none;
`;

const StyledListItemText = styled(ListItemText)`
    margin: 0;
    text-decoration: none;
`;

export const Nav = observer(() => {
    const { navLinks } = injectTSDI(Routes);
    const location = useLocation();

    const active = (path: string) => location.pathname === path;

    return (
        <StyledDrawer variant="permanent" open>
            <StyledLogoLink to="/">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    padding={2}
                    fontWeight="700"
                >
                    <MSLogo sx={{ fontSize: 60 }} />

                    <StyledTitle variant="h6" color="textPrimary">
                        MS ADMIN
                    </StyledTitle>
                </Box>
            </StyledLogoLink>

            <Divider />

            <StyledList>
                {navLinks.map(({ title, Icon, path }: Route) => (
                    <StyledNavLink key={title} to={path}>
                        <ListItemButton selected={active(path)}>
                            <StyledListItemIcon>
                                {Icon && <Icon />}
                            </StyledListItemIcon>
                            <StyledListItemText primary={title} />
                        </ListItemButton>
                    </StyledNavLink>
                ))}
            </StyledList>
        </StyledDrawer>
    );
});
