import { observer } from 'mobx-react';
import React from 'react';
import { useLocation } from 'react-router';
import { Routes } from '../../core/routes';
import { injectTSDI } from '../../core/tsdi';

export const BrowserTitle = observer(() => {
    const defaultTitle = 'Ms Admin';
    const location = useLocation();

    const { getPageTitle } = injectTSDI(Routes);

    const title = getPageTitle(location.pathname) || '';

    React.useEffect(() => {
        const titles = title
            ? [defaultTitle, title].filter((t) => Boolean(t)).join(' | ')
            : defaultTitle;

        document.title = titles;

        return () => {
            document.title = defaultTitle;
        };
    }, [defaultTitle, title]);

    return <></>;
});
