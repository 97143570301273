import { useSnackbar } from 'notistack';
import React from 'react';
import { wrapRequest } from 'wrap-request';
import { useError } from './useError';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ToupleArray = any[] | readonly [any];

interface AdminRequestOptions<Y, T> {
    deps?: Y;
    defaultData?: T;
    cacheKey?: string;
    successMessage?: (deps: Y) => string;
}

export function useWrapRequest<T, Y extends ToupleArray>(
    req: (...deps: Y) => Promise<T>,
    options: AdminRequestOptions<Y, T> = {}
) {
    const [, setResult] = React.useState<T>();
    const [, setState] = React.useState<string | Error>();
    const showError = useError();
    const { enqueueSnackbar } = useSnackbar();

    const { successMessage, ...restOptions } = options;
    const { deps: orgDeps, ...wrapRequestOptions } = restOptions;
    const deps = (orgDeps || []) as Y;

    const wrapped = React.useMemo(() => {
        const wr = wrapRequest(async (deps = []) => {
            try {
                const res = await req(...deps);

                setResult(res);
                if (successMessage) {
                    enqueueSnackbar(successMessage(deps), {
                        variant: 'success',
                        autoHideDuration: 6000
                    });
                }

                return res;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
                setState(e);
                showError(e);
                throw e;
            }
        }, wrapRequestOptions);

        wr.match({
            default: () => setState('default'),
            empty: () => setState('empty'),
            error: (e) => setState(e),
            fetched: () => setState('fetched'),
            loading: () => setState('loading')
        });

        return wr;
    }, []);

    React.useEffect(() => {
        if (orgDeps && orgDeps.every((dep) => dep !== undefined)) {
            wrapped.request(deps);
        }
    }, deps);

    return wrapped;
}
