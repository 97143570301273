import { Grid, Typography } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react';
import { Label } from '../../../components/label';
import { ActivityPointsDto } from '../../../core/api/FitnessActivityClient';

interface ActivityDetailsProps {
    activityPoints: ActivityPointsDto;
}

export const ActivityPointsDetails = observer(
    ({ activityPoints }: ActivityDetailsProps) => {
        activityPoints.changes.sort((a, b) =>
            a.createdDate > b.createdDate ? 1 : -1
        );
        return (
            <Grid container spacing={2}>
                {activityPoints.changes.map((activityPointChange) => (
                    <Grid item xs={4}>
                        <Label>{activityPointChange.createdDate}</Label>
                        <Typography>{activityPointChange.value}</Typography>
                    </Grid>
                ))}
            </Grid>
        );
    }
);
