import { Box, Typography } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { MSLogo } from '../components/svg-icons/ms-logo';
import { injectTSDI } from '../core/tsdi';
import { Api } from '../core/api';
import { links } from '../core/router';

const StyledTitle = styled(Typography)`
    font-weight: 700;
    font-size: 1.15rem;
    margin-top: 4px;
`;

export const Login = observer(() => {
    const { addPermission, removePermission, generateGoogleUserAuth } =
        injectTSDI(Api);
    const navigate = useNavigate();

    const handleSuccess = (response: CredentialResponse) => {
        if (response.credential !== undefined) {
            generateGoogleUserAuth(response.credential);
            addPermission('LOGGED_IN');
            navigate(links.tenants());
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 50px)"
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                padding={2}
                fontWeight="700"
                mb={2}
            >
                <MSLogo sx={{ fontSize: 120 }} />

                <StyledTitle variant="h5" color="textPrimary">
                    MS ADMIN
                </StyledTitle>
            </Box>

            <GoogleLogin
                onSuccess={handleSuccess}
                onError={() => {
                    console.log('Login Failed');
                    removePermission('LOGGED_IN');
                }}
                auto_select
            />
            {/* <GoogleLogin
                clientId={googleClientId}
                render={(renderProps) => (
                    <Button
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        size="large"
                    >
                        <Box component="span" mr={1}>
                            Sign in with Google
                        </Box>{' '}
                        <GoogleIcon />
                    </Button>
                )}
                buttonText="Login"
                onSuccess={handleSuccess}
                onFailure={handleFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={loggedIn}
            /> */}
        </Box>
    );
});
