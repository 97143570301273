import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { observer } from 'mobx-react';
import React from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { useTabs } from '../../components/hooks/use-tabs';

import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Button } from '../../components/button';
import { useWrapRequest } from '../../components/hooks/use-wrap-request';
import { Paper } from '../../components/paper';
import { Api } from '../../core/api';
import { UserSearchSupportDto } from '../../core/api/MobileClient';
import { links } from '../../core/router';
import { injectTSDI } from '../../core/tsdi';

const pageTabs = [
    { label: 'Details', id: 'details' },
    { label: 'Wallet', id: 'wallet' },
    { label: 'Fitness Activities', id: 'fitness-activities' },
    { label: 'Activity Points', id: 'activity-points' }
];

export const UserContext = React.createContext<UserSearchSupportDto>(
    {} as UserSearchSupportDto
);

export default observer(() => {
    const { pathname } = useLocation();
    const { tab, getPageLink, setPageTab } = useTabs(pageTabs);
    const navigate = useNavigate();
    const { mobileClient } = injectTSDI(Api);

    const { userId } = useParams();

    const userRequest = useWrapRequest<UserSearchSupportDto, any>(
        async () => {
            const result =
                await mobileClient.supportApi.userSearchSupportControllerGetUser(
                    Number(userId)
                );

            return result.data;
        },
        {
            deps: []
        }
    );

    const changePage = (page: number) => {
        navigate(getPageLink(page));
    };

    React.useEffect(() => {
        setPageTab(pathname, 3);
    }, [pathname]);
    return (
        <>
            {userRequest.match({
                fetched: (user) => (
                    <UserContext.Provider value={user}>
                        <Box maxWidth={900}>
                            <Stack
                                justifyContent="space-between"
                                flexDirection="row"
                                mb={1}
                            >
                                <Typography variant="h6">
                                    {user.firstName} {user.lastName}{' '}
                                </Typography>

                                <Button
                                    variant="text"
                                    startIcon={<ArrowBackIcon />}
                                    onClick={() => void navigate(links.users())}
                                >
                                    Back to Users
                                </Button>
                            </Stack>
                            <Paper noPadding>
                                <Tabs
                                    value={tab}
                                    onChange={(__, page: number) =>
                                        void changePage(page)
                                    }
                                    textColor="inherit"
                                >
                                    {pageTabs.map((tab) => (
                                        <Tab
                                            key={tab.id}
                                            label={tab.label}
                                            id={tab.id}
                                        />
                                    ))}
                                </Tabs>

                                <Box p={2}>
                                    <Outlet />
                                </Box>
                            </Paper>
                        </Box>
                    </UserContext.Provider>
                )
            })}
        </>
    );
});
