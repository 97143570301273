import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Popup as MapGLPopup } from 'react-map-gl';
import styled from 'styled-components';
import { FacilityDto } from '../../../core/api/StudioClient';

export const Popup = styled(MapGLPopup)`
    z-index: 3;
`;

interface NearbyFacilityInfoProps {
    facility: FacilityDto;
}

export const NearbyFacilityInfo = ({ facility }: NearbyFacilityInfoProps) => (
    <Box minWidth={300}>
        <Grid container>
            <Grid item xs={4}>
                <Typography color="black" variant="body2" fontWeight={600}>
                    Name:
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color="black">{facility.name}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography color="black" variant="body2" fontWeight={600}>
                    Address:
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color="black">
                    {facility.address.street} {facility.address.houseNumber}
                </Typography>
            </Grid>
        </Grid>
    </Box>
);
