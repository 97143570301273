import { Container, Grid } from '@mui/material';
import React from 'react';
import { PageTitle } from '../components/page-title';
import TenantOverview from './tenant-overview';
import CollectedStartReport from './collected-stars';

const Reports = () => (
    <Container maxWidth="md">
        <PageTitle>Reports</PageTitle>
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <TenantOverview />
            </Grid>
            <Grid item xs={12} md={6}>
                <CollectedStartReport />
            </Grid>
        </Grid>
    </Container>
);

export default Reports;
