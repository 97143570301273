import React from 'react';
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent
} from '@mui/material';

import styled from 'styled-components';
import { Option } from '../select';

const StyledInputLabel = styled(InputLabel)``;
const StyledFormControl = styled(FormControl)`
    ${StyledInputLabel} {
        top: -11px;
        left: -14px;
    }

    .MuiSelect-select {
        padding: 10px 26px 10px 12px;
    }
`;

interface BaseFiltersProps<T = any> {
    label: string;
    required?: boolean;
    error?: string;
    options: Option<T>[];
    multiple?: boolean;
    width?: number;
}

interface MultipleFiltersProps<T = any> extends BaseFiltersProps {
    value: T[];
    onChange(value: T[]): void;
}

interface SingleFiltersProps<T = any> extends BaseFiltersProps {
    value: T;
    onChange(value: T): void;
}

type FiltersProps<T = any, Multi = true> = Multi extends true
    ? MultipleFiltersProps<T>
    : SingleFiltersProps<T>;

export const Filters = <T extends any, Multi extends boolean>({
    label,
    required,
    value,
    error,
    options,
    multiple,
    width,
    onChange
}: FiltersProps<T, Multi>) => {
    const handleChange = (event: SelectChangeEvent<T[] | T>) => {
        const {
            target: { value }
        } = event;

        onChange(value as T[] & T);
    };

    const checkIfChecked = (id: T) => {
        return Array.isArray(value) ? value.indexOf(id) > -1 : value == id;
    };

    return (
        <StyledFormControl
            fullWidth
            sx={{ flex: width ? `0 0 ${width}px` : 1 }}
        >
            <StyledInputLabel required={required} shrink>
                {label}
            </StyledInputLabel>
            <Select
                fullWidth
                multiple={multiple}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                value={value as T[]}
                onChange={handleChange}
                renderValue={(selected): React.ReactNode => {
                    if (typeof (selected as T[] | T) === 'string') {
                        return (
                            <>
                                {options.find(
                                    (option) => option.value === selected
                                )?.label || (selected as unknown as string)}
                            </>
                        );
                    } else if (Array.isArray(selected)) {
                        return (
                            <>
                                {selected.map((item) => (
                                    <React.Fragment key={item as string}>
                                        {options.find(
                                            (option) => option.value === item
                                        )?.label || (item as React.ReactNode)}
                                    </React.Fragment>
                                ))}
                            </>
                        );
                    } else {
                        return selected;
                    }
                }}
                error={Boolean(error)}
                required={required}
            >
                {options.map(({ label, value: id }) => (
                    <MenuItem key={`${id}`} value={`${id}`}>
                        {multiple && <Checkbox checked={checkIfChecked(id)} />}
                        <ListItemText primary={label} />
                    </MenuItem>
                ))}
            </Select>
        </StyledFormControl>
    );
};
