import { FieldState, FormState } from 'formstate';
import { clone } from 'lodash';
import { useParams } from 'react-router';
import { useWrapRequest } from '../../../components/hooks/use-wrap-request';
import { assertDefined } from '../../../components/utils/error';
import { FacilityAreaDto, GeoPolygonDto } from '../../../core/api/StudioClient';
import { required, useModel, wrapInFormField } from '../../../core/forms';
import { injectTSDI } from '../../../core/tsdi';
import { Api } from './../../../core/api';
import { DrawingMode, FeatureArea } from './draw-controls';
import {
    convertFeatureAreaToAreaGeometryDto,
    convertFormDataToApi
} from './utils';

export interface AreaGeometryDto extends GeoPolygonDto {
    id: string;
}

type FormFields = ReturnType<typeof useStore>['fields'];

export const useStore = (area: FacilityAreaDto) => {
    const { studioClient } = injectTSDI(Api);

    const { facilityId } = useParams();

    const model = useModel(() => {
        const form = new FormState({
            center: new FormState({
                latitude: new FieldState<number | undefined>(
                    area?.center.latitude
                ).validators(required()),
                longitude: new FieldState<number | undefined>(
                    area?.center.longitude
                ).validators(required())
            }),
            visitArea: new FieldState<AreaGeometryDto[]>([]),
            checkInArea: new FieldState<AreaGeometryDto[]>([])
        });

        const center = {
            latitude: wrapInFormField(form.$.center.$.latitude),
            longitude: wrapInFormField(form.$.center.$.longitude)
        };

        return {
            form,
            fields: {
                center,
                visitArea: wrapInFormField(form.$.visitArea),
                checkInArea: wrapInFormField(form.$.checkInArea)
            }
        };
    });

    const addArea = (area: AreaGeometryDto, mode: DrawingMode) => {
        const field =
            mode === 'checkin-area'
                ? model.fields.checkInArea
                : model.fields.visitArea;
        const value = clone(field.value);
        value.push(area);
        field.onChange(value);
        updateFacility(model.fields);
    };

    const loadInitialData = (featureAreas: FeatureArea[]) => {
        const checkInAreas = featureAreas
            .filter((feature) => feature.properties.mode === 'checkin-area')
            .map(convertFeatureAreaToAreaGeometryDto);
        if (checkInAreas.length) {
            model.fields.checkInArea.onChange(checkInAreas);
        }
        const visitAreas = featureAreas
            .filter((feature) => feature.properties.mode === 'visit-area')
            .map(convertFeatureAreaToAreaGeometryDto);
        if (visitAreas.length) {
            model.fields.visitArea.onChange(visitAreas);
        }
    };

    const updateArea = (areaUpdate: AreaGeometryDto, mode: DrawingMode) => {
        function findArea(area: AreaGeometryDto) {
            return area.id === areaUpdate.id;
        }

        const field =
            mode === 'checkin-area'
                ? model.fields.checkInArea
                : model.fields.visitArea;

        const value = clone(field.value);
        const areaIndex = value.findIndex(findArea);

        if (areaIndex > -1) {
            value[areaIndex] = areaUpdate;
            field.onChange(value);
        }
        updateFacility(model.fields);
    };

    const deleteArea = (areaId: string, mode: DrawingMode) => {
        function deleteArea(area: AreaGeometryDto) {
            return area.id !== areaId;
        }

        const field =
            model.fields[mode === 'checkin-area' ? 'checkInArea' : 'visitArea'];

        const value = clone(field.value);
        field.onChange(value.filter(deleteArea));
        updateFacility(model.fields);
    };

    const updateFacilityArea = useWrapRequest(
        async (facilityId: number, area: FacilityAreaDto) => {
            const result = studioClient.v1.facilityControllerUpdateFacilityArea(
                facilityId,
                area
            );
            return (await result).data;
        }
    );

    const nearByFacilities = useWrapRequest(
        async (longitude: number, latitude: number) => {
            const result = await studioClient.v1.publicFacilityControllerGetFacilities(
                {
                    latitude: [latitude],
                    longitude: [longitude],
                    size: 100,
                    page: 0,
                    radius: 400,
                    geoRequestType: 'CIRCLE',
                    ...(facilityId ? { excludeId: Number(facilityId) } : {})
                }
            );

            return {
                ...result.data,
                content: result.data.content || []
            };
        },
        {
            defaultData: { content: [] }
        }
    );

    const updateFacility = ({ center, visitArea, checkInArea }: FormFields) => {
        assertDefined(center.longitude.value);
        assertDefined(center.latitude.value);

        updateFacilityArea.request([
            facilityId,
            convertFormDataToApi({
                center: {
                    longitude: center.longitude.value,
                    latitude: center.latitude.value
                },
                visitArea: visitArea.value,
                checkInArea: checkInArea.value
            })
        ]);
    };

    const updateCenter = (longitude: number, latitude: number) => {
        model.fields.center.longitude.onChange(longitude);
        model.fields.center.latitude.onChange(latitude);
        updateFacility(model.fields);
    };

    return {
        fields: model.fields,
        form: model.form,
        nearByFacilities,
        addArea,
        updateArea,
        updateFacilityArea,
        deleteArea,
        updateCenter,
        loadInitialData
    };
};
