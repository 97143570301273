import React from 'react';
import styled, { css } from 'styled-components';
import { InputLabel, Typography } from '@mui/material';
import { EntryDto } from '../../core/api/StudioClient';
import { Input } from '../../components/Input';
import { StyledSectionField, StyledSectionLabel } from './facility-details-form';

interface AdditionalDataProps {
    data: EntryDto[];
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 80px;
`;

const Item = styled.div<{ first?: boolean; last?: boolean }>`
    display: flex;
    min-width: 60%;
    align-items: center;
    label {
        text-align: right;
    }
    ${({ first }) =>
        first &&
        css`
            ${StyledSectionField} {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            } ;
        `}
    ${({ last }) =>
        last &&
        css`
            ${StyledSectionField} {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom: none;
            }
        `}
`;

const Title = styled.div`
    display: flex;
    min-width: 60%;
    justify-content: center;
    padding-left: 150px;
    margin: 10px 0;
`;

export const AdditionalData = ({ data }: AdditionalDataProps) => (
    <Container>
        <Title>
            <Typography variant="button" color="GrayText">
                ADDITIONAL DATA
            </Typography>
        </Title>
        {data.map(({ key, value }, index) => (
            <Item
                key={key}
                first={index === 0}
                last={index + 1 === data.length}
            >
                <StyledSectionLabel>
                    <InputLabel htmlFor="my-input" shrink>
                        {key}
                    </InputLabel>
                </StyledSectionLabel>
                <StyledSectionField>
                    <Input value={value} disabled />
                </StyledSectionField>
            </Item>
        ))}
    </Container>
);
