import { AppBar, Box, Container, Tab, Tabs } from '@mui/material';
import React from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { WrapRequest } from 'wrap-request';
import { PageTitle } from '../components/page-title';
import { Paper } from '../components/paper';
import { Api as CorporateApi, PromoterDto } from '../core/api/CorporateClient';
import { useTabs } from '../components/hooks/use-tabs';
import { TenantDto } from '@api/TenantClient';
import { useStore } from './store';

const pageTabs = [
    { label: 'Details', id: 'details' },
    { label: 'Contract', id: 'contract' },
    { label: 'Features', id: 'features' },
    { label: 'Employees', id: 'employees' },
    { label: 'Community', id: 'community' },
    { label: 'Users', id: 'users' }
];

export const TenantCorporateContext = React.createContext<
    CorporateApi<unknown>
>({} as CorporateApi<unknown>);
type TenantFeaturesType = WrapRequest<{
    company: PromoterDto
    tenant: TenantDto;
}>;

export const TenantFeaturesContext = React.createContext<TenantFeaturesType>(
    {} as TenantFeaturesType
);

export const Tenant = () => {
    const { tenantName } = useParams();
    const { pathname } = useLocation();
    const { tab, getPageLink, setPageTab } = useTabs(pageTabs);
    const navigate = useNavigate();

    const { corporateClient, tenantFeatures } = useStore();

    const changePage = (page: number) => {
        navigate(getPageLink(page));
    };

    React.useEffect(() => {
        setPageTab(pathname, 3);
    }, [pathname]);

    return (
        <TenantCorporateContext.Provider value={corporateClient}>
            <TenantFeaturesContext.Provider value={tenantFeatures}>
                <Container maxWidth="md">
                    <PageTitle>{tenantName}</PageTitle>
                    <Paper noPadding>
                        <AppBar position="static" sx={{ minHeight: 'auto' }}>
                            <Tabs
                                value={tab}
                                onChange={(__, page: number) =>
                                    void changePage(page)
                                }
                                textColor="inherit"
                            >
                                {pageTabs.map((tab) => (
                                    <Tab
                                        key={tab.id}
                                        label={tab.label}
                                        id={tab.id}
                                    />
                                ))}
                            </Tabs>
                        </AppBar>
                        <Box p={2}>
                            <Outlet />
                        </Box>
                    </Paper>
                </Container>
            </TenantFeaturesContext.Provider>
        </TenantCorporateContext.Provider>
    );
};

export default Tenant;
