import { FieldState, FormState } from 'formstate';
import { useWrapRequest } from 'use-wrap-request';

import {
    notNegative,
    positive,
    required,
    useModel,
    wrapInFormField
} from '../../core/forms';
import { WorkoutRuleDto } from '../../core/api/FitnessActivityClient';
import { injectTSDI } from '../../core/tsdi';
import { Api } from '../../core/api';

export function useStore({
    minWorkoutDurationMinutes,
    minAverageSpeed,
    maxAverageSpeed,
    maxAllowedSpeed,
    minStudioVisitDurationMinutes,
    noRecentCheckInConfirmationNotificationTimeoutMinutes
}: WorkoutRuleDto) {
    const { fitnessActivityClient } = injectTSDI(Api);

    const workoutRuleRequest = useWrapRequest(
        async (data: WorkoutRuleDto) => {
            const result = await fitnessActivityClient.admin.workoutRuleAdminControllerUpdateGlobalWorkoutRules(
                data
            );
            return result;
        },
        { successMessage: () => 'Workout Rule updated' }
    );

    const model = useModel(() => {
        const form = new FormState({
            minWorkoutDurationMinutes: new FieldState<number>(
                minWorkoutDurationMinutes
            ).validators(required(), notNegative()),
            minAverageSpeed: new FieldState<number>(minAverageSpeed).validators(
                required(),
                notNegative()
            ),
            maxAverageSpeed: new FieldState<number>(maxAverageSpeed).validators(
                required(),
                positive()
            ),
            maxAllowedSpeed: new FieldState<number>(maxAllowedSpeed).validators(
                required(),
                positive()
            ),
            minStudioVisitDurationMinutes: new FieldState<number>(
                minStudioVisitDurationMinutes
            ).validators(required(), positive()),
            noRecentCheckInConfirmationNotificationTimeoutMinutes: new FieldState<number>(
                noRecentCheckInConfirmationNotificationTimeoutMinutes
            ).validators(required(), positive())
        });
        return {
            form,
            fields: {
                minWorkoutDurationMinutes: wrapInFormField(form.$.minWorkoutDurationMinutes),
                minAverageSpeed: wrapInFormField(form.$.minAverageSpeed),
                maxAverageSpeed: wrapInFormField(form.$.maxAverageSpeed),
                maxAllowedSpeed: wrapInFormField(form.$.maxAllowedSpeed),
                minStudioVisitDurationMinutes: wrapInFormField(
                    form.$.minStudioVisitDurationMinutes
                ),
                noRecentCheckInConfirmationNotificationTimeoutMinutes: wrapInFormField(
                    form.$.noRecentCheckInConfirmationNotificationTimeoutMinutes
                )
            }
        };
    });

    const onSubmitHandler = async () => {
        const validation = await model.form.validate();
        const {
            minWorkoutDurationMinutes,
            minAverageSpeed,
            maxAverageSpeed,
            maxAllowedSpeed,
            minStudioVisitDurationMinutes,
            noRecentCheckInConfirmationNotificationTimeoutMinutes
        } = model.fields;

        if (validation.hasError) {
            throw new Error(model.form.error as string);
        }
        try {
            await workoutRuleRequest.request([
                {
                    minWorkoutDurationMinutes: minWorkoutDurationMinutes.value,
                    minAverageSpeed: minAverageSpeed.value,
                    maxAverageSpeed: maxAverageSpeed.value,
                    maxAllowedSpeed: maxAllowedSpeed.value,
                    minStudioVisitDurationMinutes:
                        minStudioVisitDurationMinutes.value,
                    noRecentCheckInConfirmationNotificationTimeoutMinutes:
                        noRecentCheckInConfirmationNotificationTimeoutMinutes.value
                }
            ]);
        } catch (err) {
            alert(err);
        }
    };

    return {
        fields: model.fields,
        errors: model.form.error,
        onSubmitHandler,
        workoutRuleRequest
    };
}
