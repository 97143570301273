import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useWrapRequest } from 'use-wrap-request';
import { observer } from 'mobx-react';
import { injectTSDI } from '../../../core/tsdi';
import { Api } from '../../../core/api';
import { Label } from '../../../components/label';
import { Button } from '../../../components/button';
import { I18n } from '../../../core/i18n';

interface FitnessActivityDetailsProps {
    fitnessActivityId: number;
}

export const FitnessActivityDetails = observer(
    ({ fitnessActivityId }: FitnessActivityDetailsProps) => {
        const { fitnessActivityClient } = injectTSDI(Api);
        const { formatDateTime } = injectTSDI(I18n);

        const activityDetailsRequest = useWrapRequest(
            async (fitnessActivityId) => {
                const result =
                    await fitnessActivityClient.admin.activitySupportControllerGetActivity(
                        fitnessActivityId
                    );
                return result.data;
            },
            {
                deps: [fitnessActivityId]
            }
        );

        const backfillRequest = useCallback(
            async (fitnessActivityId: number) => {
                const result =
                    await fitnessActivityClient.admin.activitySupportControllerBackfillActivity(
                        fitnessActivityId
                    );
                return result.data;
            },
            [fitnessActivityId]
        );

        const showBackfillButton = useMemo(() => {
            const fitnessActivityDetails = activityDetailsRequest.$;
            if (
                fitnessActivityDetails &&
                fitnessActivityDetails.backfill?.backfillDateTime === null
            ) {
                const fitnessSources =
                    fitnessActivityDetails.additionalProperties.find(
                        (source) => source.key === 'sources'
                    );
                return fitnessSources
                    ? (fitnessSources.value as string[]).includes(
                          'com.garmin.apis'
                      )
                    : false;
            }
            return false;
        }, [activityDetailsRequest.$]);

        return (
            <>
                {activityDetailsRequest.match({
                    loading: () => (
                        <Stack direction="row" justifyContent="center">
                            <CircularProgress disableShrink />
                        </Stack>
                    ),
                    fetched: (fitnessActivityDetails) => {
                        return (
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Label>createdDate</Label>
                                    <Typography>
                                        {fitnessActivityDetails.createdDate}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Label>modifiedDate</Label>
                                    <Typography>
                                        {fitnessActivityDetails.modifiedDate}
                                    </Typography>
                                </Grid>
                                {fitnessActivityDetails.rejectionReason !==
                                    null && (
                                    <Grid item xs={4}>
                                        <Label>rejectionReason</Label>
                                        <Typography>
                                            {
                                                fitnessActivityDetails.rejectionReason
                                            }
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={4}>
                                    <Label>activityPoints</Label>
                                    <Typography>
                                        {fitnessActivityDetails.activityPoints}
                                    </Typography>
                                </Grid>

                                {fitnessActivityDetails.additionalProperties.map(
                                    (property) => (
                                        <Grid item xs={4}>
                                            <Label>{property.key}</Label>
                                            <Typography>
                                                {JSON.stringify(property.value)}
                                            </Typography>
                                        </Grid>
                                    )
                                )}
                                {showBackfillButton && (
                                    <Grid item xs={12}>
                                        <Button
                                            onClick={async () => {
                                                await backfillRequest(
                                                    fitnessActivityId
                                                );
                                                activityDetailsRequest.request([
                                                    fitnessActivityId
                                                ]);
                                            }}
                                        >
                                            Backfill
                                        </Button>
                                    </Grid>
                                )}
                                {fitnessActivityDetails.backfill?.backfillDateTime && (
                                    <Grid item xs={12}>
                                        <Label>Last BackFill Date</Label>
                                        <Typography>
                                            {formatDateTime(
                                                fitnessActivityDetails.backfill
                                                    .backfillDateTime
                                            )}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        );
                    }
                })}
            </>
        );
    }
);
