import { FieldState, FormState } from 'formstate';
import { useWrapRequest } from 'use-wrap-request';

import {
    notNegative,
    positive,
    required,
    useModel,
    wrapInFormField
} from '../../../core/forms';
import { WorkoutRuleDto } from '../../../core/api/TenantClient';
import { injectTSDI } from '../../../core/tsdi';
import { Api } from '../../../core/api';

export function useStore({
    minDurationMinutes,
    minAverageSpeed,
    maxAverageSpeed,
    maxAllowedSpeed,
    checkInConfirmationFrequencyMinutes,
    autoCheckOutThresholdDistance,
    checkInCountFrequencyChangeThreshold,
    minFacilityZoneCheckOutTimeoutMinutes, // <-
    timedOutCheckOutMinDurationMinutes,
    autoCheckOutTimeoutMinutes,
    minStudioVisitDurationMinutes,
    noRecentCheckInConfirmationNotificationTimeoutMinutes
}: WorkoutRuleDto) {
    const { tenantClient } = injectTSDI(Api);

    const workoutRuleRequest = useWrapRequest(
        async (data: WorkoutRuleDto) => {
            const result = await tenantClient.admin.workoutRuleAdminControllerUpdateWorkoutRules(
                data
            );
            return result;
        },
        { successMessage: () => 'Workout Rule updated' }
    );

    const model = useModel(() => {
        const form = new FormState({
            minDurationMinutes: new FieldState<number>(
                minDurationMinutes
            ).validators(required(), notNegative()),
            minAverageSpeed: new FieldState<number>(minAverageSpeed).validators(
                required(),
                notNegative()
            ),
            maxAverageSpeed: new FieldState<number>(maxAverageSpeed).validators(
                required(),
                positive()
            ),
            maxAllowedSpeed: new FieldState<number>(maxAllowedSpeed).validators(
                required(),
                positive()
            ),
            checkInConfirmationFrequencyMinutes: new FieldState<number>(
                checkInConfirmationFrequencyMinutes
            ).validators(required(), positive()),
            autoCheckOutThresholdDistance: new FieldState<number>(
                autoCheckOutThresholdDistance
            ).validators(required(), positive()),
            checkInCountFrequencyChangeThreshold: new FieldState<number>(
                checkInCountFrequencyChangeThreshold
            ).validators(required(), positive()),
            minFacilityZoneCheckOutTimeoutMinutes: new FieldState<number>(
                minFacilityZoneCheckOutTimeoutMinutes
            ).validators(required(), positive()),
            timedOutCheckOutMinDurationMinutes: new FieldState<number>(
                timedOutCheckOutMinDurationMinutes
            ).validators(required(), positive()),
            autoCheckOutTimeoutMinutes: new FieldState<number>(
                autoCheckOutTimeoutMinutes
            ).validators(required(), positive()),
            minStudioVisitDurationMinutes: new FieldState<number>(
                minStudioVisitDurationMinutes
            ).validators(required(), positive()),
            noRecentCheckInConfirmationNotificationTimeoutMinutes: new FieldState<number>(
                noRecentCheckInConfirmationNotificationTimeoutMinutes
            ).validators(required(), positive())
        });
        return {
            form,
            fields: {
                minDurationMinutes: wrapInFormField(form.$.minDurationMinutes),
                minAverageSpeed: wrapInFormField(form.$.minAverageSpeed),
                maxAverageSpeed: wrapInFormField(form.$.maxAverageSpeed),
                maxAllowedSpeed: wrapInFormField(form.$.maxAllowedSpeed),
                checkInConfirmationFrequencyMinutes: wrapInFormField(
                    form.$.checkInConfirmationFrequencyMinutes
                ),
                autoCheckOutThresholdDistance: wrapInFormField(
                    form.$.autoCheckOutThresholdDistance
                ),
                checkInCountFrequencyChangeThreshold: wrapInFormField(
                    form.$.checkInCountFrequencyChangeThreshold
                ),
                minFacilityZoneCheckOutTimeoutMinutes: wrapInFormField(
                    form.$.minFacilityZoneCheckOutTimeoutMinutes
                ),
                timedOutCheckOutMinDurationMinutes: wrapInFormField(
                    form.$.timedOutCheckOutMinDurationMinutes
                ),
                autoCheckOutTimeoutMinutes: wrapInFormField(
                    form.$.autoCheckOutTimeoutMinutes
                ),
                minStudioVisitDurationMinutes: wrapInFormField(
                    form.$.minStudioVisitDurationMinutes
                ),
                noRecentCheckInConfirmationNotificationTimeoutMinutes: wrapInFormField(
                    form.$.noRecentCheckInConfirmationNotificationTimeoutMinutes
                )
            }
        };
    });

    const onSubmitHandler = async () => {
        const validation = await model.form.validate();
        const {
            minDurationMinutes,
            minAverageSpeed,
            maxAverageSpeed,
            maxAllowedSpeed,
            checkInConfirmationFrequencyMinutes,
            autoCheckOutThresholdDistance,
            checkInCountFrequencyChangeThreshold,
            minFacilityZoneCheckOutTimeoutMinutes,
            timedOutCheckOutMinDurationMinutes,
            autoCheckOutTimeoutMinutes,
            minStudioVisitDurationMinutes,
            noRecentCheckInConfirmationNotificationTimeoutMinutes
        } = model.fields;

        if (validation.hasError) {
            throw new Error(model.form.error as string);
        }
        try {
            await workoutRuleRequest.request([
                {
                    minDurationMinutes: minDurationMinutes.value,
                    minAverageSpeed: minAverageSpeed.value,
                    maxAverageSpeed: maxAverageSpeed.value,
                    maxAllowedSpeed: maxAllowedSpeed.value,
                    checkInConfirmationFrequencyMinutes:
                        checkInConfirmationFrequencyMinutes.value,
                    autoCheckOutThresholdDistance:
                        autoCheckOutThresholdDistance.value,
                    checkInCountFrequencyChangeThreshold:
                        checkInCountFrequencyChangeThreshold.value,
                    minFacilityZoneCheckOutTimeoutMinutes:
                        minFacilityZoneCheckOutTimeoutMinutes.value,
                    timedOutCheckOutMinDurationMinutes:
                        timedOutCheckOutMinDurationMinutes.value,
                    autoCheckOutTimeoutMinutes:
                        autoCheckOutTimeoutMinutes.value,
                    minStudioVisitDurationMinutes:
                        minStudioVisitDurationMinutes.value,
                    noRecentCheckInConfirmationNotificationTimeoutMinutes:
                        noRecentCheckInConfirmationNotificationTimeoutMinutes.value
                }
            ]);
        } catch (err) {
            alert(err);
        }
    };

    return {
        fields: model.fields,
        errors: model.form.error,
        onSubmitHandler,
        workoutRuleRequest
    };
}
