import React from 'react';
import { blue, green } from '@mui/material/colors';
import { ControlPosition, useControl } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw/';
import { Feature, FeatureCollection, Polygon } from 'geojson';
import { FacilityAreaDto } from '../../../core/api/StudioClient';
import { ExtendDrawBar } from './ExtendedDrawBar';
import { convertFacilityAreaGeometryToFeatureCollection } from './utils';
import { MapContextValue } from 'react-map-gl/dist/esm/components/map';

const styles: object[] | undefined = [
    {
        id: 'gl-draw-polygon-fill-inactive',
        type: 'fill',
        filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static']
        ],
        paint: {
            'fill-color': '#3bb2d0',
            'fill-outline-color': '#3bb2d0',
            'fill-opacity': 0.1
        }
    },
    {
        id: 'gl-draw-polygon-fill-active',
        type: 'fill',
        filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
        paint: {
            'fill-color': '#fbb03b',
            'fill-outline-color': '#fbb03b',
            'fill-opacity': 0.1
        }
    },
    {
        id: 'gl-draw-polygon-midpoint',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
        paint: {
            'circle-radius': 3,
            'circle-color': '#fbb03b'
        }
    },
    {
        id: 'gl-draw-polygon-stroke-inactive',
        type: 'line',
        filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static']
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#3bb2d0',
            'line-width': 2
        }
    },
    {
        id: 'gl-draw-polygon-stroke-active',
        type: 'line',
        filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#fbb03b',
            'line-dasharray': [0.2, 2],
            'line-width': 2
        }
    },
    {
        id: 'gl-draw-line-inactive',
        type: 'line',
        filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'LineString'],
            ['!=', 'mode', 'static']
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#3bb2d0',
            'line-width': 2
        }
    },
    {
        id: 'gl-draw-line-active',
        type: 'line',
        filter: [
            'all',
            ['==', '$type', 'LineString'],
            ['==', 'active', 'true']
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#fbb03b',
            'line-dasharray': [0.2, 2],
            'line-width': 2
        }
    },
    {
        id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
        ],
        paint: {
            'circle-radius': 5,
            'circle-color': '#fff'
        }
    },
    {
        id: 'gl-draw-polygon-and-line-vertex-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
        ],
        paint: {
            'circle-radius': 3,
            'circle-color': '#fbb03b'
        }
    },
    {
        id: 'gl-draw-point-point-stroke-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static']
        ],
        paint: {
            'circle-radius': 5,
            'circle-opacity': 1,
            'circle-color': '#fff'
        }
    },
    {
        id: 'gl-draw-point-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static']
        ],
        paint: {
            'circle-radius': 3,
            'circle-color': '#3bb2d0'
        }
    },
    {
        id: 'gl-draw-point-stroke-active',
        type: 'circle',
        filter: [
            'all',
            ['==', '$type', 'Point'],
            ['==', 'active', 'true'],
            ['!=', 'meta', 'midpoint']
        ],
        paint: {
            'circle-radius': 7,
            'circle-color': '#fff'
        }
    },
    {
        id: 'gl-draw-point-active',
        type: 'circle',
        filter: [
            'all',
            ['==', '$type', 'Point'],
            ['!=', 'meta', 'midpoint'],
            ['==', 'active', 'true']
        ],
        paint: {
            'circle-radius': 5,
            'circle-color': '#fbb03b'
        }
    },
    {
        id: 'gl-draw-polygon-fill-static',
        type: 'fill',
        filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
        paint: {
            'fill-color': '#404040',
            'fill-outline-color': '#404040',
            'fill-opacity': 0.1
        }
    },
    {
        id: 'gl-draw-polygon-stroke-static',
        type: 'line',
        filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#404040',
            'line-width': 2
        }
    },
    {
        id: 'gl-draw-line-static',
        type: 'line',
        filter: [
            'all',
            ['==', 'mode', 'static'],
            ['==', '$type', 'LineString']
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round'
        },
        paint: {
            'line-color': '#404040',
            'line-width': 2
        }
    },
    {
        id: 'gl-draw-point-static',
        type: 'circle',
        filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
        paint: {
            'circle-radius': 5,
            'circle-color': '#404040'
        }
    },

    {
        id: 'gl-draw-polygon-color-picker',
        type: 'fill',
        filter: ['all', ['==', '$type', 'Polygon'], ['has', 'user_portColor']],
        paint: {
            'fill-color': ['get', 'user_portColor'],
            'fill-outline-color': ['get', 'user_portColor'],
            'fill-opacity': 0.5
        }
    },
    {
        id: 'gl-draw-line-color-picker',
        type: 'line',
        filter: [
            'all',
            ['==', '$type', 'LineString'],
            ['has', 'user_portColor']
        ],
        paint: {
            'line-color': ['get', 'user_portColor'],
            'line-width': 2
        }
    },
    {
        id: 'gl-draw-point-color-picker',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['has', 'user_portColor']],
        paint: {
            'circle-radius': 3,
            'circle-color': ['get', 'user_portColor']
        }
    }
];
export interface MapBoxButton {
    on: string;
    classes: string[];
    title: string;
    elButton?: HTMLButtonElement;
    action(): void;
}

export type DrawingMode = 'visit-area' | 'checkin-area' | 'static';
interface FeatureAreaProperties {
    mode: DrawingMode;
    portColor: string;
}
export type FeatureArea = Feature<Polygon, FeatureAreaProperties>;
export type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
    position?: ControlPosition;
    disableDrag?: boolean;
    onCreate: (feature: FeatureArea, mode: DrawingMode) => void;
    onUpdate: (feature: FeatureArea) => void;
    onDelete: (feature: FeatureArea) => void;
    onInitialLoad?: (feature: FeatureArea[]) => void;
    onToolSelect?: (mode: DrawingMode) => void;
    area?: FacilityAreaDto;
};

export const DrawControl = ({
    area,
    disableDrag,
    onInitialLoad,
    onToolSelect,
    ...props
}: DrawControlProps) => {
    const modeRef = React.useRef<{ mode: DrawingMode }>({ mode: 'static' });

    const { onCreate, onUpdate, onDelete } = props;
    const draw = React.useRef(
        new MapboxDraw({
            ...props,
            userProperties: true,
            styles
        })
    );

    const drawBar = new ExtendDrawBar({
        ...props,
        draw: draw.current,
        buttons: [
            {
                on: 'click',
                action: () => {
                    if (modeRef.current.mode === 'checkin-area') {
                        draw.current.changeMode('simple_select');
                        modeRef.current.mode = 'static';
                    } else {
                        modeRef.current.mode = 'checkin-area';
                        draw.current.changeMode('draw_polygon');
                    }
                    onToolSelect?.(modeRef.current.mode);
                },
                title: 'add checking area',
                classes: ['mapbox-gl-draw_point', 'checkin-area-button']
            },
            {
                on: 'click',
                action: () => {
                    if (modeRef.current.mode === 'visit-area') {
                        draw.current.changeMode('simple_select');
                        modeRef.current.mode = 'static';
                    } else {
                        modeRef.current.mode = 'visit-area';
                        draw.current.changeMode('draw_polygon');
                    }
                    onToolSelect?.(modeRef.current.mode);
                },
                title: 'add visit area',
                classes: ['mapbox-gl-draw_polygon', 'visit-area-button']
            }
        ]
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useControl<any>(
        ({ map }: MapContextValue) => {
            map.on('draw.create', (e: FeatureCollection<Polygon>) => {
                const feature = e.features[0];
                if (feature.properties && feature.id) {
                    const color =
                        modeRef.current.mode === 'visit-area'
                            ? blue['400']
                            : green['400'];
                    feature.properties.mode = modeRef.current.mode;
                    feature.properties.portColor = color;
                    draw.current.setFeatureProperty(
                        `${feature.id}`,
                        'portColor',
                        color
                    );
                }

                onCreate(feature as FeatureArea, modeRef.current.mode);
            });
            map.on('draw.update', (e: FeatureCollection<Polygon>) => {
                const feature = e.features[0];
                onUpdate(feature as FeatureArea);
            });
            map.on('draw.delete', (e: FeatureCollection<Polygon>) => {
                onDelete(e.features[0] as FeatureArea);
            });

            return drawBar;
        },
        ({ map }: MapContextValue) => {
            map.off('draw.create', (e) => {
                onCreate(e, modeRef.current.mode);
            });
            map.off('draw.update', (e) => {
                onUpdate(e);
            });
            map.off('draw.delete', (e) => {
                onDelete(e);
            });
        },
        {
            position: props.position
        }
    );
    React.useEffect(() => {
        if (disableDrag) {
            drawBar.disableDrag();
        }
    }, [disableDrag]);

    React.useEffect(() => {
        if (drawBar && area) {
            const visitArea = area.visitArea?.polygons.length
                ? convertFacilityAreaGeometryToFeatureCollection(
                      area.visitArea,
                      'visit-area'
                  )
                : undefined;

            const checkInArea = area.checkInArea?.polygons.length
                ? convertFacilityAreaGeometryToFeatureCollection(
                      area.checkInArea,
                      'checkin-area'
                  )
                : undefined;

            if (visitArea || checkInArea) {
                const allAreas: FeatureCollection<Polygon> = {
                    type: 'FeatureCollection',
                    features: [...(visitArea || []), ...(checkInArea || [])]
                };

                drawBar.drawAreas(allAreas);
                const currentFeatures = drawBar.draw.getAll().features;
                onInitialLoad?.(currentFeatures as FeatureArea[]);
            }
        }
    }, [area]);

    return null;
};
