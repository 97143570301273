import { FieldState, FormState } from 'formstate';
import React from 'react';
import { useParams } from 'react-router';
import { useWrapRequest } from 'use-wrap-request';
import { Api } from '../../core/api';
import { required, useModel, wrapInFormField } from '../../core/forms';
import { injectTSDI } from '../../core/tsdi';
import { TenantFeaturesContext } from '../index';

export function useTeamsStore() {
    const { tenantClient } = injectTSDI(Api);
    const tenantFeatures = React.useContext(TenantFeaturesContext);
    const [currentLicenseCount, setCurrentLicenseCount] = React.useState<
        number | undefined
    >(undefined);
    const [currentFlexEnabled, setCurrentFlexEnabled] =
        React.useState<boolean>(false);
    const { tenantName } = useParams();
    const tenantContract = useWrapRequest(async (tenantName: string) => {
        const request =
            await tenantClient.admin.tenantAdminV2ControllerGetTeamsTenantContract(
                tenantName
            );
        const contract = request.data.content?.[0];
        setCurrentLicenseCount(contract.allowedLicenses);
        setCurrentFlexEnabled(
            contract.allowedLicenses === undefined ||
                contract.allowedLicenses === null
        );
        return contract;
    });

    const tenantUpdateHandler = useWrapRequest(
        async (tenantName: string, licensesCount: number) => {
            const contract =
                await tenantClient.admin.tenantAdminV2ControllerUpdateTeamsTenantContract(
                    tenantName,
                    { licensesCount }
                );
            return contract;
        },
        { successMessage: () => 'License was updated' }
    );

    React.useEffect(() => {
        if (tenantFeatures.$?.tenant.productType === 'TEAMS') {
            tenantContract.request([tenantName]);
        }
    }, [tenantName, tenantFeatures.$, tenantContract]);

    const licenseModel = useModel(() => {
        const form = new FormState({
            flexLicense: new FieldState<boolean>(false),
            licensesCount: new FieldState<number>(0).validators(required())
        });
        const licensesCount = wrapInFormField(form.$.licensesCount);
        const flexLicense = wrapInFormField(form.$.flexLicense);

        return {
            form,
            fields: {
                flexLicense,
                licensesCount
            }
        };
    });

    React.useEffect(() => {
        if (tenantContract.$?.allowedLicenses) {
            licenseModel.form.$.licensesCount.reset(
                tenantContract.$?.allowedLicenses
            );
        }
        licenseModel.form.$.flexLicense.reset(
            tenantContract.$?.allowedLicenses === undefined || tenantContract.$?.allowedLicenses === null
        );
    }, [tenantContract.$, licenseModel]);

    const licenseSubmitHandler = async () => {
        const {
            fields: { licensesCount, flexLicense }
        } = licenseModel;

        const valid = await licenseModel.form.validate();
        if (!valid.hasError) {
            const tenantUpdate = await tenantUpdateHandler.request([
                tenantName,
                flexLicense.value ? undefined : licensesCount.value
            ]);
            setCurrentLicenseCount(licensesCount.value);

            return tenantUpdate;
        }

        return false;
    };

    return {
        tenantContract,
        licenseModel,
        tenantUpdateHandler,
        currentLicenseCount,
        currentFlexEnabled,
        licenseSubmitHandler
    };
}
