import React from 'react';

interface Tab {
    label: string;
    id: string;
}

export function useTabs(tabs: Tab[]) {
    const [tab, setTab] = React.useState(0);

    function getPageLink(page: number) {
        return tabs[page].id;
    }

    const setPageTab = (path: string, level: number) => {
        const currentLevel = path.split('/')[level];

        if (currentLevel) {
            const tabIndex = tabs.findIndex((tab) => currentLevel === tab.id);
            if (tabIndex > -1) {
                setTab(tabIndex);
            }
        }
    };

    return { tab, setTab, setPageTab, getPageLink };
}
