import { Box, Grid } from '@mui/material';
import React from 'react';
import { TextField } from '../../../../../components/textfield';
import { Button } from '../../../../../components/button';
import { useStore } from './store';
import { injectTSDI } from '../../../../../core/tsdi';
import { I18n } from '../../../../../core/i18n';
import { observer } from 'mobx-react';

export const AddFacilityVisit = observer(() => {
    const {
        fields,
        errors,
        onSubmitHandler,
        addFacilityVisitRequest
    } = useStore();
    const { __ } = injectTSDI(I18n);

    const {
        from,
        to,
        facilityId,
        facilityName
    } = fields;


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    value={from.value}
                    onChange={from.onChange}
                    type='text'
                    label='Start (full date time with timezone)'
                    required
                    error={
                        from.error &&
                        __(
                            from.error as I18nKey,
                            0
                        )
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={to.value}
                    onChange={to.onChange}
                    type='text'
                    label='End (full date time with timezone)'
                    required
                    error={
                        from.error &&
                        __(
                            from.error as I18nKey,
                            0
                        )
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={facilityId.value}
                    onChange={facilityId.onChange}
                    type='text'
                    label='Facility id (can be taken from Facilities tab)'
                    required
                    error={
                        facilityId.error &&
                        __(
                            facilityId.error as I18nKey,
                            0
                        )
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={facilityName.value}
                    onChange={facilityName.onChange}
                    type='text'
                    label='Facility name (can be taken from Facilities tab)'
                    required
                    error={
                        facilityName.error &&
                        __(
                            facilityName.error as I18nKey,
                            0
                        )
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Box display='flex' justifyContent='center'>
                    <Button
                        onClick={onSubmitHandler}
                        disabled={
                            Boolean(errors) || addFacilityVisitRequest.loading
                        }
                    >
                        Save
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
});
