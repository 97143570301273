import { Typography } from '@mui/material';
import React from 'react';

interface LabelProps {
    children: React.ReactNode;
}

export function Label(props: LabelProps) {

    if (!props.children) {
        return null;
    }

    return (
        <Typography variant='body2' color='GrayText' fontSize='10px'>
            {props.children}
        </Typography>
    );
}
