import React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { injectTSDI } from '../../../core/tsdi';
import { Api } from '../../../core/api';
import { FacilityEditStore } from '../store';
import { useWrapRequest } from '../../../components/hooks/use-wrap-request';
import { Map } from './map';

const FacilityLocation = observer(() => {
    const { studioClient } = injectTSDI(Api);
    const { facility } = injectTSDI(FacilityEditStore);
    const { facilityId } = useParams();
    const facilityArea = useWrapRequest(
        async (facilityId) => {
            const result = await studioClient.v1.facilityControllerGetFacilityArea(
                Number(facilityId)
            );
            return result.data;
        },
        { deps: [facilityId] }
    );

    const externalData = facility.$?.externalData?.[0]?.data.address;

    return (
        <>
            {facilityArea.match({
                fetched: (area) => (
                    <Map
                        area={area}
                        externalLatitude={externalData?.latitude}
                        externalLongitude={externalData?.longitude}
                    />
                )
            })}
        </>
    );
});

export default FacilityLocation;
