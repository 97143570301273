import React from 'react';
import { createRoot } from 'react-dom/client';
import { TSDI } from 'tsdi';
import { App } from './app';
import { Api } from './core/api';
import { injectTSDI } from './core/tsdi';
import 'mobx-wrap-request';
import { InitialData } from './core/initial-data';
import { TsdiLifeCycle } from './core/reactions';
import './fonts.css';

const tsdi = new TSDI();
tsdi.enableComponentScanner();
tsdi.get(TsdiLifeCycle);
injectTSDI(Api);
injectTSDI(InitialData);
const domNode = document.getElementById('app');

if (domNode) {
    const root = createRoot(domNode);
    root.render(<App />);
} else {
    console.error('Element with ID "root" not found');
}
