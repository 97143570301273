import { component } from 'tsdi';
import { wrapRequest } from 'wrap-request';
import { Api } from '../../core/api';
import { injectTSDI } from '../../core/tsdi';

@component
export class FacilityEditStore {
    private api = injectTSDI(Api);

    public facility = wrapRequest(async (facilityId) => {
        const result = await this.api.studioClient.v1.customFacilityControllerGetDetails(
            Number(facilityId)
        );
        return result.data;
    });
}
