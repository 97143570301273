import { UserSearchSupportDto } from '@api/MobileClient';
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { SortingRule } from 'react-table';
import { Button } from '../components/button';
import { PageTitle } from '../components/page-title';
import { Paper } from '../components/paper';
import { Table } from '../components/table';
import { links } from '../core/router';
import { useStore } from './store';

export const Users = () => {
    const { users, sorting, setSorting, setSearchTerm, dispatch } = useStore();
    const navigate = useNavigate();

    const onSortingChange = (sorting?: SortingRule<object>) => {
        if (sorting) {
            setSorting(sorting);
        }
    };

    return (
        <>
            <PageTitle>Users</PageTitle>

            <Paper
                noPadding
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    width: '100%',
                    maxWidth: '900px'
                }}
            >
                <Table<UserSearchSupportDto[]>
                    items={users}
                    tableLayout="auto"
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            style: { width: 20 }
                        },

                        {
                            Header: 'First name',
                            accessor: 'firstName',
                            id: 'firstname',
                            style: { width: 100 }
                        },
                        {
                            Header: 'Last Name',
                            accessor: 'lastName',
                            id: 'lastname',
                            style: { width: 100 }
                        },
                        {
                            Header: 'Emails',
                            accessor: (row: UserSearchSupportDto) =>
                                row.emails.map((email) => (
                                    <Box>
                                        {email.value}
                                        {row.emails.length > 1 &&
                                            email.main && (
                                                <strong>
                                                    {' '}
                                                    (primary email)
                                                </strong>
                                            )}
                                    </Box>
                                )),

                            id: 'emails',
                            disableSortBy: true
                        },
                        {
                            Header: '',
                            id: 'actions',
                            style: { width: 100 },
                            accessor: (user) => (
                                <Button
                                    variant="text"
                                    onClick={() =>
                                        void navigate(
                                            links.userDetails(
                                                user.id.toString()
                                            )
                                        )
                                    }
                                >
                                    Details
                                </Button>
                            ),

                            disableSortBy: true
                        }
                    ]}
                    onPageSizeChange={(size) =>
                        dispatch({ type: 'size', size })
                    }
                    onChangePage={(page) =>
                        void dispatch({ type: 'page', page })
                    }
                    onSearchChange={(val) => void setSearchTerm(val)}
                    onSortingChange={onSortingChange}
                    sorting={Array.isArray(sorting) ? sorting[0] : sorting}
                />
            </Paper>
        </>
    );
};

export default Users;
