import React from 'react';
import styled, { css } from 'styled-components';
import { Input as MaterialInput, InputProps as MaterialInputProps } from '@mui/material';

interface InputProps extends MaterialInputProps {
    edit?: 'true';
}

const StyledInput = styled(MaterialInput)<{ edit?: 'true' }>`
    width: 100%;
    ${({ edit }) =>
        edit &&
        css`
            &:before {
                display: none;
            }
        `}
    &.Mui-disabled {
        &:before,
        &:after {
            display: none;
        }
    }
    input {
        color: #fff;
    }
`;

export const Input = ({ edit, ...props }: InputProps) => (
    <StyledInput {...props} disabled={!edit || props.disabled} edit={edit} />
);
