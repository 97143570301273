import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ContextMenuOption<T = any> {
    label: string | ((value: T) => string);
    value?: T;
    name?: string;
    divider?: boolean;
    hidden?(value: T): boolean;
    action(value: T): void;
}

interface ContextMenuProps {
    children?: React.ReactNode;
    items: ContextMenuOption[];
}

export function ContextMenu(props: ContextMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const items = props.items.filter((item) =>
        item.hidden ? !item.hidden(item.value) : true
    );

    if (items.length === 0) {
        return null;
    }

    return (
        <>
            {props.children ? (
                <div onClick={onClick}>{props.children}</div>
            ) : (
                <IconButton onClick={onClick}>
                    <MoreVertIcon />
                </IconButton>
            )}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onClose}
            >
                {items.map((item, i) => {
                    const lastItem = i === items.length - 1;
                    return (
                        <MenuItem
                            key={String(i)}
                            sx={{
                                borderBottom: !lastItem
                                    ? '1px solid rgba(81, 81, 81, 1)'
                                    : 'none'
                            }}
                            onClick={() => {
                                item.action(item.value);
                                onClose();
                            }}
                        >
                            {typeof item.label === 'string'
                                ? item.label
                                : item.label(item.value)}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}
