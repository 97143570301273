import React from 'react';
import { Chip, ChipTypeMap } from '@mui/material';
import { blue, green, grey, red } from '@mui/material/colors';

export type BadgeType = 'green' | 'red' | 'grey' | 'blue';

interface BadgeProps {
    type?: BadgeType;
    size?: ChipTypeMap['props']['size'];
    children?: string;
}
function getClasses(type?: BadgeType) {
    switch (type) {
        case 'green':
            return {
                background: green[500],
                color: '#fff'
            };
        case 'red':
            return {
                background: red[500],
                color: '#fff'
            };
        case 'grey':
            return {
                background: grey[500]
            };
        case 'blue':
            return {
                background: blue[500]
            };
    }
    return {};
}

export function Badge(props: BadgeProps) {
    if (!props.children) {
        return null;
    }

    return (
        <Chip
            sx={getClasses(props.type)}
            size={props.size}
            label={props.children.toUpperCase()}
        />
    );
}
