import { Alert, Box, Typography } from '@mui/material';
import React from 'react';
import { AccountBalanceIcon } from '.';
import { ConfirmationModal } from '../../../components/confirmation-modal/index';
import { Api } from '../../../core/api';
import { AccountOperationDto } from '../../../core/api/WalletClient';
import { injectTSDI } from '../../../core/tsdi';

type Props = {
    onSuccess: () => void;
    operation?: AccountOperationDto;
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

export const OperationDeleteModal = ({
    onSuccess,
    operation,
    openState: [open, setOpen]
}: Props) => {
    const { walletClient } = injectTSDI(Api);

    const handleDelete = async () => {
        if (operation) {
            await walletClient.supportApi.walletSupportControllerDeleteAccountOperation(
                operation.id
            );
        }
        onSuccess();
    };
    return (
        <ConfirmationModal
            open={open}
            submitHandler={handleDelete}
            onModalClose={() => void setOpen(false)}
            buttonText="Revert"
        >
            {operation && (
                <Box>
                    <Box display="flex">
                        <AccountBalanceIcon
                            balanceKey={operation.moneyStatus}
                        />
                        {operation.moneyStatus}
                    </Box>
                    <Typography variant="h6">
                        Value: <b>{operation.value.value}</b>
                    </Typography>
                    <Alert severity="warning">
                        Are you sure you want to reject this operation?
                    </Alert>
                </Box>
            )}
        </ConfirmationModal>
    );
};
