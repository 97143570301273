import React, { useState } from 'react';
import { ConfirmationModal } from '../../components/confirmation-modal';
import { injectTSDI } from '../../core/tsdi';
import { Api } from '../../core/api';
import { Alert, TextField, Typography } from '@mui/material';
import { CommunityAccessLevel } from '@api/ChallengeClient';

type Props = {
    onSuccess: () => void;
    tenantName: string;
    communityName: string;
    logoBucket: string | undefined;
    logoKey: string | undefined;
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

export const CommunityCreateModal = ({
    onSuccess,
    tenantName,
    communityName,
    logoBucket,
    logoKey,
    openState: [open, setOpen]
}: Props) => {
    const { challengeClient } = injectTSDI(Api);
    const [managerUserIds, setManagerUserIds] = useState<string>('');

    const handle = async () => {
        if (tenantName) {
            await challengeClient.support.communitySupportControllerCreate({
                name: communityName,
                tenantId: tenantName,
                photo: {
                    bucketName: logoBucket || 'msr-global-public-assets-prod',
                    objectKey: logoKey || 'community-images/default_community.png'
                },
                managerUserIds: managerUserIds
                    .split(',')
                    .map((id) => id.trim()),
                memberInvitation: CommunityAccessLevel.NONE,
                eventCreation: CommunityAccessLevel.NONE
            });
            onSuccess();
        }
    };

    return (
        <ConfirmationModal
            open={open}
            submitHandler={handle}
            onModalClose={() => void setOpen(false)}
            buttonText="Create"
        >
            <Typography variant="h6">
                You are about to create community for tenant <b>{tenantName}</b>
            </Typography>
            <Alert severity="warning">
                Are you sure you want to create community for this tenant?
            </Alert>
            <TextField
                label="Manager User IDs"
                value={managerUserIds}
                onChange={(e) => setManagerUserIds(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                helperText="Enter comma-separated user IDs"
            />
        </ConfirmationModal>
    );
};
