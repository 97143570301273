import { debounce } from 'lodash';
import React from 'react';
import { SortingRule } from 'react-table';
import { userSearchDebounce } from '../components/hooks/use-search';
import { useWrapRequest } from '../components/hooks/use-wrap-request';
import { useSearchReducer } from '../components/hooks/useSearchReducer';
import { Api } from '../core/api';

import { injectTSDI } from '../core/tsdi';

export function useStore() {
    const { mobileClient } = injectTSDI(Api);

    const [sorting, setSorting] = React.useState<
        SortingRule<string> | SortingRule<string>[] | undefined
    >(undefined);

    React.useEffect(() => {
        if (sorting) {
            return dispatch({ type: 'sort', sort: sorting });
        }
    }, [sorting]);

    const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
        size: 30,
        page: 0
    });
    const { setSearchTerm } = userSearchDebounce(dispatch);

    const users = useWrapRequest(
        async (searchParams?: QueryParamsDto) => {
            const result =
                await mobileClient.supportApi.userSearchSupportControllerSearchUsers(
                    {
                        ...searchParams
                    }
                );

            return { ...result.data, content: result.data.content || [] };
        },
        {
            defaultData: {
                content: [],
                number: 0,
                size: 0,
                totalPages: 0,
                totalElements: 0
            }
        }
    );

    const debouncedAPICall = React.useCallback(
        debounce((searchParams?: QueryParamsDto) => {
            users.request([searchParams]);
        }, 100),
        []
    );

    React.useEffect(() => {
        debouncedAPICall(searchParams);
    }, [searchParams, debouncedAPICall]);

    return {
        users,
        searchParams,
        sorting,
        setSorting,
        setSearchTerm,
        dispatch
    };
}
