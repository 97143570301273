import { observer } from 'mobx-react';
import { inject } from 'tsdi';

import React, { Component, ErrorInfo, ReactNode } from 'react';

import { I18n } from '../core/i18n';

interface Props {
    children?: ReactNode;
}
interface State {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hasError: boolean;
}

@observer

// eslint-disable-next-line @typescript-eslint/ban-types
export class ErrorBoundary extends Component<Props, State> {
    @inject
    private i18n!: I18n;

    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(this.i18n.__('commons.error.runtime'), error, errorInfo);
        return <div>{this.i18n.__('commons.error.runtime')}</div>;
    }
    public render() {
        if (this.state.hasError) {
            return <div>{this.i18n.__('commons.error.runtime')}</div>;
        }

        return this.props.children;
    }
}
