import { blue, green } from '@mui/material/colors';
import { Feature, Polygon, Position } from 'geojson';
import {
    FacilityAreaDto,
    FacilityAreaGeometryDto,
    GeoPointDto
} from '../../../core/api/StudioClient';
import { DrawingMode, FeatureArea } from './draw-controls';
import { AreaGeometryDto } from './store';

export function convertAreaToGeoPolygon(points: Position[]): GeoPointDto[] {
    return points.map(([longitude, latitude]) => ({
        longitude,
        latitude
    }));
}

interface FormDataDto {
    center: { latitude: number; longitude: number };
    visitArea?: AreaGeometryDto[];
    checkInArea?: AreaGeometryDto[];
}

export function convertFormDataToApi(formData: FormDataDto): FacilityAreaDto {
    return {
        center: {
            longitude: formData.center.longitude,
            latitude: formData.center.latitude
        },
        ...(formData.checkInArea && {
            checkInArea: convertAreaGeometryToFacilityAreaGeometry(
                formData.checkInArea
            )
        }),
        ...(formData.visitArea && {
            visitArea: convertAreaGeometryToFacilityAreaGeometry(
                formData.visitArea
            )
        })
    };
}

function convertAreaGeometryToFacilityAreaGeometry(
    areaGeometry: AreaGeometryDto[]
): FacilityAreaGeometryDto {
    return { polygons: areaGeometry.map((area) => ({ points: area.points })) };
}

export function convertFacilityAreaGeometryToFeatureCollection(
    areaGeometry: FacilityAreaGeometryDto,
    mode: DrawingMode
): Feature<Polygon>[] {
    return areaGeometry.polygons.map((area) => ({
        type: 'Feature',
        properties: {
            mode,
            portColor: mode === 'visit-area' ? blue['400'] : green['400']
        },
        geometry: {
            type: 'Polygon',
            coordinates: [
                area.points.map(({ longitude, latitude }) => [
                    longitude,
                    latitude
                ])
            ]
        }
    }));
}

export function convertFeatureAreaToAreaGeometryDto(feature: FeatureArea) {
    return {
        id: feature.id?.toString() || Date.now().toString(),
        points: convertAreaToGeoPolygon(feature.geometry.coordinates?.[0]) || []
    };
}
