import { Stack } from '@mui/material';
import { FieldState, FormState } from 'formstate';
import { observer } from 'mobx-react';
import React from 'react';
import { ConfirmationModal } from '../../../components/confirmation-modal/index';
import { TextField } from '../../../components/textfield';
import { assertDefined } from '../../../components/utils/error';
import { Api } from '../../../core/api';
import { required, useModel } from '../../../core/forms';
import { injectTSDI } from '../../../core/tsdi';

type Props = {
    onSuccess: () => void;
    selectedAccount?: number;
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

export const OperationCorrectionModal = observer(
    ({ onSuccess, selectedAccount, openState: [open, setOpen] }: Props) => {
        const { walletClient, googleUserAuth } = injectTSDI(Api);
        const model = useModel(() => {
            const form = new FormState({
                value: new FieldState<number | undefined>(undefined).validators(
                    required()
                ),
                comment: new FieldState<string | undefined>(
                    undefined
                ).validators(required()),
                tenantId: new FieldState<string>('').validators()
            });
            return {
                form,
                fields: {
                    value: form.$.value,
                    tenantId: form.$.tenantId,
                    comment: form.$.comment
                }
            };
        });
        type ModelType = typeof model;

        const handleCorrect = async (model: ModelType) => {
            const validate = await model.form.validate();
            if (!validate.hasError) {
                if (selectedAccount) {
                    assertDefined(model.fields.value.value);
                    assertDefined(model.fields.comment.value);

                    await walletClient.supportApi.walletSupportControllerCreateCorrection(
                        {
                            value: model.fields.value.value,
                            comment: model.fields.comment.value,
                            ...(model.fields.tenantId.value && {
                                tenantId: model.fields.tenantId.value
                            }),
                            accountId: selectedAccount,
                            createdBy: `${googleUserAuth?.email}`
                        }
                    );
                    model.form.reset();
                }
                onSuccess();
                return true;
            }
            return false;
        };
        return (
            <ConfirmationModal
                title="Add Correction Form"
                open={open}
                submitHandler={() => handleCorrect(model)}
                onModalClose={() => void setOpen(false)}
                buttonText="Add Correction"
            >
                <Stack spacing={2} minWidth={500}>
                    <TextField
                        label="Amount"
                        type="number"
                        required
                        error={
                            model.fields.value.hasError ? 'Required' : undefined
                        }
                        value={model.fields.value.value || ''}
                        onChange={(value: number) => {
                            model.fields.value.onChange(value);
                        }}
                    />

                    <TextField
                        label="Comment"
                        type="text"
                        multiline
                        maxRows={6}
                        required
                        error={
                            model.fields.comment.hasError
                                ? 'Required'
                                : undefined
                        }
                        value={model.fields.comment.value || ''}
                        onChange={(value: string) => {
                            model.fields.comment.onChange(value);
                        }}
                    />
                    <TextField
                        label="Tenant Id"
                        type="text"
                        value={model.fields.tenantId.value || ''}
                        onChange={(value: string) => {
                            model.fields.tenantId.onChange(value);
                        }}
                    />
                </Stack>
            </ConfirmationModal>
        );
    }
);
