import { AppBar, Box, Container, Stack, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { useTabs } from '../../components/hooks/use-tabs';
import { PageTitle } from '../../components/page-title';
import { Paper } from '../../components/paper';
import { Spinner } from '../../components/spinner';
import { injectTSDI } from '../../core/tsdi';
import { EmployeeData } from './employee-data';
import { FacilityEditStore } from './store';

const pageTabs = [
    { label: 'Details', id: 'details' },
    { label: 'Location', id: 'location' }
];

export const FacilityEdit = observer(() => {
    const { facility } = injectTSDI(FacilityEditStore);
    const { tab, getPageLink, setPageTab } = useTabs(pageTabs);
    const { pathname } = useLocation();
    const { facilityId } = useParams();

    const navigate = useNavigate();

    const changePage = (page: number) => {
        navigate(getPageLink(page));
    };

    React.useEffect(() => {
        setPageTab(pathname, 3);
    }, [pathname]);

    React.useEffect(() => {
        if (facilityId) {
            facility.request(facilityId);
        }
    }, [facilityId]);

    return (
        <Container maxWidth="xl" sx={{ padding: [0, 0, 0] }}>
            {facility.match({
                loading: () => (
                    <Box display="flex" justifyContent="center" minHeight={300}>
                        <Spinner />
                    </Box>
                ),
                fetched: (facility) => (
                    <>
                        <Stack
                            flexDirection="row"
                            justifyContent="space-between"
                        >
                            <PageTitle>
                                {facility.internalData.name} - edit
                            </PageTitle>
                            <EmployeeData facility={facility} />
                        </Stack>
                        <Paper noPadding>
                            <AppBar
                                position="static"
                                sx={{ minHeight: 'auto' }}
                            >
                                <Tabs
                                    value={tab}
                                    onChange={(__, page: number) =>
                                        void changePage(page)
                                    }
                                    textColor="inherit"
                                >
                                    {pageTabs.map((tab) => (
                                        <Tab
                                            key={tab.id}
                                            label={tab.label}
                                            id={tab.id}
                                        />
                                    ))}
                                </Tabs>
                            </AppBar>
                            <Box>
                                <Outlet />
                            </Box>
                        </Paper>
                    </>
                )
            })}
        </Container>
    );
});
