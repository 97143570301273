import { useSnackbar } from 'notistack';

interface ResponseObject {
    status: number;
    error: ValidationErrorResponse | ErrorResponse;
}

function isValidationErrorResponse(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any
): error is ValidationErrorResponse {
    return (error as ValidationErrorResponse).violations !== undefined;
}

function isErrorResponse(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any
): error is ErrorResponse {
    return (error as ErrorResponse).message !== undefined;
}

export function useError() {
    const { enqueueSnackbar } = useSnackbar();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function showError(error: any) {
        if ('status' in error && 'error' in error) {
            const { error: errorObject } = error as ResponseObject;
            if (isValidationErrorResponse(errorObject)) {
                return showSnackBar(errorObject.message?.defaultMessage);
            }

            if (isErrorResponse(errorObject)) {
                return showSnackBar(errorObject.message?.defaultMessage);
            }
            return showSnackBar();
        }
    }

    function showSnackBar(message?: string) {
        enqueueSnackbar(message || 'There was an error', {
            variant: 'error',
            autoHideDuration: 6000
        });
    }
    return showError;
}
