import React from 'react';
import { Box, Stack } from '@mui/material';
import { useWrapRequest } from 'use-wrap-request';
import { observer } from 'mobx-react';
import { CellProps } from 'react-table';
import { Api } from '../../core/api';
import { ProductState, ProductSupportDto } from '@api/WalletClient';
import { Badge, BadgeType } from '../../components/badge';
import { injectTSDI } from '../../core/tsdi';
import { Paper } from '../../components/paper';
import { Table } from '../../components/table';
import { useSearchReducer } from '../../components/hooks/useSearchReducer';
import { PageTitle } from '../../components/page-title';
import { Filters } from '../../components/filters';
import { useParams } from 'react-router';

function getStatusBadgeType(
    status: ProductState | undefined
): BadgeType {
    switch (status) {
        case ProductState.PRODUCTION:
            return 'green';
        case ProductState.TEST:
            return 'blue';
        case ProductState.DISABLED:
            return 'red';
        case undefined:
            return 'grey';
    }
}

type StateFilterType = 'ENABLED_FOR_USER' | 'POSSIBLE_TO_ENABLE' | 'ALL';

export const VendorProductList = observer(() => {
    const { walletClient } = injectTSDI(Api);
    const { vendorId } = useParams();

    const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
        size: 30,
        page: 0
    });

    const [statusFilter, setStatusFilter] =
        React.useState<StateFilterType>('ALL');

    const products = useWrapRequest(
        async (searchParams, stateFilter) => {
            const result = await walletClient.supportApi.vendorSupportControllerGetProductPage(
                Number(vendorId),
                {
                    ...searchParams,
                    ...(stateFilter === 'ENABLED_FOR_USER' && {
                        shopStates: [ProductState.PRODUCTION]
                    }),
                    ...(stateFilter === 'POSSIBLE_TO_ENABLE' && {
                        internalStates: [ProductState.DISABLED, ProductState.TEST],
                        internalStatesIncludeNull: true,
                        externalStates: ProductState.PRODUCTION
                    }),
                    sort: 'price.value'
                }
            );

            return { ...result.data, content: result.data.content || [] };
        },
        { defaultData: { content: [], number: 0, size: 10, totalElements: 0, totalPages: 0 } }
    );

    React.useEffect(() => {
        products.request([
            searchParams,
            statusFilter
        ]);
    }, [searchParams]);

    React.useEffect(() => {
        dispatch({ type: 'page', page: 0 });
    }, [statusFilter]);

    async function setState(item: ProductSupportDto, state: ProductState) {
        item.internalState = state;

        await walletClient.supportApi.vendorSupportControllerUpdateProductState(
            Number(vendorId),
            item.id,
            {
                state
            }
        );
        dispatch({ type: 'reload' });
    }

    return (
        <>
            <PageTitle>Available products</PageTitle>
            <Box display="flex" justifyContent="flex-start">
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    spacing={2}
                    pt={2}
                    pb={2}
                    width="100%"
                    maxWidth={400}
                >
                    <Filters<StateFilterType, false>
                        label="Status"
                        value={statusFilter}
                        width={180}
                        options={[
                            { label: 'all', value: 'ALL' },
                            { label: 'enabled for users', value: 'ENABLED_FOR_USER' },
                            { label: 'possible to enable', value: 'POSSIBLE_TO_ENABLE' }
                        ]}
                        onChange={(val) => void setStatusFilter(val)}
                    />
                </Stack>
            </Box>
            <Paper noPadding>
                <Table<ProductSupportDto[]>
                    emptyText="No entries"
                    items={products}
                    contextMenu={[
                        {
                            label: 'Disable',
                            hidden: (value) => value.internalState === ProductState.DISABLED,
                            action: (value) => setState(value, ProductState.DISABLED)
                        },
                        {
                            label: 'Enable for internal testing',
                            hidden: (value) => value.internalState === ProductState.TEST,
                            action: (value) => setState(value, ProductState.TEST)
                        },
                        {
                            label: 'Enable for production',
                            hidden: (value) => value.internalState === ProductState.PRODUCTION,
                            action: (value) => setState(value, ProductState.PRODUCTION)
                        }
                    ]}
                    columns={[
                        {
                            Header: 'Value',
                            accessor: (value) => `${value.price.value} ${value.price.currency.symbol}`
                        },
                        {
                            Header: 'Internal state',
                            Cell: ({
                                       row: {
                                           original: { internalState }
                                       }
                                   }: CellProps<ProductSupportDto>) => (
                                <Badge type={getStatusBadgeType(internalState)}>
                                    {internalState}
                                </Badge>
                            )
                        },
                        {
                            Header: 'External state',
                            Cell: ({
                                       row: {
                                           original: { externalState }
                                       }
                                   }: CellProps<ProductSupportDto>) => (
                                <Badge type={getStatusBadgeType(externalState)}>
                                    {externalState}
                                </Badge>
                            )
                        }
                    ]}
                    onPageSizeChange={(size) =>
                        dispatch({ type: 'size', size })
                    }
                    onChangePage={(page) =>
                        void dispatch({ type: 'page', page })
                    }
                />
            </Paper>
        </>
    );
});

export default VendorProductList;
