import React from 'react';
import { FieldState, FormState } from 'formstate';
import { useWrapRequest } from 'use-wrap-request';

import { Api as CorporateApi, CorporateFeatureDto, CorporateFeatureGroupDto } from '../../core/api/CorporateClient';

export interface SubFeature {
    name: FieldState<string>;
    id: FieldState<CorporateFeatureDto['feature']>;
    enabled: FieldState<boolean>;
}

export type SubFeatureType = FieldState<SubFeature>;

export interface Feature extends SubFeature {
    subFeatures: FormState<SubFeatureType[]>;
}

export type FeatureType = FieldState<Feature>;

type FeatureForm = FormState<
    FormState<{
        name: FieldState<string>;
        id: FieldState<CorporateFeatureGroupDto['group']>;
        features: FormState<FeatureType[]>;
    }>[]
>;

interface NestedCorporateFeatureDto extends CorporateFeatureDto {
    subFeatures: CorporateFeatureDto[];
}

export function useStore(corporateClient: CorporateApi<unknown>) {
    const form = React.useRef<FeatureForm>();

    const corporateFeatures = useWrapRequest(
        async () => {
            const result = await corporateClient.support.corporateFeatureGroupControllerGetFeatureGroups();
            generateFeatureModel(result.data);
            return result.data;
        },
        { deps: [] }
    );

    const disableFeatureRequest = useWrapRequest(
        async (feature: CorporateFeatureDto['feature']) => {
            const result = await corporateClient.support.corporateFeatureControllerDisableFeature(
                feature
            );
            generateFeatureModel(result.data);
            return result.data;
        },
        { successMessage: ([name]) => `${name} was disabled` }
    );

    const enableFeatureRequest = useWrapRequest(
        async (feature: CorporateFeatureDto['feature']) => {
            const result = await corporateClient.support.corporateFeatureControllerEnableFeature(
                feature
            );
            generateFeatureModel(result.data);
            return result.data;
        },
        {
            successMessage: ([name]) => `${name} was enabled`
        }
    );

    async function enableFitnessApps() {
        if (form.current) {
            const fitnessApps = getFitnessAppField();

            if (fitnessApps && !fitnessApps?.$.enabled.value) {
                await enableFeatureRequest.request(['FITNESS_APPS']);
                fitnessApps?.$.enabled.onChange(true);
            }
        }
        return;
    }

    function getFitnessAppField() {
        const featuresGroup = form.current?.$.find(
            (field) => field.$.id.value === 'FEATURES'
        );
        if (!featuresGroup) {
            return null;
        }
        return featuresGroup.$.features.$.find(
            (field) => field.$.id.value === 'FITNESS_APPS'
        );
    }

    function generateFeatureModel(groups: CorporateFeatureGroupDto[]) {
        const features = groups.map(
            (group) =>
                new FormState({
                    name: new FieldState(group.name),
                    id: new FieldState(group.group),
                    features: new FormState(
                        group.features.map(
                            (feature) =>
                                new FieldState({
                                    name: new FieldState(feature.name),
                                    id: new FieldState(feature.feature),
                                    enabled: new FieldState(feature.enabled),
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                                    subFeatures: new FormState(
                                        (feature as NestedCorporateFeatureDto).subFeatures.map(
                                            (subFeature: CorporateFeatureDto) =>
                                                new FieldState({
                                                    name: new FieldState(
                                                        subFeature.name
                                                    ),
                                                    id: new FieldState(
                                                        subFeature.feature
                                                    ),
                                                    enabled: new FieldState(
                                                        subFeature.enabled
                                                    )
                                                })
                                        )
                                    )
                                })
                        )
                    )
                })
        );

        form.current = new FormState(features);
    }

    return {
        corporateFeatures,
        enableFitnessApps,
        disableFeatureRequest,
        enableFeatureRequest,
        form
    };
}
