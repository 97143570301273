import React from 'react';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { links } from '../../../core/router';
import { useSearchReducer } from '../../../components/hooks/useSearchReducer';
import { observer } from 'mobx-react';
import { injectTSDI } from '../../../core/tsdi';
import { Api } from '../../../core/api';
import { useWrapRequest } from '../../../components/hooks/use-wrap-request';
import { EmployeeContext } from '..';
import { Table } from '../../../components/table';
import { CustomFacilitySearchDto } from '../../../core/api/StudioClient';
import { CellProps } from 'react-table';

export default observer(() => {
    const navigate = useNavigate();
    const { studioClient } = injectTSDI(Api);
    const employee = React.useContext(EmployeeContext);

    const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
        size: 30,
        page: 0
    });

    const facilities = useWrapRequest(
        async (searchParams, employee) => {
            const result =
                await studioClient.v1.customFacilityControllerGetPage({
                    ...searchParams,
                    creatorMySportsId: employee.mySportsId
                });

            return { ...result.data, content: result.data.content || [] };
        },
        { defaultData: { content: [] }, deps: [searchParams, employee] }
    );

    return (
        <>
            <Table<CustomFacilitySearchDto[]>
                emptyText="This employee did not create any facilities"
                items={facilities}
                columns={[
                    {
                        Header: 'Name',
                        Cell: ({
                            row: {
                                original: { name, id }
                            }
                        }: CellProps<CustomFacilitySearchDto>) => (
                            <Button
                                onClick={() =>
                                    void navigate(links.facilityEdit(id))
                                }
                            >
                                {name}
                            </Button>
                        )
                    },
                    {
                        Header: 'Category',
                        Cell: ({
                            row: {
                                original: { category }
                            }
                        }: CellProps<CustomFacilitySearchDto>) => (
                            <Typography>{category.label}</Typography>
                        )
                    },
                    {
                        Header: 'Address',
                        Cell: ({
                            row: {
                                original: { address }
                            }
                        }: CellProps<CustomFacilitySearchDto>) => (
                            <Typography>
                                {address.street} {address.houseNumber},{' '}
                                {address.city}
                            </Typography>
                        )
                    }
                ]}
                onPageSizeChange={(size) => dispatch({ type: 'size', size })}
                onChangePage={(page) =>
                    void dispatch({
                        type: 'page',
                        page
                    })
                }
            />
        </>
    );
});
