/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface WorkoutRuleDto {
    /** @format int64 */
    minWorkoutDurationMinutes: number;
    /** @format double */
    minAverageSpeed: number;
    /** @format double */
    maxAverageSpeed: number;
    /** @format double */
    maxAllowedSpeed: number;
    /** @format int64 */
    minStudioVisitDurationMinutes: number;
    /** @format int64 */
    noRecentCheckInConfirmationNotificationTimeoutMinutes: number;
}

export interface ActivitySourceDto {
    /** @format int64 */
    id?: number;
    applicationPackage: string;
    stepsAllowed: boolean;
    cyclingAllowed: boolean;
    priority: ActivitySourcePriority;
    origin?: ActivitySourceOriginDto;
}

export interface ActivitySourceOriginDto {
    /** @format int64 */
    userId: number;
    tenantId: string;
}

export enum ActivitySourcePriority {
    HIGH = 'HIGH',
    LOW = 'LOW'
}

export enum Job {
    ALIGN_ACTIVITY_POINTS_DISCREPANCY = 'ALIGN_ACTIVITY_POINTS_DISCREPANCY'
}

export interface ActivityBackfillDto {
    /** @format date-time */
    backfillDateTime: string;
}

export enum ActivityEventType {
    STEPS_DAY = 'STEPS_DAY',
    STEPS_PER_DAY = 'STEPS_PER_DAY',
    STEPS_EPOCH = 'STEPS_EPOCH',
    STEPS_PER_EPOCH = 'STEPS_PER_EPOCH',
    STUDIO_VISIT = 'STUDIO_VISIT',
    WORKOUT = 'WORKOUT',
    STUDIO_CHECK_IN = 'STUDIO_CHECK_IN'
}

export interface WorkoutEvent {
    /** @format uuid */
    messageId: string;
    userId: string;
    /** @format date-time */
    timestamp: string;
    /**
     * @format date
     * @example "2025-01-13"
     */
    userDay?: string;
    payload: WorkoutPayloadDto;
    type?: ActivityEventType;
}

export interface WorkoutPayloadDto {
    workoutType?: WorkoutType;
    /** @uniqueItems true */
    sources: string[];
    /** @uniqueItems true */
    devices: string[];
    dateTimeRange: ZonedDateTimeRangeDto;
    properties: WorkoutPropertiesDto;
}

export interface WorkoutPositionDto {
    /**
     * @format double
     * @min -90
     * @max 90
     */
    latitude: number;
    /**
     * @format double
     * @min -180
     * @max 180
     */
    longitude: number;
}

export interface WorkoutPropertiesDto {
    /** @format double */
    distance?: number;
    /** @format double */
    averageSpeed?: number;
    /** @format double */
    activityTime?: number;
    /** @format double */
    minimumSpeed?: number;
    /** @format double */
    maximumSpeed?: number;
    /** @format double */
    caloriesBurned?: number;
    speedEntries: WorkoutSpeedEntryDto[];
    positions?: WorkoutPositionDto[];
    externalId?: string;
}

export interface WorkoutSpeedEntryDto {
    /** @format date-time */
    timestamp: string;
    /** @format date-time */
    startTime: string;
    /** @format date-time */
    endTime: string;
    value: number;
}

export enum WorkoutType {
    CYCLING = 'CYCLING',
    RUNNING = 'RUNNING',
    HIKING = 'HIKING',
    OTHER = 'OTHER'
}

export interface ZonedDateTimeRangeDto {
    /** @format date-time */
    from: string;
    /** @format date-time */
    to: string;
    /**
     * @deprecated
     * @format date-time
     */
    endDateTime?: string;
    /**
     * @deprecated
     * @format date-time
     */
    startDateTime?: string;
}

export interface DailyStepsEvent {
    /** @format uuid */
    messageId: string;
    userId: string;
    /** @format date-time */
    timestamp: string;
    /**
     * @format date
     * @example "2025-01-13"
     */
    userDay?: string;
    /** @format int32 */
    stepCount?: number;
    dateTimeRange: ZonedDateTimeRangeDto;
    priority: StepsPriority;
    /** @uniqueItems true */
    sources: string[];
    type?: ActivityEventType;
}

export enum StepsPriority {
    LOW = 'LOW',
    HIGH = 'HIGH'
}

export interface FacilityVisitEvent {
    /** @format uuid */
    messageId: string;
    userId: string;
    /** @format date-time */
    timestamp: string;
    /**
     * @format date
     * @example "2025-01-13"
     */
    userDay?: string;
    /** @format int64 */
    facilityId?: number;
    facilityName?: string;
    facilityVisitType: FacilityVisitType;
    visitDateRange: ZonedDateTimeRangeDto;
    type?: ActivityEventType;
    /** @deprecated */
    studioName?: string;
}

export enum FacilityVisitType {
    MANUAL = 'MANUAL',
    AUTOMATIC = 'AUTOMATIC'
}

export interface PageActivitySourceDto {
    content: ActivitySourceDto[];
    /** @format int32 */
    number: number;
    /** @format int32 */
    size: number;
    /** @format int32 */
    totalPages: number;
    /** @format int64 */
    totalElements: number;
}

export interface ActivityPointsChangeDto {
    /** @format date-time */
    createdDate: string;
    /** @format date-time */
    timestamp: string;
    value: number;
}

export interface ActivityPointsDto {
    /** @format int64 */
    id: number;
    /** @format date-time */
    timestamp: string;
    activityType: ActivityType;
    value: number;
    changes: ActivityPointsChangeDto[];
}

export enum ActivityType {
    DAILY_STEPS = 'DAILY_STEPS',
    FACILITY_VISIT = 'FACILITY_VISIT',
    WORKOUT = 'WORKOUT'
}

export interface PageActivityPointsDto {
    content: ActivityPointsDto[];
    /** @format int32 */
    number: number;
    /** @format int32 */
    size: number;
    /** @format int32 */
    totalPages: number;
    /** @format int64 */
    totalElements: number;
}

export enum ActivityDataType {
    STEPS = 'STEPS',
    DISTANCE = 'DISTANCE',
    CALORIES = 'CALORIES',
    TIME = 'TIME'
}

export interface ActivityPointChangeEvent {
    activityId: string;
    value: number;
    /** @format date-time */
    timestamp: string;
    activityType: ActivityType;
    activityDataType: ActivityDataType;
    activityValue: number;
}

export interface ActivityPointsSummaryDto {
    activityPoints: number;
    activityPointChanges?: ActivityPointChangeEvent[];
}

export interface PageActivityPointsChangeDto {
    content: ActivityPointsChangeDto[];
    /** @format int32 */
    number: number;
    /** @format int32 */
    size: number;
    /** @format int32 */
    totalPages: number;
    /** @format int64 */
    totalElements: number;
}

export interface ActivityDto {
    /** @format int64 */
    id: number;
    type: ActivityType;
    /** @format date-time */
    timestamp: string;
    rejected: boolean;
}

export interface PageActivityDto {
    content: ActivityDto[];
    /** @format int32 */
    number: number;
    /** @format int32 */
    size: number;
    /** @format int32 */
    totalPages: number;
    /** @format int64 */
    totalElements: number;
}

export interface ActivityDetailsDto {
    /** @format int64 */
    id: number;
    type: ActivityType;
    /** @format date-time */
    timestamp: string;
    rejected: boolean;
    /** @format date-time */
    createdDate: string;
    /** @format date-time */
    modifiedDate: string;
    rejectionReason?: ActivityRejectionReason;
    activityPoints: number;
    additionalProperties: KeyValuePairStringObject[];
    backfill?: ActivityBackfillDto;
}

export enum ActivityRejectionReason {
    INVALID_SOURCE = 'INVALID_SOURCE',
    INVALID_AVERAGE_SPEED = 'INVALID_AVERAGE_SPEED',
    INVALID_MAX_SPEED = 'INVALID_MAX_SPEED',
    OVERWRITTEN_BY_OTHER_ACTIVITY = 'OVERWRITTEN_BY_OTHER_ACTIVITY',
    TOO_SHORT = 'TOO_SHORT',
    FACILITY_REJECTED = 'FACILITY_REJECTED'
}

export interface KeyValuePairStringObject {
    key?: string;
    value?: object;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<
    FullRequestParams,
    'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
    extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '/corporate-api';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
        fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(
            (key) => 'undefined' !== typeof query[key]
        );
        return keys
            .map((key) =>
                Array.isArray(query[key])
                    ? this.addArrayQueryParam(query, key)
                    : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null &&
            (typeof input === 'object' || typeof input === 'string')
                ? JSON.stringify(input)
                : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string'
                ? JSON.stringify(input)
                : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                          ? JSON.stringify(property)
                          : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
    };

    protected mergeRequestParams(
        params1: RequestParams,
        params2?: RequestParams
    ): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected createAbortSignal = (
        cancelToken: CancelToken
    ): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean'
                ? secure
                : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter =
            this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(
            `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
            {
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData
                        ? { 'Content-Type': type }
                        : {})
                },
                signal:
                    (cancelToken
                        ? this.createAbortSignal(cancelToken)
                        : requestParams.signal) || null,
                body:
                    typeof body === 'undefined' || body === null
                        ? null
                        : payloadFormatter(body)
            }
        ).then(async (response) => {
            const r = response.clone() as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then((data) => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch((e) => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title MySports Rewards Fitness Activity API
 * @version v1
 * @license MySports License (https://www.mysports.com)
 * @baseUrl /corporate-api
 * @contact MySports Contact <move@mysports-rewards.com>
 *
 * Service that provides Fitness Activity APIs
 */
export class Api<
    SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
    admin = {
        /**
         * No description
         *
         * @tags workout-rule-admin-controller
         * @name WorkoutRuleAdminControllerGetGlobalWorkoutRules
         * @request GET:/admin/v1/workout-rules/global
         * @secure
         */
        workoutRuleAdminControllerGetGlobalWorkoutRules: (
            params: RequestParams = {}
        ) =>
            this.request<WorkoutRuleDto, any>({
                path: `/admin/v1/workout-rules/global`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags workout-rule-admin-controller
         * @name WorkoutRuleAdminControllerUpdateGlobalWorkoutRules
         * @request PUT:/admin/v1/workout-rules/global
         * @secure
         */
        workoutRuleAdminControllerUpdateGlobalWorkoutRules: (
            data: WorkoutRuleDto,
            params: RequestParams = {}
        ) =>
            this.request<WorkoutRuleDto, any>({
                path: `/admin/v1/workout-rules/global`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-source-admin-controller
         * @name ActivitySourceAdminControllerGetActivitySource
         * @request GET:/admin/v1/activity-sources/{id}
         * @secure
         */
        activitySourceAdminControllerGetActivitySource: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<ActivitySourceDto, any>({
                path: `/admin/v1/activity-sources/${id}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-source-admin-controller
         * @name ActivitySourceAdminControllerUpdateActivitySource
         * @request PUT:/admin/v1/activity-sources/{id}
         * @secure
         */
        activitySourceAdminControllerUpdateActivitySource: (
            id: number,
            data: ActivitySourceDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/activity-sources/${id}`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-support-controller
         * @name ActivitySupportControllerExportUserActivitiesToEvaluatedActivitiesQueue
         * @request POST:/admin/v1/users/{userId}/evaluated-activities-queue-export
         * @secure
         */
        activitySupportControllerExportUserActivitiesToEvaluatedActivitiesQueue:
            (
                userId: string,
                query?: {
                    from?: string;
                    to?: string;
                },
                params: RequestParams = {}
            ) =>
                this.request<void, any>({
                    path: `/admin/v1/users/${userId}/evaluated-activities-queue-export`,
                    method: 'POST',
                    query: query,
                    secure: true,
                    ...params
                }),

        /**
         * No description
         *
         * @tags job-controller
         * @name JobControllerTriggerJob
         * @request POST:/admin/v1/jobs/{job}
         * @secure
         */
        jobControllerTriggerJob: (job: Job, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/v1/jobs/${job}`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-support-controller
         * @name ActivitySupportControllerExportActivitiesToEvaluatedActivitiesQueue
         * @request POST:/admin/v1/evaluated-activities-queue-export
         * @secure
         */
        activitySupportControllerExportActivitiesToEvaluatedActivitiesQueue: (
            query: {
                timestampFrom: string;
                timestampTo: string;
                sourceCreatedDateFrom: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/evaluated-activities-queue-export`,
                method: 'POST',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-source-admin-controller
         * @name ActivitySourceAdminControllerGetActivitySources
         * @request GET:/admin/v1/activity-sources
         * @secure
         */
        activitySourceAdminControllerGetActivitySources: (
            query?: {
                /**
                 * @format int32
                 * @min 0
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 1000
                 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageActivitySourceDto, any>({
                path: `/admin/v1/activity-sources`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-source-admin-controller
         * @name ActivitySourceAdminControllerCreateActivitySource
         * @request POST:/admin/v1/activity-sources
         * @secure
         */
        activitySourceAdminControllerCreateActivitySource: (
            data: ActivitySourceDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/activity-sources`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-support-controller
         * @name ActivitySupportControllerBackfillActivity
         * @request POST:/admin/v1/activities/{id}/backfills
         * @secure
         */
        activitySupportControllerBackfillActivity: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<ActivityBackfillDto, any>({
                path: `/admin/v1/activities/${id}/backfills`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-support-controller
         * @name ActivitySupportControllerCreateWorkoutActivity
         * @request POST:/admin/v1/activities/workout
         * @secure
         */
        activitySupportControllerCreateWorkoutActivity: (
            data: WorkoutEvent,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/activities/workout`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-support-controller
         * @name ActivitySupportControllerCreateStepsActivity
         * @request POST:/admin/v1/activities/steps
         * @secure
         */
        activitySupportControllerCreateStepsActivity: (
            data: DailyStepsEvent,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/activities/steps`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-support-controller
         * @name ActivitySupportControllerCreateFacilityVisitActivity
         * @request POST:/admin/v1/activities/facility-visit
         * @secure
         */
        activitySupportControllerCreateFacilityVisitActivity: (
            data: FacilityVisitEvent,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/activities/facility-visit`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-support-controller
         * @name ActivitySupportControllerGetActivityPoints
         * @request GET:/admin/v1/activity-points
         * @secure
         */
        activitySupportControllerGetActivityPoints: (
            query: {
                userId: string;
                /**
                 * @format int32
                 * @min 0
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 1000
                 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageActivityPointsDto, any>({
                path: `/admin/v1/activity-points`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-support-controller
         * @name ActivitySupportControllerGetActivityPointsSummary
         * @request GET:/admin/v1/activity-points-summary
         * @secure
         */
        activitySupportControllerGetActivityPointsSummary: (
            query: {
                userId: string;
                /** @format date-time */
                timeFrom: string;
                /** @format date-time */
                timeTo?: string;
                activityTypes: ActivityType[];
            },
            params: RequestParams = {}
        ) =>
            this.request<ActivityPointsSummaryDto, any>({
                path: `/admin/v1/activity-points-summary`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-support-controller
         * @name ActivitySupportControllerGetActivityPointChanges
         * @request GET:/admin/v1/activity-point-changes
         * @secure
         */
        activitySupportControllerGetActivityPointChanges: (
            query: {
                userId: string;
                /**
                 * @format int32
                 * @min 0
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 1000
                 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageActivityPointsChangeDto, any>({
                path: `/admin/v1/activity-point-changes`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-support-controller
         * @name ActivitySupportControllerGetActivities
         * @request GET:/admin/v1/activities
         * @secure
         */
        activitySupportControllerGetActivities: (
            query: {
                userId: string;
                /**
                 * @format int32
                 * @min 0
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 1000
                 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageActivityDto, any>({
                path: `/admin/v1/activities`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags activity-support-controller
         * @name ActivitySupportControllerGetActivity
         * @request GET:/admin/v1/activities/{id}
         * @secure
         */
        activitySupportControllerGetActivity: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<ActivityDetailsDto, any>({
                path: `/admin/v1/activities/${id}`,
                method: 'GET',
                secure: true,
                ...params
            })
    };
}
