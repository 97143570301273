import { Stack, Typography } from '@mui/material';
import { format, startOfMonth, subMonths } from 'date-fns';
import React from 'react';
import { Paper } from '../../components/paper';
import { Button } from '../../components/button';
import { injectTSDI } from '../../core/tsdi';
import { Api } from '../../core/api';
import { useError } from '../../components/hooks/useError';
import { DatePicker } from '@mui/x-date-pickers';

const CollectedStartReport = () => {
    const [date, setDate] = React.useState<Date | null>(null);
    const { walletClient } = injectTSDI(Api);
    const showError = useError();
    const onDownload = async () => {
        if (date) {
            const yearMonth = format(date, 'yyyy-MM');
            try {
                const response =
                    await walletClient.supportApi.reportControllerGetStarsReport(
                        yearMonth
                    );
                const blob = await response.blob();
                const data = new Blob([blob], { type: 'text/csv' });
                const csvURL = window.URL.createObjectURL(data);
                const tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute(
                    'download',
                    `collected-stars-report-${yearMonth}.csv`
                );
                tempLink.click();
            } catch (e) {
                showError(e);
            }
        }
    };
    return (
        <Paper>
            <Typography variant="h6" mb={2}>
                Collected Stars Report
            </Typography>
            <Stack direction="row" spacing={1}>
                <DatePicker
                    label="Year and Month"
                    views={['month', 'year']}
                    minDate={new Date('2020-04-01')}
                    maxDate={startOfMonth(subMonths(new Date(), 1))}
                    value={date}
                    onChange={(value) => void setDate(value)}
                    slotProps={{
                        textField: {
                            InputLabelProps: { shrink: true },
                            variant: 'outlined'
                        }
                    }}
                />

                <Button disabled={!date} onClick={onDownload}>
                    Download
                </Button>
            </Stack>
        </Paper>
    );
};

export default CollectedStartReport;
