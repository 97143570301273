import React from 'react';
import { useParams } from 'react-router';
import { useWrapRequest } from 'use-wrap-request';
import { Api } from '../../core/api';
import { UpdateCorporateTenantContractDto } from '../../core/api/TenantClient';
import { injectTSDI } from '../../core/tsdi';
import { TenantFeaturesContext } from '../index';

export function useCorporateStore() {
    const { tenantClient } = injectTSDI(Api);
    const tenantFeatures = React.useContext(TenantFeaturesContext);

    const [contractModel, setContractModel] = React.useState<
        UpdateCorporateTenantContractDto['contractModel'] | undefined
    >();

    const { tenantName } = useParams();
    const tenantContract = useWrapRequest(async (tenantName: string) => {
        const request = await tenantClient.admin.tenantAdminControllerGetCorporateTenantContract(
            tenantName
        );
        const contract = request.data.content?.[0];

        return contract;
    });

    const tenantUpdateHandler = useWrapRequest(
        async (
            tenantName: string,
            contractModel: UpdateCorporateTenantContractDto['contractModel']
        ) => {
            const contract = await tenantClient.admin.tenantAdminControllerUpdateCorporateTenantContract(
                tenantName,
                { contractModel }
            );
            return contract;
        },
        { successMessage: () => 'License was updated' }
    );

    React.useEffect(() => {
        if (tenantFeatures.$?.tenant.productType === 'CORPORATE') {
            tenantContract.request([tenantName]);
        }
    }, [tenantName, tenantFeatures.$, tenantContract]);

    React.useEffect(() => {
        if (tenantContract.$?.contractModel) {
            setContractModel(tenantContract.$?.contractModel);
        }
    }, [tenantContract.$]);

    const contractChangeHandler = async () => {
        const update = await tenantUpdateHandler.request([
            tenantName,
            contractModel
        ]);
        await tenantContract.request([tenantName]);
        return update;
    };

    return {
        tenantContract,
        contractModel,
        tenantUpdateHandler,
        setContractModel,
        contractChangeHandler
    };
}
