import React from 'react';
import { ConfirmationModal } from '../../components/confirmation-modal';
import { injectTSDI } from '../../core/tsdi';
import { Api } from '../../core/api';
import { Alert, Typography } from '@mui/material';
import { TenantDto, TenantSimpleDto } from '../../core/api/TenantClient';

type Props = {
    onSuccess: () => void;
    tenant?: TenantSimpleDto | TenantDto;
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

export const TenantActivateModal = ({
    onSuccess,
    tenant,
    openState: [open, setOpen]
}: Props) => {
    const { tenantClient } = injectTSDI(Api);

    const handleDeactivate = async () => {
        if (tenant) {
            await tenantClient.admin.tenantAdminControllerActivateTenant(
                tenant.name
            );
            onSuccess();
        }
    };
    return (
        <ConfirmationModal
            open={open}
            submitHandler={handleDeactivate}
            onModalClose={() => void setOpen(false)}
            buttonText="Activate"
        >
            <Typography variant="h6">
                You are about to activate tenant <b>{tenant?.name}</b>
            </Typography>
            <Alert severity="warning">
                Are you sure you want to activate this tenant?
            </Alert>
        </ConfirmationModal>
    );
};
