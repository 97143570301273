import { observer } from 'mobx-react';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import React from 'react';
import { TextField } from '../../components/textfield';
import { WorkoutRuleDto } from '../../core/api/FitnessActivityClient';
import { useStore } from './store';
import { Button } from '../../components/button';
import { injectTSDI } from '../../core/tsdi';
import { I18n } from '../../core/i18n';

interface GeneralWorkoutRulesFormProps {
    currentWorkoutRules: WorkoutRuleDto;
}

export const GeneralWorkoutRules = observer(
    ({ currentWorkoutRules }: GeneralWorkoutRulesFormProps) => {
        const {
            fields,
            errors,
            onSubmitHandler,
            workoutRuleRequest
        } = useStore(currentWorkoutRules);
        const { __ } = injectTSDI(I18n);

        const {
            minWorkoutDurationMinutes,
            minAverageSpeed,
            maxAverageSpeed,
            maxAllowedSpeed,
            minStudioVisitDurationMinutes,
            noRecentCheckInConfirmationNotificationTimeoutMinutes
        } = fields;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} mb={-2}>
                    <Typography variant="h6">Check-in</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={true}
                        value={0}
                        onChange={() => void null}
                        type="number"
                        label="Confirmations frequency"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={true}
                        value={0}
                        onChange={() => void null}
                        type="number"
                        label="Auto check-out distance"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    meters
                                </InputAdornment>
                            )
                        }}
                        required
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={true}
                        value={0}
                        onChange={() => void null}
                        type="number"
                        label="Amount of away confirmations to check-out"
                        required
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={noRecentCheckInConfirmationNotificationTimeoutMinutes.value}
                        onChange={
                            noRecentCheckInConfirmationNotificationTimeoutMinutes.onChange
                        }
                        type="number"
                        label="No recent confirmation notification timeout"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            noRecentCheckInConfirmationNotificationTimeoutMinutes.error &&
                            __(
                                noRecentCheckInConfirmationNotificationTimeoutMinutes.error as I18nKey,
                                0
                            )
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={true}
                        value={0}
                        onChange={() => void null}
                        type="number"
                        label="Minimum facility zone checkout timeout"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        required
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={true}
                        value={0}
                        onChange={() => void null}
                        type="number"
                        label="Timed out checkout minimum duration"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        required
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={true}
                        value={0}
                        onChange={() => void null}
                        type="number"
                        label="Automatic check out duration"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        required
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={minStudioVisitDurationMinutes.value}
                        onChange={
                            minStudioVisitDurationMinutes.onChange
                        }
                        type="number"
                        label="Minimum studio visit duration"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            minStudioVisitDurationMinutes.error &&
                            __(
                                minStudioVisitDurationMinutes.error as I18nKey,
                                0
                            )
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={12} mb={-2}>
                    <Typography variant="h6">Cycling</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={minAverageSpeed.value}
                        onChange={minAverageSpeed.onChange}
                        type="number"
                        label="Minimum average speed"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    km/h
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            minAverageSpeed.error &&
                            __(minAverageSpeed.error as I18nKey, 0)
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={maxAverageSpeed.value}
                        onChange={maxAverageSpeed.onChange}
                        type="number"
                        label="Maximum average speed"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    km/h
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            maxAverageSpeed.error &&
                            __(maxAverageSpeed.error as I18nKey, 0)
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={maxAllowedSpeed.value}
                        onChange={maxAllowedSpeed.onChange}
                        type="number"
                        label="Maximum allowed speed"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    km/h
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            maxAllowedSpeed.error &&
                            __(maxAllowedSpeed.error as I18nKey, 0)
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={minWorkoutDurationMinutes.value}
                        onChange={minWorkoutDurationMinutes.onChange}
                        type="number"
                        label="Minimum workout time"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            minWorkoutDurationMinutes.error &&
                            __(minWorkoutDurationMinutes.error as I18nKey, 0)
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}/>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            onClick={onSubmitHandler}
                            disabled={
                                Boolean(errors) || workoutRuleRequest.loading
                            }
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
    }
);
