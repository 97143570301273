import { Accordion, AccordionDetails, AccordionSummary, Chip, Stack, SvgIcon, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { I18n } from '../../../core/i18n';
import { injectTSDI } from '../../../core/tsdi';
import { ReactComponent as Step } from '../../../../assets/svg/icons/steps.svg';
import { ReactComponent as Studio } from '../../../../assets/svg/icons/target.svg';
import { ReactComponent as Workout } from '../../../../assets/svg/icons/workout.svg';
import { FitnessActivityDetails } from './fitness-activity-details';
import { ActivityDto } from '../../../core/api/FitnessActivityClient';

interface FitnessActivityProps {
    fitnessActivity: ActivityDto;
}

function getIconType(activityType: ActivityDto['type']) {
    switch (activityType) {
        case 'DAILY_STEPS':
            return { icon: Step, viewBox: '0 0 13 22' };
        case 'FACILITY_VISIT':
            return { icon: Studio, viewBox: '0 0 22 22' };
        default:
            return { icon: Workout, viewBox: '0 0 20 20' };
    }
}

const ListItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
`;

export const FitnessActivity = ({ fitnessActivity }: FitnessActivityProps) => {
    const { formatDateTime } = injectTSDI(I18n);
    const { icon: Icon, viewBox } = getIconType(fitnessActivity.type);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    return (
        <Accordion
            expanded={expanded}
            onChange={(_, isExpanded) => setExpanded(isExpanded)}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ListItem>
                    <Typography flex="3">
                        {formatDateTime(fitnessActivity.timestamp)}
                    </Typography>
                    <Stack direction="row" justifyContent="flex-start" flex="2">
                        <SvgIcon component={Icon} viewBox={viewBox} />
                        <Typography
                            component="span"
                            variant="body2"
                            ml={2}
                            color="GrayText"
                        >
                            {fitnessActivity.type}
                        </Typography>
                    </Stack>
                    <Typography
                        flex="2"
                        variant="h6"
                        ml={2}
                        mr={2}
                        textAlign="right"
                    >
                        {fitnessActivity.rejected ? (
                            <Chip
                                label="rejected"
                                color="error"
                                variant="outlined"
                            />
                        ) : (
                            <Chip
                                label="approved"
                                color="success"
                                variant="outlined"
                            />
                        )}
                    </Typography>
                </ListItem>
            </AccordionSummary>
            <AccordionDetails>
                {fitnessActivity && fitnessActivity.id && expanded && (
                    <FitnessActivityDetails
                        fitnessActivityId={fitnessActivity.id}
                    />
                )}
            </AccordionDetails>
        </Accordion>
    );
};
