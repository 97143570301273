import { observer } from 'mobx-react';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import React from 'react';
import { TextField } from '../../../components/textfield';
import { WorkoutRuleDto } from '../../../core/api/TenantClient';
import { useStore } from './store';
import { Button } from '../../../components/button';
import { injectTSDI } from '../../../core/tsdi';
import { I18n } from '../../../core/i18n';

interface GeneralWorkoutRulesFormProps {
    currentWorkoutRules: WorkoutRuleDto;
}

export const GeneralWorkoutRules = observer(
    ({ currentWorkoutRules }: GeneralWorkoutRulesFormProps) => {
        const {
            fields,
            errors,
            onSubmitHandler,
            workoutRuleRequest
        } = useStore(currentWorkoutRules);
        const { __ } = injectTSDI(I18n);

        const {
            checkInConfirmationFrequencyMinutes,
            autoCheckOutThresholdDistance,
            checkInCountFrequencyChangeThreshold,
            minDurationMinutes,
            minAverageSpeed,
            maxAverageSpeed,
            maxAllowedSpeed,
            minFacilityZoneCheckOutTimeoutMinutes,
            timedOutCheckOutMinDurationMinutes,
            autoCheckOutTimeoutMinutes,
            minStudioVisitDurationMinutes,
            noRecentCheckInConfirmationNotificationTimeoutMinutes
        } = fields;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} mb={-2}>
                    <Typography variant="h6">Check-in</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={checkInConfirmationFrequencyMinutes.value}
                        onChange={
                            checkInConfirmationFrequencyMinutes.onChange
                        }
                        type="number"
                        label="Confirmations frequency"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            checkInConfirmationFrequencyMinutes.error &&
                            __(
                                checkInConfirmationFrequencyMinutes.error as I18nKey,
                                0
                            )
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={autoCheckOutThresholdDistance.value}
                        onChange={autoCheckOutThresholdDistance.onChange}
                        type="number"
                        label="Auto check-out distance"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    meters
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            autoCheckOutThresholdDistance.error &&
                            __(
                                autoCheckOutThresholdDistance.error as I18nKey,
                                0
                            )
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={checkInCountFrequencyChangeThreshold.value}
                        onChange={checkInCountFrequencyChangeThreshold.onChange}
                        type="number"
                        label="Amount of away confirmations to check-out"
                        required
                        error={
                            checkInCountFrequencyChangeThreshold.error &&
                            __(
                                checkInCountFrequencyChangeThreshold.error as I18nKey,
                                0
                            )
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={noRecentCheckInConfirmationNotificationTimeoutMinutes.value}
                        onChange={
                            noRecentCheckInConfirmationNotificationTimeoutMinutes.onChange
                        }
                        type="number"
                        label="No recent confirmation notification timeout"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            noRecentCheckInConfirmationNotificationTimeoutMinutes.error &&
                            __(
                                noRecentCheckInConfirmationNotificationTimeoutMinutes.error as I18nKey,
                                0
                            )
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={minFacilityZoneCheckOutTimeoutMinutes.value}
                        onChange={
                            minFacilityZoneCheckOutTimeoutMinutes.onChange
                        }
                        type="number"
                        label="Minimum facility zone checkout timeout"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            minFacilityZoneCheckOutTimeoutMinutes.error &&
                            __(
                                minFacilityZoneCheckOutTimeoutMinutes.error as I18nKey,
                                0
                            )
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={timedOutCheckOutMinDurationMinutes.value}
                        onChange={timedOutCheckOutMinDurationMinutes.onChange}
                        type="number"
                        label="Timed out checkout minimum duration"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            timedOutCheckOutMinDurationMinutes.error &&
                            __(
                                timedOutCheckOutMinDurationMinutes.error as I18nKey,
                                0
                            )
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={autoCheckOutTimeoutMinutes.value}
                        onChange={autoCheckOutTimeoutMinutes.onChange}
                        type="number"
                        label="Automatic check out duration"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            autoCheckOutTimeoutMinutes.error &&
                            __(autoCheckOutTimeoutMinutes.error as I18nKey, 0)
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={minStudioVisitDurationMinutes.value}
                        onChange={
                            minStudioVisitDurationMinutes.onChange
                        }
                        type="number"
                        label="Minimum studio visit duration"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            minStudioVisitDurationMinutes.error &&
                            __(
                                minStudioVisitDurationMinutes.error as I18nKey,
                                0
                            )
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={12} mb={-2}>
                    <Typography variant="h6">Cycling</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={minAverageSpeed.value}
                        onChange={minAverageSpeed.onChange}
                        type="number"
                        label="Minimum average speed"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    km/h
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            minAverageSpeed.error &&
                            __(minAverageSpeed.error as I18nKey, 0)
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={maxAverageSpeed.value}
                        onChange={maxAverageSpeed.onChange}
                        type="number"
                        label="Maximum average speed"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    km/h
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            maxAverageSpeed.error &&
                            __(maxAverageSpeed.error as I18nKey, 0)
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={maxAllowedSpeed.value}
                        onChange={maxAllowedSpeed.onChange}
                        type="number"
                        label="Maximum allowed speed"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    km/h
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            maxAllowedSpeed.error &&
                            __(maxAllowedSpeed.error as I18nKey, 0)
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={minDurationMinutes.value}
                        onChange={minDurationMinutes.onChange}
                        type="number"
                        label="Minimum workout time"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    min
                                </InputAdornment>
                            )
                        }}
                        required
                        error={
                            minDurationMinutes.error &&
                            __(minDurationMinutes.error as I18nKey, 0)
                        }
                        helperText
                    />
                </Grid>
                <Grid item xs={6}/>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            onClick={onSubmitHandler}
                            disabled={
                                Boolean(errors) || workoutRuleRequest.loading
                            }
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
    }
);
