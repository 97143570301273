import { FieldState, FormState } from 'formstate';
import React from 'react';
import { useWrapRequest } from 'use-wrap-request';
import { useParams } from 'react-router';
import { injectTSDI } from '../../core/tsdi';
import { Api } from '../../core/api';
import { SizeRange, TenantSimpleDto } from '../../core/api/TenantClient';
import { required, useModel, wrapInFormField } from '../../core/forms';
import { assertDefined } from '../../components/utils/error';
import { TenantCompanyUpdateDto } from './../../core/api/TenantClient';
import { TenantCorporateContext, TenantFeaturesContext } from './../index';

export function useStore() {
    const corporateClient = React.useContext(TenantCorporateContext);
    const corporateFeatures = React.useContext(TenantFeaturesContext);
    const { tenantClient, googleUserAuth } = injectTSDI(Api);
    const { tenantName } = useParams();

    const model = useModel(() => {
        const form = new FormState({
            id: new FieldState<number | undefined>(undefined).validators(),
            contractModel: new FieldState<
                TenantCompanyUpdateDto['contractModel']
            >(SizeRange.MICRO).validators(required()),
            lexofficeLink: new FieldState<string>('').validators(),
            hubspotLink: new FieldState<string>('').validators()
        });
        const id = wrapInFormField(form.$.id);
        const contractModel = wrapInFormField(form.$.contractModel);
        const lexofficeLink = wrapInFormField(form.$.lexofficeLink);
        const hubspotLink = wrapInFormField(
            form.$.hubspotLink
        );

        return {
            form,
            fields: {
                id,
                contractModel,
                lexofficeLink,
                hubspotLink
            }
        };
    });

    const tenantTypes = useWrapRequest(
        async () => {
            const tenantTypes = await tenantClient.admin.tenantTypeAdminControllerGetTenantTypes();

            return tenantTypes.data.map((tenantType) => ({
                label: tenantType,
                value: tenantType
            }));
        },
        { deps: [] }
    );

    const updateTenant = useWrapRequest(
        async (fields: typeof model['fields']) => {
            assertDefined(fields.id.value);

            const id = fields.id.value;
            const company = await tenantClient.admin.companyControllerUpdateCompany(
                id,
                {
                    id,
                    contractModel: fields.contractModel.value,
                    ...(fields.lexofficeLink.value && {
                        lexofficeLink: fields.lexofficeLink.value
                    }),
                    ...(fields.hubspotLink.value && {
                        hubspotLink:
                            fields.hubspotLink.value
                    })
                }
            );
            return company.data;
        },
        { successMessage: () => 'Tenant details updated' }
    );

    const updateTenantType = useWrapRequest(
        async (type: TenantSimpleDto['type']) => {
            const tenantTypes = await tenantClient.admin.tenantAdminControllerUpdateTenantType(
                tenantName,
                type
            );
            return tenantTypes.data;
        },
        { successMessage: () => 'Tenant type updated' }
    );

    React.useEffect(() => {
        if (corporateFeatures.$?.tenant) {
            const tenant = corporateFeatures.$?.tenant;

            model.fields.id.onChange(tenant.company.id);

            if (tenant.contract.contractModel) {
                model.fields.contractModel.onChange(
                    tenant.contract.contractModel
                );
            }

            if (tenant.company.hubspotLink) {
                model.fields.hubspotLink.onChange(
                    tenant.company.hubspotLink
                );
            }
            if (tenant.company.lexofficeLink) {
                model.fields.lexofficeLink.onChange(
                    tenant.company.lexofficeLink
                );
            }
        }
    }, [corporateFeatures.$]);

    return {
        tenantClient,
        googleUserAuth,
        tenantTypes,
        updateTenantType,
        corporateFeatures,
        corporateClient,
        tenantName,
        updateTenant,
        fields: model.fields,
        model
    };
}
