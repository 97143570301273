import {
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select as MaterialSelect,
    SelectChangeEvent
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface Option<T = any> {
    label: string;
    value: T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface SelectProps<T = any> {
    label: string;
    required?: boolean;
    value: T;
    error?: string;
    options: Option<T>[];
    noLabel?: boolean;
    fullWidth?: boolean;
    onChange(value: T): void;
}

const StyledInputLabel = styled(InputLabel)``;
const StyledFormControl = styled(FormControl)`
    ${StyledInputLabel} {
        top: 6px;
        left: 13px;
    }
`;

const StyledSelect = styled(MaterialSelect)`
    height: 64px;
    fieldset {
        top: 0;
        width: 100%;
        legend {
            display: none;
        }
    }
    .MuiSelect-root {
        height: 100%;
        box-sizing: border-box;
        padding: 30px 12px 8px;
    }
`;

export function Select(props: SelectProps) {
    const inputLabel = React.useRef<HTMLLabelElement>(null);

    if (props.noLabel) {
        return (
            <FormControl fullWidth={props.fullWidth}>
                <MaterialSelect
                    sx={{ height: 40, marginTop: 1 }}
                    onChange={(e) => props.onChange(e.target.value)}
                    value={props.value || ''}
                    input={<OutlinedInput />}
                    displayEmpty
                    fullWidth={props.fullWidth}
                >
                    {props.options.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </MaterialSelect>
            </FormControl>
        );
    }

    return (
        <StyledFormControl variant="standard" sx={{ width: '100%' }} fullWidth>
            <StyledInputLabel
                ref={inputLabel}
                required={props.required}
                shrink={true}
            >
                {props.label}
            </StyledInputLabel>
            <StyledSelect
                fullWidth
                required={props.required}
                error={Boolean(props.error)}
                // tslint:disable-next-line:no-any
                onChange={(e: SelectChangeEvent<unknown>) =>
                    props.onChange(e.target.value)
                }
                value={props.value || ''}
                variant="outlined"
            >
                {props.options.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </StyledSelect>
        </StyledFormControl>
    );
}
