import { Container, Link } from '@mui/material';
import React from 'react';
import { useWrapRequest } from 'use-wrap-request';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CheckIcon from '@mui/icons-material/Check';
import { PageTitle } from '../../components/page-title';
import { injectTSDI } from '../../core/tsdi';
import { Api } from '../../core/api';
import { Paper } from '../../components/paper';
import { Table } from '../../components/table';
import { WorkoutSourceDto } from '../../core/api/TenantClient';
import { useSearchReducer } from '../../components/hooks/useSearchReducer';
import { userSearchDebounce } from '../../components/hooks/use-search';
import { links } from '../../core/router';
import { CellProps } from 'react-table';

export default observer(() => {
    const { tenantClient } = injectTSDI(Api);
    const navigate = useNavigate();

    const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
        size: 30,
        page: 0
    });

    const [initialSearch, setInitialSearch] = React.useState<
        string | undefined
    >();

    const { setSearchTerm } = userSearchDebounce(dispatch);

    const workoutSources = useWrapRequest(
        async (searchParams) => {
            const result =
                await tenantClient.admin.workoutSourceAdminControllerGetWorkoutSources(
                    searchParams
                );
            result.data.content = result.data.content.map((source) => ({
                ...source,
                id: source.name
            }));

            return {
                ...result.data,
                content: result.data.content || []
            };
        },
        {
            defaultData: { content: [], number: 0, size: 0 },
            deps: [searchParams]
        }
    );

    const workoutSourceApproveRequest = useWrapRequest(
        async (id: string) => {
            const result =
                await tenantClient.admin.workoutSourceAdminControllerApproveWorkoutSource(
                    id
                );

            return result.data;
        },
        { successMessage: () => 'Source was approved' }
    );

    const workoutSourceRejectRequest = useWrapRequest(
        async (id: string) => {
            const result =
                await tenantClient.admin.workoutSourceAdminControllerRejectWorkoutSource(
                    id
                );

            return result.data;
        },
        { successMessage: () => 'Source was rejected' }
    );

    const onToggle = async (workoutSource: WorkoutSourceDto) => {
        try {
            const id = workoutSource.name;

            if (workoutSource.allowed) {
                await workoutSourceRejectRequest.request([id]);
            } else {
                await workoutSourceApproveRequest.request([id]);
            }
        } catch (err) {
            alert(err);
        }

        setInitialSearch(undefined);
        setInitialSearch('');

        workoutSources.request();
    };

    return (
        <Container maxWidth="md">
            <Paper>
                <PageTitle>Workout sources</PageTitle>

                <Table<WorkoutSourceDto[]>
                    emptyText="No entries"
                    items={workoutSources}
                    columns={[
                        {
                            Header: 'Source package',
                            accessor: 'name',
                            disableSortBy: true
                        },

                        {
                            Header: 'Allowed',
                            disableSortBy: true,
                            Cell: ({
                                row: { original: row }
                            }: CellProps<WorkoutSourceDto>) => (
                                <>
                                    {row.allowed ? (
                                        <CheckIcon color="success" />
                                    ) : (
                                        <NotInterestedIcon color="error" />
                                    )}
                                </>
                            )
                        },
                        {
                            Header: 'User',
                            Cell: ({
                                row: { original: row }
                            }: CellProps<WorkoutSourceDto>) => {
                                const { origin } = row;
                                return (
                                    <>
                                        {origin?.userId && origin.tenantId && (
                                            <Link
                                                href="#"
                                                onClick={() => {
                                                    if (
                                                        origin.userId &&
                                                        origin.tenantId
                                                    ) {
                                                        navigate(
                                                            links.tenantEmployeeDetails(
                                                                origin.tenantId,
                                                                origin.userId.toString()
                                                            )
                                                        );
                                                    }
                                                }}
                                            >
                                                {origin.userId}
                                            </Link>
                                        )}
                                    </>
                                );
                            }
                        },
                        {
                            Header: 'Actions',
                            Cell: ({
                                row: { original: row }
                            }: CellProps<WorkoutSourceDto>) => (
                                <>
                                    <Link
                                        href="#"
                                        onClick={() => {
                                            onToggle(row);
                                        }}
                                    >
                                        {row.allowed ? 'Reject' : 'Approve'}
                                    </Link>
                                </>
                            )
                        }
                    ]}
                    onPageSizeChange={(size) =>
                        dispatch({ type: 'size', size })
                    }
                    onChangePage={(page) =>
                        void dispatch({ type: 'page', page })
                    }
                    onSearchChange={(val) => void setSearchTerm(val)}
                    initialSearch={initialSearch}
                    key={initialSearch}
                />
            </Paper>
        </Container>
    );
});
