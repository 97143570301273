import { useNavigate, useParams } from 'react-router';
import React from 'react';
import { FieldState, FormState } from 'formstate';
import { useWrapRequest } from 'use-wrap-request';
import { required, useModel, wrapInFormField } from '../../../core/forms';
import {
    AddressDto,
    CustomFacilityDetailsDto,
    CustomFacilitySearchDto,
    CustomFacilityUpdateDto
} from '../../../core/api/StudioClient';
import { Api } from '../../../core/api';
import { links } from '../../../core/router';
import { injectTSDI } from '../../../core/tsdi';

export function useStore({
                             internalData,
                             externalData
                         }: CustomFacilityDetailsDto) {
    const [selectedExternalDataIndex] = React.useState<number>(0);
    const { facilityId } = useParams();
    const { studioClient } = injectTSDI(Api);

    const navigate = useNavigate();

    const selectedExternalData = externalData.length
        ? externalData[selectedExternalDataIndex].data
        : undefined;

    const selectedDataName = externalData.length
        ? externalData[selectedExternalDataIndex].poiProvider
        : '';

    const additionalData = externalData.length
        ? externalData[selectedExternalDataIndex].additionalProperties
        : null;

    const model = useModel(() => {
        const form = new FormState({
            name: new FormState({
                internal: new FieldState<CustomFacilitySearchDto['name']>(
                    internalData.name
                ).validators(required()),
                external: new FieldState<string | undefined>(
                    selectedExternalData?.name
                )
            }),
            status: new FormState({
                internal: new FieldState<CustomFacilitySearchDto['status']>(
                    internalData.status
                ).validators(required())
            }),
            category: new FormState({
                internal: new FieldState<CustomFacilityUpdateDto['category']>(
                    (internalData.category
                        .value as CustomFacilityUpdateDto['category']) ||
                    'FITNESS_GYM'
                ).validators(required())
            }),
            address: new FormState({
                country: new FormState({
                    internal: new FieldState<AddressDto['country'] | undefined>(
                        internalData.address.country
                    ).validators(required()),
                    external: new FieldState<string | undefined>(
                        selectedExternalData?.address.country
                    )
                }),
                city: new FormState({
                    internal: new FieldState<string | undefined>(
                        internalData.address.city
                    ).validators(required()),
                    external: new FieldState<string | undefined>(
                        selectedExternalData?.address.city
                    )
                }),
                street: new FormState({
                    internal: new FieldState<string | undefined>(
                        internalData.address.street
                    ).validators(required()),
                    external: new FieldState<string | undefined>(
                        selectedExternalData?.address.street
                    )
                }),
                houseNumber: new FormState({
                    internal: new FieldState<string | undefined>(
                        internalData.address.houseNumber
                    ).validators(required()),
                    external: new FieldState<string | undefined>(
                        selectedExternalData?.address.houseNumber
                    )
                }),
                zip: new FormState({
                    internal: new FieldState<string | undefined>(
                        internalData.address.zip || internalData.address.zipCode
                    ).validators(required()),
                    external: new FieldState<string | undefined>(
                        selectedExternalData?.address.zip || selectedExternalData?.address.zipCode
                    )
                }),
                latitude: new FormState({
                    internal: new FieldState<number | undefined>(
                        internalData.address.latitude
                    ).validators(required()),
                    external: new FieldState<number | undefined>(
                        selectedExternalData?.address.latitude
                    )
                }),
                longitude: new FormState({
                    internal: new FieldState<number | undefined>(
                        internalData.address.longitude
                    ).validators(required()),
                    external: new FieldState<number | undefined>(
                        selectedExternalData?.address.longitude
                    )
                })
            })
        });
        const name = {
            internal: wrapInFormField(form.$.name.$.internal),
            external: wrapInFormField(form.$.name.$.external)
        };

        const status = {
            internal: wrapInFormField(form.$.status.$.internal)
        };

        const category = {
            internal: wrapInFormField(form.$.category.$.internal),
            external: null
        };

        const address = {
            country: {
                internal: wrapInFormField(form.$.address.$.country.$.internal),
                external: wrapInFormField(form.$.address.$.country.$.external)
            },
            city: {
                internal: wrapInFormField(form.$.address.$.city.$.internal),
                external: wrapInFormField(form.$.address.$.city.$.external)
            },
            street: {
                internal: wrapInFormField(form.$.address.$.street.$.internal),
                external: wrapInFormField(form.$.address.$.street.$.external)
            },
            houseNumber: {
                internal: wrapInFormField(
                    form.$.address.$.houseNumber.$.internal
                ),
                external: wrapInFormField(
                    form.$.address.$.houseNumber.$.external
                )
            },
            zip: {
                internal: wrapInFormField(form.$.address.$.zip.$.internal),
                external: wrapInFormField(form.$.address.$.zip.$.external)
            },
            latitude: {
                internal: wrapInFormField(form.$.address.$.latitude.$.internal),
                external: wrapInFormField(form.$.address.$.latitude.$.external)
            },
            longitude: {
                internal: wrapInFormField(
                    form.$.address.$.longitude.$.internal
                ),
                external: wrapInFormField(form.$.address.$.longitude.$.external)
            }
        };

        return {
            form,
            fields: {
                name,
                status,
                category,
                address
            }
        };
    });

    const facilityRejectRequest = useWrapRequest(
        async (id: number) => {
            const result = await studioClient.v1.customFacilityControllerReject(
                id
            );
            return result.data;
        },
        { successMessage: () => 'Facility was rejected successfully' }
    );

    const onRejectHandler = async () => {
        await facilityRejectRequest.request([Number(facilityId)]);
        navigate(links.facility());
    };

    const facilityVerifyRequest = useWrapRequest(
        async (id: number, data: CustomFacilityUpdateDto) => {
            const result = await studioClient.v1.customFacilityControllerUpdate(
                id,
                data
            );

            return result.data;
        },
        { successMessage: () => 'Facility was approved successfully' }
    );

    const onSubmitHandler = async () => {
        const validation = await model.form.validate();
        if (validation.hasError) {
            throw new Error(model.form.error as string);
        }
        try {
            await facilityVerifyRequest.request([
                Number(facilityId),
                {
                    name: model.fields.name.internal.value,
                    category: model.fields.category.internal.value,
                    contacts: [],
                    address: {
                        city: model.fields.address.city.internal.value,
                        street: model.fields.address.street.internal.value,
                        country: model.fields.address.country.internal.value,
                        houseNumber: model.fields.address.houseNumber.internal.value,
                        zip: model.fields.address.zip.internal.value,
                        latitude: model.fields.address.latitude.internal.value,
                        longitude: model.fields.address.longitude.internal.value
                    }
                }
            ]);

            await studioClient.v1.customFacilityControllerVerify(
                Number(facilityId)
            );

            navigate(links.facility());
        } catch (err) {
            // catches errors both in fetch and response.json
            alert(err);
        }
    };

    return {
        fields: model.fields,
        additionalData,

        selectedExternalData,
        onSubmitHandler,
        onRejectHandler,
        selectedDataName
    };
}
