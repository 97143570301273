import React from 'react';
import styled, { css } from 'styled-components';
import { Paper as MaterialPaper, PaperProps as MaterialPaperProps } from '@mui/material';

interface PaperProps extends MaterialPaperProps {
    noPadding?: boolean;
}

const StyledPaper = styled(MaterialPaper)<{ $noPadding?: boolean }>`
    ${({ $noPadding, theme }) =>
        !$noPadding &&
        css`
            padding: ${theme.spacing(3)};
        `}
`;

export const Paper = ({ noPadding, ...props }: PaperProps) => (
    <StyledPaper {...props} $noPadding={noPadding} />
);
