import { FieldState } from 'formstate';
import React from 'react';

export interface FormField<T> {
    value: T;
    fromInput: string;
    error: string | undefined;
    onChange(value: T): void;
    hasValue(value: T): boolean;
}

export function wrapInFormField<T>(field: FieldState<T>): FormField<T> {
    return {
        get value(): T {
            return field.value;
        },

        set value(value: T) {
            field.onChange(value);
        },

        /** use when setting values from input-fields */
        set fromInput(value: string) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            field.onChange(value as any);
        },

        get error(): string | undefined {
            return field.error;
        },

        onChange(value: T): void {
            field.onChange(value);
        },

        hasValue(value: T): boolean {
            return this.value === value;
        }
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fieldHasValue<T = any>(field: FormField<T>, val: T): boolean {
    return field.value === val;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function compare(valA: any, valB: any): boolean {
    if (valA === valB) {
        return true;
    }

    if (
        (valA === undefined || valA === null || valA === '') &&
        (valB === undefined || valB === null || valB === '')
    ) {
        return true;
    }

    return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasValue(val: any): boolean {
    return val !== null && val !== undefined;
}

export function useModel<T>(
    fromFn: () => T,

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props?: { onModel(model: any): void },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deps: any[] = []
) {
    const model = React.useMemo<T>(fromFn, deps);

    React.useEffect(() => {
        if (props) {
            props.onModel(model);
        }
    }, deps);

    return model;
}

export const required = <T = string | number | boolean | Date | undefined>(
    error: I18nKey = 'commons.validation.required'
) => (value: T) => {
    if (typeof value === 'number') {
        return isFinite(value) ? false : error;
    }

    return value ? false : error;
};

export const positive = <T = number | undefined>(
    error: I18nKey = 'commons.validation.amount.greater.than'
) => (value: T) => {
    if (typeof value === 'number') {
        return value > 0 ? undefined : error;
    }
    return undefined;
};

export const notNegative = <T = number | undefined>(
    error: I18nKey = 'commons.validation.amount.greater.than.equals'
) => (value: T) => {
    if (typeof value === 'number') {
        return value < 0 ? error : undefined;
    }
    return undefined;
};

export const validateEmail = (email: string | undefined) => {
    const mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email) {
        return 'Email is Required';
    }
    if (!email.match(mailFormat)) {
        return 'Invalid Email Format';
    }
    return undefined;
};

export const validateDate = (value: string | undefined) => {
    const expectedFormat = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
    if (value && !value.match(expectedFormat)) {
        return 'Wrong format, should be YYYY-MM-DD';
    }
    return undefined;
};

