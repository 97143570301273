import { observer } from 'mobx-react';
import { Box, Grid, Typography } from '@mui/material';
import { TextField } from '../../components/textfield';
import React from 'react';
import { useStore } from './store';
import { injectTSDI } from '../../core/tsdi';
import { I18n } from '../../core/i18n';
import { Button } from '../../components/button';
import { Option, Select } from '../../components/select';

interface CreateFacilityFormProps {
    studioCategories: Option[];
    countryOptions: Option[];
}

export const CreateFacilityForm = observer(
    ({
         studioCategories,
         countryOptions
     }: CreateFacilityFormProps) => {
        const { __ } = injectTSDI(I18n);

        const {
            fields,
            errors,
            facilityAddRequest,
            searchByLocationRequest,
            onSubmitHandler,
            tryPrefillForm
        } = useStore();

        const {
            googleLocation,
            name,
            category,
            address,
            location
        } = fields;

        const {
            city,
            zip,
            street,
            houseNumber,
            country
        } = address;
        const {
            latitude,
            longitude
        } = location;

        const requestIsRunning = facilityAddRequest.loading || searchByLocationRequest.loading;
        const isFormDisabled = requestIsRunning ||  Boolean(errors);

        const googleLocationHandler = React.useCallback(async (value: string) => {
            googleLocation.value = value;
            const worthToCallBackend = value.length > 30 && value.includes(',');
            if (worthToCallBackend) {
                await tryPrefillForm();
            }
        }, [googleLocation, tryPrefillForm]);

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Import from Google Maps</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={googleLocation.value}
                        onChange={googleLocationHandler}
                        type="text"
                        label="Location to import. For example: 50.03294547656358, 19.98998700748116"
                        error={
                            googleLocation.error &&
                            __(
                                googleLocation.error as I18nKey,
                                0
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">User Input</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={name.value}
                        onChange={name.onChange}
                        type="text"
                        label="Name"
                        disabled={requestIsRunning}
                        required
                        error={
                            name.error &&
                            __(
                                name.error as I18nKey,
                                0
                            )
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <Select
                        value={category.value}
                        onChange={category.onChange}
                        label="Category"
                        error={
                            category.error &&
                            __(
                                category.error as I18nKey,
                                0
                            )
                        }
                        options={studioCategories}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Address</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={city.value}
                        onChange={city.onChange}
                        type="text"
                        label="City"
                        disabled={requestIsRunning}
                        required
                        error={
                            city.error &&
                            __(
                                city.error as I18nKey,
                                0
                            )
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={street.value}
                        onChange={street.onChange}
                        type="text"
                        label="Street"
                        disabled={requestIsRunning}
                        required
                        error={
                            street.error &&
                            __(
                                street.error as I18nKey,
                                0
                            )
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        value={houseNumber.value}
                        onChange={houseNumber.onChange}
                        type="text"
                        label="House No."
                        disabled={requestIsRunning}
                        required
                        error={
                            houseNumber.error &&
                            __(
                                houseNumber.error as I18nKey,
                                0
                            )
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        value={zip.value}
                        onChange={zip.onChange}
                        type="text"
                        label="Zip code"
                        disabled={requestIsRunning}
                        required
                        error={
                            zip.error &&
                            __(
                                zip.error as I18nKey,
                                0
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Select
                        label="Country"
                        value={country.value}
                        onChange={country.onChange}
                        error={
                            country.error &&
                            __(
                                country.error as I18nKey,
                                0
                            )
                        }
                        options={countryOptions}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">GPS coordinates</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={latitude.value}
                        onChange={latitude.onChange}
                        type="text"
                        label="Latitude"
                        disabled={requestIsRunning}
                        required
                        error={
                            latitude.error &&
                            __(
                                latitude.error as I18nKey,
                                0
                            )
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={longitude.value}
                        onChange={longitude.onChange}
                        type="text"
                        label="Longitude"
                        disabled={requestIsRunning}
                        required
                        error={
                            longitude.error &&
                            __(
                                longitude.error as I18nKey,
                                0
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <Button
                            onClick={onSubmitHandler}
                            disabled={isFormDisabled}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
    });

