import { Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { UserContext } from '..';
import { Label } from '../../../components/label';
import { Tooltip } from '../../../components/tooltip';

export const UserDetails = observer(() => {
    const user = React.useContext(UserContext);
    console.log(user);
    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label>Name</Label>
                <Typography>
                    {user.firstName} {user.lastName}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Label>Email</Label>
                {user.emails.map((email) => (
                    <Tooltip title={email.value} hideIcon>
                        <Typography noWrap>{email.value}</Typography>
                    </Tooltip>
                ))}
            </Grid>

            <Grid item xs={4}>
                <Label>User ID</Label>
                <Typography>{user.id}</Typography>
            </Grid>
        </Grid>
    );
});

export default UserDetails;
