import { CssBaseline } from '@mui/material';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { interFont } from './theme';

const Style = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${interFont};
    background-color: #121212 !important;
    color: #fff !important;
  }
  a{
    color: #11b5ec;
    text-decoration: none;
  }
`;

export const GlobalStyles = () => (
    <>
        <CssBaseline />
        <Style />
    </>
);
