import { FieldState, FormState } from 'formstate';
import { useWrapRequest } from 'use-wrap-request';

import { CustomFacilityManualCreateDto, CustomFacilityUpdateDto } from '@api/StudioClient';
import { required, useModel, wrapInFormField } from '../../core/forms';
import { Api } from '../../core/api';
import { injectTSDI } from '../../core/tsdi';
import React from 'react';
import { links } from '../../core/router';
import { useNavigate } from 'react-router';

export function useStore() {
    const { studioClient } = injectTSDI(Api);
    const navigate = useNavigate();

    const facilityAddRequest = useWrapRequest(
        (data: CustomFacilityManualCreateDto) => studioClient.v1.customFacilityControllerCreateManually(
            data
        ),
        { successMessage: () => 'Facility created' }
    );

    const searchByLocationRequest = useWrapRequest(
        (location: string) => studioClient.v1.googleNearbySearchControllerDecodeExternalFacilityFromGoogleUrl(
            { location }
        ),
        { successMessage: () => 'Facility found' }
    );

    const model = useModel(() => {
        const form = new FormState({
            googleLocation: new FieldState<string>(''),
            name: new FieldState<string>('').validators(required()),
            category: new FieldState<CustomFacilityUpdateDto['category'] | undefined>('FITNESS_GYM')
                .validators(required()),
            address: new FormState({
                city: new FieldState<string>('').validators(required()),
                street: new FieldState<string>('').validators(required()),
                houseNumber: new FieldState<string>('').validators(required()),
                country: new FieldState<string | undefined>(undefined).validators(required()),
                zip: new FieldState<string>('').validators(required())
            }),
            location: new FormState({
                latitude: new FieldState<string>(
                    ''
                ).validators(required()),
                longitude: new FieldState<string>(
                    ''
                ).validators(required())
            })
        });
        return {
            form,
            fields: {
                googleLocation: wrapInFormField(form.$.googleLocation),
                name: wrapInFormField(form.$.name),
                category: wrapInFormField(form.$.category),
                address: {
                    city: wrapInFormField(form.$.address.$.city),
                    street: wrapInFormField(form.$.address.$.street),
                    houseNumber: wrapInFormField(form.$.address.$.houseNumber),
                    country: wrapInFormField(form.$.address.$.country),
                    zip: wrapInFormField(form.$.address.$.zip)
                },
                location: {
                    latitude: wrapInFormField(form.$.location.$.latitude),
                    longitude: wrapInFormField(form.$.location.$.longitude)
                }
            }
        };
    });

    const tryPrefillForm = React.useCallback(async () => {
        const {
            googleLocation,
            name,
            address,
            location
        } = model.fields;

        try {
            const result = await searchByLocationRequest.request([googleLocation.value]);
            name.value = result.data.name;
            address.street.value = result.data.street;
            address.houseNumber.value = result.data.houseNumber;
            address.city.value = result.data.city;
            address.zip.value = result.data.zip;
            address.country.value = result.data.countryCode;
            location.latitude.value = result.data.location.latitude.toString();
            location.longitude.value = result.data.location.longitude.toString();
        } catch (err) {
            // continue regardless of error
        }
    }, [model.fields, searchByLocationRequest]);

    const onSubmitHandler = React.useCallback(async () => {
        const validation = await model.form.validate();
        const {
            name,
            category,
            address,
            location
        } = model.fields;

        if (validation.hasError) {
            throw new Error(model.form.error as string);
        }
        try {
            const result = await facilityAddRequest.request([
                {
                    name: name.value,
                    category: category.value,
                    address: {
                        city: address.city.value,
                        street: address.street.value,
                        houseNumber: address.houseNumber.value,
                        country: address.country.value,
                        zip: address.zip.value,
                        latitude: location.latitude.value,
                        longitude: location.longitude.value
                    }
                }
            ]);
            navigate(links.facilityEdit(result.data.id));
        } catch (err) {
            alert(err);
        }
    }, [facilityAddRequest, model.fields, model.form]);

    return {
        fields: model.fields,
        errors: model.form.error,
        onSubmitHandler,
        tryPrefillForm,
        facilityAddRequest,
        searchByLocationRequest
    };
}
