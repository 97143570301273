import { useParams } from 'react-router';
import { useWrapRequest } from '../components/hooks/use-wrap-request';
import { Api } from '../core/api';
import { injectTSDI } from '../core/tsdi';

export function useStore() {
    const {
        generateCorporateClient,
        generateApiConfig,
        gatewayApiUrl,
        tenantClient
    } = injectTSDI(Api);

    const { tenantName } = useParams();

    const corporateClient = generateCorporateClient(
        generateApiConfig(gatewayApiUrl('corporate-api'), tenantName)
    );

    const tenantFeatures = useWrapRequest(
        async (tenantName) => {
            const company = await corporateClient.support.companySupportControllerGetCompanies();
            const tenant = tenantClient.admin.tenantAdminControllerGetTenant(
                tenantName
            );
            return { company: company.data, tenant: (await tenant).data };
        },

        { deps: [tenantName] }
    );

    return { corporateClient, tenantFeatures };
}
