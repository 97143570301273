import { Box, createTheme, List, ListItem, ListItemIcon, ListItemText, Paper, ThemeProvider } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { getPinColor, PinTypes } from './pins';

const lightTheme = createTheme({ palette: { mode: 'light' } });

interface LegendBox {
    type: PinTypes;
}

export const LegendBox = styled(Box)<LegendBox>`
    width: 20px;
    height: 20px;
    background-color: ${({ type }) => getPinColor(type)};
    border-radius: 4px;
`;

export const MarkerLegend = () => (
    <Box
        sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            minWidth: 160
        }}
    >
        <ThemeProvider theme={lightTheme}>
            <Paper elevation={2}>
                <List sx={{ padding: 1 }}>
                    <ListItem disablePadding>
                        <ListItemText primary="Primary" />
                        <ListItemIcon>
                            <LegendBox type="primary" />
                        </ListItemIcon>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary="External" />
                        <ListItemIcon>
                            <LegendBox type="source" />
                        </ListItemIcon>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary="Nearby" />
                        <ListItemIcon>
                            <LegendBox type="nearby-facility" />
                        </ListItemIcon>
                    </ListItem>
                </List>
            </Paper>
        </ThemeProvider>
    </Box>
);
