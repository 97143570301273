import React from 'react';
import { CellProps, SortingRule } from 'react-table';
import { useNavigate } from 'react-router';
import { Chip, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { injectTSDI } from '../core/tsdi';
import { Api } from '../core/api';
import { Paper } from '../components/paper';
import { ReactColumn, Table } from '../components/table';
import { TenantSimpleDto, TenantStatus } from '../core/api/TenantClient';
import { Button } from '../components/button';
import { links } from '../core/router';
import { Filters } from '../components/filters';
import { PageTitle } from '../components/page-title';
import { I18n } from '../core/i18n';
import { useStore } from './store';
import { TenantDeactivateModal } from './modals/tenant-deactivate-modal';
import { TenantActivateModal } from './modals/tenant-activate-modal';

export const TenantList = () => {
    const { corporateClient, googleUserAuth } = injectTSDI(Api);
    const { formatShortDate } = injectTSDI(I18n);
    const navigate = useNavigate();

    const {
        tenants,
        searchParams,
        statusFilter,
        typeFilter,
        sorting,
        productType,
        isCorporateProductType,
        isTeamsProductType,
        setSorting,
        setStatusFilter,
        setTypeFilter,
        setSearchTerm,
        dispatch
    } = useStore();

    const openDeactivateState = React.useState(false);
    const [tenantToDeactivate, setTenantToDeactivate] = React.useState<
        TenantSimpleDto | undefined
    >(undefined);
    const openActivateState = React.useState(false);
    const [tenantToActivate, setTenantToActivate] = React.useState<
        TenantSimpleDto | undefined
    >(undefined);

    const handleLogin = async (tenant: string) => {
        const session =
            await corporateClient.support.corporateSupportControllerCreateSession(
                {
                    email: googleUserAuth?.email,
                    firstName: googleUserAuth?.firstName,
                    lastName: googleUserAuth?.lastName
                },
                {
                    headers: {
                        'x-tenant-id': tenant
                    }
                }
            );
        if (session.data.redirectUrl) {
            window.open(session.data.redirectUrl, '_blank');
        }
    };

    const handleActivate = async (tenant: TenantSimpleDto) => {
        setTenantToActivate(tenant);
        const [, setOpen] = openActivateState;
        setOpen(true);
    };

    const handleDeactivate = async (tenant: TenantSimpleDto) => {
        setTenantToDeactivate(tenant);
        const [, setOpen] = openDeactivateState;
        setOpen(true);
    };
    const onSortingChange = (sorting?: SortingRule<object>) => {
        if (sorting) {
            if (sorting.id === 'status') {
                setSorting((previous) => {
                    if (!previous) {
                        return previous;
                    }
                    return Array.isArray(previous)
                        ? [sorting, ...previous]
                        : [sorting, previous];
                });
                return;
            }
            setSorting(sorting);
        }
    };

    const createTenantHandler = () => {
        navigate(links.tenantOnboarding());
    };

    const clearFilters = () => {
        setStatusFilter([]);
        setTypeFilter([]);
    };

    return (
        <>
            <PageTitle>Tenants</PageTitle>
            <TenantDeactivateModal
                openState={openDeactivateState}
                tenant={tenantToDeactivate}
                onSuccess={() =>
                    void tenants.request([
                        searchParams,
                        statusFilter,
                        typeFilter,
                        productType
                    ])
                }
            />
            <TenantActivateModal
                openState={openActivateState}
                tenant={tenantToActivate}
                onSuccess={() =>
                    void tenants.request([
                        searchParams,
                        statusFilter,
                        typeFilter,
                        productType
                    ])
                }
            />
            <Box display="flex" justifyContent="space-between">
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    pt={2}
                    pb={2}
                    width="100%"
                    maxWidth={500}
                >
                    <Button variant="text" onClick={() => void clearFilters()}>
                        <Box component="span" mr={1}>
                            Clear
                        </Box>
                        <ClearIcon fontSize="small" />
                    </Button>

                    <Filters<TenantSimpleDto['status'], true>
                        multiple
                        label="Status"
                        value={statusFilter}
                        options={[
                            { label: 'active', value: 'ACTIVE' },
                            { label: 'inactive', value: 'INACTIVE' },
                            { label: 'locked', value: 'LOCKED' }
                        ]}
                        onChange={(val) => void setStatusFilter(val)}
                    />

                    <Filters<TenantSimpleDto['type'], true>
                        multiple
                        label="Type"
                        value={typeFilter}
                        options={[
                            { label: 'client', value: 'CLIENT' },
                            { label: 'former client', value: 'FORMER_CLIENT' },
                            { label: 'BASE', value: 'MYSPORTS_BASE' },
                            { label: 'partner', value: 'PARTNER' },
                            { label: 'demo', value: 'DEMO' },
                            { label: 'open demo', value: 'OPEN_DEMO' },
                            { label: 'unmanaged', value: 'UNMANAGED' }
                        ]}
                        onChange={(val) => void setTypeFilter(val)}
                    />
                </Stack>
                {productType === 'TEAMS' && (
                    <Box display="flex" alignItems="center">
                        <Button
                            size="large"
                            endIcon={<AddIcon />}
                            onClick={createTenantHandler}
                            disabled={!productType}
                        >
                            Create tenant
                        </Button>
                    </Box>
                )}
            </Box>
            <Paper
                noPadding
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    width: '100%'
                }}
            >
                <Table<TenantSimpleDto[]>
                    items={tenants}
                    key={productType || 'NONE'}
                    tableLayout="auto"
                    columns={[
                        {
                            Header: 'Tenant ID',
                            accessor: 'name',
                            style: { width: 200 }
                        },
                        {
                            Header: 'Status',
                            accessor: 'status',
                            style: { width: 120 },
                            Cell: ({
                                row: {
                                    original: { status }
                                }
                            }: CellProps<TenantSimpleDto>) => (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    minHeight={42}
                                >
                                    <TenantStatusChip status={status} />
                                </Box>
                            )
                        },
                        {
                            Header: 'Type',
                            accessor: 'type',
                            Cell: ({
                                row: {
                                    original: { type }
                                }
                            }) => (
                                <>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        minHeight={42}
                                    >
                                        {type}
                                    </Box>
                                </>
                            )
                        },
                        {
                            Header: 'Source',
                            accessor: 'source',
                            Cell: ({
                                row: {
                                    original: { source }
                                }
                            }: CellProps<TenantSimpleDto>) => (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    minHeight={42}
                                >
                                    <Typography
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        {getTenantSourceName(source)}
                                    </Typography>
                                </Box>
                            )
                        },
                        ...(isTeamsProductType
                            ? [
                                  {
                                      Header: 'Licenses',
                                      accessor: 'allowedLicenses',
                                      disableSortBy: true,
                                      Cell: ({
                                          row: {
                                              original: { allowedLicenses }
                                          }
                                      }: CellProps<TenantSimpleDto>) => (
                                          <Typography
                                              align="center"
                                              variant="body2"
                                          >
                                              {allowedLicenses}
                                          </Typography>
                                      )
                                  } as ReactColumn<TenantSimpleDto>
                              ]
                            : []),
                        ...(isCorporateProductType
                            ? [
                                  {
                                      Header: 'Contract',
                                      accessor: 'contractModel',
                                      disableSortBy: true,
                                      style: { width: 120 }
                                  } as ReactColumn<TenantSimpleDto>
                              ]
                            : []),
                        {
                            Header: 'Employees',
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            accessor: 'company.connectedEmployeeCount' as any,
                            Cell: ({
                                row: {
                                    original: { connectedEmployeeCount }
                                }
                            }: CellProps<TenantSimpleDto>) => (
                                <Typography align="center" variant="body2">
                                    {connectedEmployeeCount}
                                </Typography>
                            )
                        },
                        {
                            Header: 'Created On',
                            accessor: 'createdDate',
                            Cell: ({
                                row: {
                                    original: { createdDate }
                                }
                            }: CellProps<TenantSimpleDto>) => (
                                <Typography variant="body2">
                                    {formatShortDate(createdDate)}
                                </Typography>
                            )
                        },
                        {
                            Header: '',
                            id: 'button',
                            style: { width: 100 },
                            Cell: ({
                                row: {
                                    original: { name, status }
                                }
                            }: CellProps<TenantSimpleDto>) => (
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    minHeight={42}
                                >
                                    {status !== 'LOCKED' && (
                                        <Button
                                            variant="text"
                                            onClick={() =>
                                                void handleLogin(name)
                                            }
                                        >
                                            <Box component="span" mr={1}>
                                                Login
                                            </Box>
                                            <ExitToAppIcon fontSize="small" />
                                        </Button>
                                    )}
                                </Box>
                            )
                        }
                    ]}
                    contextMenu={[
                        {
                            label: 'Details',
                            hidden: (value) => value.status === 'LOCKED',
                            action: (value) => {
                                navigate(links.tenantDetails(value.name));
                            }
                        },
                        {
                            label: 'Activate',
                            hidden: (value) => value.status !== 'INACTIVE',
                            action: (value) => {
                                handleActivate(value);
                            }
                        },
                        {
                            label: 'Deactivate',
                            hidden: (value) => value.status !== 'ACTIVE',
                            action: (value) => {
                                handleDeactivate(value);
                            }
                        }
                    ]}
                    onPageSizeChange={(size) =>
                        dispatch({ type: 'size', size })
                    }
                    onChangePage={(page) =>
                        void dispatch({ type: 'page', page })
                    }
                    onSearchChange={(val) => void setSearchTerm(val)}
                    onSortingChange={onSortingChange}
                    sorting={Array.isArray(sorting) ? sorting[0] : sorting}
                />
            </Paper>
        </>
    );
};

interface TenantStatusChipProps {
    status: TenantSimpleDto['status'];
}

export const getTenantSourceName = (source?: TenantSimpleDto['source']) => {
    switch (source) {
        case 'ADMIN_WEBCLIENT':
            return 'admin tool';
        case 'TRY_CORPORATE_WEBCLIENT':
            return 'try corporate';
        default:
            return source;
    }
};

const TenantStatusChip = ({ status }: TenantStatusChipProps) => {
    switch (status) {
        case TenantStatus.ACTIVE:
            return <Chip label={status} color="success" />;
        case TenantStatus.INACTIVE:
            return <Chip label={status} color="warning" />;
        case TenantStatus.LOCKED:
            return <Chip label={status} color="error" />;
    }
};

export default TenantList;
