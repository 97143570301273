import React from 'react';
import { Alert, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { Box } from '@mui/system';
import { TenantCorporateContext } from '..';
import { Button } from '../../components/button';
import { links } from '../../core/router';

interface DeleteModalProps {
    open?: boolean;
    onModalClose?(): void;
}

export const DeleteModal = ({
    open: initialOpen = false,
    onModalClose
}: DeleteModalProps) => {
    const [open, setOpen] = React.useState(initialOpen);
    const [tenantField, setTenantField] = React.useState('');
    const corporateClient = React.useContext(TenantCorporateContext);
    const { tenantName } = useParams();
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
        onModalClose?.();
    };
    React.useEffect(() => {
        setOpen(initialOpen);
    }, [initialOpen]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTenantField(event.target.value);
    };

    const handleDelete = async () => {
        try {
            await corporateClient.support.tenantSupportControllerDeleteTenant(
                tenantName
            );
            navigate(links.tenants());
        } catch (error) {
            handleClose();
        }
    };
    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth="sm">
                <DialogContent>
                    <Typography variant="h6" gutterBottom>
                        Are you absolutely sure?
                    </Typography>
                    <Alert severity="warning">
                        Unexpected bad things will happen if you don’t read
                        this!
                    </Alert>
                    <Typography pt={2}>
                        This action cannot be undone. This will permanently
                        delete the <strong>{tenantName}</strong> tenant, users,
                        employees, payout runs etc.
                    </Typography>
                    <Typography pt={2}>
                        Please type <strong>{tenantName}</strong> to confirm.
                    </Typography>
                    <Typography pt={2}></Typography>
                    <TextField
                        type="text"
                        fullWidth
                        required
                        value={tenantField}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Box ml={2} mr={2} flex={1}>
                        <Button
                            fullWidth
                            onClick={handleDelete}
                            color="error"
                            disabled={tenantField !== tenantName}
                        >
                            Delete
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
};
