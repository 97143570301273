import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { CellProps } from 'react-table';
import { useWrapRequest } from 'use-wrap-request';
import { TenantCorporateContext } from '..';
import { userSearchDebounce } from '../../components/hooks/use-search';
import { useSearchReducer } from '../../components/hooks/useSearchReducer';
import { Table } from '../../components/table';
import { EmployeeDto } from '../../core/api/CorporateClient';
import { Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { links } from '../../core/router';
import { useSnackbar } from 'notistack';

export const TenantEmployees = () => {
    const { enqueueSnackbar } = useSnackbar();
    const corporateClient = React.useContext(TenantCorporateContext);
    const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
        size: 30,
        page: 0
    });
    const { setSearchTerm } = userSearchDebounce(dispatch);
    const navigate = useNavigate();
    const { tenantName } = useParams();

    const employees = useWrapRequest(
        async (searchParams) => {
            const result =
                await corporateClient.support.employeeSupportControllerGetPage(
                    searchParams
                );

            return { ...result.data, content: result.data.content || [] };
        },
        {
            defaultData: { content: [] },
            deps: [searchParams]
        }
    );

    const copyEmail = (email: string) => {
        navigator.clipboard.writeText(email);
        enqueueSnackbar(`${email} was copied to the clipboard`, {
            variant: 'success',
            autoHideDuration: 2000
        });
    };

    return (
        <Box m={-2} mt={1}>
            <Table<EmployeeDto[]>
                items={employees}
                contextMenu={[
                    {
                        label: 'Details',
                        hidden: (value) => !value.id,
                        action: (value) => {
                            if (value.id) {
                                navigate(
                                    links.tenantEmployeeDetails(
                                        tenantName,
                                        value.id.toString()
                                    )
                                );
                            }
                        }
                    },
                    {
                        label: 'Activities',
                        hidden: (value) => !value.id,
                        action: (value) => {
                            if (value.id) {
                                navigate(
                                    links.tenantEmployeeActivities(
                                        tenantName,
                                        value.id.toString()
                                    )
                                );
                            }
                        }
                    },
                    {
                        label: 'Facilities',
                        hidden: (value) => !value.id,
                        action: (value) => {
                            if (value.id) {
                                navigate(
                                    links.tenantEmployeeFacilities(
                                        tenantName,
                                        value.id.toString()
                                    )
                                );
                            }
                        }
                    }
                ]}
                columns={[
                    { Header: 'First Name', accessor: 'firstName' },
                    { Header: 'Last Name', accessor: 'lastName' },
                    {
                        Header: 'Email',
                        style: { width: '50%' },
                        Cell: ({
                            row: {
                                original: { email }
                            }
                        }: CellProps<EmployeeDto>) => (
                            <Box display="flex">
                                <Tooltip title={email} placement="top-start">
                                    <Button
                                        onClick={() => void copyEmail(email)}
                                    >
                                        <Typography
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            {email}
                                        </Typography>
                                    </Button>
                                </Tooltip>
                            </Box>
                        )
                    }
                ]}
                onSearchChange={(val) => void setSearchTerm(val)}
                onPageSizeChange={(size) => dispatch({ type: 'size', size })}
                onChangePage={(page) => void dispatch({ type: 'page', page })}
            />
        </Box>
    );
};

export default TenantEmployees;
