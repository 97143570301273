import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useWrapRequest } from 'use-wrap-request';
import AddIcon from '@mui/icons-material/Add';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { CellProps } from 'react-table';
import { useNavigate } from 'react-router';
import { Api } from '../../core/api';
import { FacilitySearchSimpleDto } from '../../core/api/StudioClient';
import { Badge, BadgeType } from '../../components/badge';
import { injectTSDI } from '../../core/tsdi';
import { links } from '../../core/router';
import { Paper } from '../../components/paper';
import { Table } from '../../components/table';
import { Filters } from '../../components/filters';
import { useSearchReducer } from '../../components/hooks/useSearchReducer';
import { PageTitle } from '../../components/page-title';
import { userSearchDebounce } from '../../components/hooks/use-search';
import { Button } from '../../components/button';

function getStatusBadgeType(
    status: FacilitySearchSimpleDto['status']
): BadgeType {
    switch (status) {
        case 'DELETED':
            return 'red';
        case 'VERIFIED':
            return 'green';
        default:
        case 'NEW':
            return 'grey';
    }
}

function getFacilityBadgeType(
    status: FacilitySearchSimpleDto['type']
): BadgeType {
    switch (status) {
        case 'MAGICLINE_STUDIO':
            return 'green';
        default:
        case 'CUSTOM':
            return 'blue';
    }
}

type StatusFiltersType = FacilitySearchSimpleDto['status'] | 'ALL';
type FacilityFiltersType = FacilitySearchSimpleDto['type'] | 'ALL';

export const FacilityList = observer(() => {
    const navigate = useNavigate();
    const { studioClient } = injectTSDI(Api);
    const [statusFilter, setStatusFilter] =
        React.useState<StatusFiltersType>('NEW');
    const [facilityTypeFilter, setFacilityTypeFilter] =
        React.useState<FacilityFiltersType>('ALL');
    const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
        size: 30,
        page: 0
    });

    const { setSearchTerm } = userSearchDebounce(dispatch);

    const facilities = useWrapRequest(
        async (searchParams, statusFilter, facilityTypeFilter) => {
            const result = await studioClient.v1.facilityControllerGetPage({
                ...searchParams,
                ...(statusFilter !== 'ALL' ? { status: statusFilter } : {}),
                ...(facilityTypeFilter !== 'ALL'
                    ? { type: facilityTypeFilter }
                    : {})
            });

            return { ...result.data, content: result.data.content || [] };
        },
        { defaultData: { content: [] } }
    );

    const createFacilityHandler = React.useCallback(() => {
        navigate(links.facilityCreate());
    }, [navigate]);

    React.useEffect(() => {
        facilities.request([searchParams, statusFilter, facilityTypeFilter]);
    }, [searchParams]);

    React.useEffect(() => {
        dispatch({ type: 'page', page: 0 });
    }, [statusFilter]);

    React.useEffect(() => {
        dispatch({ type: 'page', page: 0 });
    }, [facilityTypeFilter]);

    return (
        <>
            <PageTitle>Unverified facilities</PageTitle>
            <Box display="flex" justifyContent="space-between">
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    pt={2}
                    pb={2}
                    width="100%"
                    maxWidth={400}
                >
                    {facilityTypeFilter !== 'MAGICLINE_STUDIO' && (
                        <Filters<StatusFiltersType, false>
                            label="Status"
                            value={statusFilter}
                            width={180}
                            options={[
                                { label: 'all', value: 'ALL' },
                                { label: 'new', value: 'NEW' },
                                { label: 'verified', value: 'VERIFIED' },
                                { label: 'deleted', value: 'DELETED' }
                            ]}
                            onChange={(val) => void setStatusFilter(val)}
                        />
                    )}
                    <Filters<FacilityFiltersType, false>
                        label="Type"
                        value={facilityTypeFilter}
                        width={180}
                        options={[
                            { label: 'all', value: 'ALL' },
                            { label: 'custom', value: 'CUSTOM' },
                            { label: 'magicline', value: 'MAGICLINE_STUDIO' }
                        ]}
                        onChange={(val) => {
                            if (val === 'MAGICLINE_STUDIO') {
                                setStatusFilter('ALL');
                            }
                            setFacilityTypeFilter(val);
                        }}
                    />
                </Stack>

                <Box display="flex" alignItems="center">
                    <Button
                        size="large"
                        endIcon={<AddIcon />}
                        onClick={createFacilityHandler}
                    >
                        Create facility
                    </Button>
                </Box>
            </Box>
            <Paper noPadding>
                <Table<FacilitySearchSimpleDto[]>
                    emptyText="No entries"
                    items={facilities}
                    columns={[
                        {
                            Header: 'Studio Id',
                            accessor: (value) => value.id
                        },
                        {
                            Header: 'Name',
                            Cell: ({
                                row: {
                                    original: { address, name }
                                }
                            }: CellProps<FacilitySearchSimpleDto>) => (
                                <Box display="flex" flexDirection="column">
                                    <Typography variant="body1" gutterBottom>
                                        {name}
                                    </Typography>
                                    <Typography variant="caption" gutterBottom>
                                        {address.city}
                                        {address.street &&
                                            `, ${address.street}`}
                                        {address.houseNumber &&
                                            ` ${address.houseNumber}, `}
                                        {address.zip && `${address.zip}, `}
                                        {address.country &&
                                            `${address.country}`}
                                    </Typography>
                                </Box>
                            )
                        },
                        {
                            Header: 'Category',
                            accessor: (value) => value.category.label
                        },
                        {
                            Header: 'Status',
                            Cell: ({
                                row: {
                                    original: { status }
                                }
                            }: CellProps<FacilitySearchSimpleDto>) => (
                                <Badge type={getStatusBadgeType(status)}>
                                    {status}
                                </Badge>
                            )
                        },
                        {
                            Header: 'Type',
                            Cell: ({
                                row: {
                                    original: { type }
                                }
                            }: CellProps<FacilitySearchSimpleDto>) => (
                                <Badge type={getFacilityBadgeType(type)}>
                                    {type}
                                </Badge>
                            )
                        },
                        {
                            Header: '',
                            accessor: 'id',
                            Cell: ({
                                row: {
                                    original: { id, status }
                                }
                            }: CellProps<FacilitySearchSimpleDto>) => (
                                <Link to={links.facilityEdit(id)}>
                                    {status === 'NEW' ? 'Verify' : 'Edit'}
                                </Link>
                            ),
                            style: { textAlign: 'right' }
                        }
                    ]}
                    onPageSizeChange={(size) =>
                        dispatch({ type: 'size', size })
                    }
                    onChangePage={(page) =>
                        void dispatch({ type: 'page', page })
                    }
                    onSearchChange={(val) => void setSearchTerm(val)}
                />
            </Paper>
        </>
    );
});

export default FacilityList;
