import { Button as MaterialButton, ButtonProps as MaterialButtonProps } from '@mui/material';
import React from 'react';
import { Spinner } from '../spinner';

interface ButtonProps {
    className?: string;
    children: React.ReactNode;
    loading?: boolean;
    color?: MaterialButtonProps['color'];
    size?: MaterialButtonProps['size'];
    type?: 'submit' | 'button' | 'reset';
    fullWidth?: boolean;
    disabled?: boolean;
    variant?: 'text' | 'contained' | 'outlined' | undefined;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
}

export function Button(props: ButtonProps) {
    const {
        color = 'primary',
        fullWidth = false,
        size = 'medium',
        variant = 'contained',
        disabled,
        className,
        type,
        startIcon,
        endIcon,
        onClick
    } = props;
    return (
        <MaterialButton
            fullWidth={fullWidth}
            variant={variant}
            className={className}
            color={color}
            type={type}
            size={size}
            onClick={onClick}
            disabled={disabled}
            startIcon={startIcon}
            endIcon={endIcon}
            disableElevation
        >
            {props.loading ? <Spinner color="white" /> : props.children}
        </MaterialButton>
    );
}
