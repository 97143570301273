import AddIcon from '@mui/icons-material/Add';
import { Card, CardContent, Chip, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import React from 'react';
import { Table } from '../../../components/table';
import { AccountOperationDto } from '../../../core/api/WalletClient';
import { I18n } from '../../../core/i18n';
import { injectTSDI } from '../../../core/tsdi';
import { useStore } from './store';

import { Cancel, ChangeCircle, CheckCircle } from '@mui/icons-material';
import { SortingRule } from 'react-table';
import { Button } from '../../../components/button';
import { OperationCorrectionModal } from './operation-correction-modal';
import { OperationDeleteModal } from './operation-reject-modal';

export const UserWallet = observer(() => {
    const {
        wallet,
        selectedAccount,
        walletAccountOperations,
        dispatch,
        setSorting,
        searchParams,
        sorting
    } = useStore();
    const { formatDateTime } = injectTSDI(I18n);
    const openDeleteState = React.useState(false);
    const [selectedOperation, setSelectedOperation] = React.useState<
        AccountOperationDto | undefined
    >(undefined);
    const openCorrectionState = React.useState(false);

    const handleDelete = async (operation: AccountOperationDto) => {
        setSelectedOperation(operation);
        const [, setOpen] = openDeleteState;
        setOpen(true);
    };
    // Todo(mjur): add sorting
    const onSortingChange = (sorting?: SortingRule<object>) => {
        if (sorting) {
            setSorting(sorting);
        }
    };
    return (
        <>
            <OperationDeleteModal
                openState={openDeleteState}
                operation={selectedOperation}
                onSuccess={async () => {
                    void wallet.request();
                    void walletAccountOperations.request([
                        selectedAccount,
                        searchParams
                    ]);
                }}
            />
            <OperationCorrectionModal
                onSuccess={async () => {
                    void wallet.request();
                    void walletAccountOperations.request([
                        selectedAccount,
                        searchParams
                    ]);
                }}
                selectedAccount={selectedAccount}
                openState={openCorrectionState}
            />
            <Box>
                <Typography variant="h4" mb={1}>
                    Accounts
                </Typography>
                {wallet.match({
                    fetched: (wallet) => (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'grid',
                                gridTemplateColumns:
                                    'repeat(auto-fill, minmax(min(200px, 100%), 1fr))',
                                gap: 2
                            }}
                        >
                            {wallet.accounts.map((account) => {
                                return (
                                    <Card
                                        elevation={2}
                                        sx={{
                                            backgroundColor: '#090909FF',
                                            marginBottom: 2
                                        }}
                                    >
                                        <CardContent sx={{ height: '100%' }}>
                                            <Typography variant="h4" mb={1}>
                                                {account.currency.code}{' '}
                                                {account.currency.symbol}
                                            </Typography>
                                            {Object.entries(
                                                account.balances
                                            ).map(([balanceKey, balance]) => (
                                                <Box display="flex">
                                                    <AccountBalanceIcon
                                                        balanceKey={balanceKey}
                                                    />
                                                    <Typography
                                                        variant="body2"
                                                        component="span"
                                                        color="text.secondary"
                                                        mb={0.8}
                                                    >
                                                        {balanceKey}:{' '}
                                                        {balance.value.value}
                                                        {
                                                            balance.value
                                                                .currency.symbol
                                                        }
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </CardContent>
                                    </Card>
                                );
                            })}
                        </Box>
                    )
                })}
            </Box>
            <Divider sx={{ marginBottom: 3, marginTop: 2 }} />
            <Box>
                <Box display="flex" justifyContent="space-between" mb={1}>
                    <Typography variant="h4" marginBottom={2}>
                        Operations
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <Button
                            size="large"
                            endIcon={<AddIcon />}
                            onClick={() => {
                                const [__, setOpenCorrectionState] =
                                    openCorrectionState;
                                setOpenCorrectionState(true);
                            }}
                        >
                            Add correction
                        </Button>
                    </Box>
                </Box>
                {wallet.$?.accounts.length > 1 ? (
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" component="span" mr={1}>
                            Accounts Filter: {''}
                        </Typography>
                        {wallet.$?.accounts.map((account) => (
                            <Chip
                                size="small"
                                label={account.currency.code}
                                variant={
                                    selectedAccount === account.id
                                        ? 'filled'
                                        : 'outlined'
                                }
                                onClick={() => {}}
                            />
                        ))}{' '}
                    </Box>
                ) : (
                    <Box />
                )}
            </Box>
            <Box>
                <Table<AccountOperationDto[]>
                    items={walletAccountOperations}
                    tableLayout="auto"
                    columns={[
                        {
                            Header: 'Date',
                            id: 'createdDate',
                            accessor: (value) =>
                                formatDateTime(value.timestamp),
                            style: { width: 100 }
                        },
                        {
                            Header: 'Value',
                            id: 'value',
                            accessor: (value) =>
                                `${value.value.value} ${value.value.currency.symbol}`,
                            disableSortBy: true
                        },
                        {
                            Header: 'Money Status',
                            id: 'status',
                            accessor: (value) => (
                                <Box display="flex">
                                    <AccountBalanceIcon
                                        balanceKey={value.moneyStatus}
                                    />
                                    {value.moneyStatus}
                                </Box>
                            ),
                            disableSortBy: true
                        },
                        {
                            Header: 'Reverted',
                            id: 'deleted',
                            accessor: (operation) =>
                                operation.deleted ? (
                                    <Box display="flex">
                                        <Cancel
                                            fontSize="small"
                                            color="error"
                                            sx={{ marginRight: 0.5 }}
                                        />{' '}
                                        Reverted
                                    </Box>
                                ) : null,
                            disableSortBy: true
                        }
                    ]}
                    onPageSizeChange={(size) =>
                        dispatch({ type: 'size', size })
                    }
                    onChangePage={(page) =>
                        void dispatch({ type: 'page', page })
                    }
                    // Todo(mjur): add sorting
                    onSortingChange={onSortingChange}
                    sorting={Array.isArray(sorting) ? sorting[0] : sorting}
                    contextMenu={[
                        {
                            label: 'Revert',
                            hidden: (value) => value.deleted,
                            action: (value) => {
                                handleDelete(value);
                            }
                        }
                    ]}
                />
            </Box>
        </>
    );
});

export function AccountBalanceIcon({ balanceKey }: { balanceKey: string }) {
    switch (balanceKey) {
        case 'APPROVED':
            return (
                <CheckCircle
                    fontSize="small"
                    color="success"
                    sx={{ marginRight: 0.5 }}
                />
            );
        case 'PENDING':
            return (
                <ChangeCircle
                    fontSize="small"
                    color="info"
                    sx={{ marginRight: 0.5 }}
                />
            );
        case 'REJECTED':
            return (
                <Cancel
                    fontSize="small"
                    color="error"
                    sx={{ marginRight: 0.5 }}
                />
            );

        default:
            return <Box component="span" width={20} />;
    }
}

export default UserWallet;
