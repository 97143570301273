import React from 'react';
import { SortingRule } from 'react-table';
import { UserContext } from '..';
import { useWrapRequest } from '../../../components/hooks/use-wrap-request';
import { useSearchReducer } from '../../../components/hooks/useSearchReducer';
import { Api } from '../../../core/api';
import { injectTSDI } from '../../../core/tsdi';

export function useStore() {
    const { walletClient } = injectTSDI(Api);

    const user = React.useContext(UserContext);

    const [selectedAccount, setSelectedAccount] = React.useState<
        number | undefined
    >(undefined);

    const [sorting, setSorting] = React.useState<
        SortingRule<string> | SortingRule<string>[] | undefined
    >({ id: 'createdDate', desc: false });

    React.useEffect(() => {
        if (sorting) {
            return dispatch({ type: 'sort', sort: sorting });
        }
    }, [sorting]);

    const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
        size: 30,
        page: 0
    });

    const wallet = useWrapRequest(
        async () => {
            const result =
                await walletClient.supportApi.walletSupportControllerGetUserWalletDetails(
                    user.id.toString()
                );

            return result.data;
        },
        { deps: [] }
    );
    const walletAccountOperations = useWrapRequest(
        async (
            selectedAccount: number | undefined,
            searchParams?: QueryParamsDto
        ) => {
            if (!selectedAccount) {
                return { content: [] };
            }
            const result =
                await walletClient.supportApi.walletSupportControllerGetAccountOperations(
                    selectedAccount,
                    searchParams
                );

            return { ...result.data, content: result.data.content || [] };
        },
        {
            deps: [selectedAccount, searchParams],
            defaultData: {
                content: [],
                number: 0,
                size: 0,
                totalPages: 0,
                totalElements: 0
            }
        }
    );

    React.useEffect(() => {
        if (wallet.$ && wallet.$.accounts.length > 0) {
            setSelectedAccount(wallet.$.accounts[0].id);
        }
    }, [wallet.$]);

    return {
        wallet,
        walletAccountOperations,
        selectedAccount,
        setSelectedAccount,
        searchParams,
        sorting,
        setSorting,
        dispatch
    };
}
