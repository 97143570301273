import { CircularProgress } from '@mui/material';
import React from 'react';

interface SpinnerProps {
    color?: 'white';
}

export function Spinner(props: SpinnerProps) {
    return (
        <CircularProgress className={props.color} color="primary" size={24} />
    );
}
