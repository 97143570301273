import { Box, Container, Stack } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useParams } from 'react-router';
import { PageTitle } from '../../components/page-title';
import { Spinner } from '../../components/spinner';
import { injectTSDI } from '../../core/tsdi';
import { VendorDetailsStore } from './store';
import VendorProductList from '../vendor-product-list';

export const VendorDetails = observer(() => {
    const { vendorId } = useParams();
    const { vendor } = injectTSDI(VendorDetailsStore);
    React.useEffect(() => {
        vendor.request(Number(vendorId));
    }, [vendorId]);

    return (
        <Container maxWidth="xl" sx={{ padding: [0, 0, 0] }}>
            {vendor.match({
                loading: () => (
                    <Box display="flex" justifyContent="center" minHeight={300}>
                        <Spinner />
                    </Box>
                ),
                fetched: (vendor) => (
                    <>
                        <Stack
                            flexDirection="row"
                            justifyContent="space-between"
                        >
                            <PageTitle>
                                {vendor.name}
                            </PageTitle>
                        </Stack>
                        <VendorProductList/>
                    </>
                )
            })}
        </Container>
    );
});
