import { debounce, DebouncedFunc, DebounceSettings } from 'lodash';
import React from 'react';

export function useDebouncedCallback<T extends (...args: any[]) => any>(
    callback: T,
    delay = 0,
    options?: DebounceSettings
): DebouncedFunc<T> {
    return React.useCallback(debounce(callback, delay, options), [
        callback,
        delay,
        options
    ]);
}

export function useDebounce<T>(
    value: T,
    delay = 0,
    options?: DebounceSettings
): T {
    const previousValue = React.useRef(value);
    const [current, setCurrent] = React.useState(value);
    const debouncedCallback = useDebouncedCallback(
        (value: T) => setCurrent(value),
        delay,
        options
    );
    React.useEffect(() => {
        // does trigger the debounce timer initially
        if (value !== previousValue.current) {
            debouncedCallback(value);
            previousValue.current = value;
            // cancel the debounced callback on clean up
            return debouncedCallback.cancel;
        }
        return () => null;
    }, [value]);

    return current;
}
