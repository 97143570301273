import { Box, IconButton, Tooltip as MaterialTooltip, TooltipProps as MaterialTooltipProps } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';
import styled from 'styled-components';

interface TooltipProps extends Omit<MaterialTooltipProps, 'children'> {
    children?: React.ReactElement<any, any>;
    hideIcon?: boolean;
}

const StyledIconButton = styled(IconButton)`
    padding: 0;
    margin-right: 2px;
`;

export const Tooltip = ({ children, ...props }: TooltipProps) => (
    <MaterialTooltip {...props} placement="top-start">
        <Box display="flex" alignItems="center">
            {!props.hideIcon && (
                <StyledIconButton>
                    <InfoIcon viewBox="0 -2 27 27" />
                </StyledIconButton>
            )}
            {children}
        </Box>
    </MaterialTooltip>
);
