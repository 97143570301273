import { createTheme } from '@mui/material';

export const interFont = [
    'Inter',
    '"Helvetica Neue"',
    '-apple-system',
    'Arial',
    'sans-serif'
].join(',');

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#4ABBBD'
        },
        background: {
            default: '#121212'
        },
        text: {
            primary: '#fff'
        },

        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2
    },

    typography: {
        fontFamily: interFont,
        button: {
            textTransform: 'none'
        },
        h1: {
            fontWeight: 600,
            fontSize: '3rem'
        },
        h2: {
            fontWeight: 600,
            fontSize: '2.5rem'
        },
        h3: {
            fontWeight: 600,
            fontSize: '1.875rem'
        },
        h4: {
            fontWeight: 600,
            fontSize: '1.5rem'
        },
        h5: {
            fontWeight: 600,
            fontSize: '1.25rem'
        },
        h6: {
            fontWeight: 600,
            fontSize: '1.125rem'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    boxShadow: 'none'
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                title: {
                    fontSize: '1rem'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    minHeight: 60
                }
            }
        }
    }
});

theme.shadows[1] =
    'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px';

type Theme = typeof theme;

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'styled-components' {
    export interface DefaultTheme extends Theme {}
}
/* eslint-enable  @typescript-eslint/no-empty-interface */

export { theme, Theme };
